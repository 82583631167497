import {
  ManagerGetAccountPortfolioSnapshotResponse,
  getAccountPortfolioSnapshot,
  setAccountPortfolios,
} from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { TotalWithdrawProps } from './total-withdraw.types';
import { mapAccountPortfoliosSnapshotToData } from '../../../../../../utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

/**
 * useTotalWithdrawData hook
 * @description The hook which processes ClosePortfolio flow TotalWithdraw stage data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useTotalWithdrawData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { withdrawPortfolioTotalWithdraw },
    dispatch,
  } = useModalsContext();
  const { user, dispatch: appDispatch } = useAppContext();
  const navigate = useNavigate();

  const [accountPortfolioSnapshot, setAccountPortfolioSnapshot] =
    useState<ManagerGetAccountPortfolioSnapshotResponse>();

  const flowData: TotalWithdrawProps | undefined = useMemo(
    () => withdrawPortfolioTotalWithdraw,
    [withdrawPortfolioTotalWithdraw],
  );

  const fetchAccountSnapshot = useCallback(async () => {
    if (!accountPortfolioSnapshot && withdrawPortfolioTotalWithdraw) {
      const { data } = await getAccountPortfolioSnapshot({
        acctId: withdrawPortfolioTotalWithdraw.portfolio.PortfolioOnAccountID,
      });

      const portfolioData = data?.find(
        ({ PortfolioID }) => PortfolioID === withdrawPortfolioTotalWithdraw.portfolio.PortfolioID,
      );

      portfolioData && setAccountPortfolioSnapshot(portfolioData);
    }
  }, [accountPortfolioSnapshot, withdrawPortfolioTotalWithdraw]);

  useEffect(() => {
    fetchAccountSnapshot();
  }, [fetchAccountSnapshot]);

  const handleCloseModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.withdrawPortfolioTotalWithdraw });
  }, [dispatch]);

  const handleSubmitTotalWithdraw = useCallback(async () => {
    if (user && flowData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { message } = await setAccountPortfolios({
          role: user.isLoggedInRole,
          roleLoginEmail: user.email,
          portfoliosDoc: {
            AccountID: flowData.portfolio.PortfolioOnAccountID,
            ClientEmailOwner: flowData.accountEmailOwner,
            ReOpenPortfolios: [],
            OpenPortfolios: [],
            ClosePortfolios: [
              {
                PortfolioID: flowData.portfolio.PortfolioID,
                PortfolioMember: flowData.portfolio.PortfolioMember,
                PortfolioOwner: flowData.portfolio.PortfolioOwner,
              },
            ],
            ChangePortfolios: [],
          },
        });

        if (message === 'ok') {
          flowData.onRefreshParticipants();

          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              id: ModalIds.withdrawPortfolioSuccess,
              data: {
                withdrawPortfolioSuccess: {
                  onSubmitSuccess: () => {
                    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.withdrawPortfolioSuccess });
                    navigate(
                      `/management/information/account/information?accountId=${flowData?.portfolio.PortfolioOnAccountID}`,
                    );
                  },
                },
              },
            },
          });
        }
      } catch (error) {
        toast.error('Failed to process set account portfolios request!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      handleCloseModal();
    }
  }, [appDispatch, dispatch, flowData, handleCloseModal, navigate, user]);

  const snapShotData = useMemo(
    () => accountPortfolioSnapshot && mapAccountPortfoliosSnapshotToData([accountPortfolioSnapshot]),
    [accountPortfolioSnapshot],
  );

  return {
    t,
    flowData,
    snapShotData,
    handleSubmitTotalWithdraw,
    handleCloseModal,
  };
};

export { useTotalWithdrawData };
