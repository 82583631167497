import { FC } from 'react';
import { useEditData } from './edit.hook';

/**
 * Edit component
 * @description Exclusively for the AutomatedOrdersConfirmation as its Edit flow.
 * @link https://www.figma.com/file/pCwb00CHfXi1s4Yd5q3q0U/Trader--%7C-File-for-development?type=design&node-id=1786-117682&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Edit: FC = () => {
  const { stages, currentStage } = useEditData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { Edit };
