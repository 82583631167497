import * as React from 'react';

import { IconProps } from './icon.props';
import classNames from 'classnames';
import styles from './icon.module.scss';

/**
 * Renders Icon with base path at assets
 */
const Icon: React.FC<IconProps> = ({ classes, checker, name, onClick, cursorDefault }) => {
  return (
    <img
      className={classNames(styles.icon, classes, {
        [styles.iconChecker]: checker,
        [styles.iconCursor]: cursorDefault,
      })}
      src={require(`../../../../../assets/images/reports/${name}`)}
      alt={'icon'}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role={'button'}
      tabIndex={0}
      onKeyDown={() => onClick && onClick()}
      onClick={() => onClick && onClick()}
    />
  );
};

export { Icon };
