import { useEffect, useState } from 'react';

import { useSettings } from '../../../contexts/settings.context';

const useExpandableRowHook = () => {
  const [state] = useSettings();
  const [expanded, setExpanded] = useState(state.expanded);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(state.expanded);
  }, [state.expanded]);

  return { expanded, handleExpand };
};

export { useExpandableRowHook };
