import React, { FC } from 'react';

import { ManagerProps } from './manager.types';
import { Typography } from '../../../../../../components';
import classNames from 'classnames';
import styles from './manager.module.scss';

/**
 * Manager component.
 * @description Progress bar of portfolio
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 * @param { ManagerProps } props - ManagerProps defined in the './manager.types.ts'
 */
const Manager: FC<ManagerProps> = ({ value, maxValue, variant }) => {
  const growthMax = (value / maxValue) * 100;
  const rubicon = 50; // magic number, after which we should switch display of percentage

  return (
    <div className={classNames(styles.progressBar, styles[variant])}>
      <div
        className={classNames(styles.grow, styles[variant])}
        style={{ width: growthMax.toFixed(0).toString().concat('%') }}
      />
      {variant === 'main' && (
        <div className={classNames(styles.percentage, growthMax >= rubicon && styles.percentageDark)}>
          <Typography.Number variant={'label3'} percent value={Number(growthMax.toFixed(0))} />
        </div>
      )}
    </div>
  );
};

export { Manager };
