import { Confirmation, Summary } from './components';
import { PlaceProps, PlaceStages } from './place.types';
import React, { useCallback, useMemo } from 'react';

import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { useModalsContext } from '../../../../../../contexts';

/**
 * usePlaceData hook
 * @description The hook which processes Order Place flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const usePlaceData = () => {
  const {
    data: { orderPlace },
    dispatch,
  } = useModalsContext();

  const flowData: PlaceProps | undefined = useMemo(() => orderPlace, [orderPlace]);

  const setStage = useCallback(
    (stage: PlaceProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.orderPlace,
          data: {
            [ModalIds.orderPlace]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<PlaceProps['currentStage'], () => void> = useMemo(
    () => ({
      [PlaceStages.summary]: () => {
        setStage(PlaceStages.confirmation);
      },
      [PlaceStages.confirmation]: async () => {
        flowData?.onSubmitPlace && flowData.onSubmitPlace();
      },
    }),
    [flowData, setStage],
  );

  const stages: Record<PlaceProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            [PlaceStages.summary]: (
              <Summary
                onClose={() => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.orderPlace })}
                onSubmit={submitHandlers.summary}
                summaryRecommendations={flowData.recommendations.map(
                  ({ account, cardDescription, id, order, orderDescription, symbol }) => ({
                    account,
                    cardDescription,
                    id,
                    order,
                    orderDescription,
                    symbol,
                  }),
                )}
              />
            ),
            [PlaceStages.confirmation]: (
              <Confirmation
                onClose={() => {
                  dispatch({ type: 'HIDE_MODAL', payload: ModalIds.orderPlace });
                }}
                onSubmit={submitHandlers.confirmation}
              />
            ),
          }
        : undefined,
    [dispatch, flowData, submitHandlers.confirmation, submitHandlers.summary],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { usePlaceData };
