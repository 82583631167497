import dayjs from 'dayjs';

/** Rubicon width, if less - make more space between ticks. */
const MAX_DISTINGUISH_WIDTH = 1570;

const currencyFormatter = (value: number, round = 0): string => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    useGrouping: false,
    maximumFractionDigits: round,
    minimumFractionDigits: round,
  });

  const formatted = formatter.format(value).replace(/\B(?=(\d{3})+(?!\d))/g, String.fromCharCode(8201));

  return formatted;
};

const dateFormatter = (pointLength: number, value: string): string | undefined => {
  switch (true) {
    case pointLength < 262:
      return dayjs(value).format('DD/MM');
    case pointLength >= 262 && pointLength <= 524:
      return dayjs(value).format('MMMM YYYY');
    case pointLength > 524:
      return dayjs(value).format('YYYY');
    default:
      return dayjs(value).format('DD/MM');
  }
};

const tickValues = (pointLength: number, windowWidth?: number) => {
  switch (true) {
    case pointLength < 131 && (windowWidth ?? 0) < MAX_DISTINGUISH_WIDTH:
      return 'every 2 weeks';
    case pointLength < 131:
      return 'every 1 week';
    case pointLength < 262:
      return 'every 1 month';
    case pointLength >= 262 && pointLength <= 524:
      return 'every 4 month';
    case pointLength > 524:
      return 'every 1 year';
    default:
      return 'every 2 weeks';
  }
};

const percentFormatter = (value: number, round = 0): string => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'percent',
    useGrouping: false,
    maximumFractionDigits: round,
    minimumFractionDigits: round,
  });

  const formatted = formatter.format(value * -1);

  return formatted;
};

export { tickValues, dateFormatter, currencyFormatter, percentFormatter };
