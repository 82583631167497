import { useCallback, useMemo } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * useTwoFaChangedData hook.
 * @description The hook which processes TwoFaChanged modal window data
 *
 * @author Oleksii Medvediev
 */
const useTwoFaChangedData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { twoFaChanged },
    dispatch,
  } = useModalsContext();

  const modalData = useMemo(() => twoFaChanged, [twoFaChanged]);

  const handleCloseModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.twoFaChanged });
  }, [dispatch]);

  return {
    modalData,
    t,
    handleCloseModal,
  };
};

export { useTwoFaChangedData };
