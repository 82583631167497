import * as React from 'react';
import styles from './row.module.scss';

import { TableRowProps } from './row.props';
import classNames from 'classnames';

const TableRow: React.FC<TableRowProps> = ({ children, onClick, border, classes }) => {
  return (
    <tr
      onClick={onClick && onClick}
      className={classNames(classes, {
        [styles.rowClickable]: !!onClick,
        [styles.rowBorder]: border,
      })}
    >
      {children}
    </tr>
  );
};

export { TableRow };
