import React, { FC } from 'react';

import { NavLink } from 'react-router-dom';
import { Tab } from '../tab';
import { TabMenuProps } from './tab-menu.types';
import classNames from 'classnames';
import styles from './tab-menu.module.scss';
import { PillTabProps } from '../tab/pill-tab/pill-tab.types';

const renderTabItem = (
  item: TabMenuProps['tabs'][0],
  size: PillTabProps['size'],
  key?: string,
): Record<TabMenuProps['menuType'], JSX.Element> => ({
  underline: <Tab.Underline {...item} />,
  pill: <Tab.Pill {...item} size={size ?? 'small'} key={key} />,
});

/**
 * TabMenu component
 * @description The horizontal menu with switchable tabs
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { TabMenuProps } props - TabMenuProps defined in the './tab-menu.types.ts'
 */
const TabMenu: FC<TabMenuProps> = ({ tabs, menuType, className, size }) => (
  <div className={classNames(styles.wrapper, className)}>
    {tabs.map((item, index) =>
      item.link ? (
        <NavLink key={item.link.concat(item.label ?? index.toString())} to={item.link} className={styles.link}>
          {renderTabItem({ ...item, isSelected: item.isSelected }, size)[menuType]}
        </NavLink>
      ) : (
        renderTabItem(item, size, item.label ?? index.toString())[menuType]
      ),
    )}
  </div>
);

export { TabMenu };
