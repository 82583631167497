import { useCallback, useMemo } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { OrderDeclinationProps } from './order-declination.types';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * useOrderDeclinationData hook
 * @description The hook which processes OrderDeclination modal data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useOrderDeclinationData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { orderDeclination },
    dispatch,
  } = useModalsContext();

  const flowData: OrderDeclinationProps | undefined = useMemo(() => orderDeclination, [orderDeclination]);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.orderDeclination }),
    [dispatch],
  );

  return {
    t,
    currentType: useMemo(() => flowData?.currentType, [flowData?.currentType]),
    handleCloseModal,
  };
};

export { useOrderDeclinationData };
