import React, { FC, useCallback } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../components';
import styles from './branding-success-modal.module.scss';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * BrandingSuccess component
 * @description Content for the BrandingSuccess window
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const BrandingSuccess: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { dispatch } = useModalsContext();

  const closeModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.brandingSuccess });
  }, [dispatch]);

  return (
    <Modal modalId={ModalIds.brandingSuccess} title={t('settings-page.modals.branding.success.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>{t('settings-page.modals.branding.success.text')}</Typography.Text>
        <Button size={'large'} variant={'main'} onClick={closeModal} className={styles.button}>
          <Typography.Text variant={'bodyText2'}>
            {t('settings-page.modals.branding.success.buttonText')}
          </Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { BrandingSuccess };
