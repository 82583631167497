import { FC } from 'react';
import { useEditData } from './edit.hook';

/**
 * Edit component
 * @description Content for the UsersManagement Edit flow.
 * @link https://www.figma.com/file/PiyMHA6tRkvaEpfgDnzUPI/Administrator--%7C-File-for-development?type=design&node-id=1525-49342&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Edit: FC = () => {
  const { stages, currentStage } = useEditData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { Edit };
