import { useCallback, useMemo } from 'react';

import { SuccessProps } from './success.types';
import { useModalsContext } from '../../../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * useSuccessData hook
 * @description The hook which processes ReplenishPortfolio flow Success data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useSuccessData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { transferBetweenPortfoliosSuccess },
  } = useModalsContext();

  const flowData: SuccessProps | undefined = useMemo(
    () => transferBetweenPortfoliosSuccess,
    [transferBetweenPortfoliosSuccess],
  );

  const handleSubmitSuccess = useCallback(() => {
    flowData && flowData.onSubmitSuccess();
  }, [flowData]);

  return {
    t,
    flowData,
    handleSubmitSuccess,
  };
};

export { useSuccessData };
