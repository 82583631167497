import { Area, ComposedChart, ReferenceDot, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import React, { FC, useState } from 'react';

import { PaymentChartProps } from './payment-chart.types';
import dayjs from 'dayjs';
import styles from './payment-chart.module.scss';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

/**
 * PaymentChart component
 * @description Display payment chart
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 * @param { ActiveChartProps } props - PaymentChartProps defined in the './payment-chart.types.ts';
 */
const PaymentChart: FC<PaymentChartProps> = ({ chartData, projectedChartData, chartLabel }) => {
  const dotId = v4();
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const formatXTick = (tick: string) => {
    return `${t(dayjs(tick).format('MMMM')).slice(0, 3)}. ${dayjs(tick).format('YYYY')}`;
  };

  const [customLabelCoordinates, setCustomLabelCoordinates] = useState<Record<'x' | 'y', number>>();

  const CustomLabel = (props: {
    readonly viewBox: {
      readonly x: number;
      readonly y: number;
    };
  }) => (
    <svg
      x={(customLabelCoordinates?.x ?? 0) - 68}
      y={(customLabelCoordinates?.y ?? 0) - 18 - 8}
      width="68"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.762451" width="66.5263" height="18" rx="2" fill="#30BB70" />
      <text
        x={props.viewBox.x + 5}
        y={props.viewBox.y - 42.5}
        r={0}
        fill="#FFFFFF"
        dy={15}
        dx={10}
        fontSize={12}
        fontWeight={400}
      >
        {`$${String(chartLabel)?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`}
      </text>
    </svg>
  );

  const composedChart = projectedChartData?.map((item, index) => ({
    ...item,
    Feesvalue: (chartData ?? [])[index]?.Feesvalue,
  }));

  return (
    <div className={styles.paymentChart}>
      <div className={styles.container} style={{ height: '93px' }}>
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <ComposedChart height={93} data={composedChart} margin={{ top: 27, right: 0, bottom: 0, left: 0 }}>
            <defs>
              <linearGradient id={'Green'} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
                <stop offset={'0%'} stopColor={'#30BB70'} stopOpacity={0.8} />
                <stop offset={'100%'} stopColor={'#30BB70'} stopOpacity={0} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id={'White'} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
                <stop offset={'0%'} stopColor={'#F5F5F5'} stopOpacity={0.3} />
                <stop offset={'100%'} stopColor={'#F5F5F5'} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey={'Date'}
              interval={'preserveStartEnd'}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value: string) => formatXTick(value)}
              style={{
                textTransform: 'capitalize',
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: 400,
              }}
            />
            <YAxis hide />
            <ReferenceDot
              x={composedChart && composedChart[0]?.Date}
              y={composedChart && composedChart[0]?.Feesvalue}
              r={0}
              label={CustomLabel}
            />
            <Area
              type={'linear'}
              strokeDasharray={'none'}
              strokeWidth={'1px'}
              dataKey={'Feesvalue'}
              fill={'url(#Green)'}
              stroke={'#30BB70'}
              isAnimationActive={false}
              dot={(props) => {
                const { cx, cy, payload } = props;
                const lastObjectWithFees = composedChart
                  ?.slice()
                  .reverse()
                  .find((obj) => obj.hasOwnProperty('Feesvalue') && obj.Feesvalue !== undefined);

                if (payload === lastObjectWithFees) {
                  (customLabelCoordinates?.x !== cx || customLabelCoordinates?.y !== cy) &&
                    setCustomLabelCoordinates({ x: cx, y: cy });
                  return (
                    <svg
                      key={payload.Date.toString()}
                      className={styles.dot}
                      id={dotId}
                      x={cx - 5.5}
                      y={cy - 5}
                      xmlns={'http://www.w3.org/2000/svg'}
                      width={'11'}
                      height={'10'}
                      viewBox={'0 0 11 10'}
                      fill={'none'}
                    >
                      <path
                        d={
                          'M10.4184 4.79581C10.4184 7.28913 8.15833 9.31036 5.37044 9.31036C2.58254 9.31036 0.32251 7.28913 0.32251 4.79581C0.32251 2.30249 2.58254 0.28125 5.37044 0.28125C8.15833 0.28125 10.4184 2.30249 10.4184 4.79581Z'
                        }
                        fill={'#30BB70'}
                      />
                      <ellipse cx={'5.37025'} cy={'4.79606'} rx={'2.01917'} ry={'1.80582'} fill={'#F5F5F5'} />
                    </svg>
                  );
                }

                return <span key={v4()} />;
              }}
            />
            <Area
              type={'linear'}
              dataKey={'Projectedvalue'}
              stroke={'#F6F8FB'}
              strokeWidth={'0.5px'}
              strokeDasharray={'1'}
              fillOpacity={0.5}
              fill={'url(#White)'}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export { PaymentChart };
