import classNames from 'classnames';
import styles from './number.module.scss';
import { NumberProps } from './number.types';

/**
 * usePasswordRecoveryHook
 * @description logic of number props
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Hooks
 * @returns modified number
 */
const useNumberHook = (props: NumberProps) => {
  let finalValue = props?.value?.toString();
  let numberValue = props.value;

  if (props.round || props.round === 0) {
    numberValue = numberValue && +numberValue.toFixed(props.round);
    finalValue = numberValue?.toFixed(props.round);
  }

  if (props.currency) {
    const currency =
      numberValue &&
      Math.abs(numberValue)
        .toFixed(props.round || 0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    finalValue = `${Number(numberValue) < 0 ? '-' : ''}$${currency}`;
  }

  if (props.percent) {
    finalValue = `${finalValue}%`;
  }

  if (props.sign) {
    let sign = '';
    if (numberValue && numberValue > 0) {
      sign = '+';
    } else if (numberValue && numberValue < 0) {
      sign = '-';

      finalValue = finalValue && finalValue.toString().replace('-', '');
    }

    finalValue = `${sign}${finalValue}`;
  }

  const colorizeClass = classNames({
    [styles.positive]: numberValue && numberValue > 0 && props.colorize,
    [styles.negative]: numberValue && numberValue < 0 && props.colorize,
    [styles.neutral]: numberValue === 0 && props.colorize,
  });

  return { finalValue, colorizeClass };
};

export { useNumberHook };
