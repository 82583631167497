import { Dropdown, Indicator, Typography } from '../../../../../components';
import React, { FC, useState } from 'react';

import { AvailableLocales } from '../../../../../localization';
import { Button } from '../button';
import { ReactComponent as ChevronUpIcon } from '../../../assets/icons/angle-up.svg';
import { HeaderProps } from './header.types';
import { ReactComponent as LogOutIcon } from '../../../assets/icons/exit.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/setting.svg';
import { ReactComponent as UAIcon } from '../../../assets/icons/flag-ua.svg';
import { ReactComponent as USIcon } from '../../../assets/icons/flag-us.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import { changeLanguage } from '../../../../../services/api';
import classNames from 'classnames';
import styles from './header.module.scss';
import { toast } from 'react-toastify';
import { useAppContext } from '../../contexts';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

/**
 * Header component
 * @description App's header
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { HeaderProps } props - HeaderProps defined in the './header.component.tsx'
 */
const Header: FC<HeaderProps> = ({
  isBrokerConnectionEstablished,
  isInvestudioConnectionEstablished,
  onLogout,
  currentRole,
  logoSrc,
  companyName,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const [languageIsOpened, setLanguageIsOpened] = useState(false);
  const { user, dispatch } = useAppContext();

  const handleLanguageChange = async (value: AvailableLocales) => {
    try {
      if (!user) return;

      dispatch({ type: 'TOGGLE_IS_LOADING' });

      const { data } = await changeLanguage({
        email: user.email,
        language: value,
      });
      data && dispatch({ type: 'SET_LANGUAGE', payload: data.language });
      toast.success('Language changed successfully!');
    } catch (error) {
      toast.error('Failed to process change language request!');
      console.error(error);
    }

    dispatch({ type: 'TOGGLE_IS_LOADING' });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <img src={logoSrc} alt={'company-logo'} />
        <Typography.Text variant={'bodyText2'} className={styles.companyName}>
          {companyName}
        </Typography.Text>
      </div>
      <div className={styles.innerWrapper}>
        {currentRole && (
          <div className={styles.currentRole}>
            <UserIcon />
            <Typography.Text variant={'bodyText3'}>{t(`roles.${currentRole}`)}</Typography.Text>
          </div>
        )}
        <div className={styles.connections}>
          <Indicator.Connection
            isConnected={isInvestudioConnectionEstablished}
            connectionName={'TradeVision connection'}
          />
          <Indicator.Connection isConnected={isBrokerConnectionEstablished} connectionName={'Broker connection'} />
        </div>
        <div className={styles.controls}>
          <Button
            type={'button'}
            variant={'main'}
            size={'medium'}
            onClick={() => navigate('/management/settings')}
            className={styles.control}
          >
            <SettingsIcon />
          </Button>
          <Dropdown
            forcePositionLeft
            className={styles.languages}
            button={
              <>
                {user?.preferences.language === AvailableLocales.UK ? <UAIcon /> : <USIcon />}
                <Typography.Text variant="bodyText4">
                  {user?.preferences.language === AvailableLocales.UK ? 'UA' : 'EN'}
                </Typography.Text>
                <ChevronUpIcon className={classNames(styles.chevron, languageIsOpened ? '' : styles.rotate)} />
              </>
            }
            options={[
              {
                id: AvailableLocales.UK,
                value: '',
                leadingIcon: (
                  <>
                    <UAIcon />
                    <Typography.Text variant="bodyText5" className={styles.langTypo}>
                      {t('languages.ukrainian')}
                    </Typography.Text>
                  </>
                ),
              },
              {
                id: AvailableLocales.EN,
                value: '',
                leadingIcon: (
                  <>
                    <USIcon />
                    <Typography.Text variant="bodyText5" className={styles.langTypo}>
                      {t('languages.english')}
                    </Typography.Text>
                  </>
                ),
              },
            ]}
            onChange={(locale) => {
              handleLanguageChange(locale as AvailableLocales);
            }}
            onToggle={(status) => {
              setLanguageIsOpened(status);
            }}
          />
          <Button type={'button'} variant={'main'} onClick={onLogout} size={'medium'} className={styles.control}>
            <LogOutIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export { Header };
