import { Action, Frame, PortfolioState } from './portfolio.types';

/**
 * @description InitialState for the PortfolioContext
 *
 * @author Oleksii Medvediev
 */
const initialState: PortfolioState = {
  yieldPeriod: Frame.month,
};

/**
 * @description Reducer function for the useReducer hook in the PortfolioContextProvider component.
 *
 * @author Oleksii Medvediev
 * @param {PortfolioState} state - State defined in the './portfolio.types.ts'
 * @param {Action} action - Action defined in the './portfolio.types.ts'
 * @returns updated PortfolioContextProvider state.
 */
const portfolioReducer = (state: PortfolioState, action: Action): PortfolioState => {
  switch (action.type) {
    case 'SET_PORTFOLIO': {
      return {
        ...state,
        portfolio: action.payload,
      };
    }

    case 'SET_PORTFOLIO_PERFORMANCE_OLD': {
      return {
        ...state,
        performanceOld: action.payload,
      };
    }

    case 'SET_PORTFOLIO_ASSETS': {
      return {
        ...state,
        assets: action.payload,
      };
    }

    case 'SET_PORTFOLIO_ALLOCATION': {
      return {
        ...state,
        allocation: action.payload,
      };
    }

    case 'SET_PORTFOLIO_PERFORMANCE': {
      return {
        ...state,
        performance: action.payload,
      };
    }

    case 'SET_PORTFOLIO_INCOME_F1': {
      return {
        ...state,
        incomeF1: action.payload,
      };
    }

    case 'SET_PORTFOLIO_INCOME_F2': {
      return {
        ...state,
        incomeF2: action.payload,
      };
    }

    case 'SET_YIELD_PERIOD': {
      return {
        ...state,
        yieldPeriod: action.payload,
      };
    }

    case 'SET_PORTFOLIO_CHART': {
      return {
        ...state,
        portfolioChart: action.payload,
      };
    }

    case 'SET_FRAMES': {
      return {
        ...state,
        frames: action.payload,
      };
    }

    case 'SET_SELECTED_YIELD_OPTION': {
      return {
        ...state,
        selectedYieldOption: action.payload,
      };
    }

    case 'SET_TICKERS_SHORT_INFO': {
      return {
        ...state,
        tickersShortInfo: action.payload,
      };
    }

    case 'OPEN_NOTIFICATION_DRAWER': {
      return { ...state, isNotificationDrawerOpened: true };
    }

    case 'CLOSE_NOTIFICATION_DRAWER': {
      return { ...state, isNotificationDrawerOpened: false, notifications: undefined };
    }

    case 'SET_NOTIFICATIONS': {
      return { ...state, notifications: action.payload };
    }

    case 'SET_TRADE_EVENTS': {
      return {
        ...state,
        operations: action.payload,
      };
    }

    case 'SET_CASHFLOW': {
      return {
        ...state,
        cashflow: action.payload,
      };
    }

    case 'SET_FINANCIAL_RESULT': {
      return {
        ...state,
        financialResult: action.payload,
      };
    }

    case 'SET_FINANCIAL_RESULT_ERROR': {
      return {
        ...state,
        financialResultError: action.payload,
      };
    }

    default:
      return state;
  }
};

export { initialState, portfolioReducer };
