import {
  ChangeColorThemePayload,
  ChangeColorThemeResponse,
  ChangeLanguagePayload,
  ChangeLanguageResponse,
  ChangePasswordPayload,
  GetTradeVisionConnectionStatusResponse,
  GetUnreadMessagesCountPayload,
  GetUnreadMessagesCountResponse,
  SetMessagesStatusReadPayload,
  ToggleTwoFaPayload,
  ToggleTwoFaResponse,
} from './office.model';

import { MoUser } from '../auth-mo';
import { request } from '../../http';

export const changePassword = (data: ChangePasswordPayload) =>
  request<{ readonly data: MoUser }>({ method: 'post', data, url: 'office/change-password' });

export const changeLanguage = (data: ChangeLanguagePayload) =>
  request<{ readonly data: ChangeLanguageResponse }>({ method: 'post', data, url: 'office/change-language' });

export const changeColorTheme = (data: ChangeColorThemePayload) =>
  request<{ readonly data: ChangeColorThemeResponse }>({ method: 'post', data, url: 'office/change-color-theme' });

export const toggle2Fa = (data: ToggleTwoFaPayload) =>
  request<{ readonly data: ToggleTwoFaResponse }>({ method: 'post', data, url: 'office/toggle-two-fa' });

export const getTradeVisionConnectionStatus = (email?: string) =>
  request<{ readonly data: GetTradeVisionConnectionStatusResponse }>({
    method: 'get',
    url: 'office/connection-status',
    params: { email },
  });

export const getEmailTransporterStatus = () =>
  request<{ readonly data?: { readonly connection: boolean } }>({
    method: 'get',
    url: 'office/email-transporter-status',
  });

export const getUnreadMessagesCount = (params: GetUnreadMessagesCountPayload) =>
  request<{ readonly data: GetUnreadMessagesCountResponse }>({
    method: 'get',
    url: 'office/unread-messages-count',
    params,
  });

export const setAllMessagesReadStatus = (params: SetMessagesStatusReadPayload) =>
  request({
    method: 'post',
    url: 'office/set-messages-status-read',
    params,
  });
