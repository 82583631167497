import {
  AcceptRecommendationsPayload,
  AcceptRecommendationsResponse,
  GetRecommendationsResponse,
  GetTradingModuleConnectionStatusResponse,
  RejectRecommendationsPayload,
  RejectRecommendationsResponse,
} from './ibkr-tws.model';

import { request } from '../../http';

export const getRecommendations = ({ accountsIds }: { readonly accountsIds: ReadonlyArray<string> }) =>
  request<{ readonly data: GetRecommendationsResponse }>({
    method: 'get',
    url: 'ibkr-tws/recommendations',
    params: { ids: accountsIds },
  });

export const acceptRecommendations = (data: AcceptRecommendationsPayload) =>
  request<{ readonly data: AcceptRecommendationsResponse }>({
    method: 'post',
    data,
    url: 'ibkr-tws/accept-recommendation',
  });

export const rejectRecommendations = (data: RejectRecommendationsPayload) =>
  request<{ readonly data: RejectRecommendationsResponse }>({
    method: 'post',
    data,
    url: 'ibkr-tws/reject-recommendation',
  });

export const getTradingModuleConnectionStatus = () =>
  request<{ readonly data: GetTradingModuleConnectionStatusResponse }>({
    method: 'get',
    url: 'ibkr-tws/connection-status',
  });

export const reinitializeIBTWSModule = () =>
  request<{ readonly data: { readonly status: 'success' | 'failed' } }>({
    method: 'post',
    url: 'ibkr-tws/reinitialize',
  });
