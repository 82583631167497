import { Chart } from './chart';
import { DatePicker } from './date-picker';
import { Settings } from './settings';

const Tab = {
  Chart,
  DatePicker,
  Settings,
};

export { Tab };
