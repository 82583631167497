import React, { FC } from 'react';

import { AssetTileBodyProps } from './asset-tile-body.types';
import { Typography } from '../../../../../../../components';
import classNames from 'classnames';
import dayjs from 'dayjs';
import styles from './asset-tile-body.module.scss';

/**
 * AssetTileBody component
 * @description AssetTileBody of AssetTile
 *
 * @author Vitalii Bodnarchuk, Ihor Dubas, Oleksii Medvediev
 * @category Components
 * @param { AssetTileBodyProps } props - AssetTileBodyProps defined in the './asset-tile-body.types.ts'
 */
const AssetTileBody: FC<AssetTileBodyProps> = ({
  Commissions,
  CommissionsValue,
  DateOpened,
  EarnedIncome,
  ExpirationDate,
  LastPrice,
  OpenPrice,
  OpenPriceValue,
  OrderType,
  PositionStatus,
  Return,
  ReturnValue,
  TypePosition,
  LastPriceValue,
}: AssetTileBodyProps) => (
  <div className={styles.assetTileBody}>
    <div className={classNames(styles.block, styles.pb18)}>
      <div className={classNames(styles.row, styles.gray)}>
        <Typography.Text variant={'label2'}>{dayjs(DateOpened).format('DD/MM/YYYY')}</Typography.Text>
        <Typography.Text variant={'label2'}>{OrderType}</Typography.Text>
      </div>
      <Typography.Text variant={'bodyText5'}>{Return}</Typography.Text>
    </div>
    <div className={classNames(styles.block, styles.pb16)}>
      <Typography.Text variant={'label2'}>{TypePosition}</Typography.Text>
      <Typography.Number variant={'h7'} value={Number(ReturnValue)} sign currency />
    </div>
    <div className={classNames(styles.block, styles.pb12)}>
      <Typography.Text variant={'bodyText5'} className={styles.gray}>
        {ExpirationDate}
      </Typography.Text>
      <Typography.Text
        variant={'bodyText5'}
        className={classNames(
          styles.row,
          PositionStatus === 'CLOSED' ? '' : EarnedIncome.includes('-') ? styles.red : styles.green,
        )}
      >
        {EarnedIncome}
      </Typography.Text>
    </div>
    <div className={classNames(styles.block, styles.pb12)}>
      <Typography.Text variant={'bodyText5'} className={styles.gray}>
        {OpenPrice}
      </Typography.Text>
      <Typography.Text variant={'bodyText5'}>{OpenPriceValue}</Typography.Text>
    </div>
    <div className={classNames(styles.block, styles.pb12)}>
      <Typography.Text variant={'bodyText5'} className={styles.gray}>
        {LastPrice}
      </Typography.Text>
      <Typography.Text variant={'bodyText5'}>{LastPriceValue}</Typography.Text>
    </div>
    <div className={classNames(styles.block, styles.pb24, styles.last)}>
      <Typography.Text variant={'bodyText5'} className={styles.gray}>
        {Commissions}
      </Typography.Text>
      <Typography.Number variant={'bodyText5'} value={Number(CommissionsValue)} currency sign round={2} />
    </div>
    <hr className={styles.hr} />
  </div>
);

export { AssetTileBody };
