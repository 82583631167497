import React, { FC } from 'react';

import { AssetChart } from '../../..';
import { AssetTileBaseProps } from './asset-tile-base.types';
import { Typography } from '../../../../../../../components';
import classNames from 'classnames';
import { environment } from '../../../../../../../environment';
import styles from './asset-tile-base.module.scss';

/**
 * AssetTileBase component
 * @description AssetTileBase of AssetTile
 *
 * @author Ihor Dubas, Oleksii Medvediev, Sergii Goncharuk
 * @category Components
 * @param { AssetTileBaseProps } props - AssetTileBaseProps defined in the './asset-tile-base.types.ts'
 */
const AssetTileBase: FC<AssetTileBaseProps> = ({
  Logo,
  Name,
  TitleReservedCapital,
  ReservedCapital,
  TitleExpectedROI,
  ExpectedROI,
  TitleGain,
  OptionsGain,
  Symbol,
  isExtended,
  chartData,
  trend,
  navigateToOpenAsset,
  titleLabelColor,
}: AssetTileBaseProps) => (
  <div
    className={styles.content}
    onClick={() => !isExtended && navigateToOpenAsset && navigateToOpenAsset()}
    onKeyDown={() => !isExtended && navigateToOpenAsset && navigateToOpenAsset()}
    role="button"
    tabIndex={0}
  >
    <div className={styles.col}>
      <div className={styles.chartData}>
        {titleLabelColor && <div className={styles.chartColor} style={{ backgroundColor: titleLabelColor }} />}
        <Typography.Text variant={'label3'} className={styles.name}>
          {Name}
          &nbsp; (<Typography.Number value={+trend.toFixed(2)} variant={'label3'} percent />)
        </Typography.Text>
      </div>

      <div className={classNames(styles.block, styles.subtitle)}>
        <div className={styles.logoWrapper}>
          {!!Logo && <img src={`${environment.imgPath}${Logo}`} alt={'logo'} className={styles.logo} />}
        </div>
        <Typography.Text variant={'h5'}>{Symbol}</Typography.Text>
      </div>
    </div>
    <div className={styles.chart}>
      <AssetChart {...chartData} isGradient />
    </div>
    <div className={styles.row}>
      <div className={styles.block}>
        {!!TitleReservedCapital && (
          <div>
            <Typography.Text variant={'label2'} className={styles.label}>
              {TitleReservedCapital}
            </Typography.Text>
            <Typography.Number variant={'h7'} value={+ReservedCapital} currency />
          </div>
        )}
        {!!TitleExpectedROI && (
          <div>
            <Typography.Text variant={'label2'} className={styles.label}>
              {TitleExpectedROI}
            </Typography.Text>
            <Typography.Number variant={'h7'} value={+ExpectedROI.toFixed(2)} percent />
          </div>
        )}
        {TitleGain && (
          <div>
            <Typography.Text variant={'label2'} className={styles.label}>
              {TitleGain}
            </Typography.Text>
            <Typography.Number variant={'h7'} value={+OptionsGain} sign currency colorize />
          </div>
        )}
      </div>
    </div>
    {isExtended && <hr className={styles.hr} />}
  </div>
);

export { AssetTileBase };
