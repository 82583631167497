import * as React from 'react';
import styles from './cell.module.scss';

import { TableCellProps } from './cell.props';
import classNames from 'classnames';

const TableCell: React.FC<TableCellProps> = ({ align, children, classes, adornment, colSpan, maxWidth }) => {
  return (
    <td
      className={classNames(styles.cell, styles.cellAlign, classes, {
        [styles.cellAlignCenter]: align === 'center',
        [styles.cellAlignRight]: align === 'right',
        [styles.cellAdornment]: !!adornment,
      })}
      colSpan={colSpan}
      width={maxWidth && maxWidth}
    >
      {children}
      {adornment && adornment}
    </td>
  );
};

export { TableCell };
