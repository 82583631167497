/**
 * StatusIndicatorProps
 * @description Props for the StatusIndicator component
 *
 * @author Rostyslav Nahornyi
 */
type StatusIndicatorProps = {
  readonly priority: Priority;
};

enum Priority {
  success = 'success',
  info = 'info',
  alarm = 'alarm',
}

export type { StatusIndicatorProps };
export { Priority };
