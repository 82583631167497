/**
 * Regular expression pattern for password validation.
 *
 * The password must meet the following criteria:
 * - Minimum 6 characters
 * - At least 1 capital letter (A-Z)
 * - At least 1 special character from /_*!#
 * - At least 1 number (0-9)
 * @category RegExp
 * @author Rostyslav Nahornyi
 */
const PasswordValidationRegExp = /^(?=.*[A-Z])(?=.*[/_*!#])(?=.*\d).{6,}$/;

/**
 * Regular expression pattern for email validation.
 *
 * The password must meet the following criteria:
 * - Must match next pattern: <1st_substr>@<2nd_substr>.<3rd_substr>
 * - 1st substring must contain only latin letters in uppercase or lowercase, numbers or symbols in range: .!#$%&’*+/=?^_`{|}~-
 * - 2nd substring must contain only latin letters in uppercase or lowercase and numbers
 * - 3rd substring must contain at least 2 symbols, only latin letters in uppercase or lowercase and numbers
 * @category RegExp
 * @author Sergii Goncharuk
 */
const EmailValidationRegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]{2,}$/;

export { PasswordValidationRegExp, EmailValidationRegExp };
