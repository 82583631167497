import { FC, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { PortalProps } from './portal.types';

/**
 * Portal component.
 * @description Global portal component for rendering children into a specified DOM node.
 *
 * @category Components
 * @param { PortalProps } props - PortalProps defined in the './portal.types.ts'
 */
const Portal: FC<PortalProps> = ({ children, domNode }) => {
  const rootElement = useMemo(() => document.getElementById(domNode), [domNode]);

  return rootElement ? createPortal(children, rootElement) : null;
};

export { Portal };
