import { useCallback, useMemo } from 'react';

import { AccountOrdersDeclinationProps } from './account-orders-declination.types';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * useAccountOrdersDeclinationData hook
 * @description The hook which processes AccountOrdersDeclination modal data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useAccountOrdersDeclinationData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { accountOrdersDeclination },
    dispatch,
  } = useModalsContext();

  const modalData: AccountOrdersDeclinationProps | undefined = useMemo(
    () => accountOrdersDeclination,
    [accountOrdersDeclination],
  );

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.accountOrdersDeclination }),
    [dispatch],
  );

  return {
    t,
    modalData,
    handleCloseModal,
  };
};

export { useAccountOrdersDeclinationData };
