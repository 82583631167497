import {
  ManagerGetAccountStrategyResponse,
  ManagerGetStrategiesResponse,
  ManagerP2Response,
} from '../../../../../../../services/api';

/**
 * NewParticipantSetupFlowProps
 * @description Props for the NewParticipantSetupFlow components
 *
 * @author Oleksii Medvediev
 */
type NewParticipantSetupFlowProps = {
  readonly currentStage: NewParticipantSetupStages;
  readonly account: ManagerP2Response[number];
  readonly accountStrategy: ManagerGetAccountStrategyResponse;
  readonly strategies: ManagerGetStrategiesResponse;
  readonly onRefetchParticipants: () => void;
};

enum NewParticipantSetupStages {
  strategy = 'strategy',
  payment = 'payment',
  portfolios = 'portfolios',
  success = 'success',
}

export type { NewParticipantSetupFlowProps };
export { NewParticipantSetupStages };
