import React, { FC } from 'react';

import { ColorTheme } from '../../../types';
import { InvestorProps } from './investor.types';
import { Typography } from '../../../../../../components';
import classNames from 'classnames';
import styles from './investor.module.scss';

/**
 * Investor component.
 * @description Progress bar of portfolio
 *
 * @author Sergii Goncharuk, Oleksii Medvediev
 * @category Components
 * @param { InvestorProps } props - InvestorProps defined in the './investor.types.ts'
 */
const Investor: FC<InvestorProps> = ({ value, maxValue, theme = ColorTheme.DARK, title = '' }) => {
  const growthMax = (value / maxValue) * 100;

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.progressBar, styles[theme])}>
        <div
          className={classNames(styles.grow, styles[theme])}
          style={{ width: growthMax.toFixed(0).toString().concat('%') }}
        />
      </div>
      <div className={styles.description}>
        <Typography.Text variant={'bodyText3'}>{title}</Typography.Text>
        <Typography.Number variant={'bodyText1'} percent value={value} />
      </div>
    </div>
  );
};

export { Investor };
