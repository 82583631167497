import { GetTickerPayload, GetTickerQuotesPayload, GetTickerQuotesResponse, GetTickerResponse } from './common.model';

import { request } from '../../http';

export const getTicker = (data: GetTickerPayload) =>
  request<{ readonly data: GetTickerResponse }>({
    method: 'get',
    url: `common/ticker/${data.ticker}/${data.isin}`,
  });

export const getTickerQuotes = (params: GetTickerQuotesPayload) =>
  request<{ readonly data: GetTickerQuotesResponse }>({
    url: 'common/ticker/quotes',
    method: 'get',
    params,
  });
