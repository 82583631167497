import React, { FC } from 'react';

import { Typography } from '../../../../../components';
import styles from './no-desktop.module.scss';
import { useAppContext } from '../../contexts';

/**
 * NoDesktop component.
 * @description React component for displaying a message when the app is not available on desktop.
 *
 * @author Rostyslav Nahornyi
 * @category Components.
 */
const NoDesktop: FC = () => {
  const { logo } = useAppContext();

  return (
    <div className={styles.noDesktop}>
      <img className={styles.icon} src={logo} alt="logo" />

      <Typography.Text variant="bodyText2">
        Please use your desktop device to see this web application content.
      </Typography.Text>
    </div>
  );
};

export { NoDesktop };
