import * as yup from 'yup';

import { GenerateReportType, GenerateReportValues } from './generate-report.types';
import {
  GetAccountsByEmailsResponse,
  GetAccountsPortfoliosResponse,
  ManagerP2Response,
  UserRole,
  getAccountsByEmail,
  getAccountsPortfolios,
  getManagerParticipants,
} from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { ReportType } from '../../reports-flow.types';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const initialValues: GenerateReportValues = {
  periodType: ReportType.monthly,
  reportType: GenerateReportType.portfolio,
  selectedDate: undefined,
  account: undefined,
  portfolio: undefined,
};

const validationSchema: yup.Schema<typeof initialValues> = yup.object({
  periodType: yup.string<ReportType>().required(),
  selectedDate: yup.string().required(),
  reportType: yup.string<GenerateReportType>().required(),
  account: yup.string(),
  portfolio: yup.string(),
});

const formatPeriodLabel = (
  startDate: Date,
  reportType: ReportType,
  language: string,
  t: (value: string) => string,
): string => {
  if (reportType === ReportType.monthly) {
    return capitalize(
      dayjs(startDate)
        .locale(language === 'uk' ? 'uk-UA' : 'en-US')
        .format('MMMM YYYY'),
    );
  } else {
    return `${startDate.getFullYear()} ${t('period.year')}`;
  }
};

/**
 * useGenerateReportData hook
 * @description The hook which processes Reports Generate Report flow data
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev, Sergii Goncharuk
 * @category Hooks
 */
const useGenerateReportData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management.modals.generateReport' });
  const {
    dispatch: modalsDispatch,
    data: { reportsGenerateReport },
  } = useModalsContext();
  const { /*currentRole,*/ user, dispatch: appDispatch } = useAppContext();
  const [accounts, setAccounts] = useState<GetAccountsByEmailsResponse>();
  const [accountsPortfolios, setAccountsPortfolios] = useState<GetAccountsPortfoliosResponse>();
  const [participants, setParticipants] = useState<ManagerP2Response>();
  const {
    i18n: { language },
  } = useTranslation('translation', { keyPrefix: 'management' });
  const [searchParams] = useSearchParams();

  const flowData = useMemo(() => reportsGenerateReport, [reportsGenerateReport]);

  const [minDate, setMinDate] = useState<string>();
  const [selectedAccountId, setSelectedAccountId] = useState<string>();
  const [reportObject, setReportObject] = useState<'portfolio' | 'account'>('portfolio');

  const fetchData = useCallback(async () => {
    if (/*currentRole && */ (!accounts || !accountsPortfolios) && flowData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await getAccountsByEmail({ emails: [flowData.clientEmailOwner] });
        const { data: accountsPortfoliosResponse } = await getAccountsPortfolios({
          clientEmail: flowData.clientEmailOwner,
        });
        const participantsResponse = user
          ? await getManagerParticipants({
              role: UserRole.manager,
              roleLoginEmail: user.email,
            })
          : undefined;
        if (data && accountsPortfoliosResponse) {
          setAccounts(data);
          setAccountsPortfolios(accountsPortfoliosResponse);
          participantsResponse && setParticipants(participantsResponse.data);
        }
      } catch (error) {
        toast.error('Failed to fetch accounts by email and accounts portfolios!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [accounts, accountsPortfolios, flowData, appDispatch, user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const closeModal = () => {
    modalsDispatch({ type: 'HIDE_MODAL', payload: ModalIds.reportsGenerateReport });
  };

  const generateReport = useCallback(
    (values: GenerateReportValues) => {
      const accountId = values.account;
      const portfolio =
        values.portfolio ?? accounts?.find((account) => account.accountId === accountId)?.portfolios[0].id;
      const lg = searchParams.get('lg');

      if (values.selectedDate && values.periodType && accountId && flowData) {
        const start = dayjs(values.selectedDate)
          .startOf(values.periodType === ReportType.monthly ? 'month' : 'year')
          .format('YYYY-MM-DD');
        const end = dayjs(values.selectedDate)
          .endOf(values.periodType === ReportType.monthly ? 'month' : 'year')
          .format('YYYY-MM-DD');

        const url =
          values.reportType === GenerateReportType.account
            ? `/management/reports/${accountId}`
            : `/management/reports/${accountId}/${portfolio}`;
        const params = `?start=${start}&end=${end}&type=${values.periodType}&email=${
          !!searchParams.get('ownership')
            ? // user?.email ??
              searchParams.get('email')
            : flowData.clientEmailOwner
        }&lg=${user?.preferences.language ?? lg}`;

        window.open(
          `${window.location.origin}${url}${params}${
            !!searchParams.get('ownership') ? `&ownership=${searchParams.get('ownership')}` : ''
          }`,
          '_blank',
        );
      }
    },
    [accounts, searchParams, flowData, user?.preferences.language],
  );

  const accountsOptions:
    | ReadonlyArray<{
        readonly openDate: string;
        readonly id: string;
        readonly value: string;
      }>
    | undefined = useMemo(() => {
    if (accountsPortfolios) {
      if (!!searchParams.get('ownership')) {
        return accountsPortfolios
          .filter(({ AccountOwning }) => (reportObject === 'portfolio' ? true : AccountOwning === 'owner'))
          .map(({ AccountID }) => ({
            openDate: accounts?.find(({ accountId }) => accountId === AccountID)?.dateAccountFunded ?? '',
            id: AccountID,
            value: AccountID,
          }));
      } else {
        return (participants ?? []).map(({ AccountID, AccountStrategyOpen }) => {
          const id = AccountID;
          const value = AccountID;
          const openDate = AccountStrategyOpen;

          return { id, value, openDate };
        });
      }
    } else {
      return undefined;
    }
  }, [accounts, accountsPortfolios, participants, reportObject, searchParams]);

  const portfoliosOptions:
    | ReadonlyArray<{
        readonly openDate: string;
        readonly id: string;
        readonly value: string;
      }>
    | undefined = useMemo(() => {
    if (accountsPortfolios) {
      if (!!searchParams.get('ownership')) {
        const selectedAccount = accountsPortfolios.find(({ AccountID }) => AccountID === selectedAccountId);
        const selectedAccountOwnership = selectedAccount?.AccountOwning;

        if (selectedAccountOwnership === 'owner') {
          return selectedAccount?.Portfolios?.map(({ PortfolioID, PortfolioName, DateOpened }) => ({
            id: PortfolioID,
            value: PortfolioName,
            openDate: DateOpened,
          }));
        } else if (selectedAccountOwnership === 'viewer') {
          return selectedAccount?.Portfolios?.filter(
            ({ PortfolioOwnerEmail }) => PortfolioOwnerEmail === flowData?.clientEmailOwner,
          )?.map(({ PortfolioID, PortfolioName, DateOpened }) => ({
            id: PortfolioID,
            value: PortfolioName,
            openDate: DateOpened,
          }));
        } else {
          return undefined;
        }
      } else {
        const selectedAccount = participants?.find(({ AccountID }) => AccountID === selectedAccountId);

        return selectedAccount?.Portfolios?.map(({ PortfolioID, PortfolioName, PortfolioOpen }) => ({
          id: PortfolioID,
          value: PortfolioName,
          openDate: PortfolioOpen,
        }));
      }
    } else {
      return undefined;
    }
  }, [accountsPortfolios, flowData?.clientEmailOwner, participants, searchParams, selectedAccountId]);

  return {
    t,
    initialValues,
    validationSchema,
    language,
    minDate,
    accounts,
    accountsPortfolios,
    accountsOptions,
    portfoliosOptions,
    ownership: useMemo(() => searchParams.get('ownership'), [searchParams]),
    setMinDate,
    modalsDispatch,
    formatPeriodLabel,
    closeModal,
    generateReport,
    setSelectedAccountId,
    setReportObject,
  };
};

export { useGenerateReportData };
