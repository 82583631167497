import { FC } from 'react';
import { PreloaderProps } from './preloader.types';
import YellowSmallPreloader from '../../assets/images/yellow-small-preloader.png';
import styles from './preloader.module.scss';

/**
 * Preloader component
 * @description Preloader component which indicates data loading/processing
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 */
const Preloader: FC<PreloaderProps> = ({ isActive, type }) => {
  return isActive ? (
    <>
      {type === 'overlay' ? (
        <div className={styles.overlay}>
          <img src={YellowSmallPreloader} alt={'preloader'} className={styles.preloader} />
        </div>
      ) : (
        <img src={YellowSmallPreloader} alt={'preloader'} className={styles.small} />
      )}
    </>
  ) : null;
};

export { Preloader };
