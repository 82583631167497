import * as yup from 'yup';

import { EditFormValues, FormProps } from './components/form/form.types';
import { Form, Success } from './components';
import React, { useCallback, useMemo } from 'react';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { EditProps } from './edit.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './components/success/success.types';
import { setAccountPortfolios } from '../../../../../../../../../services/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

/**
 * useEditData hook
 * @description The hook which processes AutomatedOrdersConformation Edit flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useEditData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { portfoliosSettingsEdit },
    dispatch,
  } = useModalsContext();

  const flowData: EditProps | undefined = useMemo(() => portfoliosSettingsEdit, [portfoliosSettingsEdit]);

  const initialValues: EditFormValues = useMemo(
    () => ({
      ownerEmail: flowData?.portfolioOwner ?? '',
      owner: flowData?.portfolioMember ?? '',
    }),
    [flowData?.portfolioMember, flowData?.portfolioOwner],
  );

  const validationSchema: yup.Schema<typeof initialValues> = yup.object({
    owner: yup.string().required(t('modals.portfoliosSettings.edit.form.errors.ownerRequired')),
    ownerEmail: yup
      .string()
      .email(t('modals.portfoliosSettings.edit.form.errors.emailFormat'))
      .required(t('modals.portfoliosSettings.edit.form.errors.ownerEmailRequired')),
  });

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.portfoliosSettingsEdit }),
    [dispatch],
  );

  const setStage = useCallback(
    (stage: EditProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.portfoliosSettingsEdit,
          data: {
            [ModalIds.portfoliosSettingsEdit]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<EditProps['currentStage'], FormProps['onSubmitStage'] | SuccessProps['onClose']> =
    useMemo(
      () => ({
        form: async ({ owner, ownerEmail }: typeof initialValues) => {
          if (flowData?.onEditPortfolioInNewParticipantSetupFlow) {
            flowData.onEditPortfolioInNewParticipantSetupFlow({ owner, ownerEmail });
            handleCloseModal();
          } else if (flowData && user) {
            appDispatch({ type: 'TOGGLE_IS_LOADING' });

            try {
              const { message } = await setAccountPortfolios({
                role: user.isLoggedInRole,
                roleLoginEmail: user.email,
                portfoliosDoc: {
                  AccountID: flowData.accountId,
                  ClientEmailOwner: flowData.accountOwnerEmail,
                  ReOpenPortfolios: [],
                  OpenPortfolios: [],
                  ClosePortfolios: [],
                  ChangePortfolios: [
                    {
                      PortfolioID: flowData.portfolioId,
                      PortfolioMember: owner,
                      PortfolioOwner: ownerEmail,
                    },
                  ],
                },
              });
              if (message === 'ok') {
                setStage('success');
                flowData.onRefetchParticipants();
              }
            } catch (error) {
              toast.error('Failed to process set account portfolios request!');
              console.error(error);
            }

            appDispatch({ type: 'TOGGLE_IS_LOADING' });
          }
        },
        success: () => {
          handleCloseModal();
        },
      }),
      [flowData, user, appDispatch, setStage, handleCloseModal],
    );

  const stages: Record<EditProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            form: (
              <Form
                initialValues={initialValues}
                onBackButtonClick={flowData.onBackButtonClick}
                onClose={
                  flowData.onEditPortfolioInNewParticipantSetupFlow ? flowData.onBackButtonClick : handleCloseModal
                }
                onSubmitStage={submitHandlers.form}
                t={t}
                currentCapital={flowData.currentCapital}
                validationSchema={validationSchema}
              />
            ),
            success: <Success onClose={handleCloseModal} portfolioMember={flowData.portfolioMember} />,
          }
        : undefined,
    [flowData, handleCloseModal, initialValues, submitHandlers.form, t, validationSchema],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { useEditData };
