import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReportType, ReportsPeriod } from '../../reports-flow.types';
import { Success, Summary } from './components';
import { getAccountsByEmail, sendBulkReport } from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { CollectiveAccountsProps } from './collective-accounts.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './components/success/success.types';
import { SummaryProps } from './components/summary/summary.types';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const formatPeriodLabel = (startDate: Date, reportType: ReportType, t: (value: string) => string): string => {
  const dividedStartDate = dayjs(startDate).format('MMMM YYYY').split(' ');
  if (reportType === ReportType.monthly) {
    return `${t(dividedStartDate[0])} ${dividedStartDate[1]}`;
  } else {
    return `${dividedStartDate[1]} ${t('modals.reports.summary.year')}`;
  }
};

/**
 * useCollectiveAccountsData hook
 * @description The hook which processes Reports CollectiveAccounts flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useCollectiveAccountsData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { reportsCollectiveAccounts },
    dispatch,
  } = useModalsContext();

  const [period, setPeriod] = useState<ReportsPeriod>();
  const [minDate, setMinDate] = useState<string>();
  const [reportType, setReportType] = useState<ReportType>(ReportType.monthly);

  const flowData: CollectiveAccountsProps | undefined = useMemo(
    () => reportsCollectiveAccounts,
    [reportsCollectiveAccounts],
  );

  const fetchAccountsByEmail = useCallback(async () => {
    if (flowData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await getAccountsByEmail({
          emails: flowData.accounts.map(({ clientEmailOwner }) => clientEmailOwner),
        });
        if (data?.length === 0) {
          setMinDate(dayjs().subtract(1, 'year').format('YYYY-MM-DD'));
        } else if (data?.length && data?.length > 0) {
          setMinDate(
            [...data]
              .filter(({ accountId }) => flowData.accounts.some((account) => account.accountId === accountId))
              .sort((a, b) => new Date(a.dateAccountFunded).getTime() - new Date(b.dateAccountFunded).getTime())[0]
              .dateAccountFunded,
          );
        }
      } catch (error) {
        toast.error('Failed to fetch accounts by email!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, flowData]);

  useEffect(() => {
    if (!minDate) {
      fetchAccountsByEmail();
    }
  }, [fetchAccountsByEmail, minDate]);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.reportsCollectiveAccounts }),
    [dispatch],
  );

  const setStage = useCallback(
    (stage: CollectiveAccountsProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.reportsCollectiveAccounts,
          data: {
            [ModalIds.reportsCollectiveAccounts]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<
    CollectiveAccountsProps['currentStage'],
    SummaryProps['onSubmitStage'] | SuccessProps['onSubmitStage']
  > = useMemo(
    () => ({
      summary: async () => {
        if (user && flowData && period) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
          try {
            const { message } = await sendBulkReport({
              clients: flowData.accounts.map(({ accountId, clientEmailOwner, clientNameOwner }) => ({
                accountId,
                email: clientEmailOwner,
                name: clientNameOwner,
              })),
              start: dayjs(period.startDate).format('YYYY-MM-DD'),
              end: dayjs(period.endDate).format('YYYY-MM-DD'),
              reportType: reportType,
            });
            if (message === 'ok' || message.includes('Duplicate entry')) {
              setStage('success');
            }
          } catch (error) {
            toast.error('Failed to process send bulk report request!');
            console.error(error);
          }
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: handleCloseModal,
    }),
    [appDispatch, flowData, handleCloseModal, period, reportType, setStage, user],
  );

  const stages: Record<CollectiveAccountsProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData && minDate
        ? {
            summary: (
              <Summary
                accounts={flowData.accounts}
                minDate={minDate}
                period={period}
                selectedReportType={reportType}
                onReportTypeSelect={(value) => setReportType(value)}
                onClose={handleCloseModal}
                onSetPeriod={(value) => setPeriod(value)}
                onSubmitStage={submitHandlers.summary}
              />
            ),
            success: <Success accounts={flowData.accounts} t={t} onSubmitStage={submitHandlers.success} />,
          }
        : undefined,
    [flowData, handleCloseModal, minDate, period, reportType, submitHandlers.success, submitHandlers.summary, t],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { formatPeriodLabel, useCollectiveAccountsData };
