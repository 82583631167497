import React, { FC } from 'react';

import { AccordionBodyProps } from './accordion-body.types';
import { ReactComponent as Approve } from '../../../../../assets/icons/active-approve.svg';
import { ReactComponent as Cancel } from '../../../../../assets/icons/active-cancel.svg';
import { Typography } from '../../../../../../../components';
import classNames from 'classnames';
import styles from './accordion-body.module.scss';

/**
 * AccordionBody component
 * @description Body of accordion
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 */
const AccordionBody: FC<AccordionBodyProps> = ({ items, isMessage }) => (
  <div className={styles.accordionBody}>
    {items.map(({ icon, text, status, htmlText }, idx) => (
      <div key={idx} className={classNames(styles.row)}>
        <Typography.Text
          variant={typeof status !== 'undefined' ? 'bodyText3' : 'bodyText5'}
          className={classNames(typeof text === 'string' && styles.text, typeof text !== 'string' && styles.flex)}
        >
          {htmlText ? (
            <div
              dangerouslySetInnerHTML={{ __html: htmlText }}
              style={isMessage ? { opacity: 0.5 } : { opacity: 0.8 }}
            />
          ) : (
            <span
              style={{
                opacity: isMessage ? 0.5 : undefined,
                width: '100%',
              }}
            >
              {text}
            </span>
          )}
        </Typography.Text>
        {typeof status !== 'undefined' && (
          <div className={styles.statusIcon}>
            {status === 0 ? <Cancel className={styles.cancel} /> : <Approve className={styles.approve} />}
          </div>
        )}
        {icon && icon}
      </div>
    ))}
  </div>
);

export { AccordionBody };
