import React, { FC } from 'react';

import { LegendTemplateProps } from './legend-template.types';
import { Typography } from '../../../../../components';
import classNames from 'classnames';
import styles from './legend-template.module.scss';

/**
 * LegendTemplate component
 * @description LegendTemplate
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 * @param { LegendTemplateProps } props - LegendTemplateProps defined in the './legend-template.types.ts'
 */
const LegendTemplate: FC<LegendTemplateProps> = ({
  valueFirst,
  firstValueColor,
  valueSecond,
  valueThird,
  className,
}) => (
  <div className={classNames(styles.legendTemplate, className)}>
    <div className={styles.customLegend}>
      <div className={styles.legend}>
        <span className={styles.green} style={firstValueColor ? { backgroundColor: firstValueColor } : undefined} />
        <Typography.Text variant={'label4'}>{valueFirst} </Typography.Text>
      </div>
      <div className={styles.legend}>
        <span className={styles.mint} />
        <Typography.Text variant={'label4'}>{valueSecond}</Typography.Text>
      </div>
      {valueThird && (
        <div className={styles.legend}>
          <span className={styles.dashed} />
          <Typography.Text variant={'label4'}>{valueThird}</Typography.Text>
        </div>
      )}
    </div>
  </div>
);

export { LegendTemplate };
