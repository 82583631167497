import 'dayjs/locale/uk';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import quarter from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarter);
dayjs.extend(isBetween);

const getQuarters = (dates: string[]): string[] => {
  const d = [] as string[];

  dates.forEach((v, i, arr) => {
    if (dayjs(v).quarter() !== dayjs(arr[i + 1]).quarter()) {
      d.push(v);
    }
  });

  return d;
};

const getYears = (dates: string[]): string[] => {
  const years = [] as string[];

  dates.forEach((v, i, arr) => {
    if (dayjs(v).year() !== dayjs(arr[i - 1] || '').year()) {
      years.push(v);
    }
  });

  return years;
};

const getMonthName = (dateString: string): string => {
  const date = dayjs(dateString);
  return date.locale('uk').format('MMMM');
};

/**
 * Get quarter number from date
 */

// Add type for return value
const getQuarter = (date: string): number => {
  const month = dayjs(date).month();

  if (month >= 0 && month <= 2) {
    return 1;
  } else if (month >= 3 && month <= 5) {
    return 2;
  } else if (month >= 6 && month <= 8) {
    return 3;
  } else {
    return 4;
  }
};

/**
 * DateIsBetween util
 * @description Checks if a given date is between two other dates, or is equal to one of them.
 *
 * @returns { boolean } - True if the date is between start and end, or is equal to one of them; false otherwise.
 * @author Rostyslav Nahornyi
 * @category Utils
 */
const dateIsBetween = (date: string, start: string, end?: string): boolean => {
  return dayjs(date).isBetween(start, end) || dayjs(date).isSame(start) || dayjs(date).isSame(end);
};

interface MonthData {
  monthNumber: number;
  isMonthInRange: boolean;
}

interface YearData {
  year: number;
  monthData: MonthData[];
}

/**
 * GetMonthFromTo util
 * @description Returns an array of year data with their corresponding month data based on the provided start and end dates.
 *
 * @param { string } startDate - The start date in string format.
 * @param { string } endDate - The end date in string format.
 * @returns { YearData[] } An array of year data with their corresponding month data.
 * @category Utils
 * @example
 *  Output:
 *  [
 *   {
 *     year: number;
 *     monthData: [
 *       {
 *         monthNumber: number;
 *         isPastOrNow: boolean;
 *       },
 *       ...month
 *     ]
 *   },
 *   ...year
 *  ]
 */
function getMonthsFromTo(startDate: string, endDate: string = dayjs().format()): YearData[] {
  const fromDateTime = dayjs(startDate);
  const endDateTime = dayjs(endDate);

  const yearData: YearData[] = [];

  for (let year = fromDateTime.year(); year <= endDateTime.year(); year++) {
    const monthData: MonthData[] = [];

    for (let month = 0; month < 12; month++) {
      const monthDate = dayjs().year(year).month(month);

      // check if the month in range of start date and end date
      const isMonthInRange: boolean = monthDate.isBetween(fromDateTime, endDateTime, 'month', '[]');

      monthData.push({
        monthNumber: monthDate.month(),
        isMonthInRange,
      });
    }

    yearData.push({
      year,
      monthData,
    });
  }

  return yearData;
}

/**
 * MonthByIndex util
 * @description Gives a month by index. [0 - 11].
 *
 * @author Rostyslav Nahornyi
 * @category Utils
 */
const monthByIndex = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export { getYears, getMonthName, getQuarters, getQuarter, dateIsBetween, getMonthsFromTo, monthByIndex };
