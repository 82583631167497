import 'dayjs/locale/uk';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import quarter from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarter);
dayjs.extend(isBetween);

/**
 * dateIsBetween util
 * @description Checks if a given date is between two other dates, or is equal to one of them.
 *
 * @returns { boolean } - True if the date is between start and end, or is equal to one of them; false otherwise.
 * @author Rostyslav Nahornyi
 * @category Utils
 */
const dateIsBetween = (date: Date, start?: Date, end?: Date): boolean => {
  if (start && !end) return dayjs(date).isSame(start, 'day');
  if (!start && end) return dayjs(date).isSame(end, 'day');

  if (start && end) return dayjs(date).isBetween(start, end, 'day', '[]');

  return false;
};

/**
 * Represents data for a specific month.
 */
interface MonthData {
  isMonthInRange: boolean;
  date: Date;
}

/**
 * Represents data for a specific year with corresponding month data.
 */
interface YearData {
  year: number;
  monthData: MonthData[];
}

/**
 * GetMonthsFromTo util
 * @description Returns an array of year data with corresponding month data based on the provided start and end dates.
 *
 * @category Utils
 * @example
 *  Output:
 *  [
 *   {
 *     year: number;
 *     monthData: [
 *       {
 *         isMonthInRange: boolean,
 *         date: Date,
 *       },
 *       ...monthData
 *     ]
 *   },
 *   ...yearData
 *  ]
 */
function getMonthsFromTo(a: Date, b: Date = new Date()): YearData[] {
  const yearData: YearData[] = [];

  for (let year = a.getFullYear(); year <= b.getFullYear(); year++) {
    const monthData: MonthData[] = [];

    for (let month = 0; month < 12; month++) {
      const monthDate = dayjs().year(year).month(month);

      const isMonthInRange: boolean = monthDate.isBetween(a, b, 'month', '[]');

      monthData.push({
        isMonthInRange,
        date: monthDate.toDate(),
      });
    }

    yearData.push({
      year,
      monthData,
    });
  }

  return yearData;
}

/**
 * MonthByIndex util
 * @description Gives a month by index. [0 - 11].
 *
 * @author Rostyslav Nahornyi
 * @category Utils
 */
const monthByIndex = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export { dateIsBetween, getMonthsFromTo, monthByIndex };
