import React, { forwardRef } from 'react';

import { AssetTileBase } from './components/asset-tile-base';
import { AssetTileBody } from './components/asset-tile-body/asset-tile-body.component';
import { AssetTileProps } from './asset-tile.types';
import styles from './asset-tile.module.scss';

/**
 * AssetTile component
 * @description AssetTile
 *
 * @author Vitalii Bodnarchuk, Ihor Dubas, Oleksii Medvediev
 * @category Components
 * @param { AssetTileProps } props - AssetTileProps defined in the './asset-tile.types.ts'
 */
// TODO: Find another solution of Storybook reference error instead of 'var'
// eslint-disable-next-line no-var
var AssetTile = forwardRef<HTMLDivElement, AssetTileProps>(({ baseData, extendedData }, ref) => (
  <div className={styles.wrapper} ref={ref}>
    <AssetTileBase {...baseData} isExtended={!!extendedData} navigateToOpenAsset={baseData.navigateToOpenAsset} />
    {extendedData && (
      <div className={styles.extendedData}>
        {extendedData.map((el, idx) => (
          <AssetTileBody {...el} key={Number(idx)} />
        ))}
      </div>
    )}
  </div>
));

AssetTile.displayName = 'AssetTile';

export { AssetTile };
