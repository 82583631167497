import { Confirmation, Success } from './components';
import React, { useCallback, useMemo } from 'react';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { ConfirmationProps } from './components/confirmation/confirmation.types';
import { DeleteProps } from './delete.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './components/success/success.types';
import { setAccountPortfolios } from '../../../../../../../../../services/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

/**
 * useDeleteData hook
 * @description The hook which processes AutomatedOrdersConformation Delete flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useDeleteData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { portfoliosSettingsDelete },
    dispatch,
  } = useModalsContext();

  const flowData: DeleteProps | undefined = useMemo(() => portfoliosSettingsDelete, [portfoliosSettingsDelete]);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.portfoliosSettingsDelete }),
    [dispatch],
  );

  const setStage = useCallback(
    (stage: DeleteProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.portfoliosSettingsDelete,
          data: {
            [ModalIds.portfoliosSettingsDelete]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<
    DeleteProps['currentStage'],
    ConfirmationProps['onSubmitStage'] | SuccessProps['onClose']
  > = useMemo(
    () => ({
      confirmation: async () => {
        if (flowData?.onDeletePortfolioInNewParticipantSetupFlow) {
          flowData.onDeletePortfolioInNewParticipantSetupFlow(flowData.portfolioId);
          handleCloseModal();
        } else if (flowData && user) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });

          try {
            const { message } = await setAccountPortfolios({
              role: user.isLoggedInRole,
              roleLoginEmail: user.email,
              portfoliosDoc: {
                AccountID: flowData.accountId,
                ClientEmailOwner: flowData.clientEmailOwner,
                ReOpenPortfolios: [],
                OpenPortfolios: [],
                ClosePortfolios: [
                  {
                    PortfolioID: flowData.portfolioId,
                    PortfolioMember: flowData.portfolioMember,
                    PortfolioOwner: flowData.portfolioOwner,
                  },
                ],
                ChangePortfolios: [],
              },
            });
            if (message === 'ok') {
              setStage('success');
              flowData.onRefetchParticipants();
            }
          } catch (error) {
            toast.error('Failed to process set account portfolios request!');
            console.error(error);
          }

          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: () => {
        handleCloseModal();
      },
    }),
    [flowData, user, appDispatch, setStage, handleCloseModal],
  );

  const stages: Record<DeleteProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            confirmation: (
              <Confirmation
                onClose={
                  flowData.onDeletePortfolioInNewParticipantSetupFlow ? flowData.onBackButtonClick : handleCloseModal
                }
                onSubmitStage={submitHandlers.confirmation}
                t={t}
              />
            ),
            success: (
              <Success
                onClose={handleCloseModal}
                accountId={flowData.accountId}
                clientNameOwner={flowData.clientNameOwner}
                portfolioMember={flowData.portfolioMember}
              />
            ),
          }
        : undefined,
    [flowData, handleCloseModal, submitHandlers.confirmation, t],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { useDeleteData };
