import React, { FC, useState } from 'react';

import { ActivityHistoryProps } from './activity-history.types';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { Typography } from '../../../../../components';
import classNames from 'classnames';
import styles from './activity-history.module.scss';

/**
 * ActivityHistory component
 * @description Activity History
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { ActivityHistoryProps } props - ActivityHistoryProps defined in the './activity-history-props.types.ts'
 */
const ActivityHistory: FC<ActivityHistoryProps> = ({
  title,
  heading,
  subHeading,
  shortDescription,
  description,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const expandDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classNames(styles.activityHistory, className)}>
      <Typography.Text className={styles.title} variant={'label4'}>
        {title}
      </Typography.Text>

      <div className={styles.section}>
        <Typography.Text className={styles.heading} variant={'bodyText3'}>
          {heading}
        </Typography.Text>
        <Typography.Text className={styles.subHeading} variant={'bodyText5'}>
          {subHeading}
        </Typography.Text>
      </div>

      {shortDescription && description && (
        <button
          tabIndex={0}
          aria-label={'button'}
          type={'button'}
          className={styles.descriptionWrapper}
          onClick={expandDescription}
        >
          <div className={styles.header}>
            <Typography.Text variant={'bodyText2'} className={styles.shortDescription}>
              {shortDescription}
            </Typography.Text>
            <div className={classNames(styles.icon, isExpanded && styles.chevronUp)}>
              <ArrowDownIcon />
            </div>
          </div>
          <div className={classNames(styles.hidden, isExpanded && styles.expanded)}>
            <Typography.Text variant={'bodyText5'} className={styles.description}>
              {description}
            </Typography.Text>
          </div>
        </button>
      )}
    </div>
  );
};

export { ActivityHistory };
