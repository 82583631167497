import { useCallback, useMemo, useState } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * useDatePickerModalData hook
 * @description The hook which processes DatePickerModal data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useDatePickerModalData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { datePicker },
    dispatch,
  } = useModalsContext();

  const modalData = useMemo(() => datePicker, [datePicker]);

  const defaultRange = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      isChanged: false,
    },
  ];

  const incomingRange = useMemo(
    () => [
      {
        startDate: modalData?.date ?? modalData?.availableRange?.min ?? new Date(),
        endDate: modalData?.date ?? modalData?.availableRange?.max ?? new Date(),
        key: 'selection',
        isChanged: !!modalData?.date,
      },
    ],
    [modalData?.date, modalData?.availableRange],
  );

  const [ranges, setRanges] = useState<
    ReadonlyArray<{
      readonly startDate: Date;
      readonly endDate: Date;
      readonly key: string;
      readonly isChanged: boolean;
    }>
  >(incomingRange);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.datePicker }),
    [dispatch],
  );

  const handleSubmit = useCallback(() => {
    modalData?.onSubmit(ranges[0].startDate);
    handleCloseModal();
  }, [handleCloseModal, modalData, ranges]);

  return { t, ranges, modalData, defaultRange, setRanges, handleSubmit };
};

export { useDatePickerModalData };
