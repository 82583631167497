import React, { FC } from 'react';

import { TabProps } from '../tab.types';
import { Typography } from '../../../../../../components';
import classNames from 'classnames';
import styles from './date-picker.module.scss';

/**
 * Exclusively DatePicker component for Tab
 * @description DatePicker tab
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { TabProps } props - TabProps defined in the '../tab-props.types.ts'
 */
const DatePicker: FC<TabProps> = ({ label, isSelected, disabled, className, style, onClick }: TabProps) => (
  <button
    tabIndex={0}
    aria-label={'button'}
    type={'button'}
    disabled={disabled}
    className={classNames(styles.datePickerTab, isSelected && styles.selected, className)}
    style={style}
    onClick={onClick}
  >
    <Typography.Text variant={'bodyText3'} className={styles.title}>
      {label}
    </Typography.Text>
  </button>
);

export { DatePicker };
