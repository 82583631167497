import { useAppContext, useModalsContext } from '../../../../contexts';
import { useCallback, useMemo } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
// import { getAdminInvoices } from '../../../../../../../services/api';
import { useTranslation } from 'react-i18next';

/**
 * useRequestAcceptedModal
 * @description Gets useRequestAcceptedModal component data from store
 *
 * @author Sergii Goncharuk
 * @category Hooks
 * @returns AccountCreatedData
 */
const useRequestAcceptedModal = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { requestAccepted },
    dispatch,
  } = useModalsContext();
  const { user } = useAppContext();

  const modalData = useMemo(() => requestAccepted, [requestAccepted]);

  const email = user?.email ?? '';

  const handleContinueClick = useCallback(async () => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.requestAccepted });
  }, [dispatch]);

  return {
    t,
    email,
    type: modalData?.type,
    handleContinueClick,
  };
};

export { useRequestAcceptedModal };
