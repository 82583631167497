import { FC, useState } from 'react';
import { Toggle, Typography } from '../../../../../../../components';

import { PersonalizationItemProps } from './personalization-item.types';
import classNames from 'classnames';
import styles from './personalization-item.module.scss';

const PersonalizationItem: FC<PersonalizationItemProps> = ({
  title,
  icon,
  toggle: { defaultValue, icons, ids, onChange },
  bottomBorder = true,
}) => {
  const [value, setValue] = useState(defaultValue ? defaultValue === ids[1] : false);

  return (
    <button className={classNames(styles.wrapper, bottomBorder && styles.bottomBorder)}>
      <div className={styles.leftBlock}>
        <div className={styles.icon}>{icon}</div>
        <Typography.Text variant="label1">{title}</Typography.Text>
      </div>

      <div className={styles.rightControls}>
        <div className={classNames(styles.icon, !value && styles.isActive)}>{icons[0]}</div>
        <Toggle
          id={title}
          value={value}
          onChange={(value) => {
            setValue(value);
            onChange(value ? ids[1] : ids[0]);
          }}
        />
        <div className={classNames(styles.icon, value && styles.isActive)}>{icons[1]}</div>
      </div>
    </button>
  );
};

export { PersonalizationItem };
