import { NoticesGroup, NoticesProps } from './notices.types';

import { NoticeItemProps } from './components/notice-item/notice-item.types';
import { useCallback } from 'react';

const getDifference = (targetDate: Date) =>
  Math.floor((new Date().getTime() - targetDate.getTime()) / 1000 / 60 / 60 / 24);

/**
 * useNoticesData
 * @description The hook which group notices by date
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 * @category Hooks
 * @param { NoticesProps['notices'] } notices
 * @returns { { readonly groups: ReadonlyArray<NoticesGroup> } }
 */
const useNoticesData = (notices: NoticesProps['notices']): { readonly groups: ReadonlyArray<NoticesGroup> } => {
  const mapNoticesToGroups: (notices: NoticesProps['notices']) => ReadonlyArray<NoticesGroup> = useCallback(
    (notices) => {
      const sortedNotices: ReadonlyArray<NoticeItemProps> = [...notices].sort((a, b) => {
        const aDate = a.date;
        const bDate = b.date;

        if ((aDate as unknown as number) > (bDate as unknown as number)) {
          return 1;
        } else if ((aDate as unknown as number) < (bDate as unknown as number)) {
          return -1;
        } else {
          return 0;
        }
      });

      const markedNotices: ReadonlyArray<NoticeItemProps & { readonly dateDifferenceMark: number }> = sortedNotices.map(
        (item) => ({ ...item, dateDifferenceMark: getDifference(item.date) }),
      );

      const periods: Array<number> = [];

      for (const { dateDifferenceMark } of markedNotices) {
        !periods.includes(dateDifferenceMark) && periods.push(dateDifferenceMark);
      }

      periods.sort((a, b) => (a > b ? 1 : -1));

      const groups: Array<NoticesGroup> = [];

      for (const period of periods) {
        const items = markedNotices
          .filter(({ dateDifferenceMark }) => dateDifferenceMark === period)
          .map(({ date, primaryRow, secondaryRow, additionalRows, detail }) => ({
            date,
            primaryRow,
            secondaryRow,
            additionalRows,
            detail,
          }));

        const group: NoticesGroup = {
          label: items[0].date.toLocaleString('en-UK').split(',')[0],
          items,
        };

        groups.push(group);
      }

      return groups;
    },
    [],
  );

  return { groups: mapNoticesToGroups(notices) };
};

export { useNoticesData };
