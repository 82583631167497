import React, { FC, useEffect, useState } from 'react';

import { ReactComponent as ChevronDownIcon } from '../../../../../assets/icons/chevron-down.svg';
import { FoldItemProps } from './fold-item.types';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import styles from './fold-item.module.scss';
import { useAppContext } from '../../../../contexts';

/**
 * FoldItem component
 * @description Exclusively for the Sidebar component as its menu folding item
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 * @category Components
 * @param { FoldItemProps } props - FoldItemProps defined in the './fold-item.types.ts'
 */
const FoldItem: FC<FoldItemProps> = ({ items, icon, label }) => {
  const [isUnfolded, setIsUnfolded] = useState(true);
  const { sidebarCollapsed } = useAppContext();

  useEffect(() => {
    setIsUnfolded(!sidebarCollapsed);
  }, [sidebarCollapsed]);

  return (
    <div className={classNames(styles.wrapper, sidebarCollapsed && styles.collapsed)}>
      <button
        className={styles.item}
        onClick={() => {
          setIsUnfolded(!isUnfolded);
        }}
      >
        <span className={styles.icon}>{icon}</span>
        <span className={styles.label}>{capitalize(label)}</span>
        <ChevronDownIcon className={classNames(isUnfolded && styles.up)} />
      </button>
      <div className={classNames(styles.list, isUnfolded && styles.unfolded)}>
        {!!items.length &&
          items.map(({ label, href, target, icon, onClick, disabled }, index) =>
            href ? (
              <a
                href={href}
                target={target ?? ''}
                className={classNames(styles.item)}
                key={label.concat(index.toString())}
              >
                {icon}
                {capitalize(label)}
              </a>
            ) : (
              <button
                className={classNames(styles.item)}
                onClick={onClick}
                key={label.concat(index.toString())}
                disabled={disabled}
              >
                {icon}
                {label === 'whatsapp' ? 'WhatsApp' : capitalize(label)}
              </button>
            ),
          )}
      </div>
    </div>
  );
};

export { FoldItem };
