import { useMemo, useState } from 'react';

import { Stage } from './change-role-modal.types';
import { useModalsContext } from '../../../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * useChangeRole hook.
 * @description The hook which processes ChangeRole component data
 *
 * @author Rostysav Nahornyi, Oleksii Medvediev
 * @category Hooks
 */
const useChangeRole = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { loginRequestChangeRole },
  } = useModalsContext();

  const role = useMemo(() => loginRequestChangeRole?.role, [loginRequestChangeRole]);

  const [stage, setStage] = useState(Stage.start);

  return { t, role, stage, setStage };
};

export { useChangeRole };
