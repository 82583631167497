import React, { FC } from 'react';

import DefaultLogo from '../../../assets/images/default-logo.png';
import { Typography } from '../../../../../components';
import styles from './no-desktop.module.scss';
import { useAppContext } from '../../context';

/**
 * NoDesktop component.
 * @description React component for displaying a message when the app is not available on desktop.
 *
 * @author Rostyslav Nahornyi, Sergii Goncharuk
 * @category Components.
 */
const NoDesktop: FC = () => {
  const { branding } = useAppContext();

  return (
    <div className={styles.noDesktop}>
      <img className={styles.icon} src={branding?.logo ?? DefaultLogo} alt={'logo'} />

      <Typography.Text variant={'bodyText2'}>
        Please use your mobile device to see this web application content.
      </Typography.Text>
    </div>
  );
};

export { NoDesktop };
