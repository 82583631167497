import { useWindowSize } from './use-window-size.hook';

type MediaDevices = {
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
};

enum Breakpoints {
  desktop = 1210,
  tablet = 768,
}

/**
 * useMediaQueries hook.
 * This custom hook determines the current device type based on screen width.
 *
 * @author Rostyslav Nahornyi
 * @category hooks.
 *
 * @returns { MediaDevices } An object containing device type booleans
 */
function useMediaQueries(): MediaDevices {
  const { width } = useWindowSize();

  const desktop = width >= Breakpoints.desktop;
  const tablet = width >= Breakpoints.tablet && width <= Breakpoints.desktop;

  return {
    desktop,
    tablet,
    mobile: !desktop && !tablet,
  };
}

export { useMediaQueries };
