import { FC } from 'react';
import { useCollectiveAccountsPortfoliosData } from './collective-accounts-portfolios.hook';

/**
 * CollectiveAccountsPortfolios component
 * @description Content for the Reports CollectiveAccountsPortfolios flow.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2118-412354&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const CollectiveAccountsPortfolios: FC = () => {
  const { stages, currentStage } = useCollectiveAccountsPortfoliosData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { CollectiveAccountsPortfolios };
