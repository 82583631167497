import { FC } from 'react';
import { useChangeStrategyFlowData } from './change-strategy-flow.hook';

/**
 * Set component
 * @description Content for the ChangeStrategy flow.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-154174&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const ChangeStrategyFlow: FC = () => {
  const { stages, currentStage } = useChangeStrategyFlowData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { ChangeStrategyFlow };
