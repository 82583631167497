const parseQuery = (queryString: string): Record<string, string> => {
  if (!queryString) return {};

  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substring(1) : queryString).split('&');

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  return query;
};

const stringifyQuery = (query: Record<string, string>): string => {
  const str = [];
  for (const p in query)
    if (query[p]) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(query[p]));
    }
  return str.join('&');
};

export { parseQuery, stringifyQuery };
