import { useEffect, useState } from 'react';
/**
 * useDebounce hook.
 * Is used for getting the last changed value by the delay.
 *
 * @author Vitalii Bodnarchuk
 * @category Hooks.
 * @param input - value that should be debounced.
 * @param delay - delay time, ms.
 * @returns - debounced input value.
 */
function useDebounce<T>(input: T, delay: number) {
  const [value, setValue] = useState<T>();
  useEffect(() => {
    const debounceHandler = setTimeout(() => {
      setValue(input);
    }, delay);
    return () => clearTimeout(debounceHandler);
  }, [input, delay]);
  return value;
}

export { useDebounce };
