import { ConnectionIndicatorProps } from './connection-indicator.types';
import { FC } from 'react';
import classNames from 'classnames';
import styles from './connection-indicator.module.scss';

/**
 * ConnectionIndicator component
 * @description Exclusively for the Header component as its connection indicator.
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { ConnectionIndicatorProps } props - ConnectionIndicatorProps defined in the './connection-indicator.types.ts'
 */
const ConnectionIndicator: FC<ConnectionIndicatorProps> = ({ size = 'm', connectionName, isConnected }) => (
  <div className={styles.wrapper}>
    <div className={classNames(styles.indicator, isConnected && styles.connected, styles[size])} />

    {connectionName && <div className={styles.name}>{connectionName}</div>}
  </div>
);

export { ConnectionIndicator };
