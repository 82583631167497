import { useCallback, useMemo } from 'react';

import { FormFilterProps } from './form-filter.types';
import { Priority } from '../../../../../../../../../../../components/indicator/components/status-indicator/status-indicator.types';
import { monthByIndex } from '../../../../../../../../shared';
import { useAppContext } from '../../../../../../../../context';
import { useTranslation } from 'react-i18next';

const statuses = [Priority.alarm, Priority.success, Priority.info];

/**
 * useFormFilterData
 * @description The hook which processes FormFilter component data
 *
 * @author Rostyslav Nahornyi
 * @category Hooks
 */

const useFormFilterData = ({
  values,
  onChange,
}: Omit<FormFilterProps, 'openMonthlyCalendar' | 'onResetFilters' | 'onSubmit'>) => {
  const { t } = useTranslation('translation', { keyPrefix: 'client' });
  const { t: tCalendar } = useTranslation('translation', { keyPrefix: 'common.months' });
  const { dispatch } = useAppContext();

  const changeStatusItemHandler = (
    status: Priority,
    values: FormFilterProps['values'],
    onChange: FormFilterProps['onChange'],
  ) => {
    const isChecked = values.statuses?.includes(status);

    if (!isChecked) return onChange({ ...values, statuses: [...(values.statuses ?? []), status] });
    else {
      const statuses = values?.statuses;
      statuses?.splice(statuses.indexOf(status), 1);

      onChange({ ...values, statuses: !!statuses?.length ? statuses : undefined });
    }
  };

  const closeFiltersDrawer = useCallback(() => {
    dispatch({ type: 'CLOSE_DRAWER' });
  }, [dispatch]);

  const resetFilter = useCallback(() => onChange({}), [onChange]);

  const buttonLabel = useMemo(
    () =>
      values.period &&
      `${tCalendar(monthByIndex[values.period.a?.getMonth() ?? 0])} ${
        values.period.a?.getFullYear() === values.period.b?.getFullYear() ? '' : values.period.a?.getFullYear()
      } ${
        values.period?.b
          ? `– ${tCalendar(
              monthByIndex[values.period.b?.getMonth() ?? 0],
            ).toLowerCase()} ${values.period.b.getFullYear()}`
          : ''
      }`,
    [tCalendar, values.period],
  );

  return { t, statuses, buttonLabel, resetFilter, closeFiltersDrawer, changeStatusItemHandler };
};

export { useFormFilterData };
