import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { CSVLink } from 'react-csv';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../../../components';
import { capitalize } from 'lodash';
import styles from './confirmation.module.scss';
import { useConfirmationData } from './confirmation.hook';

/**
 * Confirmation component
 * @description Content for the CloseAllPositions flow Confirmation stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-152283&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Confirmation: FC = () => {
  const { t, flowData, snapShotData, handleSubmitConfirmation } = useConfirmationData();

  return (
    <Modal modalId={ModalIds.closeAllPositionsConfirmation} title={t('modals.closeAllPositions.confirmation.title')}>
      {flowData && (
        <div className={styles.wrapper}>
          <Typography.Text variant={'bodyText5'} className={styles.label}>
            {t('modals.closeAllPositions.confirmation.description1')}
            <br />
            <br />
            {t('modals.closeAllPositions.confirmation.description2')}
          </Typography.Text>
          <div className={styles.buttons}>
            {flowData && snapShotData && (
              <CSVLink
                headers={snapShotData.headers}
                data={snapShotData.data}
                filename={`${flowData.account.AccountID}.csv`}
              >
                <Button size={'large'} variant={'outline'}>
                  <Typography.Text variant={'bodyText2'}>
                    {capitalize(t('modals.closeAllPositions.confirmation.controlButtons.report'))}
                  </Typography.Text>
                </Button>
              </CSVLink>
            )}
            <Button size={'large'} variant={'main'} onClick={handleSubmitConfirmation}>
              <Typography.Text variant={'bodyText2'}>
                {capitalize(t('modals.closeAllPositions.confirmation.controlButtons.continue'))}
              </Typography.Text>
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export { Confirmation };
