import { DragEvent, useCallback, useLayoutEffect, useState } from 'react';

import { KanbanCardProps } from './components/card/card.types';
import { KanbanProps } from './kanban.types';

/**
 * useKanbanData hook.
 *
 * @description The hook which processes Kanban component data.
 * @author Oleksii Medvediev
 * @param { KanbanProps } props - KanbanProps defined in the './kanban.types.ts'.
 * @returns an object with processed kanban columns and cards
 */
const useKanbanData = ({ cards, columns, areColumnsDraggable, additionalCardDropHandler }: KanbanProps) => {
  const [state, setState] = useState<Record<string, ReadonlyArray<KanbanCardProps>>>();
  const [dragoverCardIdAndPosition, setDragoverCardIdAndPosition] =
    useState<KanbanCardProps['dragoverCardAndPosition']>();

  const insertCard = useCallback(
    (cards: ReadonlyArray<KanbanCardProps>, card: KanbanCardProps, columnId: string) => {
      const dragoverItem = cards.find(({ id }) => id === dragoverCardIdAndPosition?.id);
      const dragoverItemIndex = dragoverItem && cards.indexOf(dragoverItem);

      if (typeof dragoverItemIndex !== 'undefined' && dragoverItemIndex > 0) {
        const startArr = [...cards].splice(
          0,
          dragoverCardIdAndPosition?.position === 'top' ? dragoverItemIndex : dragoverItemIndex + 1,
        );
        const endArr = [...cards].splice(
          dragoverCardIdAndPosition?.position === 'top' ? dragoverItemIndex : dragoverItemIndex + 1,
          cards.length - 1,
        );
        return [...startArr, { ...card, columnId }, ...endArr];
      } else if (dragoverItemIndex === 0) {
        const startArr = [...cards].splice(0, dragoverItemIndex + 1);
        const endArr = [...cards].splice(dragoverItemIndex + 1, cards.length - 1);
        return dragoverCardIdAndPosition?.position === 'top'
          ? [{ ...card, columnId }, ...cards]
          : [...startArr, { ...card, columnId }, ...endArr];
      }
      return [...cards, { ...card, columnId }];
    },
    [dragoverCardIdAndPosition?.id, dragoverCardIdAndPosition?.position],
  );

  const onDragOverTheCard = (id: string, position: 'top' | 'bottom') => setDragoverCardIdAndPosition({ id, position });
  const onColumnDrop = (id: string) => id;
  const onCardDrop = useCallback(
    (event: DragEvent, columnId: string) => {
      const card = Object.values(state ?? {})
        ?.flatMap((item) => item)
        ?.find(({ id }) => id === event.dataTransfer.getData('name'));

      if (card) {
        setState((prevState) =>
          card.columnId !== columnId
            ? {
                ...(prevState ?? {}),
                [columnId]: insertCard(
                  (prevState ?? {})[columnId].filter(({ id }) => id !== card.id),
                  card,
                  columnId,
                ),
                [card.columnId]: (prevState ?? {})[card.columnId]?.filter(({ id }) => id !== card.id),
              }
            : {
                ...(prevState ?? {}),
                [columnId]: insertCard(
                  (prevState ? prevState[columnId] : []).filter(({ id }) => id !== card.id),
                  card,
                  columnId,
                ),
              },
        );
        setDragoverCardIdAndPosition(undefined);
        additionalCardDropHandler && card?.columnId !== columnId && additionalCardDropHandler(card.id, columnId);
      }
    },
    [additionalCardDropHandler, insertCard, state],
  );

  useLayoutEffect(() => {
    for (const { id } of columns) {
      setState((prevState) => ({
        ...(prevState ?? {}),
        [id]: cards?.filter(({ columnId }) => columnId === id)?.map((card) => ({ ...card, onDragOverTheCard })),
      }));
    }
  }, [cards, columns]);

  return {
    state,
    dragoverCardIdAndPosition,
    onColumnDrop,
    onCardDrop,
    setDragoverCardIdAndPosition,
  };
};

export { useKanbanData };
