import * as yup from 'yup';

import { UserRole, setManagerParticipantsPortfolioTransfer } from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useMemo } from 'react';

import { FormProps } from './form.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const initialValues: {
  readonly toPortfolioId: string;
  readonly sumTransfer: number | undefined;
} = {
  toPortfolioId: '',
  sumTransfer: undefined,
};

/**
 * useFormData hook
 * @description The hook which processes WithdrawPortfolio flow Form stage data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useFormData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { transferBetweenPortfoliosForm },
    dispatch,
  } = useModalsContext();
  const { user, dispatch: appDispatch } = useAppContext();

  const validationSchema: yup.Schema<typeof initialValues> = yup.object({
    sumTransfer: yup
      .number()
      .typeError(t('modals.transferBetweenPortfolios.form.invalid'))
      .required(t('modals.transferBetweenPortfolios.form.required')),
    toPortfolioId: yup.string().required(),
  });

  const flowData: FormProps | undefined = useMemo(() => transferBetweenPortfoliosForm, [transferBetweenPortfoliosForm]);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.transferBetweenPortfoliosForm }),
    [dispatch],
  );

  const handleSubmitForm = useCallback(
    async ({ sumTransfer, toPortfolioId }: typeof initialValues) => {
      if (user && flowData) {
        if (
          (+`${sumTransfer ?? 0}`.replaceAll(' ', '')).toFixed(0) !==
          flowData.outputPortfolio.PortfolioCurrentCapital.toFixed(0)
        ) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });

          try {
            const { message } = await setManagerParticipantsPortfolioTransfer({
              sumTransfer: +(sumTransfer ?? '0')?.toString()?.replaceAll(' ', ''),
              roleLoginEmail: user.email,
              role: UserRole.manager,
              acctId: flowData.accountId,
              fromPortfolioId: flowData.outputPortfolio.PortfolioID,
              toPortfolioId,
            });

            if (message === 'ok') {
              flowData.onRefreshParticipants();

              dispatch({
                type: 'SHOW_MODAL',
                payload: {
                  id: ModalIds.transferBetweenPortfoliosSuccess,
                  data: {
                    transferBetweenPortfoliosSuccess: {
                      onSubmitSuccess: () => {
                        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.transferBetweenPortfoliosSuccess });
                      },
                    },
                  },
                },
              });
            }
          } catch (error) {
            toast.error('Failed to process set manager participants portfolio transfer!');
            console.error(error);
          }

          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        } else {
          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              id: ModalIds.transferBetweenPortfoliosTotalTransfer,
              data: {
                transferBetweenPortfoliosTotalTransfer: {
                  accountEmailOwner: flowData.clientEmailOwner,
                  onRefreshParticipants: flowData.onRefreshParticipants,
                  portfolio: flowData.outputPortfolio,
                  sumTransfer: sumTransfer ?? 0,
                  toPortfolioId,
                },
              },
            },
          });
        }

        handleCloseModal();
      }
    },
    [appDispatch, dispatch, flowData, handleCloseModal, user],
  );

  return {
    t,
    flowData,
    initialValues,
    validationSchema,
    handleSubmitForm,
  };
};

export { useFormData };
