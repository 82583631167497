import { FC } from 'react';
import { useCancelData } from './cancel.hook';

/**
 * Cancel component
 * @description Exclusively for the AutomatedOrdersConfirmation as its Cancel flow.
 * @link https://www.figma.com/file/pCwb00CHfXi1s4Yd5q3q0U/Trader--%7C-File-for-development?type=design&node-id=1786-117721&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Cancel: FC = () => {
  const { stages, currentStage } = useCancelData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { Cancel };
