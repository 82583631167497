import { Form, TechnicalRequirements } from './components';
import React, { FC } from 'react';

import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Stepper } from '../../../../../stepper';
import { SummaryProps } from './summary.types';
import styles from './summary.module.scss';

/**
 * Summary component
 * @description Content for the ChangeStrategy flow Summary stage
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-154174&mode=dev
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @param { SummaryProps } props - SummaryProps defined in the './summary.types.ts'
 * @category Components
 */
const Summary: FC<SummaryProps> = ({
  accountStrategy,
  initialValues,
  strategyRequirementsValidation,
  selectedStrategyRequirements,
  strategies,
  t,
  validationSchema,
  stepperProps,
  onSubmitStage,
  setSelectedStrategyRequirements,
  setStrategyRequirementsValidation,
}) => (
  <Modal
    modalId={ModalIds.changeStrategy}
    title={t('modals.changeStrategy.summary.title', {
      fullName: accountStrategy?.ClientNameOwner,
      accountId: accountStrategy?.AccountID,
    })}
  >
    <div className={styles.wrapper}>
      {stepperProps && <Stepper {...stepperProps} className={styles.stepper} />}
      <TechnicalRequirements
        strategyRequirementsValidation={strategyRequirementsValidation}
        accountStrategy={accountStrategy}
        selectedStrategyRequirements={selectedStrategyRequirements}
        t={t}
      />

      <Form
        t={t}
        validationSchema={validationSchema}
        initialValues={initialValues}
        strategies={strategies}
        accountStrategy={accountStrategy}
        setSelectedStrategyRequirements={setSelectedStrategyRequirements}
        setStrategyRequirementsValidation={setStrategyRequirementsValidation}
        onSubmit={onSubmitStage}
      />
    </div>
  </Modal>
);

export { Summary };
