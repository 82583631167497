import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TooltipBehavior, TooltipProps } from './tooltip.types';

import { Typography } from '../../../../../components';
import classNames from 'classnames';
import styles from './tooltip.module.scss';

const isBrowser = typeof window !== undefined;

/**
 * Tooltip component
 * @description Tooltip
 *
 * @author Vitalii Bodnarchuk, Sergii Goncharuk
 * @category Components
 * @param { TooltipProps } props - TooltipProps defined in the './tooltip.types.ts'
 */
const Tooltip: FC<TooltipProps> = ({ text, children, className, behavior = 'hover' }) => {
  const targetRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [placement, setPlacement] = useState<'top' | 'bottom' | 'topRight' | 'bottomRight'>('top');
  const showTooltip = useMemo(() => {
    if (behavior === TooltipBehavior.CLICK) {
      return isClicked;
    } else if (behavior === TooltipBehavior.FOCUS) {
      return isFocused;
    } else {
      return isHovered;
    }
  }, [behavior, isClicked, isFocused, isHovered]);

  useEffect(() => {
    setIsMounted(true);
  }, [showTooltip]);

  //TODO: add other cases for tooltip
  const handleResize = useCallback(() => {
    if (targetRef.current) {
      const { top, bottom } = targetRef.current.getBoundingClientRect();
      const tooltipHeight = tooltipRef.current?.getBoundingClientRect().height ?? 0;
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

      if (bottom + tooltipHeight * 3 > viewportHeight) {
        setPlacement('top');
      } else if (tooltipHeight * 3 > top) {
        setPlacement('bottom');
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 10);

    isBrowser && window.addEventListener('scroll', handleResize);

    return () => {
      isBrowser && window.removeEventListener('scroll', handleResize);
    };
  }, [handleResize]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (targetRef.current) {
      if (targetRef.current.contains(event.target as Node)) {
        return;
      }
      setIsClicked(false);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (behavior === TooltipBehavior.CLICK) {
      if (!isClicked) {
        document.addEventListener('click', handleOutsideClick, false);
      } else {
        document.removeEventListener('click', handleOutsideClick, false);
      }
      setIsClicked((prevState) => !prevState);
    } else {
      if (targetRef.current) {
        targetRef.current.blur();
      }
    }
  };

  return (
    <div className={classNames(styles.tooltip, className)}>
      <button
        className={styles.target}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        ref={targetRef}
      >
        {children}
      </button>
      {isMounted && (
        <div ref={tooltipRef} className={classNames(styles.tooltip, `${showTooltip ? styles.show : styles.hide}`)}>
          <div className={classNames(styles.centerContainer, styles[placement])}>
            <div className={classNames(styles.content, styles[placement])}>
              {typeof text === 'string' ? <Typography.Text variant={'label4'}>{text}</Typography.Text> : text}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { Tooltip };
