import { ModalIds } from '../../../../contexts/modals/modals.types';
import { useCallback } from 'react';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * usePasswordChangedData hook.
 * @description The hook which processes PasswordChanged modal window data
 *
 * @author Oleksii Medvediev
 */
const usePasswordChangedData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { dispatch } = useModalsContext();

  const handleCloseModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.passwordChanged });
  }, [dispatch]);

  return {
    t,
    handleCloseModal,
  };
};

export { usePasswordChangedData };
