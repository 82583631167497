import * as React from 'react';
import styles from './preloader.module.scss';

import { PreloaderProps } from './preloader.props';
import classNames from 'classnames';

/**
 * Renders Preloader
 */
const Preloader: React.FC<PreloaderProps> = ({ variant }) => {
  return (
    <div className={styles.overlay}>
      <div
        className={classNames(styles.overlayBar, {
          [styles.overlayBarBlank]: variant === 'blank',
        })}
      >
        <span className={styles.overlayBarFiller}></span>
      </div>
    </div>
  );
};

export { Preloader };
