import { Recommendation } from '../../../../../../../../../services/api';

/**
 * PlaceProps
 * @description Props for the Place component
 *
 * @author Oleksii Medvediev
 */
type PlaceProps = {
  readonly currentStage: PlaceStages;
  readonly recommendations: ReadonlyArray<Recommendation>;
  readonly onSubmitPlace: () => void;
};

enum PlaceStages {
  summary = 'summary',
  confirmation = 'confirmation',
}

export type { PlaceProps };
export { PlaceStages };
