import { FC, useState } from 'react';
import { FilterProps, FilterView } from './filter.types';
import { FormFilter, FormMonthlyCalendar } from './components';

import { FormFilterValues } from './components/form-filter/form-filter.types';

/**
 * Filter component
 * @description Filter
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev, Sergii Goncharuk
 * @category Components
 * @param { FilterProps } props - FilterProps defined in the './filter.types.ts'
 */
const Filter: FC<FilterProps> = ({ period, selectedFilters, onSubmit, onChange }) => {
  const [view, setView] = useState<FilterView>('form');
  const [filters, setFilters] = useState<FormFilterValues>(selectedFilters);

  const renderView: Record<FilterView, JSX.Element> = {
    form: (
      <FormFilter
        values={filters}
        onChange={(val) => {
          setFilters(val);
          onChange();
        }}
        onSubmit={onSubmit}
        openMonthlyCalendar={() => setView('monthlyCalendar')}
      />
    ),
    monthlyCalendar: (
      <FormMonthlyCalendar
        data={{
          period,
          selectedMonths: { a: filters.period?.a, b: filters.period?.b },
          setSelectedMonths: (period) =>
            setFilters({
              ...filters,
              period: !period.a && !period.b ? undefined : period,
            }),
        }}
        setFilterView={() => setView('form')}
      />
    ),
  };

  return renderView[view];
};

export { Filter };
