import { FC } from 'react';
import { PillTabProps } from './pill-tab.types';
import { TextProps } from '../../../../../../components/typography/text/text.types';
import { Typography } from '../../../../../../components';
import classNames from 'classnames';
import styles from './pill-tab.module.scss';

const TypographyVariants: Record<PillTabProps['size'], TextProps['variant']> = {
  small: 'label5',
  medium: 'bodyText3',
  large: 'label2',
};

/**
 * Exclusively PillTab component for Tab
 * @description Tab.Pill component
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { PillTabProps } props - TabProps defined in the './pill-tab.types.ts'
 */
const PillTab: FC<PillTabProps> = ({ label, size, isSelected, disabled, onClick }) => {
  return (
    <button
      tabIndex={0}
      aria-label={'button'}
      type={'button'}
      disabled={disabled}
      className={classNames(styles.pillTab, isSelected && styles.selected, styles[size])}
      onClick={onClick}
    >
      <Typography.Text variant={TypographyVariants[size]} className={styles.title}>
        {label}
      </Typography.Text>
    </button>
  );
};

export { PillTab };
