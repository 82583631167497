import { cancelLoginRequest, getTradeVisionConnectionStatus } from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useEffect, useMemo } from 'react';

import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

/**
 * useGiveAccessData hook.
 * @description The hook which processes GiveAccess component data
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Hooks
 */
const useGiveAccessData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { roleMigrations, dispatch: appDispatch, user } = useAppContext();
  const { dispatch: modalsDispatch } = useModalsContext();

  const checkInvestudioConnectionStatus = useCallback(async () => {
    if (user) {
      try {
        const { data } = await getTradeVisionConnectionStatus(user.email);
        if (data) {
          appDispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: data.connection });
          appDispatch({ type: 'SET_LOGIN_CANDIDATE', payload: data.loginCandidate });
          appDispatch({ type: 'SET_RESERVED_ROLES', payload: data.reservedRoles });
        } else {
          appDispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: false });
          appDispatch({ type: 'SET_LOGIN_CANDIDATE', payload: undefined });
          appDispatch({ type: 'SET_RESERVED_ROLES', payload: undefined });
        }
      } catch (error) {
        toast.error('Failed to fetch TradeVisionConnectionStatus!');
        console.error(error);
        appDispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: false });
        appDispatch({ type: 'SET_LOGIN_CANDIDATE', payload: undefined });
        appDispatch({ type: 'SET_RESERVED_ROLES', payload: undefined });
      }
    }
  }, [appDispatch, user]);

  const fullName = useMemo(() => roleMigrations?.loginCandidate?.name, [roleMigrations]);

  const rejectAccess = useCallback(async () => {
    if (roleMigrations?.loginCandidate) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        await cancelLoginRequest({ email: roleMigrations.loginCandidate.email });
        await checkInvestudioConnectionStatus();
        modalsDispatch({ type: 'HIDE_MODAL', payload: ModalIds.loginRequestGiveAccess });
      } catch (error) {
        toast.error('Failed to process cancel login and check connection status request!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, checkInvestudioConnectionStatus, modalsDispatch, roleMigrations?.loginCandidate]);

  const acceptHandler = useCallback(() => {
    modalsDispatch({ type: 'HIDE_MODAL', payload: ModalIds.loginRequestGiveAccess });
    appDispatch({ type: 'LOGOUT' });
  }, [appDispatch, modalsDispatch]);

  useEffect(() => {
    const interval = setInterval(() => checkInvestudioConnectionStatus(), 1000);

    return () => clearInterval(interval);
  }, [checkInvestudioConnectionStatus]);

  return { t, fullName, cancelHandler: rejectAccess, acceptHandler };
};

export { useGiveAccessData };
