import { AccordionBody, AccordionHeader } from './components';
import React, { FC, useEffect, useState } from 'react';

import { AccordionProps } from './accordion.types';
import classNames from 'classnames';
import styles from './accordion.module.scss';

/**
 * Message component
 * @description Accordion which have two parts: header and body, after click on header body will be shown
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 */
const Accordion: FC<AccordionProps> = ({
  id,
  title,
  body,
  isMessage,
  className,
  label,
  description,
  disabled = false,
  isExpanded = false,
  hideBottomLine = false,
  addBottomClose = false,
  bottomCloseTxt = '',
  onToggleExpandState,
}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(!!isExpanded);

  const toggleExpand = () => {
    if (!disabled) {
      setIsAccordionExpanded(!isAccordionExpanded);
      onToggleExpandState && onToggleExpandState(id);
    }
  };

  useEffect(() => {
    setIsAccordionExpanded(!!isExpanded);
  }, [isExpanded]);

  return (
    <div
      className={classNames(className, styles.accordion, disabled && styles.disabled)}
      style={{ backgroundColor: isMessage ? '#202025' : '' }}
    >
      <AccordionHeader
        label={label}
        title={title}
        description={description}
        isExpanded={isAccordionExpanded}
        onClick={toggleExpand}
        hideBottomLine={hideBottomLine}
        isMessage={isMessage}
      />
      <div className={classNames(styles.hidden, isAccordionExpanded && body && styles.expanded)}>
        {body && (
          <AccordionBody
            items={body.items}
            isMessage={isMessage}
            addBottomClose={addBottomClose}
            bottomCloseTxt={bottomCloseTxt}
            onClose={() => toggleExpand()}
          />
        )}
      </div>
    </div>
  );
};

export { Accordion };
