import React, { Ref, forwardRef } from 'react';

import { ButtonProps } from './button.types';
import classNames from 'classnames';
import styles from './button.module.scss';

/**
 * Button component
 * @description Button
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { ButtonProps } props - ButtonProps defined in the './button.types.ts'
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'medium',
      variant = 'main',
      leadingIcon,
      trailingIcon,
      selected,
      children,
      className,
      style,
      type = 'button',
      disabled,
      ignoreSVGColor,
      ignoreStopPropagation,
      onClick,
    },
    ref: Ref<HTMLButtonElement>,
  ) => (
    <button
      className={classNames(
        styles.button,
        styles[size],
        styles[variant],
        selected && styles.active,
        disabled && styles.disabled,
        !ignoreSVGColor && styles.ignoreSVGColor,
        className,
      )}
      type={type}
      disabled={disabled}
      style={style}
      tabIndex={0}
      aria-label={'button'}
      ref={ref}
      onClick={(event) => {
        !ignoreStopPropagation && event.stopPropagation();
        onClick && onClick(event);
      }}
      onPointerLeave={(event) => {
        event.currentTarget.blur();
      }}
    >
      {leadingIcon && <div className={styles.icon}>{leadingIcon}</div>}
      {children}
      {trailingIcon && <div className={styles.icon}>{trailingIcon}</div>}
    </button>
  ),
);

Button.displayName = 'Button';

export { Button };
