import React, { FC } from 'react';

import { ReactComponent as CheckIcon } from '../../../../../../../../../assets/icons/check2.svg';
import { ReactComponent as CrossIcon } from '../../../../../../../../../assets/icons/times.svg';
import { TechnicalRequirementsProps } from './technical-requirements.types';
import { Typography } from '../../../../../../../../../../../components';
import classNames from 'classnames';
import styles from './technical-requirements.module.scss';

/**
 * TechnicalRequirements component
 * @description Content for the Summary component requirements table.
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @param { TechnicalRequirementsProps } props - TechnicalRequirementsProps defined in the './technical-requirements.types.ts'
 * @category Components
 */
const TechnicalRequirements: FC<TechnicalRequirementsProps> = ({
  strategyRequirementsValidation: { areTradingPermissionsValid, isAccountTypeValid, isBrokerValid, isMinCapitalValid },
  selectedStrategyRequirements,
  accountStrategy,
  t,
}) => (
  <div className={styles.wrapper}>
    <Typography.Text variant={'bodyText2'}>
      {t('modals.changeStrategy.summary.technicalRequirements.title')}
    </Typography.Text>

    <div className={styles.table}>
      <div className={styles.col}>
        <Typography.Text variant={'bodyText5'} className={styles.label}>
          {t('modals.changeStrategy.summary.technicalRequirements.rows.0.label')}
        </Typography.Text>
        <Typography.Text variant={'bodyText5'} className={styles.label}>
          {t('modals.changeStrategy.summary.technicalRequirements.rows.1.label')}
        </Typography.Text>
        <Typography.Text variant={'bodyText5'} className={styles.label}>
          {t('modals.changeStrategy.summary.technicalRequirements.rows.2.label')}
        </Typography.Text>
        <Typography.Text variant={'bodyText5'} className={styles.label}>
          {t('modals.changeStrategy.summary.technicalRequirements.rows.3.label')}
        </Typography.Text>
      </div>
      <div className={styles.col}>
        <div className={styles.valueWrapper}>
          {isBrokerValid ? (
            <CheckIcon className={classNames(styles.icon, styles.checkIcon)} />
          ) : (
            <CrossIcon className={classNames(styles.icon, styles.crossIcon)} />
          )}
          <Typography.Text variant={'bodyText5'} className={styles.value}>
            {accountStrategy.Broker}
          </Typography.Text>
        </div>
        <div className={styles.valueWrapper}>
          {areTradingPermissionsValid ? (
            <CheckIcon className={classNames(styles.icon, styles.checkIcon)} />
          ) : (
            <CrossIcon className={classNames(styles.icon, styles.crossIcon)} />
          )}
          <Typography.Text variant={'bodyText5'} className={styles.value}>
            {accountStrategy.TradingPermissions.split(',').join(', ')}
          </Typography.Text>
        </div>
        <div className={styles.valueWrapper}>
          {isAccountTypeValid ? (
            <CheckIcon className={classNames(styles.icon, styles.checkIcon)} />
          ) : (
            <CrossIcon className={classNames(styles.icon, styles.crossIcon)} />
          )}
          <Typography.Text variant={'bodyText5'} className={styles.value}>
            {accountStrategy?.AccountType}
          </Typography.Text>
        </div>
        <div className={styles.valueWrapper}>
          {isMinCapitalValid ? (
            <CheckIcon className={classNames(styles.icon, styles.checkIcon)} />
          ) : (
            <CrossIcon className={classNames(styles.icon, styles.crossIcon)} />
          )}
          <Typography.Text variant={'bodyText5'} className={styles.value}>
            {t('modals.changeStrategy.summary.technicalRequirements.rows.3.value', {
              capital: selectedStrategyRequirements?.MinCapital ?? accountStrategy.MinCapital,
            })}
          </Typography.Text>
        </div>
      </div>
    </div>
  </div>
);

export { TechnicalRequirements };
