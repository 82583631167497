import React, { FC } from 'react';

import { MessagesIndicatorProps } from './messages-indicator.types';
import styles from './messages-indicator.module.scss';

/**
 * MessagesIndicator component
 * @description Messages count indicator
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { MessagesIndicatorProps } props - MessagesIndicatorProps defined in the './messages-indicator.types.ts'
 */
const MessagesIndicator: FC<MessagesIndicatorProps> = ({ label }) => <span className={styles.wrapper}>{label}</span>;

export { MessagesIndicator };
