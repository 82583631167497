import { TabProps } from '../tab.types';
import { Typography } from '../../../../../../components';
import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './date-picker-tab.module.scss';

/**
 * Exclusively DatePickerTab component for Tab
 * @description DatePickerTab tab
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { TabProps } props - TabProps defined in the '../tab-props.types.ts'
 */
const DatePickerTab = forwardRef<HTMLButtonElement, TabProps>(
  ({ label, isSelected, disabled, className, style, onClick }, ref) => (
    <button
      tabIndex={0}
      aria-label={'button'}
      type={'button'}
      disabled={disabled}
      className={classNames(styles.datePickerTab, isSelected && styles.selected, className)}
      style={style}
      onClick={onClick}
      ref={ref}
    >
      <Typography.Text variant={'bodyText1'} className={styles.title}>
        {label}
      </Typography.Text>
    </button>
  ),
);

DatePickerTab.displayName = 'DatePickerTab';

export { DatePickerTab };
