import { AuthContextProvider, useAuthContext } from './context';
import { FC, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { FallbackLoader } from '../../../../../components';

const Login = lazy(() => import('./components/login/login.component'));
const OfficeSelect = lazy(() => import('./components/office-select/office-select.component'));
const PasswordRecovery = lazy(() => import('./components/password-recovery/password-recovery.component'));
const PasswordRecoveryPending = lazy(
  () => import('./components/password-recovery-pending/password-recovery-pending.component'),
);
const NewPassword = lazy(() => import('./components/new-password/new-password.component'));
const PersonConfirmation = lazy(() => import('./components/person-confirmation/person-confirmation.component'));
const RootPreRegisterValidation = lazy(
  () => import('./components/root-pre-register-validation/root-pre-register-validation.component'),
);
const RootRegister = lazy(() => import('./components/root-register/root-register.component'));
const Register = lazy(() => import('./components/register/register.component'));
const LoginRequest = lazy(() => import('./components/login-request/login-request.component'));
const VerifyInvitation = lazy(() => import('./components/verify-invitation/verify-invitation.component'));
const Verify = lazy(() => import('./components/verify/verify.component'));
const Restricted = lazy(() => import('./components/restricted/restricted.component'));

/**
 * AuthRouter component
 * @description Router for the Auth pages
 *
 * @author Oleksii Medvediev, Rostyslav Nahornyi
 * @category Routers
 */
const AuthRouter: FC = () => {
  useAuthContext();

  return (
    <AuthContextProvider>
      <Routes>
        <Route
          path={'login'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path={'office-select'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <OfficeSelect />
            </Suspense>
          }
        />
        <Route
          path={'password-recovery'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <PasswordRecovery />
            </Suspense>
          }
        />
        <Route
          path={'password-recovery-pending'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <PasswordRecoveryPending />
            </Suspense>
          }
        />
        <Route
          path={'recover'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <NewPassword />
            </Suspense>
          }
        />
        <Route
          path={'person-confirmation'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <PersonConfirmation />
            </Suspense>
          }
        />
        <Route
          path={'root-pre-register-validation'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <RootPreRegisterValidation />
            </Suspense>
          }
        />
        <Route
          path={'root-register'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <RootRegister />
            </Suspense>
          }
        />
        <Route
          path={'register'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <Register />
            </Suspense>
          }
        />
        <Route
          path={'login-request'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <LoginRequest />
            </Suspense>
          }
        />
        <Route
          path={'verify-invitation'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <VerifyInvitation />
            </Suspense>
          }
        />
        <Route
          path={'verify'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <Verify />
            </Suspense>
          }
        />
        <Route
          path={'restricted'}
          element={
            <Suspense fallback={<FallbackLoader />}>
              <Restricted />
            </Suspense>
          }
        />
        <Route path={'*'} element={<Navigate to={'login'} />} />
      </Routes>
    </AuthContextProvider>
  );
};

export { AuthRouter };
