import { Button, Overlay, Portal, Typography } from '../../../../../components';
import { FC, useEffect } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/times.svg';
import { HeaderIcon } from './components';
import { ModalProps } from './modal.types';
import classNames from 'classnames';
import styles from './modal.module.scss';
import { useModalContext } from '../../context';

const isBrowser = typeof window !== 'undefined';
const isPropsValid = ({ variant, controlButtons }: ModalProps) => {
  if (variant === 'default' && !controlButtons.secondary) {
    throw new Error(`When variant is '${variant}', the secondary button must be with data. Validation error!`);
  }
  if (variant !== 'default' && controlButtons.secondary) {
    throw new Error(`When variant is '${variant}', the secondary button musn't be with data. Validation error!`);
  }
};

/**
 * Modal component.
 * @description Global modal window component
 *
 * @author Rostyslav Nohornyi, Oleksii Medvediev
 * @category Components
 * @param { ModalProps } props - ModalProps defined in the './modal.types.ts'
 */
const Modal: FC = () => {
  const { dispatch, isOpened, data } = useModalContext();

  data && isPropsValid(data);

  useEffect(() => {
    if (isBrowser && document) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  if (isOpened && data) {
    const { variant, title, content, controlButtons } = data;

    return (
      <Portal domNode={'modal-root'}>
        <Overlay isShown={!!isOpened} blur={7.5} onOverlayClick={() => dispatch({ type: 'HIDE_MODAL' })} />

        <div className={styles.modal}>
          <div className={classNames(styles.wrapper)}>
            <CloseIcon className={styles.closeIcon} onClick={() => dispatch({ type: 'HIDE_MODAL' })} />
            <HeaderIcon variant={variant} />

            <div className={styles.contentWrapper}>
              <div className={styles.content}>
                <Typography.Text variant={'h5'} className={styles.textCenter}>
                  {title}
                </Typography.Text>
                {typeof content === 'string' ? (
                  <Typography.Text variant={'bodyText3'} className={styles.textCenter}>
                    {content}
                  </Typography.Text>
                ) : (
                  <div className={styles.contentBox}>{content}</div>
                )}
              </div>

              <div className={styles.controlButtons}>
                <Button variant={'main2'} size={'medium'} {...controlButtons.main} />
                {controlButtons.secondary && (
                  <Button variant={'outline'} size={'medium'} {...controlButtons.secondary} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Portal>
    );
  }

  return null;
};

export { Modal };
