import React, { Ref, forwardRef, useEffect, useState } from 'react';

import { DropdownItem } from './components';
import { DropdownMenuProps } from './dropdown-menu.types';
import classNames from 'classnames';
import styles from './dropdown-menu.module.scss';

/**
 * DropdownMenu component
 * @description Menu of dropdown items
 *
 * @author Rostyslav Nahorny, Oleksii Medvediev
 * @category Components
 * @param { DropdownMenuProps } props - DropdownMenuProps defined in the './dropdown-menu-.types.ts'
 */
const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  ({ options, defaultValues, isOpened, multiple, style, className, onChange }, ref: Ref<HTMLDivElement>) => {
    const [values, setValues] = useState<ReadonlyArray<string>>(defaultValues ? defaultValues : []);

    useEffect(() => {
      !multiple && !values.length && defaultValues && setValues(defaultValues);
    }, [defaultValues, multiple, values.length]);

    const selectItem = (id: string) => {
      const isIdSame = values.some((value) => value === id);

      if (!multiple) {
        onChange(id);
        return setValues([]);
      }
      if (multiple && isIdSame) {
        return setValues(values.filter((value) => value !== id));
      }
      if (multiple && !isIdSame) {
        return setValues([...values, id]);
      }
      if (!multiple && !isIdSame) {
        return setValues([id]);
      }
    };

    useEffect(() => {
      multiple && onChange(values);
      // NEVER DO THAT AGAIN!!!!
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return isOpened ? (
      <div className={classNames(styles.dropdownMenu, className)} style={style} ref={ref}>
        {options.map(({ id, value, leadingIcon, disabled }, idx) => (
          <DropdownItem
            key={id}
            id={id}
            value={value}
            leadingIcon={leadingIcon}
            isSelected={values.some((value) => value === id)}
            className={classNames(
              styles.dropdownItem,
              idx === 0 && styles.dropdownItemBorderTop,
              idx === options.length - 1 && styles.dropdownItemBorderBottom,
            )}
            onSelect={(value) => !disabled && selectItem(value)}
            disabled={disabled}
          />
        ))}
      </div>
    ) : null;
  },
);

DropdownMenu.displayName = 'DropdownMenu';

export { DropdownMenu };
