import { ReportType } from '../../reports-flow.types';

enum GenerateReportType {
  portfolio = 'portfolio',
  account = 'account',
}

/**
 * GenerateReportProps
 * @description Props for the GenerateReport component
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 */
type GenerateReportProps = {
  readonly clientEmailOwner: string;
};

type GenerateReportValues = {
  readonly periodType: ReportType;
  readonly reportType: GenerateReportType;
  readonly selectedDate?: string;
  readonly account?: string;
  readonly portfolio?: string;
};

export type { GenerateReportProps, GenerateReportValues };
export { GenerateReportType };
