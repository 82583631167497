import React, { FC } from 'react';

import { StatusBarProps } from './status-bar.types';
import classNames from 'classnames';
import styles from './status-bar.module.scss';

/**
 * StatusBar component.
 * @description Status bar of portfolio
 *
 * @author Ihor Dubas, Oleksii Medvediev
 * @category Components
 * @param { StatusBarProps } props - StatusBarProps defined in the './progress-bar.types.ts'
 */
const StatusBar: FC<StatusBarProps> = ({ value, maxValue, className = { width: '100%' } }) => {
  const growthMax = (value / maxValue) * 100;

  return (
    <div className={classNames(className, styles.statusBar)}>
      <div className={styles.grow} style={{ width: growthMax.toFixed(0).toString().concat('%') }}></div>
    </div>
  );
};

export { StatusBar };
