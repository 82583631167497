import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../components';
import styles from './order-declination.module.scss';
import { useOrderDeclinationData } from './order-declination.hook';

/**
 * OrderDeclination component
 * @description Content for the OrderDeclination modal.
 * @link https://www.figma.com/file/pCwb00CHfXi1s4Yd5q3q0U/Trader--%7C-File-for-development?type=design&node-id=1787-138438&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const OrderDeclination: FC = () => {
  const { t, currentType, handleCloseModal } = useOrderDeclinationData();

  return (
    <Modal modalId={ModalIds.orderDeclination} title={t(`modals.orderDeclination.title-${currentType}`)}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>
          {t(`modals.orderDeclination.description-${currentType}`)}
        </Typography.Text>
        <Button type={'button'} variant={'main'} size={'large'} onClick={handleCloseModal}>
          <Typography.Text variant={'bodyText2'}>{t(`modals.orderDeclination.continue`)}</Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { OrderDeclination };
