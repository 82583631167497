import React, { forwardRef } from 'react';

import { TwoFaInputProps } from './two-fa-input.types';
import classNames from 'classnames';
import styles from './two-fa-input.module.scss';

/**
 * TwoFaInput component.
 * @description Exclusively for the TwoFa component's form.
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { TwoFaInputProps } props - TwoFaInputProps defined in the './two-fa-input.types.ts'
 */
const TwoFaInput = forwardRef<HTMLInputElement, TwoFaInputProps>(
  ({ onChange, onKeyUp, className, isErrorMessage }, ref) => (
    <input
      ref={ref}
      tabIndex={0}
      max={9}
      min={0}
      type={'number'}
      maxLength={1}
      placeholder="_"
      onInput={(event) => {
        if (event.currentTarget.value.length > event.currentTarget.maxLength)
          event.currentTarget.value = event.currentTarget.value.slice(0, event.currentTarget.maxLength);
      }}
      className={classNames(styles.twoFaInput, isErrorMessage && styles.error, className)}
      onClick={({ currentTarget }) => currentTarget.select()}
      onFocus={({ currentTarget }) => currentTarget.select()}
      onChange={onChange}
      onKeyUp={onKeyUp}
    />
  ),
);

TwoFaInput.displayName = 'TwoFaInput';

export { TwoFaInput };
