import {
  AccountApiModel,
  GetAccountCashFlowPayload,
  GetAccountCashFlowResponse,
  GetAccountInfoPayload,
  GetAccountInfoResponse,
  GetAccountsByEmailsPayload,
  GetAccountsByEmailsResponse,
} from './account.model';

import { request } from '../../http';

export const getAccounts = (json?: { readonly email: string }) =>
  request<{ readonly data: AccountApiModel['getAccounts'] }>({
    url: 'account',
    method: 'get',
    ...(json?.email && { params: { email: json.email } }),
  });

export const getAccountsByEmail = (data: GetAccountsByEmailsPayload) =>
  request<{ readonly data?: GetAccountsByEmailsResponse }>({
    method: 'post',
    url: 'account/get-accounts-by-email',
    data,
  });

export const getAccountCashFlow = (data: GetAccountCashFlowPayload) =>
  request<{ readonly data: GetAccountCashFlowResponse }>({
    method: 'get',
    url: `account/cashflow/${data.accountId}`,
  });

export const getAccountInfo = ({ accountId, portfolioId }: GetAccountInfoPayload) =>
  request<{ readonly data?: GetAccountInfoResponse }>({
    method: 'get',
    url: `account/${accountId}/${portfolioId}`,
  });
