import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../components';
import styles from './invoice-canceled-modal.module.scss';
import { useInvoiceCanceledModal } from './invoice-canceled-modal.hook';

/**
 * InvoiceCanceledModal component
 * @description Content for the AccountCanceled window
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 */
const InvoiceCanceledModal: FC = () => {
  const { t, isInvoiceDownloaded, handleContinueClick } = useInvoiceCanceledModal();

  return isInvoiceDownloaded ? (
    <Modal modalId={ModalIds.invoiceCanceled} title={t('modals.accountCanceled.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>{t('modals.accountCanceled.text')}</Typography.Text>
        <Button className={styles.button} variant={'main'} size={'large'} onClick={handleContinueClick}>
          <Typography.Text variant={'bodyText2'}>{t('modals.accountCanceled.button')}</Typography.Text>
        </Button>
      </div>
    </Modal>
  ) : null;
};

export { InvoiceCanceledModal };
