import React, { FC, ReactNode } from 'react';

import Preloader from '../../assets/icons/preloader_small_azure.png';
import { ReactComponent as RejectedIcon } from '../../assets/icons/times-circle.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/circle_check.svg';
import { ToastProps } from './toast.types';
import { Typography } from '../typography';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import styles from './toast.module.scss';

const icons: Record<ToastProps['status'], ReactNode> = {
  pending: <img src={Preloader} alt={'preloader'} className={classNames(styles.icon, styles.preloader)} />,
  rejected: <RejectedIcon className={styles.icon} />,
  success: <SuccessIcon className={styles.icon} />,
};

/**
 * Toast component
 * @description Component which displays action status message
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { ToastProps } props - ToastProps defined in the './toast.types.ts'
 */
const Toast: FC<ToastProps> = ({ status, text }) => {
  return (
    <div className={classNames(styles.wrapper, styles[`${status}Toast`])}>
      {icons[status]}
      <Typography.Text variant={'bodyText2'}>{capitalize(text)}</Typography.Text>
    </div>
  );
};

export { Toast };
