import { Action, SettingsState } from './settings.types';

/**
 * @description InitialState for the SettingsContext
 *
 * @author Oleksii Medvediev
 */
const initialState: SettingsState = {};

/**
 * @description Reducer function for the useReducer hook in the SettingsContextProvider component.
 *
 * @author Oleksii Medvediev
 * @param {SettingsState} state - State defined in the './settings.types.ts'
 * @param {Action} action - Action defined in the './settings.types.ts'
 * @returns updated SettingsContextProvider state.
 */
const settingsReducer = (state: SettingsState, action: Action): SettingsState => {
  switch (action.type) {
    case 'SET_ERROR_MESSAGE': {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }

    // case 'SET_SUPPORT_INFO': {
    case 'SET_BRANDING': {
      return {
        ...state,
        // supportInfo: action.payload,
        branding: action.payload,
      };
    }

    default:
      return state;
  }
};

export { initialState, settingsReducer };
