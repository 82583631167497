import { FC } from 'react';
import { useSetData } from './set.hook';

/**
 * Set component
 * @description Exclusively for the AutomatedOrdersConfirmation as its Set flow.
 * @link https://www.figma.com/file/pCwb00CHfXi1s4Yd5q3q0U/Trader--%7C-File-for-development?type=design&node-id=1786-117617&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Set: FC = () => {
  const { stages, currentStage } = useSetData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { Set };
