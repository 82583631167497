import React, { FC, useState } from 'react';

import { Button } from '../../../../../../../button';
import { ReactComponent as CalendarIcon } from '../../../../../../../../../assets/icons/calendar-16.svg';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { ReportType } from '../../../../reports-flow.types';
import { SummaryProps } from './summary.types';
import { Typography } from '../../../../../../../../../../../components';
import { formatPeriodLabel } from '../../collective-portfolios.hook';
import styles from './summary.module.scss';
import { useModalsContext } from '../../../../../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * Summary component
 * @description Content for the Reports CollectivePortfolios flow Summary stage
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2118-412405&mode=dev
 *
 * @author Oleksii Medvediev
 * @param { SummaryProps } props - SummaryProps defined in the './summary.types.ts'
 * @category Components
 */
const Summary: FC<SummaryProps> = ({ portfolios, minDate, period, onSetPeriod, onSubmitStage, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const [currentScreen, setCurrentScreen] = useState<'default' | 'calendar'>('default');
  const { dispatch } = useModalsContext();

  return currentScreen === 'default' ? (
    <Modal modalId={ModalIds.reportsCollectivePortfolios} title={t('modals.reports.summary.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>{t('modals.reports.summary.description')}</Typography.Text>
        <div className={styles.period}>
          <Typography.Text variant={'bodyText2'}>{t('modals.reports.summary.period')}</Typography.Text>
          <Button
            type={'button'}
            variant={'main'}
            size={'small'}
            leadingIcon={<CalendarIcon />}
            onClick={() => {
              dispatch({
                type: 'SHOW_MODAL',
                payload: {
                  id: ModalIds.monthlyCalendar,
                  data: {
                    monthlyCalendar: {
                      onSubmit: (startDate, endDate) => {
                        onSetPeriod({
                          startDate,
                          endDate,
                        });
                        setCurrentScreen('default');
                      },
                      onClose: () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.reportsCollectivePortfolios }),
                      onBackButtonClick: () => {
                        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.monthlyCalendar });
                        setCurrentScreen('default');
                      },
                      periods: { startDate: minDate },
                      singleItemSelect: true,
                    },
                  },
                },
              });
              setCurrentScreen('calendar');
            }}
          >
            <Typography.Text variant={'bodyText3'}>
              {period
                ? formatPeriodLabel(new Date(period.startDate), ReportType.monthly, t)
                : t('modals.reports.summary.period')}
            </Typography.Text>
          </Button>
        </div>
        <div className={styles.reportItems}>
          <Typography.Text variant={'bodyText2'}>{t('modals.reports.summary.objectCollective')}</Typography.Text>
          {portfolios.map(({ portfolioMember }, index) => (
            <div className={styles.reportItem} key={portfolioMember + index}>
              <Typography.Text variant={'label2'} className={styles.label}>
                {t('modals.reports.summary.portfolio')}
              </Typography.Text>
              <Typography.Text variant={'h7'}>{portfolioMember}</Typography.Text>
            </div>
          ))}
        </div>
        <div className={styles.controls}>
          <Button variant={'outline'} size={'large'} onClick={onClose}>
            <Typography.Text variant={'bodyText2'}>{t('modals.reports.summary.cancel')}</Typography.Text>
          </Button>
          <Button variant={'main'} size={'large'} onClick={onSubmitStage} disabled={!period}>
            <Typography.Text variant={'bodyText2'}>{t('modals.reports.summary.send')}</Typography.Text>
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
};

export { Summary };
