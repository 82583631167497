import React, { FC, useEffect, useRef, useState } from 'react';

import { InputProps } from './input.types';
import { Typography } from '../typography';
import classNames from 'classnames';
import styles from './input.module.scss';
import { useDebounce } from '../../hooks';

/**
 * Input component.
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev, Sergii Goncharuk
 * @category Components
 * @param { InputProps } props - InputProps defined in the './input.types.ts'
 */
const Input: FC<InputProps> = ({
  onChange,
  error,
  tailingIcon,
  label,
  debounceDelay = 750,
  leadingIcon,
  shouldHideErrorMessageText = false,
  className,
  leftDescription,
  rightDescription,
  onBlur,
  isCurrency,
  ignoreSvgColor,
  ...inputProps
}) => {
  const [inputValue, setInputValue] = useState<typeof inputProps.defaultValue | null>(
    inputProps.defaultValue ?? inputProps.value ?? null,
  );
  const debouncedValue = useDebounce(inputValue, debounceDelay);

  const labelRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    setInputValue(inputProps.value);
  }, [inputProps.value]);

  useEffect(() => {
    (debouncedValue || debouncedValue === '') &&
      onChange(typeof debouncedValue === 'string' ? debouncedValue.trim() : debouncedValue.toString());
    // NEVER DO THAT AGAIN!!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    inputProps.defaultValue && setInputValue(inputProps.defaultValue);
  }, [inputProps.defaultValue]);

  const formatValue = (val: string): string =>
    val
      .replaceAll(' ', '')
      .replace(/(?!-)[^0-9.]/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return (
    <div className={classNames(styles.wrapper, className, inputProps.disabled && styles.disabled)}>
      {label && (
        <label
          ref={labelRef}
          htmlFor={inputProps.id}
          className={classNames(
            styles.label,
            leadingIcon && styles.marginedLabel,
            error && styles.errored,
            (inputValue || inputValue === 0) && styles.movedLabel,
          )}
        >
          {label}
        </label>
      )}
      <div
        className={classNames(
          styles.inputContainer,
          tailingIcon && styles.withTailingIcon,
          leadingIcon && styles.withLeadingIcon,
          error && styles.errored,
          isCurrency && styles.currencyContainer,
        )}
      >
        {leadingIcon && (
          <div className={classNames(styles.icon, styles.leadingIcon, !ignoreSvgColor && styles.formattedSVGIcon)}>
            {leadingIcon}
          </div>
        )}
        <input
          {...inputProps}
          autoComplete={inputProps.autoComplete}
          value={!inputValue ? '' : isCurrency && typeof inputValue === 'string' ? formatValue(inputValue) : inputValue}
          name={inputProps.name}
          id={inputProps.id}
          disabled={inputProps.disabled}
          onChange={({ currentTarget: { value } }) => setInputValue(isCurrency ? formatValue(value) : value)}
          className={styles.inputElement}
          type={isCurrency ? 'string' : inputProps.type}
          onFocus={() => labelRef.current?.classList.add(styles.movedLabel)}
          onBlur={(event) => {
            !inputValue && !debouncedValue && labelRef.current?.classList.remove(styles.movedLabel);
            onBlur && onBlur(event);
          }}
          readOnly={inputProps.readOnly}
        />
        {tailingIcon && (
          <div className={classNames(styles.icon, styles.tailingIcon, !ignoreSvgColor && styles.formattedSVGIcon)}>
            {tailingIcon}
          </div>
        )}
      </div>
      {((error && !shouldHideErrorMessageText) || (leftDescription && !error) || rightDescription) && (
        <div className={styles.inputFooter}>
          {error &&
            !shouldHideErrorMessageText &&
            (Array.isArray(error) ? (
              <div className={styles.errorList}>
                {error.map((err, idx) => (
                  <span key={idx} className={styles.error}>
                    {error}
                  </span>
                ))}
              </div>
            ) : (
              <span className={styles.error}>{error}</span>
            ))}
          {typeof leftDescription === 'string' && !error && (
            <Typography.Text className={styles.leftDescription} variant="label4">
              {leftDescription}
            </Typography.Text>
          )}
          {Array.isArray(leftDescription) && !error && (
            <div className={styles.leftDesciptionList}>
              {leftDescription.map((description, idx) => (
                <Typography.Text className={styles.leftDescription} key={idx} variant="label4">
                  {description}
                </Typography.Text>
              ))}
            </div>
          )}
          {rightDescription && (
            <div
              role="button"
              tabIndex={0}
              className={classNames(styles.rightDescription, rightDescription.onClick && styles.pointer)}
              onClick={() => rightDescription.onClick && rightDescription.onClick()}
              onKeyDown={() => rightDescription.onClick && rightDescription.onClick()}
            >
              <Typography.Text variant={'label3'}>{rightDescription.label}</Typography.Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { Input };
