import { FC, useLayoutEffect, useRef, useState } from 'react';

import { DropdownMenu } from './components/dropdown-menu';
import { DropdownProps } from './dropdown.types';
import { Portal } from '../portal';
import classNames from 'classnames';
import styles from './dropdown.module.scss';
import { useClickOutside } from '../../hooks';

/**
 * Dropdown component
 * @description Dropdown complete element
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { DropdownProps } props - DropdownProps defined in the './dropdown.types.ts'
 */
const Dropdown: FC<DropdownProps> = ({
  forcePositionLeft,
  button,
  isHeightLimited = true,
  className,
  disabled = false,
  onToggle,
  ...menuProps
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [coordinates, setCoordinates] = useState<Record<'x' | 'y', number | undefined>>();
  const menuRef = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownMenuRef, () => {
    setCoordinates(undefined);
    setIsOpened(false);
    onToggle && onToggle(false);
  });

  useLayoutEffect(() => {
    if (dropdownMenuRef?.current?.style) {
      dropdownMenuRef.current.style.opacity = '0';
    }

    setTimeout(() => {
      if (!dropdownMenuRef.current || !menuRef.current || !coordinates?.x || !coordinates.y) return;

      dropdownMenuRef.current.style.opacity = '1';

      const menuGapPixels = 8;
      const menuHeight = dropdownMenuRef.current.getBoundingClientRect().height;
      const menuWidth = dropdownMenuRef.current.getBoundingClientRect().width;
      const { top, left, width, height } = menuRef.current?.getBoundingClientRect();
      const shouldAvoidBottom = top + menuHeight + height + menuGapPixels > window?.innerHeight;
      const shouldAvoidRight = left + menuWidth + height > window?.innerWidth;
      const shouldAvoidBottomRightCorner =
        top + menuHeight > window?.innerHeight && left + menuWidth > window?.innerWidth;

      if (shouldAvoidBottomRightCorner) {
        dropdownMenuRef.current.style.top = `${top - menuHeight - menuGapPixels}px`;
        dropdownMenuRef.current.style.left = `${left - menuWidth + width}px`;
      } else if (shouldAvoidBottom) {
        dropdownMenuRef.current.style.top = `${top - menuHeight - menuGapPixels}px`;
        dropdownMenuRef.current.style.left = forcePositionLeft ? `${left - menuWidth + width}px` : `${left}px`;
      } else if (shouldAvoidRight) {
        dropdownMenuRef.current.style.top = `${top + height + menuGapPixels}px`;
        dropdownMenuRef.current.style.left = `${left - menuWidth + width}px`;
      } else {
        dropdownMenuRef.current.style.top = `${top + height + menuGapPixels}px`;
        dropdownMenuRef.current.style.left = forcePositionLeft ? `${left - menuWidth + width}px` : `${left}px`;
      }
    }, 0);
  }, [coordinates, forcePositionLeft]);

  return (
    <>
      <div className={classNames(styles.wrapper, className, disabled ? styles.disabled : '')} ref={menuRef}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {
            setCoordinates({
              x: menuRef.current?.getBoundingClientRect().left,
              y: menuRef.current?.getBoundingClientRect().top,
            });
            setIsOpened(!isOpened);
            onToggle && onToggle(!isOpened);
          }}
          onClick={() => {
            setCoordinates({
              x: menuRef.current?.getBoundingClientRect().left,
              y: menuRef.current?.getBoundingClientRect().top,
            });
            setIsOpened(!isOpened);
            onToggle && onToggle(!isOpened);
          }}
        >
          {{
            ...button,
            props: {
              ...button.props,
              style: { cursor: disabled ? 'not-allowed' : 'pointer' },
            },
          }}
        </div>
      </div>
      <Portal domNode={'dropdown-root'}>
        <div
          ref={dropdownMenuRef}
          className={classNames(
            styles.dropdownMenu,
            !menuRef.current || !dropdownMenuRef.current ? styles.noVisible : styles.isVisible,
            isHeightLimited && styles.heightLimited,
          )}
        >
          {dropdownMenuRef.current && menuRef.current && (
            <DropdownMenu
              {...menuProps}
              isOpened={isOpened}
              onChange={(value) => {
                menuProps.onChange(value);
                !menuProps.multiple && setIsOpened(false);
                !menuProps.multiple && onToggle && onToggle(false);
              }}
            />
          )}
        </div>
      </Portal>
    </>
  );
};

export { Dropdown };
