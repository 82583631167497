import React, { FC, useCallback } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../components';
import styles from './mail-server-error-modal.module.scss';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * MailServerError component
 * @description Content for the MailServerError window
 *
 * @author Ihor Dubas, Oleksii Medvediev
 * @category Components
 */
const MailServerErrorModal: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { dispatch } = useModalsContext();

  const closeModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.mailServerError });
  }, [dispatch]);

  return (
    <Modal modalId={ModalIds.mailServerError} title={t('modals.settingsMailServerError.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>{t('modals.settingsMailServerError.description')}</Typography.Text>
        <Button className={styles.closeBtn} size="large" onClick={closeModal}>
          <Typography.Text variant={'bodyText2'}>{t('modals.settingsMailServerError.button')}</Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { MailServerErrorModal };
