import './localization/localization.config';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toastify.module.scss';

import { AuthApp, ClientOfficeApp, ManagementOfficeApp } from './modules';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Localization } from './localization';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Localization>
    <BrowserRouter>
      <Routes>
        <Route path={'/auth/*'} element={<AuthApp />} />
        <Route path={'/management/*'} element={<ManagementOfficeApp />} />
        <Route path={'/client/*'} element={<ClientOfficeApp />} />
        <Route path={'*'} element={<Navigate to={'auth'} />} />
      </Routes>
    </BrowserRouter>
    <ToastContainer
      toastStyle={{
        backgroundColor: '#202025',
        color: '#f5f5f5',
        boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.4)',
        fontSize: '14px',
        lineHeight: '16px',
      }}
      theme={'dark'}
      stacked
      position={'bottom-right'}
      autoClose={3000}
      pauseOnHover
    />
  </Localization>,
);

reportWebVitals();
