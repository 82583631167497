import { Button, Checkbox, Indicator, Typography } from '../../../../../../../../../../../components';

import { ReactComponent as CalendarIcon } from '../../../../../../../../../assets/icons/calendar.svg';
import { ReactComponent as CloseIcon } from '../../../../../../../../../assets/icons/times.svg';
import { FC } from 'react';
import { FormFilterProps } from './form-filter.types';
import { ReactComponent as InfoIcon } from '../../../../../../../../../assets/icons/info-circle.svg';
import { Priority } from '../../../../../../../../../../../components/indicator/components/status-indicator/status-indicator.types';
import { Tooltip } from '../../../../../../../../components';
import classNames from 'classnames';
import styles from './form-filter.module.scss';
import { useFormFilterData } from './form-filter.hook';

/**
 * FormFilter component
 * @description FormFilter
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 * @param { FormFilterProps } props - FormFilterProps defined in the './form-filter.types.ts'
 */

const FormFilter: FC<FormFilterProps> = ({ values, onChange, openMonthlyCalendar, onSubmit }) => {
  const { t, statuses, buttonLabel, closeFiltersDrawer, changeStatusItemHandler } = useFormFilterData({
    values,
    onChange,
  });

  const TooltipContent: FC = () => (
    <div className={styles.tooltipContent}>
      {[Priority.success, Priority.info, Priority.alarm].map((priority, idx) => (
        <Typography.Text key={priority} variant={'bodyText3'} className={styles.tooltipItem}>
          <Indicator.Status priority={priority} />
          {t(`pages.dashboard.notifications-drawer.filter.form.status.tooltip.${idx}`)}
        </Typography.Text>
      ))}
    </div>
  );

  // TODO: change to FORMIK
  return (
    <form className={styles.wrapper}>
      <div className={styles.header}>
        <Typography.Text variant={'h5'}>{t('pages.dashboard.notifications-drawer.filter.form.title')}</Typography.Text>
        <CloseIcon className={styles.icon} onClick={() => closeFiltersDrawer()} />
      </div>

      <div className={styles.content}>
        <div className={styles.period}>
          <Button
            className={styles.button}
            variant={'outline'}
            size={'medium'}
            leadingIcon={<CalendarIcon />}
            label={values.period ? buttonLabel : t('pages.dashboard.notifications-drawer.filter.form.choosePeriod')}
            onClick={() => openMonthlyCalendar && openMonthlyCalendar()}
          />
        </div>

        <div className={styles.divider} />

        <div className={styles.readStatuses}>
          <Checkbox
            onChange={() => {
              onChange({
                ...values,
                statuses: undefined,
                isRead: values.isRead === true ? undefined : true,
              });
            }}
            isChecked={!!values.isRead}
            label={t('pages.dashboard.notifications-drawer.filter.form.readStatus.read')}
          />
          <Checkbox
            onChange={() => {
              onChange({ ...values, isRead: values.isRead === false ? undefined : false });
            }}
            isChecked={values.isRead === false}
            label={t('pages.dashboard.notifications-drawer.filter.form.readStatus.unread')}
          />
        </div>

        <div className={styles.divider} />

        <div
          role={'button'}
          tabIndex={0}
          className={classNames(styles.statuses, !!values.isRead && styles.disabled)}
          onClick={(e) => !!values.isRead && e.preventDefault()}
          onKeyDown={(e) => !!values.isRead && e.preventDefault()}
        >
          <div className={styles.label}>
            <Typography.Text variant={'bodyText1'}>
              {t('pages.dashboard.notifications-drawer.filter.form.status.title')}
            </Typography.Text>
            <Tooltip content={<TooltipContent />}>
              <InfoIcon className={styles.icon} />
            </Tooltip>
          </div>
          {statuses.map((priority, idx) => (
            <div key={priority} className={styles.statusItem}>
              <Checkbox
                onChange={() => changeStatusItemHandler(priority, values, onChange)}
                isChecked={!!values.statuses?.includes(priority)}
                label={t(`pages.dashboard.notifications-drawer.filter.form.status.values.${idx}`)}
              />
              <div className={styles.indicator}>
                <Indicator.Status priority={priority} />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.divider} />
      </div>

      <div className={styles.controlButtons}>
        {Object.keys(values).length ? (
          <Button
            size={'medium'}
            variant={'outline'}
            onClick={() => {
              onChange({});
              onSubmit({});
            }}
          >
            <Typography.Text variant={'label1'}>
              {t('pages.dashboard.notifications-drawer.filter.form.controlButtons.reset')}
            </Typography.Text>
          </Button>
        ) : null}
        <Button
          size={'medium'}
          variant={'outline'}
          disabled={!Object.keys(values).length}
          onClick={() => {
            onSubmit(values);
            closeFiltersDrawer();
          }}
        >
          <Typography.Text variant={'label1'}>
            {t('pages.dashboard.notifications-drawer.filter.form.controlButtons.apply')}
          </Typography.Text>
        </Button>
      </div>
    </form>
  );
};

export { FormFilter };
