import React, { FC } from 'react';

import { InformationPanelProps } from './information-panel.types';
import { Typography } from '../../../../../components';
import styles from './information-panel.module.scss';

/**
 * InformationPanel component
 * @description Information panel separated into horizontal sections (https://www.figma.com/file/3F1NN6p1bcDJVxnKnBCi7d/Investudio-desktop%7C-UI-Kit-(new)?type=design&node-id=11-2586&mode=dev).
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const InformationPanel: FC<InformationPanelProps> = ({ sections }) => (
  <div className={styles.wrapper}>
    {!!sections.length &&
      sections.map(({ label, data }, index) => (
        <div className={styles.section} key={label.concat(index.toString())}>
          <Typography.Text variant={'label3'} className={styles.label}>
            {label}
          </Typography.Text>
          <Typography.Text variant={'h5'}>{data}</Typography.Text>
        </div>
      ))}
  </div>
);

export { InformationPanel };
