import React, { FC } from 'react';

import { NavLink } from 'react-router-dom';
import { Tab } from '../tab';
import { TabMenuProps } from './tab-menu.types';
import classNames from 'classnames';
import styles from './tab-menu.module.scss';

const renderTabItem = (item: TabMenuProps['tabs'][0], key?: string) => ({
  settings: <Tab.Settings {...item} key={key} />,
  chart: <Tab.Chart {...item} key={key} />,
});

/**
 * TabMenu component
 * @description The horizontal menu with switchable tabs
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { TabMenuProps } props - TabMenuProps defined in the './tab-menu.types.ts'
 */
const TabMenu: FC<TabMenuProps> = ({ tabs, menuType, className }) => (
  <div className={classNames(styles.wrapper, menuType === 'settings' && styles.settingsMenuWrapper, className)}>
    {tabs && tabs.map((item, index) =>
      item.link ? (
        <NavLink key={item.link.concat(item.label ?? index.toString())} to={item.link} className={styles.link}>
          {({ isActive }) => renderTabItem({ ...item, isSelected: isActive })[menuType]}
        </NavLink>
      ) : (
        renderTabItem(item, item.label ?? index.toString())[menuType]
      ),
    )}
  </div>
);

export { TabMenu };
