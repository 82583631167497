import { FC } from 'react';
import { useDeleteData } from './delete.hook';

/**
 * Delete component
 * @description Content for the UsersManagement Delete flow.
 * @link https://www.figma.com/file/PiyMHA6tRkvaEpfgDnzUPI/Administrator--%7C-File-for-development?type=design&node-id=1525-51488&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Delete: FC = () => {
  const { stages, currentStage } = useDeleteData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { Delete };
