import { AssetContextProvider, useAssetContext } from './context';
import { Navigate, Route, Routes } from 'react-router';
import React, { FC, Suspense, lazy } from 'react';

import { Preloader } from '../../../../../components';

const Asset = lazy(() => import('./components/asset/asset.component'));

const AssetRouter: FC = () => {
  useAssetContext();

  return (
    <AssetContextProvider>
      <Routes>
        <Route
          path={''}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <Asset />
            </Suspense>
          }
        />
        <Route path={'*'} element={<Navigate to={''} />} />
      </Routes>
    </AssetContextProvider>
  );
};

export { AssetRouter };
