import {
  Area,
  ComposedChart,
  Label,
  Line,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomLabelProps, StocksChartProps } from './stocks-chart.types';
import React, { FC, memo, useState } from 'react';

import { Typography } from '../../../../../components';
import dayjs from 'dayjs';
import styles from './stocks-chart.module.scss';
import { useStocksChart } from './stocks-chart.hook';

/**
 * StocksChartComponent
 * @description StocksChart
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Component
 * @param { StocksChartProps } props - StocksChartProps defined in the './stocks-chart.types.ts'
 */
const StocksChartComponent: FC<StocksChartProps> = (props) => {
  const { down, targetPricePercent, targetPrice } = props;
  const {
    list,
    weekSegment,
    daySegment,
    min,
    max,
    color,
    gradientColor,
    ticks,
    secondaryActiveDot,
    targetPriceOffsetCoef,
    green,
    red,
    tickFormatter,
  } = useStocksChart(props);
  const [start, end] = weekSegment;
  const [tpY, setTpY] = useState<number>();

  const gradientSeries = [
    {
      name: 'gray',
      data: [...(list ?? [])].splice(0, list.findIndex(({ date }) => date === start?.x) + 1).map((item, index) => ({
        count: index.toString(),
        value: item,
      })),
    },
    {
      name: 'gradient',
      data: [...(list ?? [])]
        .splice(
          list.findIndex(({ date }) => date === start?.x),
          list.length - 1,
        )
        .map((item, index) => ({
          count: (index + list.findIndex(({ date }) => date === start?.x) + 1).toString(),
          value: item,
        })),
    },
  ];

  const CustomLabel = (props: CustomLabelProps, targetPrice: number) => (
    <svg
      id={'customLabel'}
      x={props.viewBox.x - 40}
      y={props.viewBox.y}
      width="54"
      height="22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="54" height="22" rx="2" fill="#62626F" />
      <text x={-2} r={0} fill="#FFFFFF" dy={15} dx={10} fontSize={12} fontWeight={400}>
        ${targetPrice % 1 === 0 ? `${targetPrice}.00` : targetPrice.toString()}
      </text>
    </svg>
  );

  return (
    <div className={styles.stockChart}>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <ComposedChart data={[...list]} margin={{ bottom: 0, top: 10, left: 0, right: 0 }}>
          <defs>
            <linearGradient id={`color${down ? 'Red' : 'Green'}`} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
              <stop offset={'0%'} stopColor={gradientColor} stopOpacity={0.3} />
              <stop offset={'100%'} stopColor={gradientColor} stopOpacity={0} />
            </linearGradient>
            <linearGradient id={'colorWhite'} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
              <stop offset={'0%'} stopColor={'#fff'} stopOpacity={0.3} />
              <stop offset={'100%'} stopColor={'#fff'} stopOpacity={0} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey={'date'}
            orientation={'bottom'}
            type={'category'}
            ticks={[...ticks]}
            tickFormatter={tickFormatter}
            tickLine={false}
            axisLine={false}
            interval={'preserveStartEnd'}
            style={{
              textTransform: 'capitalize',
              fontSize: '12px',
              fontWeight: '400',
            }}
            allowDuplicatedCategory={false}
          />

          <YAxis
            hide
            domain={[min * 0.5, max > (targetPrice ?? 0) ? max * 1 : (targetPrice ?? 0) + targetPriceOffsetCoef]}
          />
          <Tooltip
            content={({ active, payload }) => {
              if (!active) return null;

              const [value] = payload || [];
              const date = dayjs(payload?.[0]?.payload?.date).format('DD/MM/YY');
              return (
                <>
                  <Typography.Text variant={'bodyText5'}>{date}</Typography.Text>
                  <div className={styles.tooltip} style={{ background: gradientColor }}>
                    <Typography.Number
                      variant={'bodyText5'}
                      currency
                      round={2}
                      value={value?.payload?.close || value?.payload?.week}
                    />
                  </div>
                </>
              );
            }}
            position={{ y: 0 }}
          />
          <Tooltip />

          {!!weekSegment?.length && !daySegment ? (
            gradientSeries.map(({ data, name }) =>
              name === 'gray' ? (
                <Area
                  data={data.map((data) => data.value)}
                  key={name}
                  type={'linear'}
                  dataKey={'close'}
                  stroke={'#fff'}
                  strokeWidth={'2px'}
                  fillOpacity={1}
                  fill={'url(#colorWhite)'}
                />
              ) : (
                <Area
                  key={name}
                  data={data.map((data) => data.value)}
                  type={'linear'}
                  dataKey={'close'}
                  stroke={color}
                  strokeWidth={'2px'}
                  fillOpacity={1}
                  fill={`url(#color${down ? 'Red' : 'Green'})`}
                />
              ),
            )
          ) : (
            <Area
              type={'linear'}
              dataKey={'close'}
              stroke={!weekSegment?.length && !daySegment ? color : '#fff'}
              strokeWidth={'2px'}
              fillOpacity={1}
              fill={!weekSegment?.length && !daySegment ? `url(#color${down ? 'Red' : 'Green'})` : 'url(#colorWhite)'}
            />
          )}

          <Line
            type={'monotone'}
            dataKey={'project'}
            stroke={'#FFF'}
            strokeDasharray={'3 3'}
            dot={false}
            activeDot={secondaryActiveDot}
          />
          {/* Lines for the week option */}
          <ReferenceLine x={start?.x} strokeWidth={'2px'} stroke={'#fff'} strokeDasharray={'2'} />
          <ReferenceLine x={end?.x} strokeWidth={'2px'} stroke={'#fff'} strokeDasharray={'2'} />
          <ReferenceDot x={start?.x} y={start?.y} stroke={color} strokeWidth={'4px'} r={5} />
          <ReferenceDot x={end?.x} y={end?.y} stroke={color} strokeWidth={'4px'} r={5} />
          {!!targetPrice && !!targetPricePercent && (
            <ReferenceLine y={targetPrice} strokeWidth={'0.5px'} stroke={'#fff'} strokeDasharray={'4'} />
          )}
          {ticks[ticks.length - 1] && targetPrice && targetPrice !== 0 && !!targetPricePercent && (
            <ReferenceDot
              x={ticks[ticks.length - 1]}
              y={targetPrice}
              stroke={'none'}
              fill={'none'}
              label={(props) => {
                setTpY(props.viewBox.y);
                return CustomLabel(props, targetPrice);
              }}
            />
          )}
          {/* Line for the day option */}
          <ReferenceLine x={daySegment?.x} strokeWidth={'2px'} stroke={'#fff'} strokeDasharray={'2'} />
          <ReferenceDot
            x={daySegment?.x}
            y={daySegment?.y}
            stroke={
              props.data?.list[props.data.list.length - 1]?.close < props.data?.list[props.data.list.length - 2]?.close
                ? red
                : green
            }
            strokeWidth={'4px'}
            r={5}
          />
          {/* End line label */}
          {list[list.length - 1] && (
            <ReferenceDot x={list[list.length - 1].date} y={end ? end.y + 2 : list[list.length - 1]?.close + 2} r={0}>
              <Label
                position={'left'}
                value={`$${end ? end.y : list[list.length - 1]?.close}`}
                offset={0}
                style={{
                  fill: !daySegment?.x
                    ? color
                    : props.data?.list[props.data.list.length - 1]?.close <
                        props.data?.list[props.data.list.length - 2]?.close
                      ? red
                      : green,
                  fontWeight: 600,
                  fontSize: '10px',
                }}
              />
            </ReferenceDot>
          )}
        </ComposedChart>
      </ResponsiveContainer>
      {typeof targetPricePercent !== 'undefined' && (
        <div
          className={styles.targetPriceLabel}
          style={{
            top: (tpY ?? 0) - 12,
          }}
        >
          Target Price ({Number(targetPricePercent).toFixed(1)}%)
        </div>
      )}
    </div>
  );
};

const StocksChart = memo(StocksChartComponent);
export { StocksChart };
