import { FC } from 'react';
import { ReactComponent as GreenCheck } from '../../../../../assets/icons/check.svg';
import { HeaderIconProps } from './header-icon.types';
import { Preloader } from '../../../../../../../components';
import { ReactComponent as RedTriangle } from '../../../../../assets/icons/exclamation-triangle.svg';
import classNames from 'classnames';
import styles from './header-icon.module.scss';

/**
 * HeaderIcon component
 * @description Modal window overlay
 *
 * @category Components
 * @param { HeaderIconProps } props - HeaderIconProps defined in the './overlay.types.ts'
 */
const HeaderIcon: FC<HeaderIconProps> = ({ variant }) => {
  if (variant === 'default') return <></>;

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.circleWrapper, styles[variant])}>
        {variant === 'error' && <RedTriangle />}
        {variant === 'success' && <GreenCheck className={styles.icon} />}
        {variant === 'loading' && <Preloader type="small" isActive={true} />}
      </div>
    </div>
  );
};

export { HeaderIcon };
