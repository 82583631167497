import { FC } from 'react';

import { CSSTransition } from 'react-transition-group';
import styles from './overlay.module.scss';
import { OverlayProps } from './overlay.types';

/**
 * Overlay component
 * @description Window overlay
 *
 * @category Components
 * @param { OverlayProps } props - OverlayProps defined in the './overlay.types.ts'
 */
const Overlay: FC<OverlayProps> = ({ isShown, onOverlayClick, blur }) => {
  if (!isShown) return null;

  return (
    <CSSTransition in={isShown} timeout={200} unmountOnExit classNames={styles}>
      <div
        tabIndex={0}
        role={'button'}
        style={{ backdropFilter: `blur(${blur}px)` }}
        className={styles.overlay}
        onKeyDown={() => onOverlayClick()}
        onClick={() => onOverlayClick()}
      />
    </CSSTransition>
  );
};

export { Overlay };
