import { FC, PropsWithChildren, createContext, useContext, useReducer } from 'react';
import { authReducer, initialState } from './auth.state';

import { AuthContextProps } from './auth.types';

/**
 * AuthContext
 * @description Context for AuthRouter pages
 *
 * @author Oleksii Medvediev
 * @category Contexts
 */
const AuthContext = createContext<AuthContextProps>({
  dispatch: () => null,
});

/**
 * AuthContextProvider component
 * @description Provider for the AuthContext
 *
 * @author Oleksii Medvediev
 * @category Context Providers
 */
const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};

/**
 * useAuthContext hook.
 * @description The hook for getting AuthContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useAuthContext = () => useContext(AuthContext);

export { AuthContextProvider, useAuthContext };
