import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

/**
 * Props of Tooltip component
 *
 * @author Vitalii Bodnarchuk, Sergii Goncharuk
 */
interface TooltipProps extends PropsWithChildren, Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  /**
   * content of tooltip
   */
  readonly text: string | ReactNode;

  /**
   * content of tooltip
   */
  readonly content?: JSX.Element;

  /**
   * behavior of tooltip
   */
  readonly behavior?: 'click' | 'focus' | 'hover';
}

enum TooltipBehavior {
  CLICK = 'click',
  FOCUS = 'focus',
  HOVER = 'hover',
}

export { TooltipBehavior };
export type { TooltipProps };
