import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { RequestProps } from './request.types';
import { Typography } from '../../../../../../../../../../../components';
import styles from './request.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Request component
 * @description Content for the AutomatedOrdersConfirmation Cancel flow Request stage
 *
 * @author Oleksii Medvediev
 * @param { RequestProps } props - RequestProps defined in the './request.types.ts'
 * @category Components
 */
const Request: FC<RequestProps> = ({ onSubmit }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  return (
    <Modal
      modalId={ModalIds.automatedOrdersConfirmationCancel}
      title={t('modals.automatedOrdersConfirmation.cancel.request.title')}
    >
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>
          {t('modals.automatedOrdersConfirmation.cancel.request.text')}
        </Typography.Text>
        <div className={styles.controls}>
          <Button type={'button'} variant={'outline'} size={'large'} onClick={() => onSubmit('no')}>
            <Typography.Text variant={'bodyText2'}>
              {t('modals.automatedOrdersConfirmation.cancel.request.no')}
            </Typography.Text>
          </Button>
          <Button type={'button'} variant={'main'} size={'large'} onClick={() => onSubmit('yes')}>
            <Typography.Text variant={'bodyText2'}>
              {t('modals.automatedOrdersConfirmation.cancel.request.yes')}
            </Typography.Text>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { Request };
