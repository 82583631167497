import * as React from 'react';

import { NumberProps } from './number.props';
import { useNumberHook } from './number.hook';

const Number: React.FC<NumberProps> = (props) => {
  const { final, style } = useNumberHook(props);

  return <p className={style}>{final}</p>;
};

export { Number };
