import { AppContextProvider, ModalsContextProvider } from './app/contexts';
import React, { FC } from 'react';

import App from './app/app';
import { Modals } from './app/components';

const ManagementOfficeApp: FC = () => (
  <AppContextProvider>
    <ModalsContextProvider>
      <App />
      <Modals />
    </ModalsContextProvider>
  </AppContextProvider>
);

export { ManagementOfficeApp };
