import React, { FC, useCallback } from 'react';

import { Button } from '../../../../../../../button';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { ReactComponent as RedCrossIcon } from '../../../../../../../../../assets/icons/times-circle.svg';
import { Typography } from '../../../../../../../../../../../components';
import styles from './reject-stage.module.scss';
import { useModalsContext } from '../../../../../../../../contexts';
import { useTranslation } from 'react-i18next';

const RejectStage: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { dispatch } = useModalsContext();

  const continueHandler = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.loginRequestChangeRole });
  }, [dispatch]);

  return (
    <div className={styles.rejectStage}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <RedCrossIcon />
        </div>
        <Typography.Text variant={'bodyText5'}>{t('modals.loginRequest.changeRole.rejectStage.p')}</Typography.Text>
      </div>

      <Button size={'large'} variant={'main'} onClick={continueHandler} className={styles.btn}>
        {t('modals.loginRequest.changeRole.rejectStage.controlButtons.continue')}
      </Button>
    </div>
  );
};

export { RejectStage };
