import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { appReducer, initialState } from './app.state';

import { AppContextProps } from './app.types';
import { AvailableLocales } from '../../../../../localization';
import { Cache } from 'aws-amplify/utils';
import { CacheQuery } from '../../shared';
// import DefaultLogo from '../../../assets/images/default-logo.png';
import { ReactComponent as Logo } from '../../../assets/icons/Logo.svg';
import { getBranding } from '../../../../../services/api';
import i18next from 'i18next';
import { toast } from 'react-toastify';

/**
 * AppContext
 * @description Context for global app data
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 * @category Contexts
 */
const AppContext = createContext<AppContextProps>({
  dispatch: () => null,
  logo: <Logo />,
});

/**
 * AppContextProvider component
 * @description Provider for the AppContext
 *
 * @author Oleksii Medvediev
 * @category Context Providers
 */
const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [isBrandingFetched, setIsBrandingFetched] = useState(false);
  const browserLanguage = window?.navigator?.language?.split('-')[0];
  const scrollableContentRef = useRef<HTMLDivElement>(null);
  const [cachedUser, setCachedUser] = useState<(typeof state)['user']>();
  const getCachedUser = useCallback(async () => {
    setCachedUser(await Cache.getItem(CacheQuery.USER));
  }, []);
  useEffect(() => {
    getCachedUser();
  }, [getCachedUser]);

  const changeLanguage = useCallback(
    (language?: string) => {
      i18next.changeLanguage(
        Object.values(AvailableLocales).some((locale) => locale === language) ? language : browserLanguage,
      );
    },
    [browserLanguage],
  );

  /**
   * Fetch branding
   */
  const fetchBranding = useCallback(async () => {
    if (!state.isLoading && !isBrandingFetched && !state.branding) {
      dispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        setIsBrandingFetched(true);
        const { data } = await getBranding();

        data && dispatch({ type: 'SET_BRANDING', payload: data });
      } catch (error) {
        toast.error('Failed to fetch Branding!');
        console.error(error);
      }

      dispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [isBrandingFetched, state.branding, state.isLoading]);

  useEffect(() => {
    fetchBranding();
  }, [fetchBranding]);

  useEffect(() => {
    const lang = state.user?.preferences?.language ?? browserLanguage;

    if (!state.user && cachedUser) {
      dispatch({ type: 'SET_USER', payload: cachedUser });
    }

    document?.documentElement.setAttribute('lang', lang);
    changeLanguage(lang);
    dispatch({ type: 'SET_SCROLLABLE_CONTENT', payload: scrollableContentRef });
  }, [browserLanguage, cachedUser, changeLanguage, state.user]);

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  // const link = document?.querySelector('link[rel="icon"]');

  // if (link && state.branding?.logo) {
  //   link.setAttribute('href', state.branding?.logo);
  // } else if (link) {
  //   link.setAttribute('href', DefaultLogo);
  // }
  //   }
  // }, [state.branding?.logo]);

  return <AppContext.Provider value={{ ...state, dispatch }}>{children}</AppContext.Provider>;
};

/**
 * useAppContext hook.
 * @description The hook for getting AppContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useAppContext = () => useContext(AppContext);

export { AppContextProvider, useAppContext };
