import { AppContextProvider, ModalContextProvider } from './app/context';

import App from './app/app';
import { FC } from 'react';
import { Modal } from './app/components';

const AuthApp: FC = () => (
  <AppContextProvider>
    <ModalContextProvider>
      <App />
      <Modal />
    </ModalContextProvider>
  </AppContextProvider>
);

export { AuthApp };
