import { parseQuery, stringifyQuery } from '../utils';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import dayjs from 'dayjs';

const useQueryParams = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const parsed = parseQuery(search);
  const [params, setParams] = useSearchParams();

  const add = ({ key, value }: { key: string; value: string }) => {
    parsed[key] = value;
    navigate({ pathname, search: stringifyQuery(parsed) });
  };

  const prevMonthQuery = () => {
    const queryParams = {
      start: dayjs(dayjs().subtract(1, 'month')).startOf('month').format(),
      end: dayjs(dayjs().subtract(1, 'month')).endOf('month').format(),
      type: 'monthly',
    };

    return stringifyQuery(queryParams);
  };

  return {
    add,
    parsed,
    pathname,
    params,
    setParams,
    prevMonthQuery,
  };
};

export { useQueryParams };
