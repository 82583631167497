import { useEffect, useRef } from 'react';

import { EffectCallback } from 'react';

const useDidUpdate = (effect: EffectCallback, deps: unknown[]) => {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      effect();
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export { useDidUpdate };
