import {
  UserRole,
  cancelLoginRequest,
  changeUserRole,
  getTradeVisionConnectionStatus,
  requestLogin,
} from '../../../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../../../contexts';
import { useCallback, useEffect, useState } from 'react';

import { ConfirmationStageProps } from './confirmation-stage.types';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Stage } from '../../change-role-modal.types';
import { toast } from 'react-toastify';

/**
 * useConfirmationStageData hook.
 * @description the hook which processes role change approval state
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useConfirmationStageData = (setStage: ConfirmationStageProps['setStage'], role: UserRole) => {
  const { user, roleMigrations, dispatch: appDispatch } = useAppContext();
  const { dispatch } = useModalsContext();
  const [isRequestSent, setIsRequestSent] = useState<boolean>(false);
  const [currentLoginCandidate, setCurrentCandidate] = useState<
    Partial<{
      readonly name: string;
      readonly role: UserRole;
      readonly email: string;
    }>
  >();

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.loginRequestChangeRole }),
    [dispatch],
  );

  const makeLoginRequest = useCallback(async () => {
    if (user && !isRequestSent) {
      try {
        const { message } = await requestLogin({ email: user.email, role });
        if (message === 'ok') {
          setIsRequestSent(true);
          setCurrentCandidate({
            email: user.email,
            name: user.name,
            role,
          });
        }
      } catch (error) {
        toast.error('Failed to process RequestLogin request!');
        console.error(error);
      }
    }
  }, [isRequestSent, role, user]);

  useEffect(() => {
    makeLoginRequest();
  }, [makeLoginRequest]);

  const checkInvestudioConnectionStatus = useCallback(async () => {
    try {
      const { data } = await getTradeVisionConnectionStatus(user?.email);
      if (data) {
        appDispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: data.connection });
        appDispatch({ type: 'SET_LOGIN_CANDIDATE', payload: data.loginCandidate });
        appDispatch({ type: 'SET_RESERVED_ROLES', payload: data.reservedRoles });

        if (!data.loginCandidate) {
          setCurrentCandidate(undefined);
        }
      } else {
        appDispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: false });
        appDispatch({ type: 'SET_LOGIN_CANDIDATE', payload: undefined });
        appDispatch({ type: 'SET_RESERVED_ROLES', payload: undefined });
      }
    } catch (error) {
      toast.error('Failed to fetch TradeVisionConnectionStatus!');
      console.error(error);
      appDispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: false });
      appDispatch({ type: 'SET_LOGIN_CANDIDATE', payload: undefined });
      appDispatch({ type: 'SET_RESERVED_ROLES', payload: undefined });
    }
  }, [appDispatch, user?.email]);

  useEffect(() => {
    if (isRequestSent) {
      const interval = setInterval(() => {
        checkInvestudioConnectionStatus();
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [checkInvestudioConnectionStatus, isRequestSent]);

  const requestRoleChange = useCallback(async () => {
    if (currentLoginCandidate?.email && currentLoginCandidate.role) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await changeUserRole({ email: currentLoginCandidate.email, role: currentLoginCandidate.role });

        if (data && data !== 'loginRequest') {
          appDispatch({ type: 'SET_CURRENT_ROLE', payload: currentLoginCandidate.role });

          await cancelLoginRequest({ email: currentLoginCandidate.email });
          handleCloseModal();
        }
      } catch (error) {
        toast.error('Failed to process change user role request!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, currentLoginCandidate?.email, currentLoginCandidate?.role, handleCloseModal]);

  useEffect(() => {
    if (!currentLoginCandidate && isRequestSent) {
      setStage(Stage.reject);
    } else if (isRequestSent && !roleMigrations?.reservedRoles?.some((reservedRole) => reservedRole === role)) {
      setTimeout(() => {
        currentLoginCandidate?.role && requestRoleChange();
      }, 3000);
    }
  }, [currentLoginCandidate, isRequestSent, requestRoleChange, role, roleMigrations?.reservedRoles, setStage]);
};

export { useConfirmationStageData };
