import React, { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { initialState, settingsReducer } from './settings.state';

import { SettingsContextProps } from './settings.types';
import { getBranding } from '../../../../../../services/api';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../context';

/**
 * SettingsContext
 * @description Context for SettingsRouter pages
 *
 * @author Oleksii Medvediev
 * @category Contexts
 */
const SettingsContext = createContext<SettingsContextProps>({
  dispatch: () => null,
});

/**
 * SettingsContextProvider component
 * @description Provider for the SettingsContext
 *
 * @author Oleksii Medvediev
 * @category Context Providers
 */
const SettingsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading } = useAppContext();
  const [state, dispatch] = useReducer(settingsReducer, initialState);

  // const fetchSupportInfo = useCallback(async () => {
  //   if (!isLoading && !state.supportInfo) {
  //     try {
  //       const { data } = await getSupportInfo();

  //       data && dispatch({ type: 'SET_SUPPORT_INFO', payload: data });
  //     } catch (error) {
  //       console.error(error);
  //       dispatch({
  //         type: 'SET_SUPPORT_INFO',
  //         payload: {
  //           Mail: 'a@a.a',
  //           Phone: '+111111111111',
  //           Telegram: '+111111111111',
  //           WhatsApp: '+111111111111',
  //         },
  //       });
  //     }
  //   }
  // }, [isLoading, state.supportInfo]);

  const fetchBranding = useCallback(async () => {
    if (!isLoading && !state.branding && !state.errorMessage) {
      try {
        const { data } = await getBranding();

        data && dispatch({ type: 'SET_BRANDING', payload: data });
      } catch (error) {
        toast.error('Failed to fetch branding!');
        console.error(error);
        dispatch({ type: 'SET_ERROR_MESSAGE', payload: 'Error has occurred while fetching "Branding"' });
      }
    }
  }, [isLoading, state.branding, state.errorMessage]);

  useEffect(() => {
    // fetchSupportInfo();
    fetchBranding();
  }, [fetchBranding]);

  return <SettingsContext.Provider value={{ ...state, dispatch }}>{children}</SettingsContext.Provider>;
};

/**
 * useSettingsContext hook.
 * @description The hook for getting SettingsContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useSettingsContext = () => useContext(SettingsContext);

export { SettingsContextProvider, useSettingsContext };
