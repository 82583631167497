import React, { FC, PropsWithChildren, createContext, useContext, useReducer } from 'react';
import { initialState, modalReducer } from './modal.state';

import { ModalContextProps } from './modal.types';

/**
 * ModalContext
 * @description Context for global Modal data
 *
 * @author Rostyslav Nahornyi
 * @category Contexts
 */
const ModalContext = createContext<ModalContextProps>({
  dispatch: () => null,
  data: undefined,
});

/**
 * ModalContextProvider component
 * @description Provider for the ModalContext
 *
 * @author Rostyslav Nahornyi
 * @category Context Providers
 */
const ModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  return <ModalContext.Provider value={{ ...state, dispatch }}>{children}</ModalContext.Provider>;
};

/**
 * useModalContext hook.
 * @description The hook for getting ModalContext data
 *
 * @author Rostyslav Nahornyi
 * @category Hooks
 */
const useModalContext = () => useContext(ModalContext);

export { ModalContextProvider, useModalContext };
