import { useCallback, useMemo } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * SettingsConnectionModal hook data
 * @description The hook which processes SettingsConnectionModal data
 *
 * @author Anna Botsula, Oleksii Medvediev
 */
const useSettingsConnectionModalHook = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  const {
    data: { settingsConnection },
    dispatch,
  } = useModalsContext();

  const modalData = useMemo(() => settingsConnection, [settingsConnection]);

  const handleCloseModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.settingsConnection });
  }, [dispatch]);

  return {
    t,
    modalData,
    handleCloseModal,
  };
};

export { useSettingsConnectionModalHook };
