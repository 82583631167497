import { Action, ModalState } from './modal.types';

/**
 * @description InitialState for the ModalContext
 *
 * @author Rostyslav Nahornyi
 */
const initialState: ModalState = {};

/**
 * @author Rostyslav Nahornyi
 * @description Reducer function for the useReducer hook in the ModalContextProvider component.
 *
 * @param { ModalState } state - State defined in the './Modal.types.ts'
 * @param { Action } action - Action defined in the './Modal.types.ts'
 *
 * @returns updated ModalContextProvider state.
 */
const modalReducer = (state: ModalState, action: Action): ModalState => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        isOpened: true,
        data: action.payload,
      };

    case 'HIDE_MODAL':
      return {
        ...state,
        isOpened: false,
      };

    default:
      return state;
  }
};

export { initialState, modalReducer };
