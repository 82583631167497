import { FC } from 'react';

import classNames from 'classnames';
import textStyles from '../text/text.module.scss';
import { useNumberHook } from './number.hook';
import { NumberProps } from './number.types';

/**
 * Typography.Number component
 * @description component which render number with different props
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { NumberProps } props - NumberProps defined in the './number.types.ts'
 */
const Number: FC<NumberProps> = (props) => {
  const { finalValue, colorizeClass } = useNumberHook(props);
  return <div className={classNames(textStyles[props.variant], colorizeClass)}>{finalValue}</div>;
};

export { Number };
