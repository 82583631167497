import { useCallback, useState } from 'react';

import { NotificationItemProps } from './notification-item.types';
import { useTranslation } from 'react-i18next';

/**
 * useNotificationItemData
 * @description The hook which processes Notification item component
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Hooks
 */
const useNotificationItemData = ({
  UUID,
  ReadMark,
  onRefetchNotifications,
}: Pick<NotificationItemProps, 'UUID' | 'ReadMark' | 'onRefetchNotifications'>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'client.pages.dashboard.notifications-drawer.notifications' });

  const [isOpened, setIsOpened] = useState(false);

  const onReadHandler = useCallback(async () => {
    setIsOpened((prev) => !prev);

    if (!ReadMark) {
      onRefetchNotifications(UUID);
    }
  }, [ReadMark, UUID, onRefetchNotifications]);

  return { t, isOpened, onReadHandler };
};

export { useNotificationItemData };
