import React, { FC, useCallback } from 'react';

import { Button } from '../../../../../../../button';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { Stage } from '../../change-role-modal.types';
import { StartStageProps } from './start-stage.types';
import { Typography } from '../../../../../../../../../../../components';
import styles from './start-stage.module.scss';
import { useModalsContext } from '../../../../../../../../contexts';
import { useTranslation } from 'react-i18next';

const StartStage: FC<StartStageProps> = ({ setStage }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { dispatch } = useModalsContext();

  const cancelHandler = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.loginRequestChangeRole }),
    [dispatch],
  );
  const requestHandler = () => {
    setStage(Stage.confirmation);
  };

  return (
    <div className={styles.startStage}>
      <Typography.Text variant={'bodyText5'}>
        {t('modals.loginRequest.changeRole.startStage.p1')}
        <br />
        <br />
        {t('modals.loginRequest.changeRole.startStage.p2')}
      </Typography.Text>

      <div className={styles.controlButtons}>
        <Button variant={'outline'} size={'large'} className={styles.btn} onClick={cancelHandler}>
          {t('modals.loginRequest.changeRole.startStage.controlButtons.cancel')}
        </Button>
        <Button variant={'main'} size={'large'} className={styles.btn} onClick={requestHandler}>
          {t('modals.loginRequest.changeRole.startStage.controlButtons.request')}
        </Button>
      </div>
    </div>
  );
};

export { StartStage };
