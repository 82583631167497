import { Checkbox, Typography } from '../../../../../components';
import React, { FC } from 'react';

import { Cell } from './components';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/angle-down.svg';
import { TableProps } from './table.types';
import classNames from 'classnames';
import styles from './table.module.scss';

const selectAllHandler = (selectHandler: (value: string) => void, rows: TableProps['data'][0]['rows']) => {
  if ((rows.every(({ isSelected }) => isSelected) || rows.every(({ isSelected }) => !isSelected)) && rows.length > 0) {
    rows.forEach(({ id }) => {
      selectHandler(id);
    });
  } else {
    [...rows]
      .filter(({ isSelected }) => !isSelected)
      .forEach(({ id }) => {
        selectHandler(id);
      });
  }
};

const calculateColumnsCount = (
  dataColumnsCount: number,
  includeCta: boolean,
  includeCheckbox: boolean,
  expandedColumnOptions?: {
    readonly columnIndex: number;
    readonly preferredWidth: number;
  },
) => {
  const count = dataColumnsCount;
  const columns = includeCheckbox ? ['min-content'] : [];
  for (let i = 0; i < count; i++) {
    expandedColumnOptions && expandedColumnOptions.columnIndex === i
      ? columns.push(`${expandedColumnOptions.preferredWidth}px`)
      : columns.push('1fr');
  }

  includeCta && columns.push('min-content');

  return columns.join(' ');
};

// const createTooltip = (element: Element, textContent: string, isLastRow: boolean): void => {
//   element.setAttribute('style', 'position: relative; overflow: visible; cursor: pointer;');

//   const tooltip = document.createElement('div');
//   tooltip.setAttribute('id', 'tooltipElement');
//   tooltip.textContent = textContent;
//   tooltip.classList.add(styles.tooltip);
//   element.append(tooltip);
//   tooltip.setAttribute(
//     'style',
//     `left: calc(50% - ${tooltip.scrollWidth / 2}px); top: ${isLastRow ? 'unset' : '54px'}; bottom: ${isLastRow ? '54px' : 'unset'}`,
//   );

//   const triangle = document.createElement('div');
//   triangle.setAttribute('id', 'tooltipTriangle');
//   triangle.classList.add(styles.triangle);
//   element.append(triangle);
//   triangle.setAttribute(
//     'style',
//     `left: calc(50% - ${triangle.scrollWidth / 2}px); top: ${isLastRow ? 'unset' : 'calc(54px - 5px)'}; bottom: ${isLastRow ? 'calc(54px - 5px)' : 'unset'}; transform: rotateX(${isLastRow ? '180deg' : '0'})`,
//   );
// };

// const createTooltipEventHandlers = (isLastRow: boolean) => ({
//   onPointerEnter: ({ currentTarget }: PointerEvent) => {
//     const element = currentTarget;
//     const firstChildChildrenLength = currentTarget.children[0].children.length;
//     const firstFactChild = currentTarget.children[0];

//     if (firstChildChildrenLength > 1) {
//       if (
//         currentTarget.clientWidth - 32 <
//         firstFactChild.children[0].scrollWidth + firstFactChild.children[1].scrollWidth + 8
//       ) {
//         createTooltip(element, firstFactChild.children[1].textContent ?? '', isLastRow);
//       }
//     } else {
//       if (currentTarget.clientWidth - 32 < firstFactChild.scrollWidth) {
//         createTooltip(element, firstFactChild.textContent ?? '', isLastRow);
//       }
//     }
//   },
//   onPointerLeave: ({ currentTarget }: PointerEvent) => {
//     currentTarget.removeAttribute('style');
//     while (!!document?.getElementById('tooltipElement') || !!document?.getElementById('tooltipTriangle')) {
//       document?.getElementById('tooltipElement')?.remove();
//       document?.getElementById('tooltipTriangle')?.remove();
//     }
//   },
// });

/**
 * Table component.
 * @description Universal table component for the global app usage. Includes search, select tools and row addition CTA.
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { TableProps } props - TableProps defined in the './table.types.ts'.
 */
const Table: FC<TableProps> = ({ data }) => (
  <div className={styles.wrapper}>
    {!!data.length &&
      data.map(
        (
          {
            sectionName,
            header,
            isLoading,
            rows,
            availableSortingColumnsIndexes,
            cta,
            currentSortingOptions,
            onSelect,
            onSort,
            onRowClick,
            selectedRowId,
            expandedColumnOptions,
          },
          index,
        ) => {
          const onSortHandler = (index: number) => {
            onSort &&
              (!availableSortingColumnsIndexes || availableSortingColumnsIndexes.some((item) => index === item)) &&
              onSort({
                direction: currentSortingOptions?.direction === 'ascending' ? 'descending' : 'ascending',
                sortByColumnIndex: index,
              });
          };

          return (
            <div key={`table${index}`} className={styles.table}>
              <div className={styles.heading}>
                {sectionName && (
                  <Typography.Text variant={'label1'} className={styles.sectionName}>
                    {sectionName}
                  </Typography.Text>
                )}
                <div
                  className={classNames(styles.tr, styles.thead)}
                  style={{
                    gridTemplateColumns: `${calculateColumnsCount(
                      header.columns.length,
                      !!cta,
                      !!onSelect,
                      expandedColumnOptions,
                    )}`,
                  }}
                >
                  {onSelect && (
                    <div className={styles.checkbox}>
                      <Checkbox
                        isChecked={rows.every(({ isSelected }) => isSelected) && rows.length > 0}
                        onChange={() => selectAllHandler(onSelect, rows)}
                      />
                    </div>
                  )}
                  {header.columns.map((heading, index) => (
                    <div className={styles.th} key={index.toString()}>
                      <div
                        className={styles.thHeading}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => onSortHandler(index)}
                        onClick={() => onSortHandler(index)}
                      >
                        {heading}
                        {onSort &&
                          (!availableSortingColumnsIndexes ||
                            availableSortingColumnsIndexes.some((item) => index === item)) && (
                            <ChevronDownIcon
                              className={classNames(
                                styles.chevronDown,
                                currentSortingOptions?.direction === 'ascending' &&
                                  currentSortingOptions.sortByColumnIndex === index &&
                                  styles.chevronUp,
                              )}
                            />
                          )}
                      </div>
                    </div>
                  ))}
                  {cta && <div className={classNames(styles.th, styles.hiddenTh, styles.cta)}>{cta('')}</div>}
                </div>
              </div>

              {isLoading ? (
                <div
                  className={styles.tr}
                  style={{
                    gridTemplateColumns: `${calculateColumnsCount(
                      header.columns.length,
                      !!cta,
                      !!onSelect,
                      expandedColumnOptions,
                    )}`,
                  }}
                >
                  <div
                    className={classNames(styles.td, styles.preloader)}
                    style={{
                      gridColumnStart: '1',
                      gridColumnEnd: `${cta ? header.columns.length + 2 : header.columns.length + 1}`,
                    }}
                  >
                    Loading...
                  </div>
                </div>
              ) : (
                rows.map(({ columns, id, isSelected }, rowIndex) => (
                  <div
                    role={'button'}
                    tabIndex={0}
                    key={id + rowIndex}
                    className={classNames(styles.tr, selectedRowId === id && styles.selectedRow)}
                    style={{
                      gridTemplateColumns: `${calculateColumnsCount(
                        header.columns.length,
                        !!cta,
                        !!onSelect,
                        expandedColumnOptions,
                      )}`,
                    }}
                    onKeyDown={() => onRowClick && onRowClick(id)}
                    onClick={() => onRowClick && onRowClick(id)}
                  >
                    {onSelect && (
                      <div className={styles.checkbox}>
                        <Checkbox isChecked={!!isSelected} onChange={() => onSelect(id)} />
                      </div>
                    )}
                    {columns.map((col, index) => (
                      // <div
                      //   title={typeof col === 'string' ? col : undefined}
                      //   className={styles.td}
                      //   key={id.concat(index.toString())}
                      //   {...createTooltipEventHandlers(rowIndex === rows.length - 1)}
                      // >
                      //   {col}
                      // </div>
                      <Cell key={id.concat(index.toString())} content={col} isLastRow={rowIndex === rows.length - 1} />
                    ))}
                    {cta && (
                      <div
                        role={'button'}
                        tabIndex={-1}
                        onKeyDown={(event) => event.stopPropagation()}
                        onClick={(event) => event.stopPropagation()}
                        className={classNames(styles.cta, styles.td)}
                      >
                        {cta(id)}
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          );
        },
      )}
  </div>
);

export { Table };
