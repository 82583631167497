import { SegmentType, StocksChartProps } from './stocks-chart.types';
import { getQuarters, getYears } from '../../utils';
import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const useStocksChart = (props: StocksChartProps) => {
  const { data, highlight, down } = props;
  const { list, min, max } = data || { list: [], min: 0, max: 0 };
  const [ticks, setTicks] = useState<string[]>([]);
  const [weekSegment, setWeekSegment] = useState<ReadonlyArray<{ x: string; y: number }>>([]);
  const [daySegment, setDaySegment] = useState<{ x: string; y: number }>();
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  const red = '#e82518';
  const green = '#40f493';
  const gradientRed = '#c91d12';
  const gradientGreen = '#30BB70';
  const [color, setColor] = useState(down ? red : green);
  const [gradientColor, setGradientColor] = useState<string>(down ? gradientRed : gradientGreen);

  const secondaryActiveDot = { stroke: '#fff', strokeWidth: 2, r: 3, fill: '#4B4B57' };

  const targetPriceOffsetCoef = 2;
  const minCoef = 1;

  useEffect(() => {
    switch (true) {
      case list.length < 63:
        setTicks(list.filter((_, index) => index % 2).map((value) => value.date));
        break;
      case list.length >= 63 && list.length < 252:
        setTicks([list[0].date, list[Math.floor((list.length - 1) / 2)].date, list[list.length - 1].date]);
        break;
      case list.length >= 252 && list.length < 1260:
        setTicks(getQuarters(list.map((o) => o.date)));
        break;

      case list.length >= 1260:
        setTicks(getYears(list.map((o) => o.date)));
        break;
      default:
    }
  }, [list, list.length]);

  useEffect(() => {
    setColor(!down ? green : red);
    setGradientColor(!down ? gradientGreen : gradientRed);
  }, [down]);

  useEffect(() => {
    let segment: SegmentType = [];
    const listLastEl = list[list.length - 1];

    if (highlight === 'week') {
      if (list.length >= 16) {
        segment = [
          { x: list[16].date, y: list[16].close },
          { x: listLastEl.date, y: listLastEl?.week ? listLastEl.week : listLastEl.close },
        ];
        setWeekSegment(segment);
      } else {
        segment = [
          { x: list[list.length - 6].date, y: list[list.length - 6].close },
          { x: listLastEl.date, y: listLastEl.week || listLastEl.close },
        ];
        setWeekSegment(segment);
      }

      if (segment[0]?.y > segment[1]?.y) {
        setColor(red);
        setGradientColor(gradientRed);
      } else {
        setGradientColor(gradientGreen);
        setColor(green);
      }
      setDaySegment(undefined);
    } else if (highlight === 'day') {
      const end = { x: listLastEl.date, y: listLastEl.close };
      setDaySegment(end);
      setWeekSegment([]);
      setColor(!down ? green : red);
      setGradientColor(!down ? gradientGreen : gradientRed);
    } else {
      setDaySegment(undefined);
      setWeekSegment([]);
      setColor(!down ? green : red);
      setGradientColor(!down ? gradientGreen : gradientRed);
    }
  }, [down, highlight, list]);

  const tickFormatter = (value: string): string => {
    switch (true) {
      case list?.length < 63:
        return dayjs(value).format('DD/MM');
      case list?.length >= 63 && list?.length < 252:
        return `${t(`${dayjs(value).format('MMMM')}`)}  ${dayjs(value).format('YYYY')}`;
      case list?.length >= 252 && list?.length < 1260:
        switch (true) {
          case dayjs(value).toDate().getMonth() >= 0 && dayjs(value).toDate().getMonth() <= 2:
            return t('dashboard.content.summaries.quarter', { info: '2' });

          case dayjs(value).toDate().getMonth() >= 3 && dayjs(value).toDate().getMonth() <= 5:
            return t('dashboard.content.summaries.quarter', { info: '3' });

          case dayjs(value).toDate().getMonth() >= 6 && dayjs(value).toDate().getMonth() <= 8:
            return t('dashboard.content.summaries.quarter', { info: '4' });

          case dayjs(value).toDate().getMonth() >= 9 && dayjs(value).toDate().getMonth() <= 11:
            return t('dashboard.content.summaries.quarter', { info: '1' });

          default:
            return `${t(`${dayjs(value).format('MMMM')}`)}  ${dayjs(value).format('YYYY')}`;
        }

      case list?.length >= 1260:
        return dayjs(value).format('YYYY');
      default:
        return '';
    }
  };

  return {
    list,
    highlight,
    weekSegment,
    daySegment,
    tickFormatter,
    min,
    max,
    color,
    gradientColor,
    ticks,
    secondaryActiveDot,
    targetPriceOffsetCoef,
    minCoef,
    t,
    red,
    green,
  };
};

export { useStocksChart };
