import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './languages/en.json';
import uk from './languages/uk.json';

i18next.use(initReactI18next).init({
  resources: {
    uk: {
      translation: uk,
    },
    en: {
      translation: en,
    },
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
