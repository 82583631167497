import { useMemo, useState } from 'react';

const ROW_HEIGHT = 56;

/**
 * usePagination hook.
 * Used for handling pagination logic based on table height and row count.
 *
 * @author Rostyslav Nahornyi
 * @category Hooks
 */
const usePagination = (tableHeight?: number, rowsLength?: number, rowHeight = ROW_HEIGHT) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = useMemo(() => {
    const size = Math.floor((tableHeight ?? 0) / (rowHeight + 10 / (rowsLength ?? 1)) - 1);

    return size <= 0 ? 1 : size;
  }, [rowHeight, rowsLength, tableHeight]);
  const totalPages = useMemo(() => Math.ceil((rowsLength ?? 0) / pageSize), [pageSize, rowsLength]);

  return { pageSize, totalPages, currentPage, setCurrentPage };
};

export { usePagination };
