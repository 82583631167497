import React, { FC } from 'react';

import { Indicator } from '../../../../../../../components';
import { MenuItemProps } from './menu-item.types';
import { NavLink } from 'react-router-dom';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import styles from './menu-item.module.scss';

const renderContent = ({
  icon,
  label,
  messagesCount,
  collapsed,
}: Pick<MenuItemProps, 'icon' | 'label' | 'messagesCount' | 'collapsed'>): JSX.Element => (
  <>
    <div className={styles.content}>
      {!!icon && <span className={styles.icon}>{icon}</span>}
      {!collapsed && !!label && <span className={styles.label}>{label === 'FAQ' ? 'FAQ' : capitalize(label)}</span>}
    </div>
    {!collapsed && messagesCount && typeof messagesCount === 'number' && messagesCount > 0 ? (
      <Indicator.Messages label={messagesCount} />
    ) : (
      <></>
    )}
  </>
);

/**
 * MenuItem
 * @description Sidebar's menu button component.
 * Exclusively for the Sidebar component.
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { MenuItemProps } props - MenuItemProps defined in the './menu-item.types.ts'.
 */
const MenuItem: FC<MenuItemProps> = ({ icon, label, onClick, disabled, linkTo, messagesCount, isHidden, collapsed }) =>
  !isHidden ? (
    <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
      {linkTo ? (
        <NavLink
          className={({ isActive }) => classNames(styles.item, isActive && styles.active)}
          type={'button'}
          to={linkTo}
          onClick={onClick}
        >
          {renderContent({ icon, label, messagesCount, collapsed })}
        </NavLink>
      ) : (
        <button className={styles.item} type={'button'} disabled={disabled} onClick={onClick}>
          {renderContent({ icon, label, messagesCount, collapsed })}
        </button>
      )}
    </div>
  ) : (
    <></>
  );

export { MenuItem };
