import React, { FC } from 'react';

import { DropdownItemProps } from './dropdown-item.types';
import { Typography } from '../../../../../typography';
import classNames from 'classnames';
import styles from './dropdown-item.module.scss';
import { useWindowSize } from '../../../../../../modules/management-office/app/hooks';

/**
 * DropdownItem component
 * @description Exclusive dropdown item for DropdownMenu
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 * @param { DropdownItemProps } props - DropdownItemProps defined in the './dropdown-item-.types.ts'
 */
const DropdownItem: FC<DropdownItemProps> = ({
  id,
  value,
  leadingIcon,
  isSelected,
  className,
  style,
  disabled,
  onSelect,
}: DropdownItemProps) => {
  const { width } = useWindowSize();
  return (
    <div
      role={'button'}
      tabIndex={0}
      onKeyDown={() => onSelect(id)}
      className={classNames(
        styles.dropdownItem,
        isSelected && styles.isSelected,
        disabled && styles.disabled,
        className,
      )}
      style={style}
      onClick={() => !disabled && onSelect(id)}
    >
      {leadingIcon && <div className={styles.leadingIcon}>{leadingIcon}</div>}
      <Typography.Text variant={width >= 1024 ? 'bodyText5' : 'bodyText3'}>{value}</Typography.Text>
    </div>
  );
};

export { DropdownItem };
