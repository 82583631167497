/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';

import { TextProps } from './text.props';
import classNames from 'classnames';
import styles from './text.module.scss';

const Text: React.FC<TextProps> = ({
  bold,
  color,
  align,
  onClick,
  variant,
  classes,
  children,
  transform,
  lineHeight,
}) => {
  const style = classNames(classes, styles.text, {
    // size
    [styles.textBody]: !variant || variant === 'body1',
    [styles.textH1]: variant === 'h1',
    [styles.textH2]: variant === 'h2',
    [styles.textH3]: variant === 'h3',
    [styles.textH4]: variant === 'h4',
    [styles.textH5]: variant === 'h5',
    [styles.textH6]: variant === 'h6',
    [styles.textBody1]: variant === 'body2',
    [styles.textBody3]: variant === 'body3',
    [styles.textSmall]: variant === 'small',

    // baldness
    [styles.textFw500]: bold === 500,
    [styles.textFw600]: bold === 600,
    [styles.textFw700]: bold === 700,

    // line height
    [styles.textLh13]: lineHeight === 13,
    [styles.textLh15]: lineHeight === 15,
    [styles.textLh18]: lineHeight === 18,

    // transform
    [styles.textCapitalize]: transform === 'capitalize',
    [styles.textUppercase]: transform === 'uppercase',

    // color
    [styles.textInverted]: color === 'invert',
    [styles.textSecondary]: color === 'secondary',

    // align
    [styles.textAlignLeft]: align === 'left',
    [styles.textAlignRight]: align === 'right',
    [styles.textAlignCenter]: align === 'center',
  });

  return (
    <p
      className={classNames(styles.text, style)}
      onClick={onClick && onClick}
      tabIndex={onClick && -1}
      role={onClick && 'button'}
    >
      {children}
    </p>
  );
};

export { Text };
