import {
  GetPortfolioPerformanceOldPayload,
  GetPortfolioPerformanceOldResponse,
  GetPortfoliosResponse,
} from './portfolio.model';

import { request } from '../../http';

export const getPortfolios = () =>
  request<{ readonly data?: GetPortfoliosResponse }>({
    method: 'get',
    url: 'portfolio',
  });

export const getPortfolioPerformanceOld = (data: GetPortfolioPerformanceOldPayload) =>
  request<{ readonly data: GetPortfolioPerformanceOldResponse }>({
    method: 'get',
    url: `portfolio/performance/${data.accountId}/${data.portfolioId}`,
  });
