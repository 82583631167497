import { AccordionHeaderProps } from './accordion-header.types';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/angle-down.svg';
import React from 'react';
import { Typography } from '../../../../../../../components';
import classNames from 'classnames';
import styles from './accordion-header.module.scss';

/**
 * AccordionHeader component
 * @description Header of accordion
 *
 * @author Rostyslav Nahornyi
 * @category Components
 */
const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  title,
  label,
  description,
  isExpanded,
  onClick,
  hideBottomLine,
  isMessage,
}) => (
  <div
    role={'button'}
    tabIndex={0}
    onClick={onClick}
    onKeyDown={onClick}
    className={classNames(
      styles.accordionHeader,
      hideBottomLine ? isExpanded && styles.expandedHeaderWithoutLine : isExpanded && styles.expandedHeader,
    )}
  >
    <div className={styles.wrapperHeader}>
      <Typography.Text variant="label4">{title}</Typography.Text>
      <div className={styles.control}>
        {typeof title === 'string' ? (
          <Typography.Text variant={isMessage ? 'bodyText1' : 'h6'}>{label}</Typography.Text>
        ) : (
          label
        )}
        <ArrowIcon className={classNames(styles.icon, isExpanded ? styles.on : styles.off)} />
      </div>
    </div>
    {description?.length && (
      <div className={styles.wrapperBottom}>
        {Array.from({ length: Math.ceil(description.length / 3) }, (_, index) =>
          description.slice(index * 3, (index + 1) * 3),
        ).map((row, descIdx) => (
          <div key={descIdx} className={styles.row}>
            {row.map((info, rowIdx) => (
              <Typography.Text
                className={classNames(styles.item, !descIdx && !rowIdx && styles.first)}
                key={info}
                variant="bodyText3"
              >
                {info}
              </Typography.Text>
            ))}
          </div>
        ))}
      </div>
    )}
  </div>
);

export { AccordionHeader };
