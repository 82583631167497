import React, { FC } from 'react';

import { CSSTransition } from 'react-transition-group';
import { OverlayProps } from './overlay.types';
import classNames from 'classnames';
import styles from './overlay.module.scss';

/**
 * Overlay component
 * @description Modal window overlay
 *
 * @category Components
 * @param { OverlayProps } props - OverlayProps defined in the './overlay.types.ts'
 */
const Overlay: FC<OverlayProps> = ({ isShown, z, onOverlayClick }) => {
  if (!isShown) return null;
  const zIndex = z * 10;

  return (
    <CSSTransition in={isShown} timeout={200} unmountOnExit classNames={styles}>
      <div
        tabIndex={0}
        role={'button'}
        className={classNames(styles.overlay, {
          [styles.transparent]: true,
        })}
        style={{ zIndex }}
        onKeyDown={() => onOverlayClick()}
        onClick={() => onOverlayClick()}
      />
    </CSSTransition>
  );
};

export { Overlay };
