import { useEffect, useState } from 'react';

import { NumberProps } from './number.props';
import classNames from 'classnames';
import styles from './number.module.scss';

const useNumberHook = ({
  classes,
  value = 0,
  variant,
  currency,
  color,
  round,
  bold,
  lineHeight,
  percent,
}: NumberProps) => {
  const [final, setFinal] = useState('');

  const style = classNames(classes, styles.number, {
    // size
    [styles.numberBody]: !variant || variant === 'body1',
    [styles.numberH1]: variant === 'h1',
    [styles.numberH2]: variant === 'h2',
    [styles.numberH3]: variant === 'h3',
    [styles.numberH4]: variant === 'h4',
    [styles.numberH5]: variant === 'h5',
    [styles.numberH6]: variant === 'h6',
    [styles.numberBody1]: variant === 'body2',
    [styles.numberBody2]: variant === 'body3',
    [styles.numberSmall]: variant === 'small',

    // color
    [styles.numberSecondary]: color === 'secondary',
    [styles.numberInverted]: color === 'invert',

    // baldness
    [styles.numberFw500]: bold === 500,
    [styles.numberFw600]: bold === 600,
    [styles.numberFw700]: bold === 700,

    // line height
    [styles.numberLh13]: lineHeight === 13,
    [styles.numberLh18]: lineHeight === 18,
  });

  useEffect(() => {
    let formatted = '';
    if (currency) {
      const formatter = new Intl.NumberFormat('en', {
        style: 'currency',
        currency: 'USD',
        useGrouping: false,
        maximumFractionDigits: round,
        minimumFractionDigits: round,
      });

      formatted = formatter.format(value).replace(/\B(?=(\d{3})+(?!\d))/g, String.fromCharCode(8201));
    }

    if (percent) {
      const formatter = new Intl.NumberFormat('en', {
        style: 'percent',
        useGrouping: false,
        maximumFractionDigits: round,
        minimumFractionDigits: round,
      });

      formatted = formatter.format(value);
    }

    if (!percent && !currency) {
      const formatter = new Intl.NumberFormat('en', {
        useGrouping: false,
        maximumFractionDigits: round,
        minimumFractionDigits: round,
      });

      formatted = formatter.format(value);
    }

    setFinal(formatted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return { final, style };
};

export { useNumberHook };
