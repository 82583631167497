import React, { FC, PropsWithChildren, createContext, useContext, useReducer } from 'react';
import { initialState, modalsReducer } from './modals.state';

import { ModalsContextProps } from './modals.types';

/**
 * ModalsContext
 * @description Context for global Modals data
 *
 * @author Oleksii Medvediev
 * @category Contexts
 */
const ModalsContext = createContext<ModalsContextProps>({
  dispatch: () => null,
  data: {},
});

/**
 * ModalsContextProvider component
 * @description Provider for the ModalsContext
 *
 * @author Oleksii Medvediev
 * @category Context Providers
 */
const ModalsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(modalsReducer, initialState);

  return <ModalsContext.Provider value={{ ...state, dispatch }}>{children}</ModalsContext.Provider>;
};

/**
 * useModalsContext hook.
 * @description The hook for getting ModalsContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useModalsContext = () => useContext(ModalsContext);

export { ModalsContextProvider, useModalsContext };
