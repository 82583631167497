import React, { FC } from 'react';

import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { CheckboxProps } from './checkbox.types';
import classNames from 'classnames';
import styles from './checkbox.module.scss';
import { v4 } from 'uuid';

/**
 * Checkbox component.
 * @description Checkbox component for entire app usage.
 *
 * @author Rostyslav Nahornyi
 * @category Components.
 * @param { CheckboxProps } props - CheckboxProps defined in the './checkbox.types.ts'
 */
const Checkbox: FC<CheckboxProps> = ({ isChecked, label, disabled, onChange }: CheckboxProps) => {
  const id = v4();

  return (
    <label
      className={styles.wrapper}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role={'button'}
      tabIndex={0}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <input
        hidden
        type={'checkbox'}
        id={id}
        onChange={() => !disabled && onChange()}
        checked={isChecked}
        disabled={disabled}
      />
      <div className={classNames(styles.checkWrapper, isChecked && styles.checked)}>
        <span className={classNames(styles.checkIcon, !isChecked && styles.invisibleCheck)}>
          <CheckIcon />
        </span>
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </label>
  );
};

export { Checkbox };
