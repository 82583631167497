import { GetReportPayload, ReportApiModel } from './report.model';

import { request } from '../../http';

export const getReport = (json: GetReportPayload) => {
  const url = json.portfolioId ? `report/${json.accountId}/${json.portfolioId}` : `report/${json.accountId}`;
  return request<{ readonly data: ReportApiModel['getReport'] }>({
    url,
    params: {
      start: json.start,
      end: json.end,
      type: json.type,
      ...(json.email && { email: json.email }),
      ...(json.id && { id: json.id }),
    },
  });
};
