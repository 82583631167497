import { Dropdown, Typography } from '../../../../../../../../../components';
import React, { useCallback, useMemo } from 'react';

import { DeleteProps } from '../delete/delete.types';
import { DropdownProps } from '../../../../../../../../../components/dropdown/dropdown.types';
import { EditProps } from '../edit/edit.types';
import { ReactComponent as EllipsisIcon } from '../../../../../../../assets/icons/ellipsis-v.svg';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { ReactComponent as PenIcon } from '../../../../../../../assets/icons/pen.svg';
import { SummaryProps } from './summary.types';
import { TableProps } from '../../../../../table/table.types';
import { ReactComponent as TrashIcon } from '../../../../../../../assets/icons/trash-alt.svg';
import { capitalize } from 'lodash';
import styles from './summary.module.scss';
import { useModalsContext } from '../../../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * useSummaryData hook
 * @description The hook which processes AutomatedOrdersConformation Summary flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useSummaryData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { portfoliosSettingsSummary },
    dispatch,
  } = useModalsContext();

  const flowData: SummaryProps | undefined = useMemo(() => portfoliosSettingsSummary, [portfoliosSettingsSummary]);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.portfoliosSettingsSummary }),
    [dispatch],
  );

  const handleOpenAddPortfolioFlow = useCallback(() => {
    flowData &&
      dispatch({
        type: 'SHOW_MODAL',
        payload: {
          id: ModalIds.portfoliosSettingsAdd,
          data: {
            portfoliosSettingsAdd: {
              currentStage: 'form',
              onBackButtonClick: () => {
                dispatch({
                  type: 'SHOW_MODAL',
                  payload: { id: ModalIds.portfoliosSettingsSummary, data: { portfoliosSettingsSummary: flowData } },
                });
                dispatch({ type: 'HIDE_MODAL', payload: ModalIds.portfoliosSettingsAdd });
              },
              undividedCapital: flowData.accountUnlocatedCapitalPortfolio,
              accountId: flowData.accountId,
              clientEmailOwner: flowData.clientEmailOwner,
              clientNameOwner: flowData.clientNameOwner,
              onRefetchParticipants: flowData.onRefetchParticipants,
              onAddPortfolioInNewParticipantSetupFlow: flowData.onSubmitNewParticipantSetupFlowStage
                ? ({ capital, owner, ownerEmail }) => {
                    dispatch({
                      type: 'SHOW_MODAL',
                      payload: {
                        id: ModalIds.portfoliosSettingsSummary,
                        data: {
                          portfoliosSettingsSummary: {
                            ...flowData,
                            accountUnlocatedCapitalPortfolio: flowData.accountUnlocatedCapitalPortfolio - +capital,
                            portfolios: [
                              ...flowData.portfolios,
                              {
                                portfolioCurrentCapital: +capital,
                                portfolioCurrentWeight: +capital / flowData.accountUnlocatedCapitalPortfolio,
                                portfolioId: 'add' + capital + owner + ownerEmail,
                                portfolioMember: owner,
                                portfolioOwner: ownerEmail,
                              },
                            ],
                          },
                        },
                      },
                    });
                  }
                : undefined,
            },
          },
        },
      });
    handleCloseModal();
  }, [dispatch, flowData, handleCloseModal]);

  const handleOpenEditPortfolioFlow = useCallback(
    (payload: EditProps) => {
      dispatch({
        type: 'SHOW_MODAL',
        payload: { id: ModalIds.portfoliosSettingsEdit, data: { portfoliosSettingsEdit: payload } },
      });
      handleCloseModal();
    },
    [dispatch, handleCloseModal],
  );
  const handleOpenDeletePortfolioFlow = useCallback(
    (payload: DeleteProps) => {
      dispatch({
        type: 'SHOW_MODAL',
        payload: { id: ModalIds.portfoliosSettingsDelete, data: { portfoliosSettingsDelete: payload } },
      });
      handleCloseModal();
    },
    [dispatch, handleCloseModal],
  );

  const tableHeadings = useMemo(
    () => [t('modals.portfoliosSettings.summary.owner'), t('modals.portfoliosSettings.summary.capital')],
    [t],
  );

  const mapPortfoliosToTableRows = ({
    portfolios,
    accountCurrentCapital,
  }: {
    readonly portfolios?: SummaryProps['portfolios'];
    readonly accountCurrentCapital?: SummaryProps['accountCurrentCapital'];
  }): TableProps['data'][0]['rows'] =>
    portfolios && accountCurrentCapital
      ? portfolios?.map(({ portfolioCurrentCapital, portfolioCurrentWeight, portfolioMember, portfolioId }, index) => ({
          columns: [
            <Typography.Text variant={'bodyText3'} key={portfolioId + index}>
              {portfolioMember}
            </Typography.Text>,
            <div
              className={styles.capitalItem}
              key={portfolioCurrentCapital
                .toString()
                .concat(portfolioCurrentWeight.toString().concat(index.toString()))}
            >
              <Typography.Number currency value={portfolioCurrentCapital} variant={'bodyText3'} />
              &nbsp;(
              <Typography.Number
                percent
                value={+((portfolioCurrentCapital / +accountCurrentCapital) * 100).toFixed(0)}
                variant={'bodyText3'}
              />
              )
            </div>,
          ],
          id: portfolioId,
        }))
      : [];

  const tableAction: DropdownProps['options'] = useMemo(
    () => [
      {
        id: 'edit',
        value: capitalize(t('modals.portfoliosSettings.summary.dropdown.edit')),
        leadingIcon: <PenIcon />,
      },
      {
        id: 'delete',
        value: capitalize(t('modals.portfoliosSettings.summary.dropdown.delete')),
        leadingIcon: <TrashIcon />,
      },
    ],
    [t],
  );

  const handleTableAction = useCallback(
    (action: 'edit' | 'delete', portfolioId: string) => {
      const currentPortfolio = flowData?.portfolios.find((item) => item.portfolioId === portfolioId);

      if (currentPortfolio && flowData) {
        switch (action) {
          case 'edit': {
            handleOpenEditPortfolioFlow({
              accountOwnerEmail: flowData.clientEmailOwner,
              onBackButtonClick: () => {
                dispatch({
                  type: 'SHOW_MODAL',
                  payload: { id: ModalIds.portfoliosSettingsSummary, data: { portfoliosSettingsSummary: flowData } },
                });
                dispatch({ type: 'HIDE_MODAL', payload: ModalIds.portfoliosSettingsEdit });
              },
              accountId: flowData.accountId,
              portfolioId: currentPortfolio.portfolioId,
              currentCapital: currentPortfolio.portfolioCurrentCapital,
              currentStage: 'form',
              portfolioOwner: currentPortfolio.portfolioOwner,
              portfolioMember: currentPortfolio.portfolioMember,
              onRefetchParticipants: flowData.onRefetchParticipants,
              onEditPortfolioInNewParticipantSetupFlow: flowData.onSubmitNewParticipantSetupFlowStage
                ? ({ owner, ownerEmail }) => {
                    dispatch({
                      type: 'SHOW_MODAL',
                      payload: {
                        id: ModalIds.portfoliosSettingsSummary,
                        data: {
                          portfoliosSettingsSummary: {
                            ...flowData,
                            portfolios: [
                              ...[...flowData.portfolios].filter((item) => item.portfolioId !== portfolioId),
                              {
                                portfolioCurrentCapital: currentPortfolio.portfolioCurrentCapital,
                                portfolioCurrentWeight: currentPortfolio.portfolioCurrentWeight,
                                portfolioId: currentPortfolio.portfolioId,
                                portfolioMember: owner,
                                portfolioOwner: ownerEmail,
                              },
                            ],
                          },
                        },
                      },
                    });
                  }
                : undefined,
            });
            break;
          }

          case 'delete': {
            handleOpenDeletePortfolioFlow({
              accountId: flowData.accountId,
              clientEmailOwner: flowData.clientEmailOwner,
              clientNameOwner: flowData.clientNameOwner,
              currentCapital: currentPortfolio.portfolioCurrentCapital,
              currentStage: 'confirmation',
              portfolioId: currentPortfolio.portfolioId,
              portfolioMember: currentPortfolio.portfolioMember,
              portfolioOwner: flowData.clientNameOwner,
              onRefetchParticipants: flowData.onRefetchParticipants,
              onBackButtonClick: () => {
                dispatch({
                  type: 'SHOW_MODAL',
                  payload: { id: ModalIds.portfoliosSettingsSummary, data: { portfoliosSettingsSummary: flowData } },
                });
                dispatch({ type: 'HIDE_MODAL', payload: ModalIds.portfoliosSettingsDelete });
              },
              onDeletePortfolioInNewParticipantSetupFlow: flowData.onSubmitNewParticipantSetupFlowStage
                ? (portfolioId) => {
                    dispatch({
                      type: 'SHOW_MODAL',
                      payload: {
                        id: ModalIds.portfoliosSettingsSummary,
                        data: {
                          portfoliosSettingsSummary: {
                            ...flowData,
                            accountUnlocatedCapitalPortfolio:
                              flowData.accountUnlocatedCapitalPortfolio +
                              (flowData.portfolios.find((item) => item.portfolioId === portfolioId)
                                ?.portfolioCurrentCapital ?? 0),
                            portfolios: [...flowData.portfolios].filter((item) => item.portfolioId !== portfolioId),
                          },
                        },
                      },
                    });
                  }
                : undefined,
            });
            break;
          }

          default: {
            break;
          }
        }
      }
    },
    [dispatch, flowData, handleOpenDeletePortfolioFlow, handleOpenEditPortfolioFlow],
  );

  const tableData: TableProps = useMemo(
    () => ({
      data: [
        {
          header: {
            columns: tableHeadings,
          },
          rows: mapPortfoliosToTableRows({
            portfolios: flowData?.portfolios,
            accountCurrentCapital: flowData?.accountCurrentCapital,
          }),
          isLoading: false,
          cta: (rowId) => (
            <Dropdown
              forcePositionLeft
              button={<EllipsisIcon />}
              onChange={(value) => handleTableAction(Array.isArray(value) ? value[0] : value, rowId)}
              options={tableAction}
            />
          ),
        },
      ],
    }),
    [flowData?.portfolios, handleTableAction, tableAction, tableHeadings, flowData?.accountCurrentCapital],
  );

  return {
    t,
    flowData,
    tableData,
    handleCloseModal,
    handleOpenAddPortfolioFlow,
  };
};

export { useSummaryData };
