import {
  CollectiveAccounts,
  CollectiveAccountsPortfolios,
  CollectivePortfolios,
  IndividualAccount,
  IndividualPortfolio,
  GenerateReport,
} from './components';

const ReportsFlow = {
  IndividualAccount,
  IndividualPortfolio,
  CollectiveAccountsPortfolios,
  CollectiveAccounts,
  CollectivePortfolios,
  GenerateReport,
};

export { ReportsFlow };
