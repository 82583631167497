import { FC } from 'react';

import { HeaderLarge, HeaderSmall } from './components';
import { HeaderProps } from './header.types';

const Header: FC<HeaderProps> = ({ large, small }) => {
  if (large && small) {
    throw new Error('Header must be only one size. Either small or large!');
  }

  const HeaderVariants: Record<keyof HeaderProps, JSX.Element> = {
    small: small ? <HeaderSmall {...small} /> : <></>,
    large: large ? <HeaderLarge {...large} /> : <></>,
  };

  return HeaderVariants[small ? 'small' : 'large'];
};

export { Header };
