import { AvailableLocales } from '../../../localization';

export type InviteUserPayload = {
  readonly email: string;
  readonly name: string;
  readonly role: ReadonlyArray<UserRole>;
};

export type MoUser = {
  readonly email: string;
  readonly uuid: string;
  readonly name: string;
  readonly role: ReadonlyArray<UserRole>;
  readonly preferences: UserPreferences;
  readonly status: UserStatus;
  readonly isRoot: boolean;
  readonly isLoggedInRole: UserRole;
  readonly createdAt: string;
};

enum UserStatus {
  active = 'active',
  inviteSent = 'inviteSent',
  inviteAccepted = 'inviteAccepted',
  deleted = 'deleted',
}

type UserPreferences = {
  /**
   * User preferred language
   */
  readonly language: AvailableLocales;

  /**
   * Is dark theme enabled
   */
  readonly darkTheme: boolean;

  /**
   * Dial in for support
   */
  readonly dial: string;

  /**
   * Default messenger
   */
  readonly messenger: 'telegram' | 'viber';

  /**
   * Telegram link
   */
  readonly telegram: string;

  /**
   * Is two factor enabled
   */
  readonly twoFactor: boolean;

  /**
   * Viber link
   */
  readonly viber: string;

  /**
   * Is email verified
   */
  readonly verified: boolean;

  /**
   * Specifies last password change date
   */
  readonly lastPasswordChangeDate?: string | null;

  readonly portals: ReadonlyArray<Office>;
};

export enum Office {
  management = 'management',
  client = 'client',
}

export type VerifyInvitationPayload = {
  readonly email: string;
  readonly token: string;
};

export type EditUserPayload = {
  readonly email: string;
  readonly name: string;
  readonly role: ReadonlyArray<UserRole>;
  readonly id: string;
};

export type GetUsersResponse = ReadonlyArray<MoUser>;

export type ValidatePreRegisterCredentialsPayload = {
  readonly email: string;
  readonly password: string;
};

export type RegisterRootUserPayload = {
  readonly name: string;
  readonly email: string;
  readonly password: string;
  readonly rootRegisterToken: string;
};

export type RegisterUserPayload = {
  readonly email: string;
  readonly password: string;
};

export type VerifyEmailPayload = {
  readonly email: string;
  readonly token: string;
};

export type LoginPayload = {
  readonly email: string;
  readonly password: string;
  readonly rememberUser: boolean;
  readonly isClientOfficeLogin?: boolean;
  readonly alreadyVerifiedTwoFa?: boolean;
};

export type SendTwoFaPayload = { readonly email: string; readonly currentPassword: string };

export type VerifyTwoFaPayload = {
  readonly userEmail: string;
  readonly code: string;
  readonly isSettingsChange?: boolean;
  readonly isClientOfficeLogin?: boolean;
  readonly rememberUser?: boolean;
};

export type SetupNewPasswordPayload = { readonly password: string; readonly email: string; readonly token: string };

export enum UserRole {
  admin = 'admin',
  manager = 'manager',
  trader = 'trader',
}

export type RequestLoginPayload = {
  readonly email: string;
  readonly role?: UserRole;
};

export type CancelLoginRequestPayload = {
  readonly email: string;
};

export type GetPortalsResponse = {
  readonly affiliation: ReadonlyArray<Office>;
};

export type LoginCandidate = {
  readonly name: string;
  readonly role: UserRole;
  readonly email: string;
};
