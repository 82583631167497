import React, { FC } from 'react';

import { ReactComponent as TendencyIcon } from '../../../../assets/icons/tendency.svg';
import { TendencyIndicatorProps } from './tendency-indicator.types';
import classNames from 'classnames';
import styles from './tendency-indicator.module.scss';

/**
 * TendencyIndicator component
 * @description Tendency count indicator
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { TendencyIndicatorProps } props - TendencyIndicatorProps defined in the './messages-indicator.types.ts'
 */
const TendencyIndicator: FC<TendencyIndicatorProps> = ({ direction, text }) => (
  <div className={styles.wrapper}>
    {<TendencyIcon className={classNames(styles.icon, styles[direction])} />}
    <div className={styles[direction]}>{text}</div>
  </div>
);

export { TendencyIndicator };
