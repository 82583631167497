import React, { FC } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../components';
import styles from './request-accepted-modal.module.scss';
import { useRequestAcceptedModal } from './request-accepted-modal.hook';

/**
 * RequestAcceptedModal component
 * @description Content for the RequestAccepted window
 *
 * @author Sergii Goncharuk
 * @category Components
 */
const RequestAcceptedModal: FC = () => {
  const { t, type, email, handleContinueClick } = useRequestAcceptedModal();

  return (
    <Modal modalId={ModalIds.requestAccepted} title={t(`modals.requestAccepted.${type}.title`)}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>
          {t(`modals.requestAccepted.${type}.text`)}
          {email}.
        </Typography.Text>
        <Button className={styles.button} variant={'main'} size={'large'} onClick={handleContinueClick}>
          <Typography.Text variant={'bodyText2'}>{t(`modals.requestAccepted.${type}.button`)}</Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { RequestAcceptedModal };
