import { useCallback, useMemo } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { OrderAcceptationProps } from './order-acceptation.types';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * useOrderAcceptationData hook
 * @description The hook which processes OrderAcceptation modal data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useOrderAcceptationData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { orderAcceptation },
    dispatch,
  } = useModalsContext();

  const flowData: OrderAcceptationProps | undefined = useMemo(() => orderAcceptation, [orderAcceptation]);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.orderAcceptation }),
    [dispatch],
  );

  return {
    t,
    currentType: useMemo(() => flowData?.currentType, [flowData?.currentType]),
    handleCloseModal,
  };
};

export { useOrderAcceptationData };
