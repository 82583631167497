import { Priority, PriorityIndicatorProps } from './priority-indicator.types';
import React, { FC, ReactElement } from 'react';

import { ReactComponent as High } from '../../../../assets/icons/High.svg';
import { ReactComponent as Inactive } from '../../../../assets/icons/Inactive.svg';
import { ReactComponent as Low } from '../../../../assets/icons/Low.svg';
import { ReactComponent as Middle } from '../../../../assets/icons/Middle.svg';

const indicators: Record<Priority, ReactElement> = {
  [Priority.high]: <High />,
  [Priority.inactive]: <Inactive />,
  [Priority.low]: <Low />,
  [Priority.middle]: <Middle />,
};

/**
 * PriorityIndicator component
 * @description https://www.figma.com/file/3F1NN6p1bcDJVxnKnBCi7d/Investudio-desktop%7C-UI-Kit-(new)?type=design&node-id=250-1415&mode=dev
 *
 * @author Oleksii Medvediev
 * @param { PriorityIndicatorProps } props - PriorityIndicatorProps defined in the './priority-indicator.types.ts'
 * @category Components
 */
const PriorityIndicator: FC<PriorityIndicatorProps> = ({ priority }) => indicators[priority];

export { PriorityIndicator };
