import React, { FC, useCallback } from 'react';

import { Button } from '../../../button';
import { Modal } from '../../../modal/modal.component';
import { ModalIds } from '../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../components';
import styles from './data-account-success-modal.module.scss';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * DataAccountSuccess component
 * @description Content for the DataAccountSuccess window
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 */
const DataAccountSuccess: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { dispatch } = useModalsContext();

  const closeModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.dataAccountSuccess });
  }, [dispatch]);

  return (
    <Modal modalId={ModalIds.dataAccountSuccess} title={t('modals.settingsDataAccountSuccess.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>{t('modals.settingsDataAccountSuccess.description')}</Typography.Text>
        <Button size={'large'} variant={'main'} onClick={closeModal} className={styles.button}>
          <Typography.Text variant={'bodyText2'}>{t('modals.settingsDataAccountSuccess.button')}</Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { DataAccountSuccess };
