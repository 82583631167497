import * as React from 'react';
import styles from './card.module.scss';

import { CardProps } from './card.props';
import classNames from 'classnames';

const Card: React.FC<CardProps> = ({ children, p = true, classes }) => {
  return (
    <div
      className={classNames(styles.card, classes, {
        [styles.cardP]: p,
      })}
    >
      {children}
    </div>
  );
};

export { Card };
