/**
 * Regular expression pattern for password validation.
 *
 * The password must meet the following criteria:
 * - Minimum 6 characters
 * - At least 1 capital letter (A-Z)
 * - At least 1 special character from /_*!#
 * - At least 1 number (0-9)
 * @category RegExp
 * @author Rostyslav Nahornyi
 */
const PasswordValidationRegExp = /^(?=.*[A-Z])(?=.*[/_*!#])(?=.*\d).{6,}$/;

export { PasswordValidationRegExp };
