import React, { FC } from 'react';

import { Button } from '../../../../../../../button';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { RequestProps } from './request.types';
import { Typography } from '../../../../../../../../../../../components';
import styles from './request.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Request component
 * @description Content for the AutomatedOrdersConfirmation Set flow Request stage
 *
 * @author Oleksii Medvediev
 * @param { RequestProps } props - RequestProps defined in the './request.types.ts'
 * @category Components
 */
const Request: FC<RequestProps> = ({ onSubmit }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  return (
    <Modal
      modalId={ModalIds.automatedOrdersConfirmationSet}
      title={t('modals.automatedOrdersConfirmation.set.request.title')}
    >
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>
          {t('modals.automatedOrdersConfirmation.set.request.text')}
        </Typography.Text>
        <Button type={'button'} variant={'main'} size={'large'} onClick={onSubmit}>
          <Typography.Text variant={'bodyText2'}>
            {t('modals.automatedOrdersConfirmation.set.request.continue')}
          </Typography.Text>
        </Button>
      </div>
    </Modal>
  );
};

export { Request };
