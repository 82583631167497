import React, { FC } from 'react';

import { RadioTypes } from './radio.types';
import { Typography } from '../typography';
import classNames from 'classnames';
import styles from './radio.module.scss';

/**
 * Radio component
 * @description Radio
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { RadioTypes } props - RadioTypes defined in the './radios.types.ts'
 */
const Radio: FC<RadioTypes> = ({ name, id, value, onChange, checked, text, disabled }) => {
  return (
    <label htmlFor={id} className={classNames(styles.wrapper, disabled && styles.disabledWrapper)}>
      <input
        className={styles.radioInput}
        type={'radio'}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
        hidden
        disabled={disabled}
      />
      <span className={styles.customRadio} />
      <Typography.Text variant={'bodyText2'}>{text}</Typography.Text>
    </label>
  );
};

export { Radio };
