import {
  CancelLoginRequestPayload,
  EditUserPayload,
  GetPortalsResponse,
  GetUsersResponse,
  InviteUserPayload,
  LoginPayload,
  MoUser,
  RegisterRootUserPayload,
  RegisterUserPayload,
  RequestLoginPayload,
  SendTwoFaPayload,
  SetupNewPasswordPayload,
  UserRole,
  ValidatePreRegisterCredentialsPayload,
  VerifyEmailPayload,
  VerifyInvitationPayload,
  VerifyTwoFaPayload,
} from './auth-mo.model';

import { GetAdminSettingsResponse } from '../office';
import { request } from '../../http';

export const inviteUser = (data: InviteUserPayload) =>
  request<{ readonly data?: MoUser; readonly message?: string }>({
    method: 'post',
    data,
    url: 'auth-management-office/invite-user',
  });

export const verifyInvitation = (data: VerifyInvitationPayload) =>
  request<{ readonly data: { readonly email: string; readonly name: string } }>({
    method: 'post',
    data,
    url: 'auth-management-office/verify-invitation',
  });

export const editUser = (data: EditUserPayload) =>
  request<{ readonly data: MoUser }>({ method: 'post', data, url: 'auth-management-office/edit-user' });

export const deleteUser = (data: { id: string }) =>
  request({ method: 'post', data, url: 'auth-management-office/delete-user' });

export const getUsers = () =>
  request<{ readonly data: GetUsersResponse }>({ method: 'get', url: 'auth-management-office/get-users' });

export const validatePreRegisterCredentials = (data: ValidatePreRegisterCredentialsPayload) =>
  request<{ readonly data?: { readonly apiKey: string; readonly companyName: string } }>({
    method: 'post',
    url: 'auth-management-office/pre-register-validation',
    data,
  });

export const registerRootUser = (data: RegisterRootUserPayload) =>
  request({ method: 'post', url: 'auth-management-office/root-sign-up', data });

export const registerUser = (data: RegisterUserPayload) =>
  request<{ readonly data?: { link: string } }>({ method: 'post', url: 'auth-management-office/sign-up', data });

export const verifyEmail = (data: VerifyEmailPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/verify' });

export const login = (data: LoginPayload) =>
  request<{
    readonly data:
      | (MoUser & { readonly settings: ReadonlyArray<GetAdminSettingsResponse> })
      | 'secondFactor'
      | 'loginRequest'
      | 'restrictedMode';
    readonly token?: string;
  }>({
    method: 'post',
    data,
    url: 'auth-management-office/login',
  });

export const sendTwoFa = (data: SendTwoFaPayload) =>
  request<{ readonly data: string }>({ method: 'post', data, url: 'auth-management-office/send-two-fa' });

export const resendTwoFa = (data: { readonly userEmail: string }) =>
  request({ method: 'post', data, url: 'auth-management-office/resend-two-fa' });

export const verifyTwoFa = (data: VerifyTwoFaPayload) =>
  request<{
    readonly data:
      | (MoUser & { readonly settings: ReadonlyArray<GetAdminSettingsResponse> })
      | 'loginRequest'
      | 'restrictedMode';
    readonly token?: string;
  }>({
    method: 'post',
    data,
    url: 'auth-management-office/verify-two-fa',
  });

export const authenticate = () => request<{ readonly data: MoUser }>({ method: 'get', url: 'auth-management-office' });

export const passwordRecoveryRequest = (data: { readonly email: string }) =>
  request({ method: 'post', data, url: 'auth-management-office/password-recovery' });

export const setupNewPassword = (data: SetupNewPasswordPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/new-password-setup' });

export const logout = (data: { readonly email: string }) =>
  request<{ readonly data: MoUser }>({ method: 'post', data, url: 'auth-management-office/logout' });

export const changeUserRole = (data: { readonly role: UserRole; readonly email: string }) =>
  request<{ readonly data: UserRole | 'loginRequest' }>({
    method: 'post',
    data,
    url: 'auth-management-office/change-role',
  });

export const requestLogin = (data: RequestLoginPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/request-login' });

export const cancelLoginRequest = (data: CancelLoginRequestPayload) =>
  request({ method: 'post', data, url: 'auth-management-office/cancel-login-request' });

export const getPortals = (email: string) =>
  request<{ readonly data: GetPortalsResponse }>({
    method: 'get',
    url: 'auth-management-office/get-user-affiliation',
    params: { email },
  });
