import * as yup from 'yup';

import { UserRole, setManagerParticipantsPortfolioName } from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useMemo } from 'react';

import { FormProps } from './form.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

/**
 * useFormData hook
 * @description The hook which processes RenamePortfolio flow Form stage data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useFormData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { renamePortfolioForm },
    dispatch,
  } = useModalsContext();
  const { user, dispatch: appDispatch } = useAppContext();

  const flowData: FormProps | undefined = useMemo(() => renamePortfolioForm, [renamePortfolioForm]);

  const initialValues: {
    readonly newNamePortfolio: string;
  } = useMemo(
    () => ({
      newNamePortfolio: flowData?.portfolioName ?? '',
    }),
    [flowData?.portfolioName],
  );

  const validationSchema: yup.Schema<typeof initialValues> = yup.object({
    newNamePortfolio: yup.string().required(t('modals.renamePortfolio.form.required')),
  });

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.renamePortfolioForm }),
    [dispatch],
  );

  const handleSubmitForm = useCallback(
    async ({ newNamePortfolio }: typeof initialValues) => {
      if (user && flowData) {
        appDispatch({ type: 'TOGGLE_IS_LOADING' });

        try {
          const { message } = await setManagerParticipantsPortfolioName({
            newNamePortfolio,
            roleLoginEmail: user.email,
            role: UserRole.manager,
            acctId: flowData.accountId,
            portfolioId: flowData.portfolioId,
          });

          if (message === 'ok') {
            flowData.onRefreshParticipants();

            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                id: ModalIds.renamePortfolioSuccess,
                data: {
                  renamePortfolioSuccess: {
                    newPortfolioName: newNamePortfolio,
                    oldPortfolioName: flowData.portfolioName,
                    onSubmitSuccess: () => {
                      dispatch({ type: 'HIDE_MODAL', payload: ModalIds.renamePortfolioSuccess });
                    },
                  },
                },
              },
            });
          }
        } catch (error) {
          toast.error('Failed to process set manager participants portfolio name request!');
          console.error(error);
        }

        appDispatch({ type: 'TOGGLE_IS_LOADING' });

        handleCloseModal();
      }
    },
    [appDispatch, dispatch, flowData, handleCloseModal, user],
  );

  return {
    t,
    flowData,
    initialValues,
    validationSchema,
    handleSubmitForm,
    handleCloseModal,
  };
};

export { useFormData };
