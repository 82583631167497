import { BrandingType, UserRole, getBranding } from '../../../../../services/api';
import { Footer, Header } from './layout';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import React, { FC, Suspense, lazy, useCallback, useEffect, useState } from 'react';

import { Cache } from 'aws-amplify/utils';
import { CacheQuery } from '../../../../auth/app/shared';
import { Contexts } from './contexts';
import { Preloader } from '../../components';
import { toast } from 'react-toastify';
import { useAppContext } from '../../contexts';

const PortfolioReportPage = lazy(() => import('./portfolio-report-page'));
const AccountReportPage = lazy(() => import('./account-report-page'));

// ATTENTION: THE PAGES BELOW ARE COPIED FROM THE PREVIOUS PROJECT!!!
// They should be totally rewrote because of lack of typification and global syntax issues!!!
// In the current version they are just cleaned from critical runtime errors, but stay inappropriate to the project!!!

const ReportsRouter: FC = () => {
  const [branding, setBranding] = useState<BrandingType>();
  const { user, currentRole } = useAppContext();
  const { pathname, search } = useLocation();
  const [cachedUser, setCachedUser] = useState<typeof user>();

  const redirectUrl = `/auth/login?url=${pathname
    .concat(search)
    .split('/')
    .splice(pathname.split('/').indexOf('reports'), pathname.split('/').length - 1)
    .join('/')}`;

  const fetchBranding = useCallback(async () => {
    if (!branding) {
      try {
        const { data } = await getBranding();
        data && setBranding(data);
      } catch (error) {
        toast.error('Failed to fetch branding!');
        console.error(error);
      }
    }
  }, [branding]);

  const getCachedUser = useCallback(async () => {
    await Cache.getItem(CacheQuery.USER).then((data) => setCachedUser(data));
  }, []);

  useEffect(() => {
    fetchBranding();
    getCachedUser();
  }, [fetchBranding, getCachedUser]);

  return (
    <div id={'reportsPage'}>
      {(user && user.role.some((role) => role === UserRole.manager)) ||
      currentRole === UserRole.manager ||
      cachedUser ||
      localStorage.getItem('local-auth-co') ? (
        <Routes>
          <Route
            path={':accountId'}
            element={
              <Suspense fallback={<Preloader isActive type={'overlay'} />}>
                <Contexts>
                  <Header />
                  <AccountReportPage />
                  {branding && <Footer {...branding} />}
                </Contexts>
              </Suspense>
            }
          />
          <Route
            path={':accountId/:portfolioId'}
            element={
              <Contexts>
                <Header />
                <PortfolioReportPage />
                {branding && <Footer {...branding} />}
              </Contexts>
            }
          />
          <Route path={'/'} element={<Navigate to={'/'} replace />} />
        </Routes>
      ) : (
        <Navigate to={`${redirectUrl}`} replace />
      )}
    </div>
  );
};

export { ReportsRouter };
