import { FC, PointerEvent, ReactElement, useCallback, useRef, useState } from 'react';

import { CellProps } from './cell.types';
import classNames from 'classnames';
import styles from './cell.module.scss';
import tableStyles from '../../table.module.scss';

/**
 * Cell component
 * @description Cell for the Table component
 *
 * @author Oleksii Medvediev
 * @param { CellProps } props - CellProps defined in the './cell.types.ts'
 * @category Components
 */
const Cell: FC<CellProps> = ({ content, isLastRow }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const triangleRef = useRef<HTMLDivElement>(null);
  const [tooltipContent, setTooltipContent] = useState<string>();

  const createTooltipEventHandlers = useCallback(
    () => ({
      onPointerMove: ({ currentTarget }: PointerEvent) => {
        const firstChildChildrenLength = currentTarget.children[0].children.length;
        const firstFactChild = currentTarget.children[0];

        if (firstChildChildrenLength > 1) {
          if (
            currentTarget.clientWidth - 32 <
            firstFactChild.children[0].scrollWidth + firstFactChild.children[1].scrollWidth + 8
          ) {
            setTooltipContent(firstFactChild.children[1].textContent ?? '');
          }
        } else {
          if (currentTarget.clientWidth - 32 < firstFactChild.scrollWidth) {
            setTooltipContent(firstFactChild.textContent ?? (content as ReactElement).props['children']);
          }
        }
      },
      onPointerLeave: () => {
        setTooltipContent(undefined);
      },
    }),
    [content],
  );

  return (
    <div
      ref={wrapperRef}
      title={typeof content === 'string' ? content : undefined}
      className={classNames(tableStyles.td, styles.wrapper)}
      style={!!tooltipContent ? { overflow: 'visible', cursor: 'pointer' } : undefined}
      {...createTooltipEventHandlers()}
    >
      {content}

      <div
        ref={tooltipRef}
        className={styles.tooltip}
        style={{
          display: !!tooltipContent ? 'block' : 'none',
          zIndex: tooltipContent ? 10 : -1,
          position: 'absolute',
          left: `0`,
          top: `${isLastRow ? 'auto' : '54px'}`,
          bottom: `${isLastRow ? '54px' : 'auto'}`,
        }}
      >
        {tooltipContent}
      </div>
      <div
        ref={triangleRef}
        style={{
          display: !!tooltipContent ? 'block' : 'none',
          zIndex: tooltipContent ? 10 : -1,
          position: 'absolute',
          left: `calc(50% - ${(triangleRef.current?.scrollWidth ?? 0) / 2}px)`,
          top: `${isLastRow ? 'auto' : 'calc(54px - 5px)'}`,
          bottom: `${isLastRow ? 'calc(54px - 5px)' : 'auto'}`,
          transform: `rotateX(${isLastRow ? '180deg' : '0'}`,
        }}
        className={styles.triangle}
      />
    </div>
  );
};

export { Cell };
