import { Navigate, Route, Routes } from 'react-router';
import React, { FC, Suspense, lazy } from 'react';

import { InformationContextProvider } from './context';
import { Preloader } from '../../components';

const Account = lazy(() => import('./pages/account'));
const Portfolio = lazy(() => import('./pages/portfolio'));

/**
 * InformationRouter
 * @description Router for the Information pages
 *
 * @author Oleksii Medvediev
 * @category Routers
 */
const InformationRouter: FC = () => (
  <InformationContextProvider>
    <Routes>
      <Route
        path={'account/*'}
        element={
          <Suspense fallback={<Preloader isActive type={'overlay'} />}>
            <Account />
          </Suspense>
        }
      />
      <Route
        path={'portfolio/*'}
        element={
          <Suspense fallback={<Preloader isActive type={'overlay'} />}>
            <Portfolio />
          </Suspense>
        }
      />
      <Route path={''} element={<Navigate to={'/dashboard'} />} />
    </Routes>
  </InformationContextProvider>
);

export { InformationRouter };
