/**
 * PriorityIndicatorProps
 * @description Props for the PriorityIndicator component
 *
 * @author Oleksii Medvediev
 */
type PriorityIndicatorProps = {
  readonly priority: Priority;
};

enum Priority {
  high = 'high',
  low = 'low',
  inactive = 'inactive',
  middle = 'middle',
}

export type { PriorityIndicatorProps };
export { Priority };
