import { Frame, PortfolioContextProps, PortfolioState } from './portfolio.types';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  getFrames,
  getManagerParticipantsIncomeF1,
  getManagerParticipantsIncomeF2,
  getNotifications,
  getPortfolioAllocation,
  getPortfolioAssets,
  getPortfolioChart,
  getPortfolioPerformance,
  getPortfolioPerformanceOld,
  getPortfolios,
  getTickerShortInfo,
} from '../../../../../../services/api';
import { initialState, portfolioReducer } from './portfolio.state';

import { compileYieldOption } from '../components/portfolio/components/yield/yield.hook';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../context';
import { useParams } from 'react-router-dom';

/**
 * PortfolioContext
 * @description Context for PortfolioRouter pages
 *
 * @author Oleksii Medvediev
 * @category Contexts
 */
const PortfolioContext = createContext<PortfolioContextProps>({
  dispatch: () => null,
  yieldPeriod: Frame.month,
});

/**
 * PortfolioContextProvider component
 * @description Provider for the PortfolioContext
 *
 * @author Oleksii Medvediev
 * @category Context Providers
 */
const PortfolioContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(portfolioReducer, initialState);
  const { isLoading, user, dispatch: appDispatch } = useAppContext();
  const { portfolioId } = useParams();
  const [isPortfolioFetched, setIsPortfolioFetched] = useState(false);

  /**
   * Fetching portfolio
   */
  const fetchPortfolios = useCallback(async () => {
    if (!isLoading && !state.portfolio && portfolioId && !isPortfolioFetched) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        setIsPortfolioFetched(true);

        const { data } = await getPortfolios();

        const portfolio = data?.find(({ id }) => id === portfolioId);
        portfolio && dispatch({ type: 'SET_PORTFOLIO', payload: portfolio });
      } catch (error) {
        toast.error('Failed to fetch portfolios!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, isLoading, isPortfolioFetched, portfolioId, state.portfolio]);

  /**
   * Fetching performanceOld
   */
  const fetchPortfolioPerformanceOld = useCallback(async () => {
    if (!isLoading && state.portfolio && !state.performanceOld) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await getPortfolioPerformanceOld({
          accountId: state.portfolio.accountId,
          portfolioId: state.portfolio.id,
        });

        data && dispatch({ type: 'SET_PORTFOLIO_PERFORMANCE_OLD', payload: data });
      } catch (error) {
        toast.error('Failed to fetch portfolio performance (old)!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, isLoading, state.performanceOld, state.portfolio]);

  /**
   * Fetching Assets
   */
  const fetchPortfolioAssets = useCallback(async () => {
    if (!isLoading && state.portfolio && state.performanceOld && !state.assets) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await getPortfolioAssets({
          portfolioId: state.portfolio.id,
          acctId: state.portfolio.accountId,
          unlocatedCap: state.performanceOld?.unallocatedCapital,
          weightPortfolioIdNumeric: state.performanceOld.currentWeight,
        });

        data && dispatch({ type: 'SET_PORTFOLIO_ASSETS', payload: data });
      } catch (error) {
        toast.error('Failed to fetch portfolio assets!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, isLoading, state.assets, state.performanceOld, state.portfolio]);

  /**
   * Fetching allocation
   */
  const fetchPortfolioAllocation = useCallback(async () => {
    if (!isLoading && state.portfolio && state.assets && state.performanceOld && !state.allocation) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await getPortfolioAllocation({
          portfolioId: state.portfolio.id,
          acctId: state.portfolio.accountId,
          unlocatedCap: state.performanceOld.unallocatedCapital,
          weightPortfolioIdNumeric: state.performanceOld.currentWeight,
        });

        data && dispatch({ type: 'SET_PORTFOLIO_ALLOCATION', payload: data });
      } catch (error) {
        toast.error('Failed to fetch portfolio allocation!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, isLoading, state.allocation, state.assets, state.performanceOld, state.portfolio]);

  /**
   * Fetching performance
   */
  const fetchPortfolioPerformance = useCallback(async () => {
    if (!isLoading && state.portfolio && state.allocation && !state.performance) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await getPortfolioPerformance({
          portfolioId: state.portfolio.id,
          acctId: state.portfolio.accountId,
          limitCount: 1,
        });

        data && dispatch({ type: 'SET_PORTFOLIO_PERFORMANCE', payload: data });
      } catch (error) {
        toast.error('Failed to fetch portfolio performance!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, isLoading, state.allocation, state.performance, state.portfolio]);

  /**
   * Fetching IncomeF1
   */
  const fetchPortfolioIncomeF1 = useCallback(async () => {
    if (!state.incomeF1 && state.portfolio && state.performance && !isLoading) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        if (state.portfolio) {
          const { data } = await getManagerParticipantsIncomeF1({
            acctId: state.portfolio.accountId,
            limitCount: 1,
            portfolioId: state.portfolio.id,
          });
          data && dispatch({ type: 'SET_PORTFOLIO_INCOME_F1', payload: data });
        }
      } catch (error) {
        toast.error('Failed to fetch manager participants income F1!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [state.incomeF1, state.portfolio, state.performance, isLoading, appDispatch]);

  /**
   * Fetching IncomeF2
   */
  const fetchPortfolioIncomeF2 = useCallback(async () => {
    if (!state.incomeF2 && state.incomeF1 && state.portfolio && !isLoading) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        if (state.portfolio) {
          const { data } = await getManagerParticipantsIncomeF2({
            acctId: state.portfolio.accountId,
            portfolioId: state.portfolio.id,
          });
          data && dispatch({ type: 'SET_PORTFOLIO_INCOME_F2', payload: data });
        }
      } catch (error) {
        toast.error('Failed to fetch manager participants income F2!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, isLoading, state.incomeF1, state.incomeF2, state.portfolio]);

  /**
   * Fetching portfolioChart and  frames
   */
  const fetchPortfolioChart = useCallback(async () => {
    if (!state.portfolioChart && !state.frames && state.incomeF2 && state.portfolio && !isLoading) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        if (state.portfolio) {
          const { data: chartData } = await getPortfolioChart({
            accountId: state.portfolio.accountId,
            portfolioId: state.portfolio.id,
            period: state.yieldPeriod,
          });
          chartData && dispatch({ type: 'SET_PORTFOLIO_CHART', payload: chartData });
        }
        const { data } = await getFrames();
        data && dispatch({ type: 'SET_FRAMES', payload: data });
      } catch (error) {
        toast.error('Failed to fetch portfolio chart!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      !!state.incomeF1?.length &&
        dispatch({
          type: 'SET_SELECTED_YIELD_OPTION',
          payload: compileYieldOption(state.yieldPeriod, state.incomeF1, state.selectedYieldOption),
        });

      dispatch({ type: 'SET_YIELD_PERIOD', payload: state.yieldPeriod });
    }
  }, [
    appDispatch,
    isLoading,
    state.frames,
    state.incomeF1,
    state.incomeF2,
    state.portfolio,
    state.portfolioChart,
    state.selectedYieldOption,
    state.yieldPeriod,
  ]);

  /**
   * Fetching portfolio tickers short info
   */
  const fetchTickersShortInfo = useCallback(async () => {
    if (
      !isLoading &&
      state.allocation &&
      state.assets &&
      state.portfolioChart &&
      state.frames &&
      !state.tickersShortInfo
    ) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const info: PortfolioState['tickersShortInfo'] = {};

        for (const { Symbol, ISIN } of state.assets) {
          const { data } = await getTickerShortInfo({ ticker: Symbol, limit: 180, isin: ISIN });

          if (data) {
            info[Symbol] = { ...data, Charts: [...data.Charts].reverse() };
          }
        }

        dispatch({ type: 'SET_TICKERS_SHORT_INFO', payload: info });
      } catch (error) {
        toast.error('Failed to fetch ticker short info!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [
    appDispatch,
    isLoading,
    state.allocation,
    state.assets,
    state.frames,
    state.portfolioChart,
    state.tickersShortInfo,
  ]);

  /**
   * Fetching Notifications
   */
  const fetchNotifications = useCallback(async () => {
    try {
      if (!state.notifications && !isLoading && state.portfolio && state.tickersShortInfo && user) {
        const { data } = await getNotifications({ clientEmail: user?.email, lang: user.preferences.language });

        data && dispatch({ type: 'SET_NOTIFICATIONS', payload: data });
      }
    } catch (error) {
      toast.error('Failed to fetch notifications!');
      console.error(error);
      dispatch({ type: 'SET_NOTIFICATIONS', payload: [] });
    }
  }, [isLoading, state.notifications, state.portfolio, state.tickersShortInfo, user]);

  /**
   * Triggering data fetching
   */
  useEffect(() => {
    fetchPortfolios();
    fetchPortfolioPerformanceOld();
    fetchPortfolioAssets();
    fetchPortfolioAllocation();
    fetchPortfolioPerformance();
    fetchPortfolioIncomeF1();
    fetchPortfolioIncomeF2();
    fetchPortfolioChart();
    fetchTickersShortInfo();
    fetchNotifications();
  }, [
    fetchNotifications,
    fetchPortfolioAllocation,
    fetchPortfolioAssets,
    fetchPortfolioChart,
    fetchPortfolioIncomeF1,
    fetchPortfolioIncomeF2,
    fetchPortfolioPerformance,
    fetchPortfolioPerformanceOld,
    fetchPortfolios,
    fetchTickersShortInfo,
  ]);

  // Setting Portfolio title
  useEffect(() => {
    if (document) {
      document.title = 'Portfolio | Clients Office';
    }
  }, []);

  return <PortfolioContext.Provider value={{ ...state, dispatch }}>{children}</PortfolioContext.Provider>;
};

/**
 * usePortfolioContext hook.
 * @description The hook for getting PortfolioContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const usePortfolioContext = () => useContext(PortfolioContext);

export { PortfolioContextProvider, usePortfolioContext };
