import * as React from 'react';

import {
  Area,
  ComposedChart,
  Label,
  Line,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { ComplexLineChartProps } from './complex-line-chart.types';
import { LegendTemplate } from '..';
import { Typography } from '../../../../../components';
import classNames from 'classnames';
import dayjs from 'dayjs';
import styles from './complex-line-chart.module.scss';
import { useComplexLineChartHook } from './complex-line-chart.hook';

/**
 * ComplexLineChart component
 * @description Old project ComplexLineChart
 *
 * @author unknown, Ihor Dubas, Oleksii Medvediev
 * @category Components
 */
const ComplexLineChartComponent: React.FC<ComplexLineChartProps> = (props) => {
  const {
    t,
    week,
    color,
    labels,
    ticks,
    minRelative,
    absoluteData,
    relativeData,
    gradientColor,
    tickFormatter,
    primaryActiveDot,
    secondaryActiveDot,
  } = useComplexLineChartHook(props);

  const [start, end] = week;

  const { period, legendLabels } = props;

  return (
    <div className={styles.complexLineChart}>
      <div className={styles.complexLineChartContainer}>
        <ResponsiveContainer width={'100%'} height={'70%'}>
          <ComposedChart data={absoluteData} syncId={'tes'}>
            <defs>
              <linearGradient id={'colorGreen'} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
                <stop offset={'0%'} stopColor={gradientColor} stopOpacity={0.3} />
                <stop offset={'100%'} stopColor={gradientColor} stopOpacity={0} />
              </linearGradient>
              <linearGradient id={'colorInactive'} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
                <stop offset={'0%'} stopColor={'#fff'} stopOpacity={0.3} />
                <stop offset={'100%'} stopColor={'#fff'} stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type={'linear'}
              dataKey={'navs'}
              strokeWidth={2}
              stroke={period === 'week' ? 'white' : color}
              fillOpacity={1}
              fill={period === 'week' ? 'url(#colorInactive)' : 'url(#colorGreen)'}
              activeDot={primaryActiveDot}
            />
            <Area
              type={'linear'}
              dataKey={'week'}
              strokeWidth={2}
              stroke={color}
              fillOpacity={1}
              fill={'url(#colorGreen)'}
              activeDot={primaryActiveDot}
            />
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              content={({ payload, active }) => {
                if (!active) return null;

                const value = payload?.[0]?.value;
                const date = dayjs(payload?.[0]?.payload?.name).format('DD/MM/YY');
                // const project = dayjs(payload?.[0]?.payload?.project);
                return (
                  <div className={styles.tooltipContainer}>
                    <Typography.Text variant={'label3'}>{date}</Typography.Text>

                    {/* {value && +value > +payload?.[0]?.payload?.project ? ( */}
                    <div className={styles.complexLineTooltip2} style={{ background: gradientColor, marginTop: '5px' }}>
                      <Typography.Number variant={'label4'} round={0} currency value={Number(value)} />
                    </div>
                    {/* ) : (
                      <div
                        className={styles.complexLineTooltip2}
                        style={{ background: '#fff', marginTop: '5px', color: '#000' }}
                      >
                        <Typography.Number variant={'label4'} round={0} currency value={Number(project)} />
                      </div>
                    )} */}
                  </div>
                );
              }}
              position={{ y: 0 }}
              isAnimationActive={false}
            />
            <ReferenceDot
              x={absoluteData[absoluteData.length - 1]?.name}
              y={
                (absoluteData[absoluteData.length - 1]?.navs || (absoluteData[absoluteData.length - 1]?.week ?? 0)) *
                0.97
              }
              r={0}
            >
              <Label
                position={'left'}
                value={labels?.navs || labels?.week}
                offset={3}
                style={{
                  fill: gradientColor,
                  fontWeight: 600,
                  fontSize: '10px',
                }}
              />
            </ReferenceDot>
            <ReferenceDot
              x={absoluteData[absoluteData.length - 1]?.name}
              y={absoluteData[absoluteData.length - 1]?.lineDd * 1.04}
              r={0}
            >
              <Label
                position={'left'}
                value={labels?.lineDd}
                offset={0}
                style={{
                  fill: '#FFFFFF',
                  fontWeight: 600,
                  fontSize: '10px',
                }}
              />
            </ReferenceDot>
            <YAxis type={'number'} domain={[(min: number) => min * 0.9, (max: number) => max * 1.1]} hide />
            <XAxis tick={false} dataKey={'name'} hide />

            {/* Lines for the week option */}
            <ReferenceLine x={start?.x} strokeWidth={'1px'} stroke={'#fff'} strokeDasharray={'2'} />
            <ReferenceLine x={end?.x} strokeWidth={'1px'} stroke={'#fff'} strokeDasharray={'2'} />
            <ReferenceDot x={start?.x} y={start?.y} stroke={color} strokeWidth={'4px'} r={5} />
            <ReferenceDot x={end?.x} y={end?.y} stroke={color} strokeWidth={'4px'} r={5} />
          </ComposedChart>
        </ResponsiveContainer>
        <ResponsiveContainer width={'100%'} height={'30%'} className={styles.complexLineChartDrawdownMargin}>
          <ComposedChart data={relativeData} syncId={'tes'}>
            <defs>
              <linearGradient id={'colorWhite'} x1={'0'} y1={'0'} x2={'0'} y2={'1'}>
                <stop offset={'0%'} stopColor={'#28282E'} stopOpacity={0.1} />
                <stop offset={'100%'} stopColor={'#FFF'} stopOpacity={0.5} />
              </linearGradient>
            </defs>
            <Line type={'linear'} dataKey={'drawdown'} stroke={'#FFF'} dot={false} activeDot={secondaryActiveDot} />
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              filterNull
              content={({ payload, active }) => {
                if (!active) return null;
                return (
                  <div className={styles.tooltipContainer}>
                    <div
                      className={classNames(styles.complexLineTooltip, styles.darkFont)}
                      style={{ background: '#FFFFFF' }}
                    >
                      <Typography.Number variant={'label4'} round={2} percent value={Number(payload?.[0]?.value)} />
                    </div>
                  </div>
                );
              }}
              isAnimationActive={false}
            />
            <ReferenceDot
              x={relativeData[relativeData.length - 1]?.name}
              y={relativeData[relativeData.length - 1]?.drawdown * 3}
              r={0}
            >
              <Label
                position={'left'}
                value={labels?.drawdown}
                offset={0}
                style={{
                  fill: '#FFFFFF',
                  fontWeight: 600,
                  fontSize: '10px',
                }}
              />
            </ReferenceDot>
            <Line type={'linear'} dataKey={'default'} opacity={0.2} stroke={'#FFF'} dot={false} activeDot={false} />
            <Area
              type={'linear'}
              dataKey={'drawdown'}
              strokeWidth={2}
              stroke={'#FFFFFF'}
              fillOpacity={1}
              fill={'url(#colorWhite)'}
            />
            <YAxis type={'number'} domain={[minRelative, 0]} hide />
            <XAxis
              dataKey={'name'}
              orientation={'bottom'}
              ticks={ticks}
              tickFormatter={tickFormatter}
              axisLine={false}
              interval={'preserveStartEnd'}
              tickLine={false}
              style={{
                textTransform: 'capitalize',
              }}
            />

            {/* Lines for the week option */}
            <ReferenceLine x={start?.x} strokeWidth={'1px'} stroke={'#fff'} strokeDasharray={'2'} />
            <ReferenceLine x={end?.x} strokeWidth={'1px'} stroke={'#fff'} strokeDasharray={'2'} />
            <ReferenceDot x={start?.x} y={start?.y} stroke={color} strokeWidth={'4px'} r={5} />
            <ReferenceDot x={end?.x} y={end?.y} stroke={color} strokeWidth={'4px'} r={5} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.customLegend}>
        <LegendTemplate
          firstValueColor={color}
          valueFirst={(legendLabels && legendLabels[0]) ?? t('charts.profitability')}
          valueSecond={(legendLabels && legendLabels[1]) ?? t('charts.profit')}
          valueThird={(legendLabels && legendLabels[2]) ?? t('charts.growthPotential')}
        />
      </div>
    </div>
  );
};

const ComplexLineChart = React.memo(ComplexLineChartComponent);
export { ComplexLineChart };
