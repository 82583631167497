import { useCallback, useMemo, useState } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * useDateRangePickerModalData hook
 * @description The hook which processes DateRangePickerModal data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useDateRangePickerModalData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { dateRangePicker },
    dispatch,
  } = useModalsContext();

  const modalData = useMemo(() => dateRangePicker, [dateRangePicker]);

  const defaultRange = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      isChanged: false,
      startNow: !!modalData?.startNow,
    },
  ];

  const incomingRange = useMemo(
    () => [
      {
        startDate: modalData?.startDate ?? new Date(),
        endDate: modalData?.endDate ?? new Date(),
        key: 'selection',
        isChanged: !!modalData?.startDate,
        startNow: !!modalData?.startNow,
      },
    ],
    [modalData?.endDate, modalData?.startDate, modalData?.startNow],
  );

  const [ranges, setRanges] = useState<
    ReadonlyArray<{
      readonly startDate: Date;
      readonly endDate: Date;
      readonly key: string;
      readonly isChanged: boolean;
      readonly startNow?: boolean;
    }>
  >(incomingRange);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.dateRangePicker }),
    [dispatch],
  );

  const handleSubmit = useCallback(() => {
    modalData?.onSubmit({ startDate: ranges[0].startDate, endDate: ranges[0].endDate });
    handleCloseModal();
  }, [handleCloseModal, modalData, ranges]);

  return { t, ranges, modalData, defaultRange, setRanges, handleSubmit };
};

export { useDateRangePickerModalData };
