import { AbsolutePart, ComplexLineChartProps, Labels, RelativePart } from './complex-line-chart.types';
import { currency, getQuarters, getYears } from '../../utils';
import { useEffect, useState } from 'react';

import { AvailableLocales } from '../../../../../localization';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const useComplexLineChartHook = ({ frames, period, navs, currentPortfolio }: ComplexLineChartProps) => {
  const [color, setColor] = useState<'#40f493' | '#e82518'>('#40f493');
  const [gradientColor, setGradientColor] = useState<'#30BB70' | '#c91d12'>('#30BB70');
  const [absoluteData, setAbsoluteData] = useState<AbsolutePart[]>([]);
  const [relativeData, setRelativeData] = useState<RelativePart[]>([]);
  const [minRelative, setMinRelative] = useState<number>(0);
  const [labels, setLabels] = useState<Labels | null>(null);
  const [ticks, setTicks] = useState<string[]>([]);
  const [week, setWeek] = useState<{ readonly x: string; readonly y: number }[]>([]);
  const [weekFrame, setWeekFrame] = useState(0);

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'management' });

  const secondaryActiveDot = { stroke: '#fff', strokeWidth: 2, r: 3, fill: '#4B4B57' };
  const primaryActiveDot = { stroke: period === 'week' ? 'white' : color, strokeWidth: 3, r: 4, fill: '#fff' };

  useEffect(() => {
    if (currentPortfolio) {
      const linesLength = currentPortfolio?.lineNavs.length;
      if (period === 'week' && weekFrame) {
        const segment = [
          {
            x: currentPortfolio?.lineNavs[linesLength - weekFrame >= 0 ? linesLength - weekFrame : 0].x,
            y: currentPortfolio?.lineNavs[linesLength - weekFrame >= 0 ? linesLength - weekFrame : 0].y,
          },
          {
            x: currentPortfolio?.lineNavs[linesLength - 1].x,
            y: currentPortfolio?.lineNavs[linesLength - 1].y,
          },
        ];
        setWeek(segment);
      } else {
        setWeek([]);
      }
      const formatted = currentPortfolio?.lineNavs.map((v, i) => {
        return {
          name: v.x,
          lineDd: currentPortfolio.lineDd[i].y,
          project: currentPortfolio.project[i].y,
          ...(period === 'week' && i > linesLength - weekFrame ? { week: v.y } : { navs: v.y }),
          ...(period === 'week' && i === linesLength - weekFrame && { week: v.y, navs: v.y }),
        };
      });

      const drawdown = currentPortfolio?.lineDrawdown.map((v) => {
        return {
          name: v.x,
          drawdown: v.y * 100,
          default: 0,
        };
      });

      setLabels({
        name: formatted[formatted.length - 1].name,
        lineDd: currency(formatted[formatted.length - 1].lineDd),
        drawdown: `${drawdown[drawdown.length - 1].drawdown.toFixed(2)}%`,
        ...(period === 'week'
          ? { week: currency(formatted[formatted.length - 1].week ?? 0) }
          : { navs: currency(formatted[formatted.length - 1].navs ?? 0) }),
      });

      setAbsoluteData(formatted);
      setRelativeData(drawdown);

      setColor(navs >= 0 ? '#40f493' : '#e82518');
      setGradientColor(navs >= 0 ? '#30BB70' : '#c91d12');
    }
  }, [currentPortfolio, navs, period, weekFrame]);

  useEffect(() => {
    if (frames) {
      setWeekFrame(frames.preferences.week);
    }
  }, [frames]);

  useEffect(() => {
    /**
     * Calculate minimum value of relative chart, default value is maxDrawDown, if last is smaller then last
     */
    if (currentPortfolio) {
      const maxDrawDown =
        relativeData[relativeData.length - 1]?.drawdown > currentPortfolio?.currentDrawdown
          ? currentPortfolio?.currentDrawdown
          : relativeData[relativeData.length - 1]?.drawdown;
      setMinRelative(maxDrawDown);
    }

    /**
     * Set ticks array for X-Axis
     */
    switch (true) {
      case relativeData.length < 66:
        setTicks(relativeData.filter((value, index) => index % 2).map((value) => value.name));
        break;
      case relativeData.length >= 66 && relativeData.length < 262:
        setTicks([
          relativeData[0].name,
          relativeData[Math.floor((relativeData.length - 1) / 2)].name,
          relativeData[relativeData.length - 1].name,
        ]);
        break;
      case relativeData.length === 252:
        setTicks(getQuarters(relativeData.map((o) => o.name)));
        break;
      case relativeData.length > 252:
        setTicks(getYears(relativeData.map((o) => o.name)));
        break;
      default:
    }
  }, [currentPortfolio, relativeData]);

  const tickFormatter = (value: string): string => {
    const isUkrainianLocalization = i18n.language === AvailableLocales.UK;

    switch (true) {
      case relativeData.length < 66:
        return dayjs(value).format('DD/MM');
      case relativeData.length >= 66 && relativeData.length < 262:
        return isUkrainianLocalization
          ? dayjs(value).locale('uk').format('MMMM YYYY')
          : dayjs(value).format('MMMM YYYY');
      case relativeData.length === 262:
        return isUkrainianLocalization
          ? dayjs(value).locale('uk').format('MMMM YYYY')
          : dayjs(value).format('MMMM YYYY');
      case relativeData.length > 262:
        return dayjs(value).format('YYYY');
      default:
        return dayjs(value).format('DD/MM');
    }
  };

  return {
    t,
    week,
    ticks,
    color,
    labels,
    minRelative,
    absoluteData,
    relativeData,
    tickFormatter,
    gradientColor,
    primaryActiveDot,
    secondaryActiveDot,
  };
};

export { useComplexLineChartHook };
