import { DashboardContextProvider, useDashboardContext } from './context';
import { Navigate, Route, Routes } from 'react-router';
import React, { FC, Suspense, lazy } from 'react';

import { Preloader } from '../../../../../components';

const Dashboard = lazy(() => import('./components/dashboard/dashboard.component'));
const CreatePortfolio = lazy(() => import('./components/create-portfolio/create-portfolio.component'));

const DashboardRouter: FC = () => {
  useDashboardContext();
  return (
    <DashboardContextProvider>
      <Routes>
        <Route
          path={''}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path={'create-portfolio'}
          element={
            <Suspense fallback={<Preloader isActive type={'overlay'} />}>
              <CreatePortfolio />
            </Suspense>
          }
        />
        <Route path={'*'} element={<Navigate to={''} />} />
      </Routes>
    </DashboardContextProvider>
  );
};

export { DashboardRouter };
