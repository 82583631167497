import * as React from 'react';
import styles from './expandable.module.scss';

import { ExpandableRowProps } from './expandable.props';
import { TableRow } from '../row';
import classNames from 'classnames';
import { useExpandableRowHook } from './expandable.hook';

const ExpandableRow: React.FC<ExpandableRowProps> = ({ row, content }) => {
  const { expanded, handleExpand } = useExpandableRowHook();

  return (
    <>
      {/* Expandable and clickable table row that makes additional content appear */}
      <TableRow
        onClick={handleExpand}
        classes={classNames(styles.expandable, {
          [styles.expandableExpanded]: expanded,
        })}
      >
        {row}
      </TableRow>

      {/* Additional content which is appended to the table rows if above area is clicked */}
      {expanded && content}
    </>
  );
};

export { ExpandableRow };
