export type GetRecommendationsResponse = ReadonlyArray<Recommendation>;

export type Recommendation = {
  readonly id: number;

  readonly account: string;

  readonly datetime: string;

  readonly symbol: string;

  readonly reqCapital: number;

  readonly notation: RecommendationNotation;

  readonly breakeven: number;

  readonly stockLast: number;

  readonly breakevenDistance: number;

  readonly action: RecommendationAction;

  readonly status: RecommendationStatus;

  readonly order: Order;

  readonly orderDescription: OrderDescription;

  readonly orderDescriptionOld: OrderDescriptionOld;

  readonly cardDescription: RecommendationCardDescription;
};

export enum RecommendationAction {
  place = 'place',
  cancel = 'cancel',
  modify = 'modify',
}

export type OrderDescription = {
  readonly commission: number;
  readonly profit: number;
  readonly returns: number;
  readonly returnsAnnual: number;
  readonly takeProfit: number;
  readonly stopLoss: number;
  readonly openDate: string;
  readonly open_date: string;
};

export type OrderDescriptionOld = {
  readonly commission: number;
  readonly profit: number;
  readonly returns: number;
  readonly returns_annual: number;
  readonly take_profit: number;
  readonly stop_loss: number;
  readonly open_date: string;
  readonly quantity: number;
  readonly order_price: number;
};

export type RecommendationCardDescription = {
  readonly Logo: string;
  readonly INFO1: string;
  readonly INFO2: string;
  readonly INFO3: string;
  readonly INFO4: string;
  readonly INFO5: string;
  readonly INFO6: string;
  readonly INFO7: string;
  readonly INFO8: string;
  readonly INFO9: string;
  readonly INFO14: string;
  readonly Charts: ReadonlyArray<ChartRecord>;
  readonly TITLE1: string;
  readonly TITLE2: string;
  readonly TITLE3: string;
  readonly TITLE4: string;
  readonly TITLE5: string;
  readonly TITLE6: string;
  readonly TITLE7: string;
  readonly TITLE8: string;
  readonly TITLE9: string;
  readonly INFO10: string;
  readonly TITLE13: string;
  readonly TITLE11: string;
  readonly TITLE10: string;
  readonly INFO11: string;
  readonly TITLE12: string;
  readonly TITLE15: string;
  readonly INFO13: string;
  readonly TITLE14: string;
  readonly INFO12: string;
  // readonly INFO15: string;
};

export type Contract = {
  readonly price: string;
  readonly secType: 'STK' | 'OPT' | 'FUT' | 'CASH' | 'CRYPTO' | 'IND' | 'CFD' | 'FOP' | 'BOND' | 'CMDTY' | 'WAR';
  readonly symbol: string;
  readonly currency: string;
  readonly multiplier: number;
  readonly strike?: number;
  readonly right?: string;
  readonly lastTradeDateOrContractMonth?: string;
  readonly ratio: number;
  readonly side: 'BUY' | 'SELL';
};

export type Order = {
  readonly account: string;
  readonly quantity: number;
  readonly orderType: string;
  readonly timeInForce: string;
  readonly side: 'BUY' | 'SELL';
  readonly order_id: number;
  readonly contracts: ReadonlyArray<Contract>;
  readonly lmtPrice: number;
  readonly auxPrice: number;
  readonly status: string;
  readonly transactionTime: string;
};

export enum RecommendationNotation {
  buy = 'buy',
  sell = 'sell',
  close = 'close',
  cancel = 'cancel',
  put_spread = 'put_spread',
  call_spread = 'call_spread',
  covered_all = 'covered_all',
  cash_secured_put = 'cash_secured_put',
  rolling = 'rolling',
  rotation = 'rotation',
  rebalance = 'rebalance',
}

export enum RecommendationStatus {
  new = 'new',
  accepted = 'accepted',
  rejected = 'rejected',
  placed = 'placed',
  expired = 'expired',
}

export type ChartRecord = {
  readonly Date: string;
  readonly Close: number;
};

export type AcceptRecommendationsPayload = { readonly id: number; readonly strategyName?: string };

export type AcceptRecommendationsResponse = 'success' | 'error';

export type RejectRecommendationsPayload = {
  readonly id: number;
  readonly strategyName?: string;
  readonly tickers?: ReadonlyArray<Recommendation>;
};

export type RejectRecommendationsResponse = 'success' | 'error';

export type GetTradingModuleConnectionStatusResponse = { readonly connection: boolean };
