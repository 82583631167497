import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { assetReducer, initialState } from './asset.state';
import { getPortfolioAllocation, getTickerDescription, getTickerQuotes } from '../../../../../../services/api';

import { AssetContextProps } from './asset.types';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../context';
import { useSearchParams } from 'react-router-dom';

/**
 * AssetContext
 * @description Context for AssetRouter pages
 *
 * @author Oleksii Medvediev
 * @category Contexts
 */
const AssetContext = createContext<AssetContextProps>({
  dispatch: () => null,
});

/**
 * AssetContextProvider component
 * @description Provider for the AssetContext
 *
 * @author Oleksii Medvediev
 * @category Context Providers
 */
const AssetContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, dispatch: appDispatch } = useAppContext();
  const [state, dispatch] = useReducer(assetReducer, initialState);
  const [params] = useSearchParams();
  const [hasDataFetchingStarted, setHasDataFetchingStarted] = useState(false);

  const symbol = useMemo(() => params.get('symbol'), [params]);
  const accountId = useMemo(() => params.get('accountId'), [params]);
  const portfolioId = useMemo(() => params.get('portfolioId'), [params]);
  const currentWeight = useMemo(() => params.get('currentWeight'), [params]);
  const unlocatedCapital = useMemo(() => params.get('unlocatedCapital'), [params]);

  const initQuotesPeriod = useCallback(async () => {
    if (symbol) {
      try {
        const { data } = await getTickerQuotes({
          isin: 'US9581021055',
          period: 'month',
          ticker: symbol,
        });
        data && dispatch({ type: 'SET_TICKER_QUOTES', payload: data });
        dispatch({ type: 'SET_QUOTES_PERIOD', payload: 'month' });
      } catch (error) {
        toast.error('Failed to fetch ticker quotes!');
        console.error(error);
      }
    }
  }, [symbol]);

  useLayoutEffect(() => {
    initQuotesPeriod();
  }, [initQuotesPeriod]);

  const fetchData = useCallback(async () => {
    if (symbol && state.asset?.Symbol !== symbol && !isLoading && !hasDataFetchingStarted) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        setHasDataFetchingStarted(true);

        const { data } = await getTickerDescription({ ticker: symbol, isin: '' });

        data && dispatch({ type: 'SET_ASSET', payload: data });
      } catch (error) {
        toast.error('Failed to fetch ticker description!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }

    if (!state.allocation && state.asset && accountId && portfolioId && !isLoading) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await getPortfolioAllocation({
          acctId: accountId,
          portfolioId: portfolioId,
          weightPortfolioIdNumeric: Number(currentWeight ?? 1),
          unlocatedCap: Number(unlocatedCapital ?? 0),
        });
        data && dispatch({ type: 'SET_ALLOCATION', payload: data });
      } catch (error) {
        toast.error('Failed to fetch portfolio allocation!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [
    symbol,
    state.asset,
    state.allocation,
    isLoading,
    hasDataFetchingStarted,
    accountId,
    portfolioId,
    appDispatch,
    currentWeight,
    unlocatedCapital,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return <AssetContext.Provider value={{ ...state, dispatch }}>{children}</AssetContext.Provider>;
};

/**
 * useAssetContext hook.
 * @description The hook for getting AssetContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useAssetContext = () => useContext(AssetContext);

export { AssetContextProvider, useAssetContext };
