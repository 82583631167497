import { CollectiveAccountsPortfoliosProps } from './components/collective-accounts-portfolios/collective-accounts-portfolios.types';
import { CollectiveAccountsProps } from './components/collective-accounts/collective-accounts.types';
import { CollectivePortfoliosProps } from './components/collective-portfolios/collective-portfolios.types';
import { GenerateReportProps } from './components/generate-report/generate-report.types';
import { IndividualAccountProps } from './components/individual-account/individual-account.types';
import { IndividualPortfolioProps } from './components/individual-portfolio/individual-portfolio.types';

/**
 * ReportsFlowProps
 * @description Props for the ReportsFlow component
 *
 * @author Oleksii Medvediev
 */
type ReportsFlowProps = {
  readonly individualAccountProps: IndividualAccountProps;
  readonly individualPortfolioProps: IndividualPortfolioProps;
  readonly collectiveAccountsPortfoliosProps: CollectiveAccountsPortfoliosProps;
  readonly collectiveAccountsProps: CollectiveAccountsProps;
  readonly collectivePortfoliosProps: CollectivePortfoliosProps;
  readonly generateReportProps: GenerateReportProps;
};

enum ReportType {
  monthly = 'monthly',
  yearly = 'yearly',
}

type ReportsPeriod = {
  readonly startDate: string;
  readonly endDate: string;
};

export type { ReportsFlowProps, ReportsPeriod };
export { ReportType };
