import { Button, Indicator, Typography } from '../../../../../../../../../components';
import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';
import { NotificationItemProps } from './notification-item.types';
import { Priority } from '../../../../../../../../../components/indicator/components/status-indicator/status-indicator.types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import styles from './notification-item.module.scss';
import { useNotificationItemData } from './notification-item.hook';

const indicatorColorToType = (color: string): Priority => {
  switch (color.replaceAll(' ', '')) {
    case 'RGB(202,23,10)':
      return Priority.alarm;

    case 'RGB(241,244,94)':
      return Priority.info;

    case 'RGB(44,188,113)':
      return Priority.success;

    case 'RGB(0,128,0)':
      return Priority.success;

    case 'RGB(48,187,112)':
      return Priority.success;

    default:
      return Priority.info;
  }
};

/**
 * NotificationItem component
 * @description Notifications list item
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev, Sergii Goncharuk
 * @param { NotificationItemProps } props - NotificationItemProps defined in the './notification-item.types.ts'
 * @category Components
 */
const NotificationItem: FC<NotificationItemProps> = ({
  UUID,
  ReadMark,
  Date,
  Color,

  Message,

  index,
  Topic,
  poll,

  isLocallyRead,
  onRefetchNotifications,
}) => {
  const { t, isOpened, onReadHandler } = useNotificationItemData({ UUID, ReadMark, onRefetchNotifications });

  const replaceUrlsWithAnchor = (msg: string) => {
    const res: Array<string | ReactElement> = [];
    const urlSegments = msg.split('http');

    urlSegments.forEach((item, i) => {
      if (i === 0) return res.push(item);

      const [urlPart, ...restArr] = item.split(' ');

      res.push(
        <Link target="_blank" className={styles.link} to={`http${urlPart}`}>
          http{urlPart}
        </Link>,
      );

      res.push(' ' + restArr.join(' '));
    });

    return res;
  };

  return (
    <div key={UUID} className={classNames(styles.notification, index % 2 ? styles.light : styles.dark)}>
      <div className={styles.header}>
        <div className={styles.messageDate}>
          <Typography.Text variant={'label4'}>{dayjs(Date).format('DD/MM/YY (HH:mm:ss)')}</Typography.Text>
          {!ReadMark && !isLocallyRead && <Indicator.Status priority={indicatorColorToType(Color)} />}
        </div>
        <Typography.Text className={styles.levelType} variant={'h6'}>
          {Topic}
        </Typography.Text>
      </div>
      <Typography.Text
        className={classNames(styles.textContent, isOpened && styles.isOpened, poll && styles.isOpened)}
        variant={'bodyText3'}
      >
        {replaceUrlsWithAnchor(Message)}
      </Typography.Text>
      {poll ? (
        <Button
          size="small"
          variant="outline"
          label={t('poll')}
          onClick={() => window?.open(poll, '_blank', 'noreferrer')}
          className={styles.pollButton}
        />
      ) : (
        <Button
          variant={'underline'}
          size={'small'}
          label={t(isOpened ? 'hide' : 'readMore')}
          onClick={onReadHandler}
          type={'button'}
          className={styles.readMoreButton}
        />
      )}
    </div>
  );
};

export { NotificationItem };
