/* eslint-disable react/prop-types */
import '../../styles.scss'; // main css file
import '../../theme/default.scss';

import { Dropdown, Typography } from '../../../../../../../components';
import React, { PureComponent } from 'react';
import {
  addDays,
  addMonths,
  addYears,
  differenceInCalendarMonths,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  max,
  min,
  setMonth,
  setYear,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { calcFocusDate, generateStyles, getMonthDisplayRange } from '../../utils';

import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowLeftBtn } from '../../../../../assets/icons/arrow-left-btn.svg';
import { ReactComponent as ArrowRightBtn } from '../../../../../assets/icons/arrow-right-btn.svg';
import { Button } from '../../..';
import { ReactComponent as CalendarDay } from '../../../../../assets/icons/calendar-day.svg';
import Month from '../Month';
import PropTypes from 'prop-types';
import ReactList from 'react-list';
import { ariaLabelsShape } from '../../accessibility';
import { capitalize } from 'lodash';
import classnames from 'classnames';
import coreStyles from '../../styles';
import defaultLocale from 'date-fns/locale/en-US';
import { rangeShape } from '../DayCell';
import { shallowEqualObjects } from 'shallow-equal';
import { uk } from 'date-fns/locale';
import { withTranslation } from 'react-i18next';

class Calendar extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.dateOptions = { locale: this.props.i18n.language === 'uk' ? uk : defaultLocale };
    if (props.weekStartsOn !== undefined) this.dateOptions.weekStartsOn = props.weekStartsOn;
    this.styles = generateStyles([coreStyles, props.classNames]);
    this.listSizeCache = {};
    this.isFirstRender = true;
    this.state = {
      monthNames: this.getMonthNames(),
      focusedDate: calcFocusDate(null, props),
      drag: {
        status: false,
        range: { startDate: null, endDate: null },
        disablePreview: false,
      },
      scrollArea: this.calcScrollArea(props),
      selectedMonth: '',
      selectedYear: '',
      selectedDate: props.minDate && props.minDate > new Date() ? props.minDate : new Date(),
    };
  }
  getMonthNames() {
    if (this.props.i18n.language === 'uk') {
      const months = [];
      for (let i = 0; i < 12; i++) {
        const date = new Date(2000, i, 1);

        const month = format(date, 'LLLL', { locale: uk });
        months.push(capitalize(month));
      }
      return months;
    } else {
      return [...Array(12).keys()].map((i) => this.props.locale.localize.month(i));
    }
  }

  calcScrollArea(props) {
    const { direction, months, scroll } = props;
    if (!scroll.enabled) return { enabled: false };

    const longMonthHeight = scroll.longMonthHeight || scroll.monthHeight;
    if (direction === 'vertical') {
      return {
        enabled: true,
        monthHeight: scroll.monthHeight || 220,
        longMonthHeight: longMonthHeight || 260,
        calendarWidth: 'auto',
        calendarHeight: (scroll.calendarHeight || longMonthHeight || 240) * months,
      };
    }
    return {
      enabled: true,
      monthWidth: scroll.monthWidth || 332,
      calendarWidth: (scroll.calendarWidth || scroll.monthWidth || 332) * months,
      monthHeight: longMonthHeight || 300,
      calendarHeight: longMonthHeight || 300,
    };
  }
  focusToDate = (date, props = this.props, preventUnnecessary = true) => {
    if (!props.scroll.enabled) {
      if (preventUnnecessary && props.preventSnapRefocus) {
        const focusedDateDiff = differenceInCalendarMonths(date, this.state.focusedDate);
        const isAllowedForward = props.calendarFocus === 'forwards' && focusedDateDiff >= 0;
        const isAllowedBackward = props.calendarFocus === 'backwards' && focusedDateDiff <= 0;
        if ((isAllowedForward || isAllowedBackward) && Math.abs(focusedDateDiff) < props.months) {
          return;
        }
      }
      this.setState({ focusedDate: date });
      return;
    }
    const targetMonthIndex = differenceInCalendarMonths(date, props.minDate, this.dateOptions);
    const visibleMonths = this.list.getVisibleRange();
    if (preventUnnecessary && visibleMonths.includes(targetMonthIndex)) return;
    this.isFirstRender = true;
    this.list.scrollTo(targetMonthIndex);
    this.setState({ focusedDate: date });
  };
  updateShownDate = (props = this.props) => {
    const newProps = props.scroll.enabled
      ? {
          ...props,
          months: this.list.getVisibleRange().length,
        }
      : props;
    const newFocus = calcFocusDate(this.state.focusedDate, newProps);
    this.focusToDate(newFocus, newProps);
  };
  updatePreview = (val) => {
    if (!val) {
      this.setState({ preview: null });
      return;
    }
    const preview = {
      startDate: val,
      endDate: val,
      color: this.props.color,
    };
    this.setState({ preview });
  };
  componentDidMount() {
    if (this.props.scroll.enabled) {
      // prevent react-list's initial render focus problem
      setTimeout(() => this.focusToDate(this.state.focusedDate));
    }
  }

  componentDidUpdate(prevProps) {
    const propMapper = {
      dateRange: 'ranges',
      date: 'date',
    };
    const targetProp = propMapper[this.props.displayMode];
    if (this.props[targetProp] !== prevProps[targetProp]) {
      this.updateShownDate(this.props);
    }

    if (prevProps.locale !== this.props.locale || prevProps.weekStartsOn !== this.props.weekStartsOn) {
      this.dateOptions = { locale: this.props.locale };
      if (this.props.weekStartsOn !== undefined) this.dateOptions.weekStartsOn = this.props.weekStartsOn;
      this.setState({
        monthNames: this.getMonthNames(),
      });
    }

    if (!shallowEqualObjects(prevProps.scroll, this.props.scroll)) {
      this.setState({ scrollArea: this.calcScrollArea(this.props) });
    }
  }

  changeShownDate = (value, mode = 'set') => {
    const { focusedDate } = this.state;
    const { onShownDateChange, minDate, maxDate } = this.props;
    const modeMapper = {
      monthOffset: () => addMonths(focusedDate, value),
      setMonth: () => setMonth(focusedDate, value),
      setYear: () => setYear(focusedDate, value),
      set: () => value,
    };

    const newDate = min([max([modeMapper[mode](), minDate]), maxDate]);
    this.focusToDate(newDate, this.props, false);
    this.setState({ selectedDate: newDate });
    onShownDateChange && onShownDateChange(newDate);
  };
  handleRangeFocusChange = (rangesIndex, rangeItemIndex) => {
    this.props.onRangeFocusChange && this.props.onRangeFocusChange([rangesIndex, rangeItemIndex]);
  };
  handleScroll = () => {
    const { onShownDateChange, minDate } = this.props;
    const { focusedDate } = this.state;
    const { isFirstRender } = this;

    const visibleMonths = this.list.getVisibleRange();
    // prevent scroll jump with wrong visible value
    if (visibleMonths[0] === undefined) return;
    const visibleMonth = addMonths(minDate, visibleMonths[0] || 0);
    const isFocusedToDifferent = !isSameMonth(visibleMonth, focusedDate);
    if (isFocusedToDifferent && !isFirstRender) {
      this.setState({ focusedDate: visibleMonth });
      onShownDateChange && onShownDateChange(visibleMonth);
    }
    this.isFirstRender = false;
  };
  renderMonthAndYear = (focusedDate, changeShownDate, props) => {
    const { showMonthArrow, minDate, maxDate, showMonthAndYearPickers } = props;
    const upperYearLimit = (maxDate || Calendar.defaultProps.maxDate).getFullYear();
    const lowerYearLimit = (minDate || Calendar.defaultProps.minDate).getFullYear();

    const styles = this.styles;

    const monthsWithIds = this.state.monthNames.map((month, index) => ({ id: index, value: month }));

    let yearsWithIds = new Array(upperYearLimit - lowerYearLimit + 1).fill(lowerYearLimit).map((val, i) => {
      const year = val + i;
      return { id: i, value: year };
    });

    this.setState({ selectedMonth: this.state.selectedDate.getMonth() });
    this.setState({ selectedYear: this.state.selectedDate.getFullYear() });

    return (
      <div role={'button'} tabIndex={0} onMouseUp={(e) => e.stopPropagation()} className={styles.monthAndYearWrapper}>
        {showMonthArrow ? (
          <ArrowLeftBtn
            className={classnames(styles.nextPrevButton, styles.prevButton)}
            onClick={() => changeShownDate(-1, 'monthOffset')}
          />
        ) : null}
        {showMonthAndYearPickers ? (
          <span className={styles.monthAndYearPickers}>
            <span className={styles.monthPicker}>
              <Dropdown
                isHeightLimited={false}
                options={monthsWithIds.filter(
                  ({ id }) =>
                    (this.state.selectedYear >= upperYearLimit ? (maxDate ? id <= maxDate.getMonth() : true) : true) &&
                    (this.state.selectedYear <= lowerYearLimit ? (minDate ? id >= minDate.getMonth() : true) : true),
                )}
                button={
                  <Button variant={'outline'} size={'small'} trailingIcon={<ArrowIcon />} ignoreStopPropagation>
                    <Typography.Text variant={'bodyText3'}>
                      {monthsWithIds[this.state.selectedMonth]?.value}
                    </Typography.Text>
                  </Button>
                }
                onChange={(value) => changeShownDate(value, 'setMonth')}
              />
            </span>
            <span className={styles.monthAndYearDivider} />
            <span className={styles.yearPicker}>
              <Dropdown
                options={yearsWithIds}
                button={
                  <Button variant={'outline'} size={'small'} trailingIcon={<ArrowIcon />} ignoreStopPropagation>
                    <Typography.Text variant={'bodyText3'}>{focusedDate.getFullYear()}</Typography.Text>
                  </Button>
                }
                onChange={(value) => changeShownDate(yearsWithIds[value]?.value, 'setYear')} // changeShownDate(value, 'setYear')
              />
            </span>
          </span>
        ) : (
          <span className={styles.monthAndYearPickers}>
            {this.state.monthNames[focusedDate.getMonth()]} {focusedDate.getFullYear()}
          </span>
        )}
        {showMonthArrow ? (
          <ArrowRightBtn
            className={classnames(styles.nextPrevButton, styles.nextButton)}
            onClick={() => changeShownDate(+1, 'monthOffset')}
          />
        ) : null}
      </div>
    );
  };
  renderWeekdays() {
    const now = new Date();
    return (
      <div className={this.styles.weekDays}>
        {eachDayOfInterval({
          start: startOfWeek(now, this.dateOptions),
          end: endOfWeek(now, this.dateOptions),
        }).map((day, i) => (
          <span className={this.styles.weekDay} key={i}>
            {format(day, this.props.weekdayDisplayFormat, this.dateOptions)}
          </span>
        ))}
      </div>
    );
  }
  renderDateDisplay = () => {
    const { ranges } = this.props;

    const styles = this.styles;

    const { t } = this.props;
    const formattedDates =
      ranges &&
      ranges.map((range) => {
        const start = new Date(range?.startDate);
        const end = new Date(range?.endDate);
        const locale = this.props.i18n.language === 'uk' ? uk : defaultLocale;

        if (isSameDay(start, end)) {
          return `${format(start, 'd MMMM yyyy', { locale: locale })}`;
        }

        // Check if start and end dates are in the same month and year
        if (isSameMonth(start, end) && isSameYear(start, end)) {
          return `${format(start, 'd', { locale: locale })} - ${format(end, 'd MMMM yyyy', { locale: locale })}`;
        }

        // Check if start and end dates are in the same year
        if (isSameYear(start, end)) {
          return `${format(start, 'd MMMM', { locale: locale })} - ${format(end, 'd MMMM yyyy', { locale: locale })}`;
        }

        // Otherwise
        return `${format(start, 'd MMMM yyyy', { locale: locale })} - ${format(end, 'd MMMM yyyy', {
          locale: locale,
        })}`;
      });
    return (
      <div className={styles.dateDisplayWrapper}>
        <Button variant="borderless" trailingIcon={<CalendarDay />}>
          <Typography.Text variant="bodyText3">
            {ranges[0].endDate ? formattedDates : t('common.calendar.period')}
          </Typography.Text>
        </Button>
      </div>
    );
  };
  onDragSelectionStart = (date) => {
    const { onChange, dragSelectionEnabled } = this.props;

    if (dragSelectionEnabled) {
      this.setState({
        drag: {
          status: true,
          range: { startDate: date, endDate: date },
          disablePreview: true,
        },
      });
    } else {
      onChange && onChange(date);
    }
  };

  onDragSelectionEnd = (date) => {
    const { updateRange, displayMode, onChange, dragSelectionEnabled } = this.props;

    if (!dragSelectionEnabled) return;

    if (displayMode === 'date' || !this.state.drag.status) {
      onChange && onChange(date);
      return;
    }
    const newRange = {
      startDate: this.state.drag.range.startDate,
      endDate: date,
    };
    if (displayMode !== 'dateRange' || isSameDay(newRange.startDate, date)) {
      this.setState({ drag: { status: false, range: {} } }, () => onChange && onChange(date));
    } else {
      this.setState({ drag: { status: false, range: {} } }, () => {
        updateRange && updateRange(newRange);
      });
    }
  };
  onDragSelectionMove = (date) => {
    const { drag } = this.state;
    if (!drag.status || !this.props.dragSelectionEnabled) return;
    this.setState({
      drag: {
        status: drag.status,
        range: { startDate: drag.range.startDate, endDate: date },
        disablePreview: true,
      },
    });
  };

  estimateMonthSize = (index, cache) => {
    const { direction, minDate } = this.props;
    const { scrollArea } = this.state;
    if (cache) {
      this.listSizeCache = cache;
      if (cache[index]) return cache[index];
    }
    if (direction === 'horizontal') return scrollArea.monthWidth;
    const monthStep = addMonths(minDate, index);
    const { start, end } = getMonthDisplayRange(monthStep, this.dateOptions);
    const isLongMonth = differenceInDays(end, start, this.dateOptions) + 1 > 7 * 5;
    return isLongMonth ? scrollArea.longMonthHeight : scrollArea.monthHeight;
  };
  render() {
    const {
      showDateDisplay,
      onPreviewChange,
      scroll,
      direction,
      disabledDates,
      maxDate,
      minDate,
      rangeColors,
      color,
      navigatorRenderer,
      className,
      preview,
    } = this.props;
    const { scrollArea, focusedDate } = this.state;
    const isVertical = direction === 'vertical';
    const monthAndYearRenderer = navigatorRenderer || this.renderMonthAndYear;

    const ranges = this.props.ranges.map((range, i) => ({
      ...range,
      color: range.color || rangeColors[i] || color,
    }));
    return (
      <div>
        {showDateDisplay && this.renderDateDisplay()}

        <div
          role={'button'}
          tabIndex={0}
          className={classnames(this.styles.calendarWrapper, className)}
          onMouseUp={() => this.setState({ drag: { status: false, range: {} } })}
          onMouseLeave={() => {
            this.setState({ drag: { status: false, range: {} } });
          }}
        >
          {monthAndYearRenderer(focusedDate, this.changeShownDate, this.props)}
          {scroll.enabled ? (
            <div>
              {isVertical && this.renderWeekdays(this.dateOptions)}
              <div
                role={'button'}
                tabIndex={0}
                className={classnames(
                  this.styles.infiniteMonths,
                  isVertical ? this.styles.monthsVertical : this.styles.monthsHorizontal,
                )}
                onMouseLeave={() => onPreviewChange && onPreviewChange()}
                style={{
                  width: scrollArea.calendarWidth + 11,
                  height: scrollArea.calendarHeight + 11,
                }}
                onScroll={this.handleScroll}
              >
                <ReactList
                  length={differenceInCalendarMonths(
                    endOfMonth(maxDate),
                    addDays(startOfMonth(minDate), -1),
                    this.dateOptions,
                  )}
                  treshold={500}
                  type="variable"
                  ref={(target) => (this.list = target)}
                  itemSizeEstimator={this.estimateMonthSize}
                  axis={isVertical ? 'y' : 'x'}
                  itemRenderer={(index, key) => {
                    const monthStep = addMonths(minDate, index);
                    return (
                      <Month
                        {...this.props}
                        onPreviewChange={onPreviewChange || this.updatePreview}
                        preview={preview || this.state.preview}
                        ranges={ranges}
                        key={key}
                        drag={this.state.drag}
                        dateOptions={this.dateOptions}
                        disabledDates={disabledDates}
                        month={monthStep}
                        onDragSelectionStart={this.onDragSelectionStart}
                        onDragSelectionEnd={this.onDragSelectionEnd}
                        onDragSelectionMove={this.onDragSelectionMove}
                        onMouseLeave={() => onPreviewChange && onPreviewChange()}
                        styles={this.styles}
                        style={
                          isVertical
                            ? { height: this.estimateMonthSize(index) }
                            : { height: scrollArea.monthHeight, width: this.estimateMonthSize(index) }
                        }
                        showMonthName
                        showWeekDays={!isVertical}
                      />
                    );
                  }}
                />
              </div>
            </div>
          ) : (
            <div
              className={classnames(
                this.styles.months,
                isVertical ? this.styles.monthsVertical : this.styles.monthsHorizontal,
              )}
            >
              {new Array(this.props.months).fill(null).map((_, i) => {
                let monthStep = addMonths(this.state.focusedDate, i);
                if (this.props.calendarFocus === 'backwards') {
                  monthStep = subMonths(this.state.focusedDate, this.props.months - 1 - i);
                }
                return (
                  <Month
                    {...this.props}
                    onPreviewChange={onPreviewChange || this.updatePreview}
                    preview={preview || this.state.preview}
                    ranges={ranges}
                    key={i}
                    drag={this.state.drag}
                    dateOptions={this.dateOptions}
                    disabledDates={disabledDates}
                    month={monthStep}
                    onDragSelectionStart={this.onDragSelectionStart}
                    onDragSelectionEnd={this.onDragSelectionEnd}
                    onDragSelectionMove={this.onDragSelectionMove}
                    onMouseLeave={() => onPreviewChange && onPreviewChange()}
                    styles={this.styles}
                    showWeekDays={!isVertical || i === 0}
                    showMonthName={!isVertical || i > 0}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Calendar.defaultProps = {
  showMonthArrow: true,
  showMonthAndYearPickers: true,
  disabledDates: [],
  classNames: {},
  locale: defaultLocale,

  ranges: [],
  focusedRange: [0, 0],
  dateDisplayFormat: 'MMM d, yyyy',
  monthDisplayFormat: 'MMM yyyy',
  weekdayDisplayFormat: 'EEEEEE',
  dayDisplayFormat: 'd',
  showDateDisplay: true,
  showPreview: true,
  displayMode: 'date',
  months: 1,
  color: '#4B4B57',
  scroll: {
    enabled: false,
  },
  direction: 'vertical',
  maxDate: addYears(new Date(), 20),
  minDate: addYears(new Date(), -100),
  rangeColors: ['#4B4B57', '#3ecf8e', '#fed14c'],
  startDatePlaceholder: 'Early',
  endDatePlaceholder: 'Continuous',
  editableDateInputs: false,
  dragSelectionEnabled: true,
  fixedHeight: false,
  calendarFocus: 'forwards',
  preventSnapRefocus: false,
  ariaLabels: {},
};

Calendar.propTypes = {
  showMonthArrow: PropTypes.bool,
  showMonthAndYearPickers: PropTypes.bool,
  disabledDates: PropTypes.array,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  date: PropTypes.object,
  onChange: PropTypes.func,
  onPreviewChange: PropTypes.func,
  onRangeFocusChange: PropTypes.func,
  classNames: PropTypes.object,
  locale: PropTypes.object,
  shownDate: PropTypes.object,
  onShownDateChange: PropTypes.func,
  ranges: PropTypes.arrayOf(rangeShape),
  preview: PropTypes.shape({
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    color: PropTypes.string,
  }),
  dateDisplayFormat: PropTypes.string,
  monthDisplayFormat: PropTypes.string,
  weekdayDisplayFormat: PropTypes.string,
  weekStartsOn: PropTypes.number,
  dayDisplayFormat: PropTypes.string,
  focusedRange: PropTypes.arrayOf(PropTypes.number),
  initialFocusedRange: PropTypes.arrayOf(PropTypes.number),
  months: PropTypes.number,
  className: PropTypes.string,
  showDateDisplay: PropTypes.bool,
  showPreview: PropTypes.bool,
  displayMode: PropTypes.oneOf(['dateRange', 'date']),
  color: PropTypes.string,
  updateRange: PropTypes.func,
  scroll: PropTypes.shape({
    enabled: PropTypes.bool,
    monthHeight: PropTypes.number,
    longMonthHeight: PropTypes.number,
    monthWidth: PropTypes.number,
    calendarWidth: PropTypes.number,
    calendarHeight: PropTypes.number,
  }),
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  startDatePlaceholder: PropTypes.string,
  endDatePlaceholder: PropTypes.string,
  navigatorRenderer: PropTypes.func,
  rangeColors: PropTypes.arrayOf(PropTypes.string),
  editableDateInputs: PropTypes.bool,
  dragSelectionEnabled: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  calendarFocus: PropTypes.string,
  preventSnapRefocus: PropTypes.bool,
  ariaLabels: ariaLabelsShape,
};

// export default Calendar;
export default withTranslation()(Calendar);
