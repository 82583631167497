import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReportType, ReportsPeriod } from '../../reports-flow.types';
import { Success, Summary } from './components';
import { getAccountsByEmail, sendReport } from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { IndividualAccountProps } from './individual-account.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './components/success/success.types';
import { SummaryProps } from './components/summary/summary.types';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const formatPeriodLabel = (startDate: Date, reportType: ReportType, t: (value: string) => string): string => {
  const dividedStartDate = dayjs(startDate).format('MMMM YYYY').split(' ');
  if (reportType === ReportType.monthly) {
    return `${t(dividedStartDate[0])} ${dividedStartDate[1]}`;
  } else {
    return `${dividedStartDate[1]} ${t('modals.reports.summary.year')}`;
  }
};

/**
 * useIndividualAccountData hook
 * @description The hook which processes Reports IndividualAccount flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useIndividualAccountData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { reportsIndividualAccount },
    dispatch,
  } = useModalsContext();

  const [minDate, setMinDate] = useState<string>();
  const [period, setPeriod] = useState<ReportsPeriod>();
  const [reportType, setReportType] = useState<ReportType>(ReportType.monthly);

  const flowData: IndividualAccountProps | undefined = useMemo(
    () => reportsIndividualAccount,
    [reportsIndividualAccount],
  );

  const fetchAccountsByEmail = useCallback(async () => {
    if (flowData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await getAccountsByEmail({
          emails: [flowData.clientEmailOwner],
        });

        if (data?.length === 0) {
          setMinDate(dayjs().subtract(1, 'year').format('YYYY-MM-DD'));
        } else if (data?.length && data?.length > 0) {
          setMinDate(
            [...(data ?? [])].filter(({ accountId }) => flowData.accountId === accountId)[0]?.dateAccountFunded,
          );
        }
      } catch (error) {
        toast.error('Failed to fetch accounts by email!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, flowData]);

  useEffect(() => {
    if (!minDate) {
      fetchAccountsByEmail();
    }
  }, [fetchAccountsByEmail, minDate]);

  const setStage = useCallback(
    (stage: IndividualAccountProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.reportsIndividualAccount,
          data: {
            [ModalIds.reportsIndividualAccount]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<
    IndividualAccountProps['currentStage'],
    SummaryProps['onSubmitStage'] | SuccessProps['onSubmitStage']
  > = useMemo(
    () => ({
      summary: async () => {
        if (user && flowData && period) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
          try {
            const { message } = await sendReport({
              accountId: flowData.accountId,
              email: flowData.clientEmailOwner,
              fullName: flowData.clientNameOwner,
              portfolioId: '',
              type: 'account',
              reportType,
              start: dayjs(period.startDate).format('YYYY-MM-DD'),
              end: dayjs(period.endDate).format('YYYY-MM-DD'),
            });

            if (message === 'ok' || message.includes('Duplicate entry')) {
              setStage('success');
            }
          } catch (error) {
            toast.error('Failed to process send report request!');
            console.error(error);
          }
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.reportsIndividualAccount });
      },
    }),
    [appDispatch, dispatch, flowData, period, reportType, setStage, user],
  );

  const onViewReport = useCallback(() => {
    if (flowData && period && user) {
      const { accountId, clientEmailOwner } = flowData;
      const start = dayjs(period.startDate).format('YYYY-MM-DD');
      const end = dayjs(period.endDate).format('YYYY-MM-DD');

      const url = `/management/reports/${accountId}`;
      const params = `?start=${start}&end=${end}&type=${reportType}&email=${clientEmailOwner}&lg=${user.preferences.language}`;

      window.open(`${window.location.origin}${url}${params}`, '_blank');
    }
  }, [flowData, period, reportType, user]);

  const stages: Record<IndividualAccountProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData && minDate
        ? {
            summary: (
              <Summary
                accountId={flowData.accountId}
                clientNameOwner={flowData.clientNameOwner}
                minDate={minDate}
                period={period}
                selectedReportType={reportType}
                onReportTypeSelect={(value) => setReportType(value)}
                onViewReport={onViewReport}
                onSetPeriod={(value) => setPeriod(value)}
                onSubmitStage={submitHandlers.summary}
              />
            ),
            success: (
              <Success clientNameOwner={flowData.clientNameOwner} t={t} onSubmitStage={submitHandlers.success} />
            ),
          }
        : undefined,
    [flowData, minDate, onViewReport, period, reportType, submitHandlers.success, submitHandlers.summary, t],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { formatPeriodLabel, useIndividualAccountData };
