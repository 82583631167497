import { Recommendation } from '../../../../../../../../../services/api';

/**
 * ModifyProps
 * @description Props for the Modify component
 *
 * @author Oleksii Medvediev
 */
type ModifyProps = {
  readonly currentStage: ModifyStages;
  readonly recommendations: ReadonlyArray<Recommendation>;
  readonly onSubmitModify: () => void;
};

enum ModifyStages {
  summary = 'summary',
  confirmation = 'confirmation',
}

export type { ModifyProps };
export { ModifyStages };
