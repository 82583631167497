import { useAccounts, useSettings } from '../../contexts';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { useCallback } from 'react';
import { useModalsContext } from '../../../../contexts';
import { useQueryParams } from '../../shared';
import { useTranslation } from 'react-i18next';

const useHeaderHook = () => {
  const [state] = useSettings();
  const { t } = useTranslation('translation', { keyPrefix: 'management.reports.common.header' });
  const { dispatch: modalsDispatch } = useModalsContext();
  const { currentAccount } = useAccounts();
  const { parsed } = useQueryParams();

  const handlePrint = () => {
    typeof window !== 'undefined' && window.print();
  };

  const handleGenerateModalOpen = useCallback(() => {
    if (currentAccount) {
      modalsDispatch({
        type: 'SHOW_MODAL',
        payload: {
          id: ModalIds.reportsGenerateReport,
          data: {
            reportsGenerateReport: {
              clientEmailOwner: !parsed?.ownership ? currentAccount.ClientEmailOwner : parsed?.email,
            },
          },
        },
      });
    }
  }, [currentAccount, modalsDispatch, parsed?.email, parsed?.ownership]);

  return { t, state, handlePrint, handleGenerateModalOpen };
};

export { useHeaderHook };
