import {
  ManagerGetAccountPortfolioSnapshotResponse,
  getAccountPortfolioSnapshot,
  setAccountPortfolios,
} from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ConfirmationProps } from './confirmation.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { mapAccountPortfoliosSnapshotToData } from '../../../../../../utils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

/**
 * useConfirmationData hook
 * @description The hook which processes ClosePortfolio flow Confirmation stage data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useConfirmationData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { closePortfolioConfirmation },
    dispatch,
  } = useModalsContext();
  const { user, dispatch: appDispatch } = useAppContext();

  const [accountPortfolioSnapshot, setAccountPortfolioSnapshot] =
    useState<ManagerGetAccountPortfolioSnapshotResponse>();

  const flowData: ConfirmationProps | undefined = useMemo(
    () => closePortfolioConfirmation,
    [closePortfolioConfirmation],
  );

  const fetchAccountSnapshot = useCallback(async () => {
    if (!accountPortfolioSnapshot && closePortfolioConfirmation) {
      const { data } = await getAccountPortfolioSnapshot({
        acctId: closePortfolioConfirmation.portfolio.PortfolioOnAccountID,
      });

      const portfolioData = data?.find(
        ({ PortfolioID }) => PortfolioID === closePortfolioConfirmation.portfolio.PortfolioID,
      );

      portfolioData && setAccountPortfolioSnapshot(portfolioData);
    }
  }, [accountPortfolioSnapshot, closePortfolioConfirmation]);

  useEffect(() => {
    fetchAccountSnapshot();
  }, [fetchAccountSnapshot]);

  const handleCloseModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.closePortfolioConfirmation });
  }, [dispatch]);

  const handleSubmitConfirmation = useCallback(async () => {
    if (user && flowData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { message } = await setAccountPortfolios({
          role: user.isLoggedInRole,
          roleLoginEmail: user.email,
          portfoliosDoc: {
            AccountID: flowData.portfolio.PortfolioOnAccountID,
            ClientEmailOwner: flowData.accountEmailOwner,
            ReOpenPortfolios: [],
            OpenPortfolios: [],
            ClosePortfolios: [
              {
                PortfolioID: flowData.portfolio.PortfolioID,
                PortfolioMember: flowData.portfolio.PortfolioMember,
                PortfolioOwner: flowData.portfolio.PortfolioOwner,
              },
            ],
            ChangePortfolios: [],
          },
        });

        if (message === 'ok') {
          flowData.onRefreshParticipants();

          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              id: ModalIds.closePortfolioSuccess,
              data: {
                closePortfolioSuccess: {
                  portfolioName: flowData.portfolio.PortfolioName,
                  portfolioCurrentCapital: flowData.portfolio.PortfolioCurrentCapital,
                  accountId: flowData.portfolio.PortfolioOnAccountID,
                  onSubmitSuccess: () => {
                    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.closePortfolioSuccess });
                  },
                },
              },
            },
          });
        }
      } catch (error) {
        toast.error('Failed to process set account portfolios request!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      handleCloseModal();
    }
  }, [appDispatch, dispatch, flowData, handleCloseModal, user]);

  const snapShotData = useMemo(
    () => accountPortfolioSnapshot && mapAccountPortfoliosSnapshotToData([accountPortfolioSnapshot]),
    [accountPortfolioSnapshot],
  );

  return {
    t,
    flowData,
    snapShotData,
    handleSubmitConfirmation,
    handleCloseModal,
  };
};

export { useConfirmationData };
