import * as yup from 'yup';

import { AddFormValues, FormProps } from './components/form/form.types';
import { Form, Success } from './components';
import React, { useCallback, useMemo } from 'react';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { AddProps } from './add.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './components/success/success.types';
import { setAccountPortfolios } from '../../../../../../../../../services/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const initialValues: AddFormValues = {
  capital: '',
  ownerEmail: '',
  owner: '',
};

/**
 * useAddData hook
 * @description The hook which processes AutomatedOrdersConformation Add flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useAddData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { portfoliosSettingsAdd },
    dispatch,
  } = useModalsContext();

  const flowData: AddProps | undefined = useMemo(() => portfoliosSettingsAdd, [portfoliosSettingsAdd]);

  const validationSchema: yup.Schema<typeof initialValues> = yup.object({
    capital: yup
      .string()
      .notOneOf(['0'], t('modals.portfoliosSettings.add.form.errors.zeroBalance'))
      .required(t('modals.portfoliosSettings.add.form.errors.capitalRequired')),
    owner: yup.string().required(t('modals.portfoliosSettings.add.form.errors.ownerRequired')),
    ownerEmail: yup
      .string()
      .email(t('modals.portfoliosSettings.add.form.errors.emailFormat'))
      .required(t('modals.portfoliosSettings.add.form.errors.ownerEmailRequired')),
  });

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.portfoliosSettingsAdd }),
    [dispatch],
  );

  const setStage = useCallback(
    (stage: AddProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.portfoliosSettingsAdd,
          data: {
            [ModalIds.portfoliosSettingsAdd]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<AddProps['currentStage'], FormProps['onSubmitStage'] | SuccessProps['onClose']> =
    useMemo(
      () => ({
        form: async ({ owner, capital, ownerEmail }: typeof initialValues) => {
          if (flowData?.onAddPortfolioInNewParticipantSetupFlow) {
            flowData.onAddPortfolioInNewParticipantSetupFlow({ owner, capital, ownerEmail });
            handleCloseModal();
          } else if (flowData && user) {
            appDispatch({ type: 'TOGGLE_IS_LOADING' });

            try {
              const { message } = await setAccountPortfolios({
                role: user.isLoggedInRole,
                roleLoginEmail: user.email,
                portfoliosDoc: {
                  AccountID: flowData.accountId,
                  ClientEmailOwner: flowData.clientEmailOwner,
                  ReOpenPortfolios: [],
                  OpenPortfolios: [
                    {
                      PortfolioMember: owner.toString(),
                      PortfolioOwner: ownerEmail.toString(),
                      PortfolioStartCapital: parseFloat(capital.toString().replace(/[^0-9.-]+/g, '')),
                    },
                  ],
                  ClosePortfolios: [],
                  ChangePortfolios: [],
                },
              });
              if (message === 'ok') {
                setStage('success');
                flowData.onRefetchParticipants();
              }
            } catch (error) {
              toast.error('Failed to process set account portfolios request!');
              console.error(error);
            }

            appDispatch({ type: 'TOGGLE_IS_LOADING' });
          }
        },
        success: () => {
          handleCloseModal();
        },
      }),
      [flowData, user, appDispatch, setStage, handleCloseModal],
    );

  const stages: Record<AddProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData
        ? {
            form: (
              <Form
                initialValues={initialValues}
                onBackButtonClick={flowData.onBackButtonClick}
                onClose={
                  flowData.onAddPortfolioInNewParticipantSetupFlow ? flowData.onBackButtonClick : handleCloseModal
                }
                onSubmitStage={submitHandlers.form}
                t={t}
                undividedCapital={flowData.undividedCapital}
                validationSchema={validationSchema}
              />
            ),
            success: (
              <Success
                onClose={handleCloseModal}
                accountId={flowData.accountId}
                clientNameOwner={flowData.clientNameOwner}
              />
            ),
          }
        : undefined,
    [flowData, handleCloseModal, submitHandlers.form, t, validationSchema],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { useAddData };
