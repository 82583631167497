import { FC } from 'react';
import { useModifyData } from './modify.hook';

/**
 * Modify component
 * @description Exclusively for the AutomatedOrdersConfirmation as its Modify flow.
 * @link https://www.figma.com/file/pCwb00CHfXi1s4Yd5q3q0U/Trader--%7C-File-for-development?type=design&node-id=1787-139246&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Modify: FC = () => {
  const { stages, currentStage } = useModifyData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { Modify };
