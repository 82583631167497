import {
  BrandingType,
  SettingsSection,
  UserRole,
  getAdminSettings,
  getEmailTransporterStatus,
  getProjectConfigStatuses,
  getTradeVisionConnectionStatus,
  getTradingModuleConnectionStatus,
  logout,
} from '../../../../../services/api';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { appReducer, initialState } from './app.state';

import { AppContextProps } from './app.types';
import { AvailableLocales } from '../../../../../localization';
import { Cache } from 'aws-amplify/utils';
import { CacheQuery } from '../../typescript';
import i18next from 'i18next';
import logo from '../../../assets/images/default-logo.png';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';

/**
 * AppContext
 * @description Context for global app data
 *
 * @author Oleksii Medvediev
 * @category Contexts
 */
const AppContext = createContext<AppContextProps>({
  dispatch: () => null,
  logo: '',
  isLoading: false,
  sidebarCollapsed: false,
});

/**
 * AppContextProvider component
 * @description Provider for the AppContext
 *
 * @author Oleksii Medvediev, Sergii Goncharuk
 * @category Context Providers
 */
const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [cachedUser, setCachedUser] = useState<typeof state.user>();
  const { pathname } = useLocation();
  const browserLanguage = window?.navigator?.language?.split('-')[0];

  const changeLanguage = useCallback(
    (language?: string) => {
      i18next.changeLanguage(
        Object.values(AvailableLocales).some((locale) => locale === language) ? language : browserLanguage,
      );
    },
    [browserLanguage],
  );

  const getCachedUser = useCallback(async () => setCachedUser(await Cache.getItem(CacheQuery.USER)), []);
  useEffect(() => {
    getCachedUser();
  }, [getCachedUser]);

  useEffect(() => {
    if (!state.user && cachedUser) {
      dispatch({ type: 'SET_USER', payload: cachedUser });
      dispatch({ type: 'SET_CURRENT_ROLE', payload: cachedUser.role[0] as UserRole });
    }
  }, [browserLanguage, cachedUser, state.currentRole, state.user]);

  useEffect(() => {
    state.user?.preferences.language && changeLanguage(state.user.preferences.language);
  }, [changeLanguage, state.user?.preferences.language]);

  const checkBrokerConnectionStatus = useCallback(async () => {
    if (!pathname.includes('/reports')) {
      try {
        if (!state.user) return;

        const { data } = await getTradingModuleConnectionStatus();

        dispatch({ type: 'SET_BROKER_CONNECTION_STATUS', payload: data.connection });
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch project config statuses!');
        dispatch({ type: 'SET_BROKER_CONNECTION_STATUS', payload: false });
      }
    }
  }, [pathname, state.user]);

  const checkIBGatewayConnectionStatus = useCallback(async () => {
    if (!pathname.includes('/reports')) {
      try {
        if (!state.user) return;

        const { data } = await getProjectConfigStatuses('ibGateway');

        if (data.ibGatewayStatus) {
          dispatch({ type: 'SET_IBGATEWAY_CONNECTION_STATUS', payload: !!data.ibGatewayStatus.status });
          dispatch({ type: 'SET_IBGATEWAY_NAME', payload: data.ibGatewayStatus.name });
          dispatch({ type: 'SET_IBGATEWAY_IP_ADDRESS', payload: data.ibGatewayStatus.ipAddress });
        } else {
          dispatch({ type: 'SET_IBGATEWAY_CONNECTION_STATUS', payload: false });
          dispatch({ type: 'SET_IBGATEWAY_NAME', payload: '-' });
          dispatch({ type: 'SET_IBGATEWAY_IP_ADDRESS', payload: '-' });
        }
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch project config statuses!');
        dispatch({ type: 'SET_IBGATEWAY_CONNECTION_STATUS', payload: false });
      }
    }
  }, [pathname, state.user]);

  const checkTradingModuleConnectionStatus = useCallback(async () => {
    if (!pathname.includes('/reports')) {
      try {
        const { data } = await getProjectConfigStatuses('tradingModule');

        if (data.tradingModuleStatus) {
          dispatch({ type: 'SET_TRADING_MODULE_CONNECTION_STATUS', payload: !!data.tradingModuleStatus.status });
          dispatch({ type: 'SET_TRADING_MODULE_NAME', payload: data.tradingModuleStatus.name });
          dispatch({ type: 'SET_TRADING_MODULE_IP_ADDRESS', payload: data.tradingModuleStatus.ipAddress });
        } else {
          dispatch({ type: 'SET_TRADING_MODULE_CONNECTION_STATUS', payload: false });
          dispatch({ type: 'SET_TRADING_MODULE_NAME', payload: '-' });
          dispatch({ type: 'SET_TRADING_MODULE_IP_ADDRESS', payload: '-' });
        }
      } catch (error) {
        toast.error('Failed to fetch project config statuses!');
        console.error(error);
        dispatch({ type: 'SET_TRADING_MODULE_CONNECTION_STATUS', payload: false });
      }
    }
  }, [pathname]);

  const checkTradeVisionConnectionStatus = useCallback(async () => {
    if (!pathname.includes('/reports')) {
      try {
        if (!state.user) return;

        const { data } = await getTradeVisionConnectionStatus(
          !pathname.includes('auth') ? state.user?.email : undefined,
        );

        if (data) {
          dispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: data.connection });
          dispatch({ type: 'SET_LOGIN_CANDIDATE', payload: data.loginCandidate });
          dispatch({ type: 'SET_RESERVED_ROLES', payload: data.reservedRoles });
          dispatch({ type: 'SET_RESTRICTED_MODE', payload: data.mode && data.mode === 'mr' });
        } else {
          dispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: false });
          dispatch({ type: 'SET_LOGIN_CANDIDATE', payload: undefined });
          dispatch({ type: 'SET_RESERVED_ROLES', payload: undefined });
        }
        if (data.mode && data.mode === 'mr' && state.currentRole && state.currentRole !== UserRole.admin) {
          await logout({ email: state.user?.email });
          window?.location.reload();
        }
      } catch (error) {
        toast.error('Failed to fetch TradeVisionConnectionStatus!');
        console.error(error);
        dispatch({ type: 'SET_TRADEVISION_CONNECTION_STATUS', payload: false });
        dispatch({ type: 'SET_LOGIN_CANDIDATE', payload: undefined });
        dispatch({ type: 'SET_RESERVED_ROLES', payload: undefined });
      }
    }
  }, [pathname, state.currentRole, state.user]);

  const checkEmailTransporterStatus = useCallback(async () => {
    if (!pathname.includes('/reports')) {
      try {
        const { data } = await getEmailTransporterStatus();
        if (data) {
          dispatch({ type: 'SET_EMAIL_TRANSPORTER_STATUS', payload: data.connection });
        } else {
          dispatch({ type: 'SET_EMAIL_TRANSPORTER_STATUS', payload: false });
        }
      } catch (error) {
        toast.error('Failed to fetch email transporter status!');
        console.error(error);
        dispatch({ type: 'SET_EMAIL_TRANSPORTER_STATUS', payload: false });
      }
    }
  }, [pathname]);

  const fetchBranding = useCallback(async () => {
    if (
      (typeof state.companyName === 'undefined' || (typeof state.logo === 'undefined' && !state.isLoading)) &&
      !pathname.includes('management/reports')
    ) {
      try {
        const { data } = await getAdminSettings();
        const branding = data?.find(({ section }) => section === SettingsSection.branding);

        if ((branding?.data as BrandingType)?.companyName) {
          dispatch({ type: 'SET_COMPANY_NAME', payload: (branding?.data as BrandingType)?.companyName });
          dispatch({ type: 'SET_LOGO', payload: (branding?.data as BrandingType)?.logo ?? logo });
        }
      } catch (error) {
        toast.error('Failed to fetch admin settings!');
        console.error(error);
      }
    }
  }, [pathname, state.companyName, state.isLoading, state.logo]);

  const checkStatuses = useCallback(() => {
    checkIBGatewayConnectionStatus();
    checkTradingModuleConnectionStatus();
    checkTradeVisionConnectionStatus();
    checkEmailTransporterStatus();
    checkBrokerConnectionStatus();
  }, [
    checkIBGatewayConnectionStatus,
    checkTradingModuleConnectionStatus,
    checkTradeVisionConnectionStatus,
    checkEmailTransporterStatus,
    checkBrokerConnectionStatus,
  ]);

  useEffect(() => {
    if (!window?.location.href.includes('reports')) {
      checkStatuses();

      const interval = setInterval(() => {
        checkStatuses();
      }, 30000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [checkStatuses]);

  useEffect(() => {
    fetchBranding();
  }, [fetchBranding]);

  return <AppContext.Provider value={{ ...state, dispatch }}>{children}</AppContext.Provider>;
};

/**
 * useAppContext hook.
 * @description The hook for getting AppContext data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useAppContext = () => useContext(AppContext);

export { AppContextProvider, useAppContext };
