import { FC } from 'react';
import { useIndividualPortfolioData } from './individual-portfolio.hook';

/**
 * IndividualPortfolio component
 * @description Content for the Reports IndividualPortfolio flow.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2118-411844&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const IndividualPortfolio: FC = () => {
  const { stages, currentStage } = useIndividualPortfolioData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { IndividualPortfolio };
