import { FC } from 'react';
import { StepperProps } from './stepper-tab.types';
import { Typography } from '../../../../../../components';
import classNames from 'classnames';
import styles from './stepper-tab.module.scss';
import { v4 } from 'uuid';

/**
 * Exclusively StepperTab component for Tab
 * @description StepperTab tab
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { TabProps } props - TabProps defined in the '../tab-props.types.ts'
 */
const StepperTab: FC<StepperProps> = ({ label, isActive = false, isPrevious = false, onStepChange }) => {
  const id = v4();

  return (
    <div
      className={classNames(styles.tab, isActive && styles.active, isPrevious && styles.prev)}
      key={id}
      tabIndex={0}
      role={'button'}
      onKeyDown={() => isPrevious && onStepChange()}
      onClick={() => isPrevious && onStepChange()}
    >
      <Typography.Text variant={'bodyText2'} className={styles.label}>
        {label}
      </Typography.Text>
      <div className={styles.bottomLine} />
    </div>
  );
};

export { StepperTab };
