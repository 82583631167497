import { useCallback, useEffect, useRef } from 'react';

import { MonthlyCalendarProps } from './monthly-calendar.types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 useMonthlyCalendarData
 * @description Gets useMonthlyCalendarModal component data from store
 *
 * @author Rostyslav Nahornyi
 * @category Hooks
 */
const useMonthlyCalendarData = ({ singleItemSelect, selectedMonths, setSelectedMonths }: MonthlyCalendarProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'common.months' });

  const lastYearRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (tabRef.current) {
      tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  const resetDates = useCallback(() => {
    setSelectedMonths({});
  }, [setSelectedMonths]);

  const selectDate = useCallback(
    (selectedDate: Date) => {
      if (singleItemSelect) {
        setSelectedMonths({
          a: selectedDate,
          b: selectedDate,
        });
      } else if (!selectedMonths.a && !selectedMonths.b) {
        setSelectedMonths({
          a: selectedDate,
          b: undefined,
        });
      } else if (selectedMonths.a && !selectedMonths.b) {
        const isSame = dayjs(selectedMonths.a).isSame(selectedDate, 'day');
        const isSelectedAfter = dayjs(selectedDate).isAfter(selectedMonths.a);

        if (isSame) return resetDates();

        return setSelectedMonths({
          a: isSelectedAfter ? selectedMonths.a : selectedDate,
          b: isSelectedAfter ? selectedDate : selectedMonths.a,
        });
      } else if (selectedMonths.a && selectedMonths.b) {
        setSelectedMonths({ a: selectedDate, b: undefined });
      }
    },
    [singleItemSelect, selectedMonths, setSelectedMonths, resetDates],
  );

  return { t, lastYearRef, tabRef, selectDate };
};

export { useMonthlyCalendarData };
