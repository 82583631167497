import React, { FC } from 'react';

import BlackSmallPreloader from '../../../assets/icons/preloader-small.png';
import { PreloaderProps } from './preloader.types';
import WhiteSmallPreloader from '../../../assets/icons/preloader-small-white.png';
import YellowSmallPreloader from '../../../assets/icons/yellow-small-preloader.png';
import styles from './preloader.module.scss';

/**
 * Preloader component
 * @description Preloader component which indicates data loading/processing
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev, Sergii Goncharuk
 * @category Components
 */
const Preloader: FC<PreloaderProps> = ({ isActive, type = 'overlay', color = 'black' }) => {
  const getSrcSmallPreloader: Record<typeof color, string> = {
    black: BlackSmallPreloader,
    yellow: YellowSmallPreloader,
    white: WhiteSmallPreloader,
  };

  return isActive ? (
    <>
      {type === 'overlay' ? (
        <div className={styles.overlay}>
          <div className={styles.ldsSpinner}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      ) : (
        <img src={getSrcSmallPreloader[color]} alt={'preloader'} className={styles.small} />
      )}
    </>
  ) : null;
};

export { Preloader };
