import { Ref, forwardRef } from 'react';

import classNames from 'classnames';
import styles from './iconed-button.module.scss';
import { IconedButtonProps } from './iconed-button.types';

/**
 * IconedButton component
 * @description IconedButton
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { IconedButtonProps } props - IconedButtonProps defined in the './button.types.ts'
 */
const IconedButton = forwardRef<HTMLButtonElement, IconedButtonProps>(
  (
    { size, variant, icon, selected, className, style, type = 'button', disabled, ignoreSVGColor, onClick },
    ref: Ref<HTMLButtonElement>,
  ) => (
    <button
      className={classNames(
        styles.button,
        styles[size],
        size !== 'floating' && styles[variant],
        selected && styles.active,
        disabled && styles.disabled,
        ignoreSVGColor && styles.ignoreSVGColor,
        className,
      )}
      type={type}
      disabled={disabled}
      style={style}
      tabIndex={0}
      aria-label={'button'}
      ref={ref}
      onClick={onClick}
    >
      {<div className={styles.icon}>{icon}</div>}
    </button>
  ),
);

IconedButton.displayName = 'IconedButton';

export { IconedButton };
