import React, { DragEvent, FC, useRef } from 'react';

import { KanbanCardProps } from './card.types';
import classnames from 'classnames';
import styles from './card.module.scss';

/**
 * KanbanCard component
 *
 * @description KanbanCard for KanbanColumn component
 * @author Oleksii Medvediev
 * @param { KanbanCardProps } props - KanbanCardProps defined in the './card.types.ts'
 * @category Components
 */
const KanbanCard: FC<KanbanCardProps> = ({
  content,
  id,
  dragoverCardAndPosition,
  isInTargetColumn,
  onDragOverTheCard,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const ghostRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      role={'button'}
      tabIndex={0}
      draggable
      className={classnames(
        styles.wrapper,
        dragoverCardAndPosition?.id === id && isInTargetColumn && styles[dragoverCardAndPosition.position],
      )}
      onDragStart={(event: DragEvent) => {
        event.dataTransfer.setData('name', id);

        const dragElement = event.currentTarget;

        const ghostElement = dragElement.cloneNode(true) as HTMLElement;
        (ghostElement as Element).classList.add(styles.dragging);
        // (ghostElement as Element).setAttribute(
        //   'style',
        //   `max-width: ${(dragElement.parentNode as Element).getBoundingClientRect().width}px`,
        // );
        if (dragElement.parentNode && dragElement.parentNode instanceof Element) {
          const parentWidth = dragElement.parentNode.getBoundingClientRect().width;
          ghostElement.style.maxWidth = `${parentWidth}px`;
        }
        // end ========================
        document.body.appendChild(ghostElement);

        const nodeRect = dragElement.getBoundingClientRect();
        event.dataTransfer.setDragImage(
          ghostElement as Element,
          event.clientX - nodeRect.left,
          event.clientY - nodeRect.top,
        );

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ghostRef.current = ghostElement;
      }}
      // onDragEnd={() => {
      //   ref.current?.removeAttribute('style');
      //   ghostRef.current && ghostRef.current.remove();
      // }}
      // onDrag={() => {
      //   ref.current?.setAttribute('style', `opacity: 0; width: 0px; height: 0px;`);
      // }}
      onDrag={() => {
        ref.current?.classList.add('hidden');
      }}
      onDragEnd={() => {
        ref.current?.classList.remove('hidden');
        ghostRef.current && ghostRef.current.remove();
      }}
      onDragOver={(event) => {
        onDragOverTheCard(
          id,
          event.clientY >=
            (ref.current?.getBoundingClientRect().top ?? 0) + (ref.current?.getBoundingClientRect().height ?? 0) / 2
            ? 'bottom'
            : 'top',
        );
      }}
    >
      {content}
    </div>
  );
};

export { KanbanCard };
