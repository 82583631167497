// export type AdminInvoicePdf = BlobPart;

export enum AdminInvoicesAction {
  // download = 'download',
  cancel = 'cancel',
}

export type AdminInvoicesDto = {
  readonly id: string;
  readonly currentSubscription?: string;
};

export type AdminSubscriptionsResponse = ReadonlyArray<{
  readonly Currency: string;
  readonly DailyCost: number;
  readonly AnnualCost: number;
  readonly Recomendation: string;
  readonly MinimunCapital: number;
  readonly SubscriptionID: string;
  readonly SubscriptionName: string;
}>;

export type GetSupportInfoResponse = {
  readonly Mail: string;
  readonly Phone: string;
  readonly Telegram: string;
  readonly WhatsApp: string;
};
