import React, { FC, PropsWithChildren } from 'react';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

/**
 * Localization.
 * @description Localization wrapper component for the App content.
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { PropsWithChildren } children
 */
const Localization: FC<PropsWithChildren> = ({ children }) => (
  <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
);
export { Localization };
