import {
  ManagerGetAccountPortfolioSnapshotResponse,
  UserRole,
  getAccountPortfolioSnapshot,
  setAccountPortfolios,
  setManagerParticipantsPortfolioTransfer,
} from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { TotalTransferProps } from './total-transfer.types';
import { mapAccountPortfoliosSnapshotToData } from '../../../../../../utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

/**
 * useTotalTransferData hook
 * @description The hook which processes ClosePortfolio flow TotalTransfer stage data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useTotalTransferData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { transferBetweenPortfoliosTotalTransfer },
    dispatch,
  } = useModalsContext();
  const { user, dispatch: appDispatch } = useAppContext();
  const navigate = useNavigate();

  const [accountPortfolioSnapshot, setAccountPortfolioSnapshot] =
    useState<ManagerGetAccountPortfolioSnapshotResponse>();

  const flowData: TotalTransferProps | undefined = useMemo(
    () => transferBetweenPortfoliosTotalTransfer,
    [transferBetweenPortfoliosTotalTransfer],
  );

  const fetchAccountSnapshot = useCallback(async () => {
    if (!accountPortfolioSnapshot && transferBetweenPortfoliosTotalTransfer) {
      const { data } = await getAccountPortfolioSnapshot({
        acctId: transferBetweenPortfoliosTotalTransfer.portfolio.PortfolioOnAccountID,
      });

      const portfolioData = data?.find(
        ({ PortfolioID }) => PortfolioID === transferBetweenPortfoliosTotalTransfer.portfolio.PortfolioID,
      );

      portfolioData && setAccountPortfolioSnapshot(portfolioData);
    }
  }, [accountPortfolioSnapshot, transferBetweenPortfoliosTotalTransfer]);

  useEffect(() => {
    fetchAccountSnapshot();
  }, [fetchAccountSnapshot]);

  const handleCloseModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.transferBetweenPortfoliosTotalTransfer });
  }, [dispatch]);

  const handleSubmitTotalTransfer = useCallback(async () => {
    if (user && flowData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { message: message1 } = await setManagerParticipantsPortfolioTransfer({
          sumTransfer: +(flowData.sumTransfer ?? '0')?.toString()?.replaceAll(' ', ''),
          roleLoginEmail: user.email,
          role: UserRole.manager,
          acctId: flowData.portfolio.PortfolioOnAccountID,
          fromPortfolioId: flowData.portfolio.PortfolioID,
          toPortfolioId: flowData.toPortfolioId,
        });
        const { message: message2 } = await setAccountPortfolios({
          role: user.isLoggedInRole,
          roleLoginEmail: user.email,
          portfoliosDoc: {
            AccountID: flowData.portfolio.PortfolioOnAccountID,
            ClientEmailOwner: flowData.accountEmailOwner,
            ReOpenPortfolios: [],
            OpenPortfolios: [],
            ClosePortfolios: [
              {
                PortfolioID: flowData.portfolio.PortfolioID,
                PortfolioMember: flowData.portfolio.PortfolioMember,
                PortfolioOwner: flowData.portfolio.PortfolioOwner,
              },
            ],
            ChangePortfolios: [],
          },
        });

        if (message1 === 'ok' && message2 === 'ok') {
          flowData.onRefreshParticipants();

          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              id: ModalIds.transferBetweenPortfoliosSuccess,
              data: {
                transferBetweenPortfoliosSuccess: {
                  onSubmitSuccess: () => {
                    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.transferBetweenPortfoliosSuccess });
                    navigate(
                      `/management/information/account/information?accountId=${flowData?.portfolio.PortfolioOnAccountID}`,
                    );
                  },
                },
              },
            },
          });
        }
      } catch (error) {
        toast.error(
          'Failed to process set manager participants portfolio transfer and set account portfolios requests!',
        );
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      handleCloseModal();
    }
  }, [appDispatch, dispatch, flowData, handleCloseModal, navigate, user]);

  const snapShotData = useMemo(
    () => accountPortfolioSnapshot && mapAccountPortfoliosSnapshotToData([accountPortfolioSnapshot]),
    [accountPortfolioSnapshot],
  );

  return {
    t,
    flowData,
    snapShotData,
    handleSubmitTotalTransfer,
    handleCloseModal,
  };
};

export { useTotalTransferData };
