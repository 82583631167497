enum Priority {
  high = 'high',
  low = 'low',
  middle = 'middle',
}

/**
 * TableIndicatorProps
 * @description Props for the TableIndicator component.
 *
 * @author Rostyslav Nahornyi
 */
type TableIndicatorProps = {
  readonly priority: Priority;
};

export type { TableIndicatorProps };
export { Priority };
