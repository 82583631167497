import { Button, Modal, Stepper } from '../../../../..';
import { Dropdown, Input, Typography } from '../../../../../../../../../components';
import { Form as FormComponent, Formik } from 'formik';
import React, { FC } from 'react';

import { ReactComponent as ChevronIconDown } from '../../../../../../../assets/icons/chevron-down.svg';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import styles from './form.module.scss';
import { useFormData } from './form.hook';

/**
 * Form component
 * @description Content for the PaymentSettings flow Form stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-152691&mode=dev
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 */
const Form: FC = () => {
  const { t, validationSchema, paymentSettingsInitialValues, flowData, handleSubmitForm } = useFormData();

  return (
    <Modal
      modalId={ModalIds.paymentSettingsForm}
      title={t('modals.paymentSettings.form.title', {
        fullName: flowData?.clientNameOwner,
        accountId: flowData?.accountId,
      })}
    >
      <div className={styles.wrapper}>
        {flowData?.stepperProps && <Stepper {...flowData.stepperProps} className={styles.stepper} />}
        <Typography.Text variant={'bodyText5'}>{t('modals.paymentSettings.form.description')}</Typography.Text>

        {paymentSettingsInitialValues ? (
          <Formik
            initialValues={paymentSettingsInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
            validateOnMount
          >
            {({ values, errors, touched, setFieldTouched, setFieldValue }) => (
              <FormComponent className={styles.form}>
                <Typography.Text variant={'bodyText2'}>{t('modals.paymentSettings.form.formTitle')}</Typography.Text>
                <Input
                  id={'ManagementFee'}
                  label={t('modals.paymentSettings.form.labels.0')}
                  type={'text'}
                  value={values.ManagementFee}
                  onChange={(managementFee) => setFieldValue('ManagementFee', managementFee)}
                  onBlur={() => setFieldTouched('ManagementFee', true)}
                  error={errors.ManagementFee && touched.ManagementFee ? errors.ManagementFee : undefined}
                />
                <Input
                  id={'ManagementFrequency'}
                  label={t('modals.paymentSettings.form.labels.1')}
                  type={'text'}
                  onChange={() => null}
                  value={t(
                    `modals.paymentSettings.form.inputs.periodManagementFee.${
                      values.ManagementFrequency || paymentSettingsInitialValues.ManagementFrequency
                    }.label`,
                  )}
                  readOnly
                  tailingIcon={
                    <Dropdown
                      forcePositionLeft
                      button={<ChevronIconDown />}
                      options={[
                        {
                          id: t('modals.paymentSettings.form.inputs.periodManagementFee.daily.id'),
                          value: t('modals.paymentSettings.form.inputs.periodManagementFee.daily.label'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.periodManagementFee.weekly.id'),
                          value: t('modals.paymentSettings.form.inputs.periodManagementFee.weekly.label'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.periodManagementFee.monthly.id'),
                          value: t('modals.paymentSettings.form.inputs.periodManagementFee.monthly.label'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.periodManagementFee.quarterly.id'),
                          value: t('modals.paymentSettings.form.inputs.periodManagementFee.quarterly.label'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.periodManagementFee.semi-annual.id'),
                          value: t('modals.paymentSettings.form.inputs.periodManagementFee.semi-annual.label'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.periodManagementFee.annual.id'),
                          value: t('modals.paymentSettings.form.inputs.periodManagementFee.annual.label'),
                        },
                      ]}
                      onChange={(value) => {
                        setFieldValue('ManagementFrequency', value);
                      }}
                    />
                  }
                  onBlur={() => setTimeout(() => setFieldTouched('ManagementFrequency', true))}
                  error={
                    errors?.ManagementFrequency && touched.ManagementFrequency ? errors.ManagementFrequency : undefined
                  }
                />
                <Input
                  id={'PerformanceFee'}
                  label={t('modals.paymentSettings.form.labels.2')}
                  type={'text'}
                  value={values.PerformanceFee}
                  onChange={(performanceFee) => setFieldValue('PerformanceFee', performanceFee)}
                  onBlur={() => setFieldTouched('PerformanceFee', true)}
                  error={errors.PerformanceFee && touched.PerformanceFee ? errors.PerformanceFee : undefined}
                />
                <Input
                  id={'PerformanceFrequency'}
                  label={t('modals.paymentSettings.form.labels.3')}
                  type={'text'}
                  onChange={() => null}
                  value={t(
                    `modals.paymentSettings.form.inputs.periodPerfomanceFee.${
                      values.PerformanceFrequency || paymentSettingsInitialValues.PerformanceFrequency
                    }.label`,
                  )}
                  readOnly
                  tailingIcon={
                    <Dropdown
                      forcePositionLeft
                      button={<ChevronIconDown />}
                      options={[
                        {
                          id: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.daily.id'),
                          value: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.daily.label'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.weekly.id'),
                          value: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.weekly.label'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.monthly.id'),
                          value: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.monthly.label'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.quarterly.id'),
                          value: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.quarterly.label'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.semi-annual.id'),
                          value: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.semi-annual.label'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.annual.id'),
                          value: t('modals.paymentSettings.form.inputs.periodPerfomanceFee.annual.label'),
                        },
                      ]}
                      onChange={(value) => {
                        setFieldValue('PerformanceFrequency', value);
                      }}
                    />
                  }
                  onBlur={() => setTimeout(() => setFieldTouched('PerformanceFrequency', true))}
                  error={
                    errors?.PerformanceFrequency && touched.PerformanceFrequency
                      ? errors.PerformanceFrequency
                      : undefined
                  }
                />
                <Input
                  id={'HighWaterMark'}
                  label={t('modals.paymentSettings.form.labels.4')}
                  type={'text'}
                  onChange={() => null}
                  value={t(
                    `modals.paymentSettings.form.inputs.highWaterMark.${values.HighWaterMark === 0 ? 'no' : 'yes'}`,
                  )}
                  readOnly
                  tailingIcon={
                    <Dropdown
                      forcePositionLeft
                      button={<ChevronIconDown />}
                      options={[
                        {
                          id: t('modals.paymentSettings.form.inputs.highWaterMark.no'),
                          value: t('modals.paymentSettings.form.inputs.highWaterMark.no'),
                        },
                        {
                          id: t('modals.paymentSettings.form.inputs.highWaterMark.yes'),
                          value: t('modals.paymentSettings.form.inputs.highWaterMark.yes'),
                        },
                      ]}
                      onChange={(value) => {
                        const formattedValue =
                          value === t('modals.paymentSettings.form.inputs.highWaterMark.no') ? 0 : 1;
                        setFieldValue('HighWaterMark', formattedValue);
                      }}
                    />
                  }
                  onBlur={() => setTimeout(() => setFieldTouched('highWaterMark', true))}
                  error={errors?.HighWaterMark && touched.HighWaterMark ? errors.HighWaterMark : undefined}
                />
                <Input
                  id={'Retrospective'}
                  label={t('modals.paymentSettings.form.labels.5')}
                  type={'number'}
                  value={values.Retrospective}
                  onChange={(retrospective) => setFieldValue('Retrospective', retrospective)}
                  onBlur={() => setFieldTouched('Retrospective', true)}
                  error={errors.Retrospective && touched.Retrospective ? errors.Retrospective : undefined}
                />
                <div className={styles.controls}>
                  <Button
                    size={'large'}
                    variant={'main'}
                    type={'submit'}
                    disabled={
                      !!Object.values(errors).some((error) => !!error) ||
                      (!!values.HighWaterMark && !values.Retrospective)
                    }
                  >
                    <Typography.Text variant={'bodyText2'}>
                      {t('modals.paymentSettings.form.continueButton')}
                    </Typography.Text>
                  </Button>
                </div>
              </FormComponent>
            )}
          </Formik>
        ) : null}
      </div>
    </Modal>
  );
};

export { Form };
