import { AccountsProvider } from './accounts.context';
import { PropsWithChildren } from 'react';
import React from 'react';
import { SettingsProvider } from './settings.context';

export * from './accounts.context';
export * from './settings.context';

const Contexts: React.FC<PropsWithChildren> = ({ children }) => (
  <SettingsProvider>
    <AccountsProvider>{children}</AccountsProvider>
  </SettingsProvider>
);

export { Contexts };
