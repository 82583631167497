import * as yup from 'yup';

import { UserRole, setAccountWithdraw } from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useMemo, useState } from 'react';

import { FormProps } from './form.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

const initialValues = {
  withdrawAmount: '',
};

/**
 * useFormData hook
 * @description The hook which processes WithdrawAccount flow Form stage data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useFormData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { t: tMonths } = useTranslation('translation', { keyPrefix: 'common.casedMonths' });
  const {
    data: { withdrawAccountForm },
    dispatch,
  } = useModalsContext();
  const { user, dispatch: appDispatch } = useAppContext();

  const [date, setDate] = useState<Date>();
  const [currentStage, setCurrentSage] = useState<'default' | 'calendar'>('default');

  const validationSchema: yup.Schema<typeof initialValues> = yup.object({
    withdrawAmount: yup.string().required(t('modals.withdrawAccount.form.required')),
  });

  const flowData: FormProps | undefined = useMemo(() => withdrawAccountForm, [withdrawAccountForm]);

  const handleCloseModal = useCallback(
    () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.withdrawAccountForm }),
    [dispatch],
  );

  const handleSubmitForm = useCallback(
    async ({ withdrawAmount }: typeof initialValues) => {
      if (user && flowData && date) {
        appDispatch({ type: 'TOGGLE_IS_LOADING' });

        try {
          const { message } = await setAccountWithdraw({
            sumWithdraw: +withdrawAmount.replaceAll(' ', ''),
            roleLoginEmail: user.email,
            role: UserRole.manager,
            acctId: flowData.accountId,
            targetDate: dayjs(date).format('YYYY-MM-DD'),
          });

          if (message === 'ok') {
            flowData.onRefreshParticipants();

            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                id: ModalIds.withdrawAccountSuccess,
                data: {
                  withdrawAccountSuccess: {
                    date: dayjs(date).format('DD/MM/YYYY'),
                    onSubmitSuccess: () => {
                      dispatch({ type: 'HIDE_MODAL', payload: ModalIds.withdrawAccountSuccess });
                    },
                  },
                },
              },
            });
          }
        } catch (error) {
          toast.error('Failed to process set account withdraw request!');
          console.error(error);
        }

        appDispatch({ type: 'TOGGLE_IS_LOADING' });

        handleCloseModal();
      }
    },
    [appDispatch, date, dispatch, flowData, handleCloseModal, user],
  );

  const handleOpenCalendar = useCallback(() => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        id: ModalIds.datePicker,
        data: {
          datePicker: {
            onSubmit: (date) => {
              setDate(date);
              date && setCurrentSage('default');
            },
            onClose: () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.withdrawAccountForm }),
            onBackButtonClick: () => {
              dispatch({ type: 'HIDE_MODAL', payload: ModalIds.datePicker });
              setCurrentSage('default');
            },
            date,
            availableRange: {
              min: dayjs().add(7, 'day').startOf('day').toDate(),
              max: dayjs().add(1, 'month').add(7, 'day').startOf('day').toDate(),
            },
            title: t('modals.withdrawAccount.form.calendarTitle'),
            description: t('modals.withdrawAccount.form.calendarDescription'),
          },
        },
      },
    });
    setCurrentSage('calendar');
  }, [date, dispatch, t]);

  const {
    errors,
    touched,
    isValid,
    values: { withdrawAmount },
    setFieldTouched,
    setFieldValue,
    handleSubmit,
  } = useFormik<typeof initialValues>({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit: handleSubmitForm,
  });

  return {
    t,
    tMonths,
    flowData,
    date,
    currentStage,
    errors,
    touched,
    withdrawAmount,
    isValid,
    handleOpenCalendar,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  };
};

export { useFormData };
