import React, { FC, useState } from 'react';

import { Accordion } from '../accordion';
import { AccordionListProps } from './accordion-list.types';
import styles from './accordion-list.module.scss';
import classNames from 'classnames';

/**
 * Message component
 * @description Accordion which have two parts: header and body, after click on header body will be shown, but another accordion will be not closed, only if click on his header.
 *
 * @author Vitalii Bodnarchuk, Oleksii Medvediev
 * @category Components
 */
const AccordionList: FC<AccordionListProps> = ({ accordionListItems, className }) => {
  const [expandedItem, setExpandedItem] = useState<ReadonlyArray<string | number>>([]);
  return (
    <div className={classNames(styles.accordionList, className)}>
      {accordionListItems.map(({ id, title, body }, idx) => {
        const expanded = expandedItem.includes(id);

        return (
          <Accordion
            className={expanded ? styles.expanded : ''}
            key={id + String(idx)}
            id={id}
            isExpanded={expanded}
            onToggleExpandState={() =>
              setExpandedItem(expanded ? expandedItem.filter((item) => id !== item) : [...expandedItem, id])
            }
            title={title}
            body={body}
          />
        );
      })}
    </div>
  );
};

export { AccordionList };
