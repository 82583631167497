import { useCallback, useMemo } from 'react';

import { SuccessProps } from './success.types';
import { useModalsContext } from '../../../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * useSuccessData hook
 * @description The hook which processes WithdrawAccount flow Success data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useSuccessData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { withdrawAccountSuccess },
  } = useModalsContext();

  const flowData: SuccessProps | undefined = useMemo(() => withdrawAccountSuccess, [withdrawAccountSuccess]);

  const handleSubmitSuccess = useCallback(() => {
    flowData && flowData.onSubmitSuccess();
  }, [flowData]);

  return {
    t,
    flowData,
    handleSubmitSuccess,
  };
};

export { useSuccessData };
