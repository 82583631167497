import { FC } from 'react';
import { useEditData } from './edit.hook';

/**
 * Edit component
 * @description Content for the PortfoliosSettings flow Edit stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-153307&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Edit: FC = () => {
  const { stages, currentStage } = useEditData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { Edit };
