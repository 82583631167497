import React, { PropsWithChildren, createContext, useContext, useState } from 'react';

interface InitialState {
  // expand all tables
  expanded: boolean;
  // chosen entity: portfolio name or account id
  entity: string;
  // chosen date/date range
  date: string;
  //type of entity
  type: 'portfolio' | 'account';
  // is generator modal open
  isGenerateModalOpen: boolean;
  // initial loading screen
  isInitialLoading: boolean;
}

const initialState: InitialState = {
  expanded: false,
  entity: '',
  date: '',
  type: '' as 'account',
  isGenerateModalOpen: false,
  isInitialLoading: true,
};

const Context = createContext<[InitialState, React.Dispatch<React.SetStateAction<InitialState>>]>([
  initialState,
  (state) => state,
]);

const { Provider } = Context;

const SettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const state = useState(initialState);

  return <Provider value={state}>{children}</Provider>;
};

const useSettings = () => {
  return useContext(Context);
};

export { SettingsProvider, useSettings };
