import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReportType, ReportsPeriod } from '../../reports-flow.types';
import { Success, Summary } from './components';
import { getAccountsByEmail, sendReport } from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';

import { IndividualPortfolioProps } from './individual-portfolio.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './components/success/success.types';
import { SummaryProps } from './components/summary/summary.types';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const formatPeriodLabel = (startDate: Date, reportType: ReportType, t: (value: string) => string): string => {
  const dividedStartDate = dayjs(startDate).format('MMMM YYYY').split(' ');
  if (reportType === ReportType.monthly) {
    return `${t(dividedStartDate[0])} ${dividedStartDate[1]}`;
  } else {
    return `${dividedStartDate[1]} ${t('modals.reports.summary.year')}`;
  }
};

/**
 * useIndividualPortfolioData hook
 * @description The hook which processes Reports IndividualPortfolio flow data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useIndividualPortfolioData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { user, dispatch: appDispatch } = useAppContext();
  const {
    data: { reportsIndividualPortfolio },
    dispatch,
  } = useModalsContext();

  const [minDate, setMinDate] = useState<string>();
  const [period, setPeriod] = useState<ReportsPeriod>();

  const flowData: IndividualPortfolioProps | undefined = useMemo(
    () => reportsIndividualPortfolio,
    [reportsIndividualPortfolio],
  );

  const fetchAccountsByEmail = useCallback(async () => {
    if (flowData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const { data } = await getAccountsByEmail({
          emails: [flowData.clientEmailOwner],
        });
        data &&
          setMinDate(
            [...data]
              .filter(({ portfolios }) => portfolios.some(({ id }) => flowData.portfolioId === id))[0]
              ?.portfolios.filter(({ id }) => id === flowData.portfolioId)[0]?.opened,
          );
      } catch (error) {
        toast.error('Failed to fetch accounts by email!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });
    }
  }, [appDispatch, flowData]);

  useEffect(() => {
    if (!minDate) {
      fetchAccountsByEmail();
    }
  }, [fetchAccountsByEmail, minDate]);

  const setStage = useCallback(
    (stage: IndividualPortfolioProps['currentStage']) =>
      flowData &&
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: {
          id: ModalIds.reportsIndividualPortfolio,
          data: {
            [ModalIds.reportsIndividualPortfolio]: { ...flowData, currentStage: stage },
          },
        },
      }),
    [dispatch, flowData],
  );

  const submitHandlers: Record<
    IndividualPortfolioProps['currentStage'],
    SummaryProps['onSubmitStage'] | SuccessProps['onSubmitStage']
  > = useMemo(
    () => ({
      summary: async () => {
        if (user && flowData && period) {
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
          try {
            const { message } = await sendReport({
              accountId: flowData.accountId,
              email: flowData.clientEmailOwner,
              fullName: flowData.clientNameOwner,
              portfolioId: flowData.portfolioId,
              type: 'account',
              reportType: ReportType.monthly,
              start: dayjs(period.startDate).format('YYYY-MM-DD'),
              end: dayjs(period.endDate).format('YYYY-MM-DD'),
            });
            if (message === 'ok' || message.includes('Duplicate entry')) {
              setStage('success');
            }
          } catch (error) {
            toast.error('Failed to process send report request!');
            console.error(error);
          }
          appDispatch({ type: 'TOGGLE_IS_LOADING' });
        }
      },
      success: () => {
        dispatch({ type: 'HIDE_MODAL', payload: ModalIds.reportsIndividualPortfolio });
      },
    }),
    [appDispatch, dispatch, flowData, period, setStage, user],
  );

  const onViewReport = useCallback(() => {
    if (flowData && period && user) {
      const { accountId, clientEmailOwner, portfolioId } = flowData;
      const start = dayjs(period.startDate).format('YYYY-MM-DD');
      const end = dayjs(period.endDate).format('YYYY-MM-DD');

      const url = `/management/reports/${accountId}/${portfolioId}`;
      const params = `?start=${start}&end=${end}&type=${'monthly'}&email=${clientEmailOwner}&lg=${user.preferences.language}`;

      window.open(`${window.location.origin}${url}${params}`, '_blank');
    }
  }, [flowData, period, user]);

  const stages: Record<IndividualPortfolioProps['currentStage'], JSX.Element> | undefined = useMemo(
    () =>
      flowData && minDate
        ? {
            summary: (
              <Summary
                portfolioMember={flowData.portfolioMember}
                minDate={minDate}
                period={period}
                onViewReport={onViewReport}
                onSetPeriod={(value) => setPeriod(value)}
                onSubmitStage={submitHandlers.summary}
              />
            ),
            success: (
              <Success portfolioMember={flowData.portfolioMember} t={t} onSubmitStage={submitHandlers.success} />
            ),
          }
        : undefined,
    [flowData, minDate, onViewReport, period, submitHandlers.success, submitHandlers.summary, t],
  );

  return {
    stages,
    currentStage: useMemo(() => flowData?.currentStage, [flowData?.currentStage]),
  };
};

export { formatPeriodLabel, useIndividualPortfolioData };
