import { Link, useNavigate } from 'react-router-dom';
import React, { FC } from 'react';

import { Button } from '../button';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/angle-left-b.svg';
import { SectionProps } from './section.types';
import { Typography } from '../../../../../components';
import classNames from 'classnames';
import styles from './section.module.scss';

/**
 * Section component
 * @description Exclusively for the Dashboard page as its left-hand content section.
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Section: FC<SectionProps> = ({ className, title, children, isTitleSticky, backButtonRoute }) => {
  const navigate = useNavigate();

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={classNames(styles.title, isTitleSticky && styles.sticky)}>
        {backButtonRoute &&
          (backButtonRoute === 'back' ? (
            <Button type={'button'} variant={'outline'} size={'small'} onClick={() => navigate(-1)}>
              <ChevronLeft />
            </Button>
          ) : (
            <Link to={backButtonRoute}>
              <Button type={'button'} variant={'outline'} size={'small'}>
                <ChevronLeft />
              </Button>
            </Link>
          ))}
        {typeof title === 'string' ? <Typography.Text variant={'h5'}>{title}</Typography.Text> : title}
      </div>
      {children}
    </div>
  );
};

export { Section };
