import { Button, Modal } from '../../../../../../../../components';
import { Radio, Typography } from '../../../../../../../../../../../components';
import React, { FC, useState } from 'react';

import { ReactComponent as CalendarIcon } from '../../../../../../../../../assets/icons/calendar-16.svg';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { ReportType } from '../../../../reports-flow.types';
import { SummaryProps } from './summary.types';
import { formatPeriodLabel } from '../../individual-account.hook';
import styles from './summary.module.scss';
import { useModalsContext } from '../../../../../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * Summary component
 * @description Content for the Reports IndividualAccount flow Summary stage
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2118-411844&mode=dev
 *
 * @author Oleksii Medvediev
 * @param { SummaryProps } props - SummaryProps defined in the './Summary.types.ts'
 * @category Components
 */
const Summary: FC<SummaryProps> = ({
  accountId,
  clientNameOwner,
  selectedReportType,
  minDate,
  period,
  onReportTypeSelect,
  onSetPeriod,
  onSubmitStage,
  onViewReport,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const [currentScreen, setCurrentScreen] = useState<'default' | 'calendar'>('default');
  const { dispatch } = useModalsContext();

  return currentScreen === 'default' ? (
    <Modal modalId={ModalIds.reportsIndividualAccount} title={t('modals.reports.summary.title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>{t('modals.reports.summary.description')}</Typography.Text>
        <div className={styles.period}>
          <Typography.Text variant={'bodyText2'}>{t('modals.reports.summary.period')}</Typography.Text>
          <div className={styles.radios}>
            <div className={styles.row}>
              {Object.values(ReportType).map((item, index) => (
                <Radio
                  checked={selectedReportType === item}
                  id={item}
                  value={item}
                  name={t(`modals.reports.summary.${item}`)}
                  text={t(`modals.reports.summary.${item}`)}
                  onChange={(value) => {
                    onReportTypeSelect(value.currentTarget.value as ReportType);
                    onSetPeriod(undefined);
                  }}
                  key={item + index}
                />
              ))}
            </div>
            <Button
              type={'button'}
              variant={'main'}
              size={'small'}
              leadingIcon={<CalendarIcon />}
              onClick={() => {
                dispatch({
                  type: 'SHOW_MODAL',
                  payload: {
                    id: ModalIds.monthlyCalendar,
                    data: {
                      monthlyCalendar: {
                        onSubmit: (startDate, endDate) => {
                          onSetPeriod({
                            startDate,
                            endDate,
                          });
                          setCurrentScreen('default');
                        },
                        onClose: () => dispatch({ type: 'HIDE_MODAL', payload: ModalIds.reportsIndividualAccount }),
                        onBackButtonClick: () => {
                          dispatch({ type: 'HIDE_MODAL', payload: ModalIds.monthlyCalendar });
                          setCurrentScreen('default');
                        },
                        isAnnual: selectedReportType === ReportType.yearly,
                        periods: { startDate: minDate },
                        singleItemSelect: true,
                      },
                    },
                  },
                });
                setCurrentScreen('calendar');
              }}
            >
              <Typography.Text variant={'bodyText3'}>
                {period
                  ? formatPeriodLabel(new Date(period.startDate), selectedReportType, t)
                  : t('modals.reports.summary.period')}
              </Typography.Text>
            </Button>
          </div>
        </div>
        <div className={styles.reportItems}>
          <Typography.Text variant={'bodyText2'}>{t('modals.reports.summary.objectIndividual')}</Typography.Text>
          <div className={styles.reportItem}>
            <Typography.Text variant={'label2'} className={styles.label}>
              {t('modals.reports.summary.account')}
            </Typography.Text>
            <Typography.Text variant={'h7'}>
              {clientNameOwner}&nbsp;{accountId.toUpperCase()}
            </Typography.Text>
          </div>
        </div>
        <div className={styles.controls}>
          <Button variant={'outline'} size={'large'} onClick={onViewReport} disabled={!period}>
            <Typography.Text variant={'bodyText2'}>{t('modals.reports.summary.viewReport')}</Typography.Text>
          </Button>
          <Button variant={'main'} size={'large'} onClick={onSubmitStage} disabled={!period}>
            <Typography.Text variant={'bodyText2'}>{t('modals.reports.summary.send')}</Typography.Text>
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
};

export { Summary };
