import { FC, ReactElement } from 'react';
import { Priority, StatusIndicatorProps } from './status-indicator.types';

import { ReactComponent as AlarmIcon } from '../../../../assets/icons/alarm-status-priority.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info-status-priority.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success-status-priority.svg';
import styles from './status-indicator.module.scss';

const indicators: Record<Priority, ReactElement> = {
  [Priority.success]: <SuccessIcon className={styles.icon} />,
  [Priority.info]: <InfoIcon className={styles.icon} />,
  [Priority.alarm]: <AlarmIcon className={styles.icon} />,
};

/**
 * PriorityIndicator Component
 *
 * @author Rostyslav Nahornyi
 * @param { StatusIndicatorProps } props - StatusIndicatorProps defined in the './status-indicator.types.ts'
 * @category Components
 */
const StatusIndicator: FC<StatusIndicatorProps> = ({ priority }) => indicators[priority];

export { StatusIndicator };
