import {
  ManagerGetAccountPortfoliosSnapshotResponse,
  ManagerSetAccountEntitiesPayload,
  UserRole,
  getAccountPortfoliosSnapshot,
  setAccountPortfolios,
  setAccountStrategy,
} from '../../../../../../../../../services/api';
import { useAppContext, useModalsContext } from '../../../../../../contexts';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ConfirmationProps } from './confirmation.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { mapAccountPortfoliosSnapshotToData } from '../../../../../../utils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

/**
 * useConfirmationData hook
 * @description The hook which processes CloseAllPositions flow Confirmation stage data
 *
 * @author Oleksii Medvediev
 * @category Hooks
 */
const useConfirmationData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const {
    data: { closeAllPositionsConfirmation },
    dispatch,
  } = useModalsContext();
  const { user, dispatch: appDispatch } = useAppContext();

  const [accountPortfoliosSnapshot, setAccountPortfoliosSnapshot] =
    useState<ManagerGetAccountPortfoliosSnapshotResponse>();

  const flowData: ConfirmationProps | undefined = useMemo(
    () => closeAllPositionsConfirmation,
    [closeAllPositionsConfirmation],
  );

  const fetchAccountSnapshot = useCallback(async () => {
    if (!accountPortfoliosSnapshot && flowData && user) {
      const { data } = await getAccountPortfoliosSnapshot({
        acctId: flowData.account.AccountID,
        role: UserRole.manager,
        roleLoginEmail: user.email,
      });

      data && setAccountPortfoliosSnapshot(data);
    }
  }, [accountPortfoliosSnapshot, flowData, user]);

  useEffect(() => {
    fetchAccountSnapshot();
  }, [fetchAccountSnapshot]);

  const handleCloseModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.closeAllPositionsConfirmation });
  }, [dispatch]);

  const handleSubmitConfirmation = useCallback(async () => {
    if (user && flowData) {
      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      try {
        const strategyDoc: ManagerSetAccountEntitiesPayload['strategyDoc'] = {
          AccountID: flowData.account.AccountID,
          CAGR: 0.0,
          ClientEmailOwner: flowData.account.ClientEmailOwner,
          MaxDrawDown: 0.0,
          StrategyID: 'ef7b5536-907e-11eb-bbfc-960000a43241',
          StrategyName: 'Base',
        };

        const portfoliosDoc: ManagerSetAccountEntitiesPayload['portfoliosDoc'] = {
          AccountID: flowData.account.AccountID,
          ClientEmailOwner: flowData.account.ClientEmailOwner,
          ChangePortfolios: [],
          ClosePortfolios: (flowData.account.Portfolios ?? []).map(
            ({ PortfolioID, PortfolioMember, PortfolioOwner }) => ({
              PortfolioID,
              PortfolioMember,
              PortfolioOwner,
            }),
          ),
          OpenPortfolios: [],
          ReOpenPortfolios: [],
        };

        const { message: message1 } = await setAccountStrategy({
          roleLoginEmail: user.email,
          role: UserRole.manager,
          strategyDoc,
        });

        const { message: message2 } = await setAccountPortfolios({
          role: user.isLoggedInRole,
          roleLoginEmail: user.email,
          portfoliosDoc,
        });

        if (message1 === 'ok' && message2 === 'ok') {
          flowData.onRefreshParticipants();

          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              id: ModalIds.closeAllPositionsSuccess,
              data: {
                closeAllPositionsSuccess: {
                  onSubmitSuccess: () => {
                    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.closeAllPositionsSuccess });
                  },
                },
              },
            },
          });
        }
      } catch (error) {
        toast.error('Failed to process set account strategy and set account portfolios requests!');
        console.error(error);
      }

      appDispatch({ type: 'TOGGLE_IS_LOADING' });

      handleCloseModal();
    }
  }, [appDispatch, dispatch, flowData, handleCloseModal, user]);

  const snapShotData = useMemo(
    () => accountPortfoliosSnapshot && mapAccountPortfoliosSnapshotToData(accountPortfoliosSnapshot),
    [accountPortfoliosSnapshot],
  );

  return {
    t,
    flowData,
    snapShotData,
    handleSubmitConfirmation,
    handleCloseModal,
  };
};

export { useConfirmationData };
