import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useModalsContext } from '../../../../contexts';

/**
 * RequestServiceActionModal hook data
 * @description The hook which processes RequestServiceActionModal data
 *
 * @author Sergii Goncharuk
 */
const useRequestServiceActionModalHook = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  const {
    data: { requestServiceAction },
  } = useModalsContext();

  const modalData = useMemo(() => requestServiceAction, [requestServiceAction]);

  return {
    t,
    modalData,
  };
};

export { useRequestServiceActionModalHook };
