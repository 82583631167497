/**
 * RecommendationPriorityIndicatorProps
 * @description Props for the PriorityIndicator component
 *
 * @author Oleksii Medvediev
 */
type RecommendationPriorityIndicatorProps = {
  readonly priority: Priority;
};

enum Priority {
  high = 'high',
  low = 'low',
  inactive = 'inactive',
  middle = 'middle',
}

export type { RecommendationPriorityIndicatorProps };
export { Priority };
