import { ChangeRole, GiveAccess } from './components';

/**
 * LoginRequest component
 * @description The component containing all modals of LoginReques flow.
 *
 * @author Rostyslav Nahornyi
 * @category Components
 */
const LoginRequest = {
  ChangeRole,
  GiveAccess,
};

export { LoginRequest };
