import { Action, AuthState } from './auth.types';

/**
 * @description InitialState for the AuthContext
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 */
const initialState: AuthState = {};

/**
 * @description Reducer function for the useReducer hook in the AuthContextProvider component.
 *
 * @author Oleksii Medvediev
 * @param {AuthState} state - State defined in the './auth.types.ts'
 * @param {Action} action - Action defined in the './auth.types.ts'
 * @returns updated AuthContextProvider state.
 */
const authReducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'SET_ERROR_MESSAGE': {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }

    case 'SET_ROOT_USER_PRE_REGISTER_TOKEN':
      return {
        ...state,
        rootUserPreRegisterToken: action.payload,
      };

    case 'SET_IS_ROOT_USER_REGISTERED': {
      return {
        ...state,
        isRootUserRegistered: action.payload,
      };
    }

    case 'SET_TRADEVISION_CONNECTION_STATUS':
      return {
        ...state,
        establishedConnections: {
          ...(state.establishedConnections ?? {}),
          investudio: action.payload,
        },
      };

    case 'SET_LOGIN_CANDIDATE':
      return {
        ...state,
        roleMigrations: {
          ...(state.roleMigrations ?? {}),
          loginCandidate: action.payload,
        },
      };

    case 'SET_RESERVED_ROLES':
      return {
        ...state,
        roleMigrations: {
          ...(state.roleMigrations ?? {}),
          reservedRoles: action.payload,
        },
      };

    default:
      return state;
  }
};

export { authReducer, initialState };
