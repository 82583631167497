import React, { FC } from 'react';

import App from './app/app';
import { AppContextProvider } from './app/context/app';
import { Drawer } from './app/components';
import { Modal } from './app/components/modal';
import { ModalContextProvider } from './app/context';

const ClientOfficeApp: FC = () => (
  <AppContextProvider>
    <ModalContextProvider>
      <App />
      <Modal />
      <Drawer />
    </ModalContextProvider>
  </AppContextProvider>
);

export { ClientOfficeApp };
