import React, { FC } from 'react';

import { SliderProps } from './slider.types';
import classNames from 'classnames';
import styles from './slider.module.scss';

/**
 * Slider component
 * @description Slider dots
 *
 * @author Rostyslav Nahornyi
 * @param { SliderProps } - SliderProps defined in the './slider.types.ts'
 */
const Slider: FC<SliderProps> = ({ count, activeItem, className }) => (
  <div className={classNames(styles.slider, className)}>
    {Array.from({ length: count }, (_, idx) => (
      <div key={idx} className={classNames(styles.item, idx === activeItem && styles.active)} />
    ))}
  </div>
);

export { Slider };
