import { FC } from 'react';
import { PersonalizationItemProps as InvestorPersonalizationProps } from './components/personalization-item/personalization-item.types';
import { SecurityItemProps as InvestorSecurityItemProps } from './components/security-item/security-item.types';
import styles from './menu-list.module.scss';
import { MenuListProps } from './menu-list.types';
import { v4 } from 'uuid';

import { PersonalizationItem, SecurityItem } from './components';

const renderItemType = (
  variant: MenuListProps['variant'],
  itemProps: InvestorPersonalizationProps | InvestorSecurityItemProps,
) => {
  switch (variant) {
    case 'personalization':
      return <PersonalizationItem key={v4()} {...(itemProps as InvestorPersonalizationProps)} />;

    case 'security':
      return <SecurityItem key={v4()} {...(itemProps as InvestorSecurityItemProps)} />;
  }
};

const MenuList: FC<MenuListProps> = ({ variant, list }) => {
  return (
    <div className={styles.wrapper}>
      {list?.map((itemData, idx) =>
        renderItemType(variant, { ...itemData, bottomBorder: idx === list.length - 1 ? false : true }),
      )}
    </div>
  );
};

export { MenuList };
