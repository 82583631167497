import { Area, ComposedChart, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import React, { FC, useLayoutEffect, useState } from 'react';

import { ActiveChartProps } from './active-chart.types';
import { Typography } from '../../../../../components';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import styles from './active-chart.module.scss';
import { v4 } from 'uuid';

const checkIfIsNegative = ({
  targetPrice,
  currentPrice,
}: {
  readonly targetPrice?: number;
  readonly currentPrice?: number;
}): boolean => !!targetPrice && !!currentPrice && currentPrice < targetPrice;

/**
 * ActiveChart component
 * @description Displays active price statistics
 *
 * @author Oleksii Medvediev, Goncharuk Sergii
 * @category Components
 * @param { ActiveChartProps } props - ActiveChartProps defined in the './active-chart.types.ts';
 */
const ActiveChart: FC<ActiveChartProps> = ({
  activeType,
  breakpoint,
  info1,
  isGradient = false,
  labelDescription,
  values,
  chartHeight = 50,
  info2,
  gradientStartIndex,
}) => {
  const [chartDotX, setChartDotX] = useState<number>();
  const dotId = v4();

  useLayoutEffect(() => {
    setTimeout(() => {
      const dotElement = document.getElementById(dotId);
      setChartDotX(+(dotElement?.getAttribute('x') ?? 0));
    }, 1);
  }, [dotId]);

  const gradientSeries = [
    {
      name: 'clear',
      data: [...(values ?? [])]
        .splice(0, gradientStartIndex ?? Math.round((values ? values.length : 0) * 0.75))
        .map((item, index) => ({
          count: index.toString(),
          value: item,
        })),
    },
    {
      name: 'gradient',
      data: [...(values ?? [])]
        .splice((gradientStartIndex ?? Math.round((values ? values.length : 0) * 0.75)) - 1, (values?.length ?? 0) - 1)
        .map((item, index) => ({
          count: (index + (gradientStartIndex ?? Math.round((values ? values.length : 0) * 0.75)) - 1).toString(),
          value: item,
        })),
    },
  ];

  const calculateChartTop = () => {
    if (!isGradient) {
      return checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 })
        ? `calc(66% - ${chartHeight}px)`
        : '33%';
    } else {
      return checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 })
        ? '33%'
        : `calc(66% - ${chartHeight}px)`;
    }
  };

  return (
    <div
      className={styles.wrapper}
      style={{
        margin: `${chartHeight - 42 * 0.66}px 0 ${chartHeight - 42 * 0.66}px 0`,
      }}
    >
      {/* CHART */}
      <div
        className={styles.chart}
        style={{
          height: chartHeight,
          top: info1 ? calculateChartTop() : `calc(50% - ${chartHeight / 2}px)`,
        }}
      >
        <ResponsiveContainer width={activeType === 'combo' ? '80%' : '100%'} height={'100%'}>
          <ComposedChart
            height={chartHeight}
            data={
              isGradient
                ? undefined
                : values?.map((value, index) => ({
                    name: value.toString().concat(index.toString()),
                    value,
                    key: value.toString().concat(index.toString()),
                  }))
            }
          >
            {isGradient ? (
              <>
                <defs>
                  <linearGradient id={'positiveGradient'} x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
                    <stop offset={'24.79%'} stopColor={'#30BB70'} stopOpacity={0.2} />
                    <stop offset={'50%'} stopColor={'#30BB70'} stopOpacity={0} />
                    <stop offset={'100%'} stopColor={'rgb(48, 187, 112, 0)'} stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id={'negativeGradient'} x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
                    <stop offset={'24.79%'} stopColor={'#F05C5E'} stopOpacity={0.2} />
                    <stop offset={'50%'} stopColor={'#F05C5E'} stopOpacity={0} />
                    <stop offset={'100%'} stopColor={'rgba(240, 92, 94, 0)'} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <YAxis
                  type={'number'}
                  domain={[
                    (min: number) => Math.min(...(values ?? [])) * 0.9,
                    (max: number) => Math.max(...(values ?? [])) * 1.1,
                  ]}
                  hide
                />
                <XAxis dataKey={'count'} type={'category'} allowDuplicatedCategory={false} hide />
                {gradientSeries.map(({ data, name }) =>
                  name === 'clear' ? (
                    <Area
                      isAnimationActive={false}
                      data={data}
                      name={name}
                      key={name}
                      fillOpacity={0}
                      dataKey={'value'}
                      stroke={'#F6F8FB'}
                      strokeWidth={1}
                      type={'linear'}
                    />
                  ) : (
                    <Area
                      key={name}
                      data={data}
                      name={name}
                      isAnimationActive={false}
                      dataKey={'value'}
                      stroke={
                        checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 }) ? '#e82518' : '#40f493'
                      }
                      strokeWidth={1}
                      fill={`url(#${
                        checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 }) ? 'negative' : 'positive'
                      }Gradient)`}
                      fillOpacity={1}
                      type={'linear'}
                      dot={(props) => {
                        const { cx, cy, index, payload } = props;

                        if (values && payload.value === values[values.length - 1] && index === data.length - 1) {
                          return (
                            <svg
                              key={payload.value.toString() + dotId}
                              id={dotId}
                              x={cx - 3.86}
                              y={cy - 3.86}
                              width={'7'}
                              height={'7'}
                              viewBox={'0 0 7 7'}
                              fill={'none'}
                              xmlns={'http://www.w3.org/2000/svg'}
                            >
                              <ellipse
                                cx={'3.86962'}
                                cy={'3.87695'}
                                rx={'3.12939'}
                                ry={'3'}
                                fill={
                                  checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 })
                                    ? '#e82518'
                                    : '#40f493'
                                }
                              />
                            </svg>
                          );
                        }
                        return <span key={v4() + index} />;
                      }}
                    />
                  ),
                )}
              </>
            ) : (
              <Line
                isAnimationActive={false}
                dataKey={'value'}
                stroke={'#F6F8FB90'}
                strokeWidth={1}
                dot={(props) => {
                  const { cx, cy, payload, index } = props;
                  if (values && payload.value === values[values.length - 1] && index === values.length - 1) {
                    return (
                      <svg
                        key={payload.value.toString() + dotId}
                        id={dotId}
                        x={cx - 3.86}
                        y={cy - 3.86}
                        width={'7'}
                        height={'7'}
                        viewBox={'0 0 7 7'}
                        fill={'none'}
                        xmlns={'http://www.w3.org/2000/svg'}
                      >
                        <ellipse cx={'3.86962'} cy={'3.87695'} rx={'3.12939'} ry={'3'} fill={'white'} />
                      </svg>
                    );
                  }

                  return <span key={v4() + index} />;
                }}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      {/* CONTENT */}
      <div className={styles.content}>
        {/* DESCRIPTIONS */}
        <div
          className={classNames(
            styles.labelDescription,
            (isGradient
              ? activeType !== 'combo'
                ? !checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 })
                : !checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 })
              : activeType !== 'combo'
                ? !checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 })
                : checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 })) && styles.reversed,
          )}
        >
          {info1 && (
            <div className={styles.description}>
              {labelDescription && (
                <>
                  <Typography.Text variant={'label4'}>
                    <span style={{ color: labelDescription.color }}>{capitalize(labelDescription.value)}</span>
                  </Typography.Text>
                  {typeof chartDotX !== 'undefined' &&
                    (activeType === 'combo' ? (
                      <>
                        <svg
                          style={{ left: chartDotX - 4, top: 2 }}
                          className={styles.dotClone}
                          xmlns={'http://www.w3.org/2000/svg'}
                          width={'15'}
                          height={'15'}
                          viewBox={'0 0 15 15'}
                          fill={'none'}
                        >
                          <circle
                            cx={'7.84766'}
                            cy={'7.67773'}
                            r={'7'}
                            fill={
                              !checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 })
                                ? '#40f493'
                                : '#e82518'
                            }
                          />
                          <text x="5" y="12" fill="#f5f5f5" fontSize={12} fontWeight={400}>
                            1
                          </text>
                        </svg>
                        <svg
                          style={{ left: chartDotX + 24, top: 2 }}
                          className={styles.dotClone}
                          xmlns={'http://www.w3.org/2000/svg'}
                          width={'15'}
                          height={'15'}
                          viewBox={'0 0 15 15'}
                          fill={'none'}
                        >
                          <circle
                            cx={'7.84766'}
                            cy={'7.67773'}
                            r={'7'}
                            fill={
                              !checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 })
                                ? '#40f493'
                                : '#e82518'
                            }
                          />
                          <text x="4" y="12" fill="#f5f5f5" fontSize={12} fontWeight={400}>
                            2
                          </text>
                        </svg>
                      </>
                    ) : (
                      <svg
                        style={{ left: chartDotX }}
                        className={styles.dotClone}
                        width={'7'}
                        height={'7'}
                        viewBox={'0 0 7 7'}
                        fill={'none'}
                        xmlns={'http://www.w3.org/2000/svg'}
                      >
                        <ellipse
                          cx={'3.86962'}
                          cy={'1.87695'}
                          rx={'3.12939'}
                          ry={'3'}
                          fill={
                            isGradient
                              ? '#C9CBD7'
                              : !checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 })
                                ? '#40f493'
                                : '#e82518'
                          }
                        />
                      </svg>
                    ))}
                </>
              )}
            </div>
          )}
          {info1 && <div className={styles.description} />}
        </div>

        {/* LABELS */}
        <div
          className={classNames(
            styles.labels,
            checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 }) && styles.reversed,
          )}
        >
          {/* TargetPrice */}
          {typeof info1 !== 'undefined' && (
            <div
              className={classNames(
                styles.label,
                checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 }) && info2 && styles.labelRed,
                !checkIfIsNegative({ currentPrice: breakpoint, targetPrice: info2 }) && info2 && styles.labelGreen,
              )}
            >
              <Typography.Number variant={'label3'} currency value={info1} round={2} />
            </div>
          )}

          {/* LastClose */}
          {typeof info2 !== 'undefined' && (
            <div aria-label={'strike'} className={classNames(styles.label)}>
              <Typography.Number variant={'label3'} currency value={info2} round={2} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { ActiveChart };
