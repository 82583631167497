import React, { FC } from 'react';

import { UsersIndicatorProps } from './users-indicator.types';
import styles from './users-indicator.module.scss';

/**
 * UsersIndicator component
 * @description Users count indicator
 *
 * @author Rostyslav Nahornyi
 * @category Components
 * @param { UsersIndicatorProps } props - UsersIndicatorProps defined in the './messages-indicator.types.ts'
 */
const UsersIndicator: FC<UsersIndicatorProps> = ({ value }) => <div className={styles.wrapper}>{value}</div>;

export { UsersIndicator };
