import { FC } from 'react';
import { Preloader } from '../preloader';
import styles from './fallback-loader.module.scss';

/**
 * FallbackLoader component.
 *
 * @author Rostyslav Nahornyi
 * @category Components
 */
const FallbackLoader: FC = () => (
  <div className={styles.wrapper}>
    <Preloader type={'small'} isActive={true} />
  </div>
);

export { FallbackLoader };
