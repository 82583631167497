import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { CSVLink } from 'react-csv';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Typography } from '../../../../../../../../../components';
import styles from './confirmation.module.scss';
import { useConfirmationData } from './confirmation.hook';

/**
 * Confirmation component
 * @description Content for the ClosePortfolio flow Confirmation stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-161593&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Confirmation: FC = () => {
  const { t, flowData, snapShotData, handleSubmitConfirmation } = useConfirmationData();

  return (
    <Modal modalId={ModalIds.closePortfolioConfirmation} title={t('modals.closePortfolio.confirmation.title')}>
      {flowData && (
        <div className={styles.wrapper}>
          <Typography.Text variant={'bodyText5'} className={styles.label}>
            {t('modals.closePortfolio.confirmation.description1', { portfolioName: flowData.portfolio.PortfolioName })}
            <br />
            <br />
            {t('modals.closePortfolio.confirmation.description2', {
              portfolioCurrentCapital: flowData.portfolio.PortfolioCurrentCapital.toFixed(0).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ' ',
              ),
            })}
            <br />
            <br />
            {t('modals.closePortfolio.confirmation.description3')}
          </Typography.Text>
          <div className={styles.buttons}>
            {flowData && snapShotData && (
              <CSVLink
                headers={snapShotData.headers}
                data={snapShotData.data}
                filename={`${flowData.portfolio.PortfolioOnAccountID}.csv`}
              >
                <Button size={'large'} variant={'outline'}>
                  <Typography.Text variant={'bodyText2'}>
                    {t('modals.closePortfolio.confirmation.controlButtons.report')}
                  </Typography.Text>
                </Button>
              </CSVLink>
            )}
            <Button size={'large'} variant={'main'} onClick={handleSubmitConfirmation}>
              <Typography.Text variant={'bodyText2'}>
                {t('modals.closePortfolio.confirmation.controlButtons.continue')}
              </Typography.Text>
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export { Confirmation };
