import {
  GetAccountsPortfoliosPayload,
  GetAccountsPortfoliosResponse,
  GetCashFlowPayload,
  GetCashFlowResponse,
  GetCashPayload,
  GetCashResponse,
  GetClientPayload,
  GetClientResponse,
  GetClientsPayload,
  GetEmailPayload,
  GetEmailResponse,
  GetFinancialResultPayload,
  GetFinancialResultResponse,
  GetNotificationsPayload,
  GetNotificationsResponse,
  GetPortfolioAllocationPayload,
  GetPortfolioAllocationResponse,
  GetPortfolioAssetsPayload,
  GetPortfolioAssetsResponse,
  GetPortfolioPerformancePayload,
  GetPortfolioPerformanceResponse,
  GetTickerDescriptionPayload,
  GetTickerDescriptionResponse,
  GetTradeEventsPayload,
  GetTradeEventsResponse,
  GetUserPortfolioRatesPayload,
  GetUserPortfolioRatesResponse,
  GetUserPortfoliosAccountsPayload,
  GetUserPortfoliosAccountsResponse,
  RequestCreatePayload,
  UpdateNotificationMessageStatusPayload,
} from './client-office.model';

import { request } from '../../http';

export const getAccountsPortfolios = (params: GetAccountsPortfoliosPayload) =>
  request<{ readonly data?: GetAccountsPortfoliosResponse }>({
    method: 'get',
    url: 'client-office/get-accounts-portfolios',
    params,
  });

export const getPortfolioPerformance = (params: GetPortfolioPerformancePayload) =>
  request<{ readonly data?: GetPortfolioPerformanceResponse }>({
    method: 'get',
    url: 'client-office/get-portfolio-performance',
    params,
  });

export const getPortfolioAssets = (params: GetPortfolioAssetsPayload) =>
  request<{ readonly data?: GetPortfolioAssetsResponse }>({
    method: 'get',
    url: 'client-office/get-portfolio-assets',
    params,
  });

export const getPortfolioAllocation = (params: GetPortfolioAllocationPayload) =>
  request<{ readonly data?: GetPortfolioAllocationResponse }>({
    method: 'get',
    url: 'client-office/get-portfolio-allocation',
    params,
  });

export const getTickerDescription = (params: GetTickerDescriptionPayload) =>
  request<{ readonly data?: GetTickerDescriptionResponse }>({
    method: 'get',
    url: 'client-office/get-ticker-description',
    params,
  });

export const requestCreate = (data: RequestCreatePayload) =>
  request<{ readonly message?: string }>({
    method: 'post',
    url: 'client-office/request-create',
    data,
  });

export const getCashflow = (params: GetCashFlowPayload) =>
  request<{ readonly data?: GetCashFlowResponse }>({
    method: 'get',
    url: 'client-office/get-cash-flow',
    params,
  });

export const getTradeEvents = (params: GetTradeEventsPayload) =>
  request<{ readonly data?: GetTradeEventsResponse }>({
    method: 'get',
    url: 'client-office/get-trade-events',
    params,
  });

export const getFinancialResult = (params: GetFinancialResultPayload) =>
  request<{ readonly data?: GetFinancialResultResponse & { readonly Error?: string } }>({
    method: 'get',
    url: 'client-office/get-financial-result',
    params,
  });

export const getClient = (params: GetClientPayload) =>
  request<{ readonly data?: GetClientResponse }>({
    method: 'get',
    url: 'client-office/get-client',
    params,
  });

export const getClients = (params: GetClientsPayload) =>
  request<{ readonly data?: GetClientsPayload }>({
    method: 'get',
    url: 'client-office/get-clients',
    params,
  });

export const getUserPortfolioAccounts = (params: GetUserPortfoliosAccountsPayload) =>
  request<{ readonly data?: GetUserPortfoliosAccountsResponse }>({
    method: 'get',
    url: 'client-office/get-user-portfolios-accounts',
    params,
  });

export const getUserPortfolioRates = (params: GetUserPortfolioRatesPayload) =>
  request<{ readonly data?: GetUserPortfolioRatesResponse }>({
    method: 'get',
    url: 'client-office/get-user-portfolio-rates',
    params,
  });

export const getEmail = (params: GetEmailPayload) =>
  request<{ readonly data?: GetEmailResponse }>({
    method: 'get',
    url: 'client-office/get-user-portfolios-accounts',
    params,
  });

export const getCash = (params: GetCashPayload) =>
  request<{ readonly data?: GetCashResponse }>({
    method: 'get',
    url: 'client-office/get-user-portfolios-accounts',
    params,
  });

export const getNotifications = ({ clientEmail, lang }: GetNotificationsPayload) =>
  request<{ readonly data?: GetNotificationsResponse }>({
    method: 'get',
    url: `client-office/notifications?clientEmail=${clientEmail}&lang=${lang}`,
  });

export const updateNotificationMessageStatus = (data: UpdateNotificationMessageStatusPayload) =>
  request<{ readonly message?: string }>({
    method: 'patch',
    url: `client-office/notifications`,
    data,
  });
