import { useEffect, useMemo, useState } from 'react';

import { useModalsContext } from '../../../../contexts';

/**
 * VNCModal hook data
 * @description The hook which processes VNCModal data
 *
 * @author Sergii Goncharuk
 */
const useVNCModalHook = () => {
  const {
    data: { vnc },
  } = useModalsContext();

  const modalData = useMemo(() => vnc, [vnc]);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const vncUrl = modalData?.url;
  const vncPassword = modalData?.password;

  useEffect(() => {
    if (!vncUrl?.startsWith('ws://') && !vncUrl?.startsWith('wss://')) return setIsValidUrl(false);

    setIsValidUrl(true);
  }, [vncUrl]);

  return {
    isValidUrl,
    vncUrl,
    vncPassword,
  };
};

export { useVNCModalHook };
