import { SummariesChartData, SummariesChartProps } from './summaries-chart.types';
import { useEffect, useState } from 'react';

import { Frame } from '../../../../../services/api';
import dayjs from 'dayjs';
import { getQuarter } from '../../utils';
import { useTranslation } from 'react-i18next';

/**
 * useSummariesChartHook
 * @description Provides all data and setting for the Summaries chart component.
 *
 * @author Ihor Dubas, Oleksii Medvediev
 * @category Hooks
 */
const useSummariesChartHook = (
  rawData: SummariesChartProps['rawData'],
  frame: SummariesChartProps['frame'],
  isPositive: SummariesChartProps['isPositive'],
) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const [chartData, setChartData] = useState<SummariesChartData[]>([]);
  const [color, setColor] = useState<string>('#30BB70');
  const [profitabilityEnd, setProfitabilityEnd] = useState<{ x: string; y: number }>();

  useEffect(() => {
    const formatXTick = (tick: string) => {
      if (frame === Frame.week || frame === Frame.month) {
        return dayjs(tick).format('DD/MM');
      } else if (frame === Frame.quarter) {
        return `${t(dayjs(tick).format('MMMM')).slice(0, 3)}. ${dayjs(tick).format('YYYY')}`;
      } else if (frame === Frame.year) {
        return t(`dashboard.content.summaries.quarter`, { info: `${getQuarter(tick)} ${dayjs(tick).format('YYYY')}` });
      } else {
        return dayjs(tick).format('YYYY');
      }
    };

    let data: SummariesChartData[] = [];

    const today = dayjs();

    for (let i = 0; i < rawData?.CapitalChart?.length; i++) {
      // Add reference line ticks.
      if (i !== 0)
        data.push({
          name: `${i.toString()}-refline`,
          capital: null,
          profitability: null,
          projCapital: null,
          projProfitability: null,
        });

      // Real data
      if (dayjs(today).isAfter(rawData?.CapitalChart[i]?.Date) || dayjs(today).isSame(rawData?.CapitalChart[i]?.Date)) {
        // Add dot for projection to start from.
        if (dayjs(rawData?.CapitalChart[i - 1]?.Date).isAfter(today)) {
          data.push({
            name: formatXTick(rawData?.CapitalChart[i]?.Date),
            capital: rawData?.CapitalChart[i]?.Capital,
            profitability: rawData?.ProfitabilityChart[i]?.Profitability,
            projCapital: null,
            projProfitability: rawData?.ProfitabilityChart[i]?.Profitability,
          });
        } else {
          // Usual data.
          data.push({
            name: formatXTick(rawData?.CapitalChart[i]?.Date),
            capital: rawData?.CapitalChart[i]?.Capital,
            profitability: rawData?.ProfitabilityChart[i]?.Profitability,
            projCapital: null,
            projProfitability: null,
          });
        }
      } else {
        // Projection data
        data.push({
          name: formatXTick(rawData?.CapitalChart[i]?.Date),
          capital: null,
          profitability: null,
          projCapital: rawData?.CapitalChart[i]?.Capital,
          projProfitability: rawData?.ProfitabilityChart[i]?.Profitability
            ? rawData?.ProfitabilityChart[i]?.Profitability * 100
            : null,
        });
      }
    }

    data = data.reverse();

    // setColor(
    //   rawData?.ProfitabilityChart[rawData?.ProfitabilityChart?.length - 1]?.Profitability <
    //     rawData?.ProfitabilityChart[0]?.Profitability
    //     ? '#30BB70'
    //     : '#e82518',
    // );
    setColor(isPositive ? '#30BB70' : '#e82518');

    setChartData(data);

    setProfitabilityEnd({
      x: rawData?.ProfitabilityChart[0]?.Date,
      y: rawData?.ProfitabilityChart[0]?.Profitability * 100,
    });
    // NEVER DO THAT AGAIN!!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frame, rawData]);

  return { chartData, color, profitabilityEnd, t };
};

export { useSummariesChartHook };
