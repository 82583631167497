import { FC } from 'react';
import { useAddData } from './add.hook';

/**
 * Add component
 * @description Content for the PortfoliosSettings flow Add stage.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-153205&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const Add: FC = () => {
  const { stages, currentStage } = useAddData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { Add };
