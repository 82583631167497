import { Action, AppState } from './app.types';

import { Cache } from 'aws-amplify/utils';
import { CacheQuery } from '../../shared';

/**
 * @description InitialState for the AppContext
 *
 * @author Oleksii Medvediev
 */
const initialState: AppState = {
  user: (await Cache.getItem(CacheQuery.USER)) ?? undefined,
};

/**
 * @description Reducer function for the useReducer hook in the AppContextProvider component.
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @param {AppState} state - State defined in the './app.types.ts'
 * @param {Action} action - Action defined in the './app.types.ts'
 * @returns updated AppContextProvider state.
 */
const appReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'TOGGLE_IS_LOADING': {
      return { ...state, isLoading: !state.isLoading };
    }

    case 'SET_USER': {
      Cache.setItem(CacheQuery.USER, action.payload);
      return { ...state, user: action.payload };
    }

    case 'SET_HEADER_CONTENT': {
      return {
        ...state,
        headerContent: action.payload,
      };
    }

    case 'SET_BRANDING': {
      return {
        ...state,
        branding: action.payload,
      };
    }

    default:
      return state;
  }
};

export { initialState, appReducer };
