import { AssetRouter, DashboardRouter, PortfolioRouter, SettingsRouter } from './pages';
import { Navigate, Route, Routes } from 'react-router';

import { FC } from 'react';
import { useAppContext } from './context';

const ProtectedRoutes: FC = () => {
  const { user } = useAppContext();

  if (!user || !localStorage.getItem('local-auth-co')) {
    // window?.location.replace(`${environment.portals.authFrontend}`);
    window?.location.replace(`/auth`);
  }

  return (
    <Routes>
      <Route path={'dashboard/*'} element={<DashboardRouter />} />
      <Route path={'settings/*'} element={<SettingsRouter />} />
      <Route path={'portfolio/:portfolioId/*'} element={<PortfolioRouter />} />
      <Route path={'asset/*'} element={<AssetRouter />} />
      <Route path={'*'} element={<Navigate to={'dashboard'} />} />
    </Routes>
  );
};
export { ProtectedRoutes };
