import React, { FC } from 'react';

import { Button } from '../../../../../button';
import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { SuccessProps } from './success.types';
import { Typography } from '../../../../../../../../../components';
import styles from './success.module.scss';

/**
 * Success component
 * @description Content for the ChangeStrategy flow Success stage
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2305-154636&mode=dev
 *
 * @author Oleksii Medvediev
 * @param { SuccessProps } props - SuccessProps defined in the './success.types.ts'
 * @category Components
 */
const Success: FC<SuccessProps> = ({ onSubmitStage, t }) => (
  <Modal modalId={ModalIds.changeStrategy} title={t('modals.changeStrategy.success.title')}>
    <div className={styles.wrapper}>
      <Typography.Text variant={'bodyText5'}>{t('modals.changeStrategy.success.text')}</Typography.Text>
      <Button variant={'main'} size={'large'} onClick={onSubmitStage}>
        <Typography.Text variant={'bodyText2'}>{t('modals.changeStrategy.success.report')}</Typography.Text>
      </Button>
    </div>
  </Modal>
);

export { Success };
