import React, { FC } from 'react';

import { NoticeItemProps } from './notice-item.types';
import { Typography } from '../../../../../../../components';
import classNames from 'classnames';
import styles from './notice-item.module.scss';

/**
 * NoticeItem component
 * @description Exclusively for the Notices component as its item
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { NoticeItemProps } props - NoticeItemProps defined in the './notice-item.types.ts'
 */
const NoticeItem: FC<NoticeItemProps> = ({ additionalRows, date, primaryRow, secondaryRow, detail }) => (
  <div className={styles.wrapper}>
    <div className={classNames(styles.dateRow, styles.row)}>
      <Typography.Text variant={'label4'}>
        {new Intl.DateTimeFormat('en-UK', { dateStyle: 'short' }).format(date)}
      </Typography.Text>
      <Typography.Text variant={'label4'}>{detail}</Typography.Text>
    </div>
    <div className={styles.infoRows}>
      <div className={styles.row}>
        <Typography.Text variant={'bodyText2'}>{primaryRow.label}</Typography.Text>
        <Typography.Text variant={'bodyText2'}>
          {typeof primaryRow.value === 'string' && primaryRow.value.includes(',')
            ? primaryRow.value.substring(0, primaryRow.value.indexOf(','))
            : primaryRow.value}
        </Typography.Text>
      </div>
      <div className={classNames(styles.secondaryRow, styles.row)}>
        <Typography.Text variant={'bodyText3'}>{secondaryRow.label}</Typography.Text>
        <Typography.Text variant={'bodyText3'}>
          {typeof secondaryRow.value === 'string' && secondaryRow.value.includes(',')
            ? secondaryRow.value.substring(0, secondaryRow.value.indexOf(','))
            : secondaryRow.value}
        </Typography.Text>
      </div>
      {!!additionalRows?.length &&
        additionalRows.map(({ label, value }, index) => (
          <div className={classNames(styles.additionalRow, styles.row)} key={label.concat(index.toString())}>
            <Typography.Text variant={'bodyText5'}>{label}</Typography.Text>
            <Typography.Text variant={'bodyText5'}>{value}</Typography.Text>
          </div>
        ))}
    </div>
  </div>
);

export { NoticeItem };
