import React, { DragEvent, FC, useState } from 'react';

import { KanbanCard } from '../card';
import { KanbanColumnProps } from './column.types';
import { Typography } from '../../../../../../../components';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import styles from './column.module.scss';

/**
 * KanbanColumn component
 *
 * @description KanbanColumn component exclusively for the Kanban component.
 * @author Oleksii Medvediev
 * @param { KanbanColumnProps } props - KanbanColumnProps defined in the './column.types.ts';
 * @category Components
 */
const KanbanColumn: FC<KanbanColumnProps> = ({
  cards,
  id,
  name,
  dragoverCardAndPosition,
  onCardDrop,
  setDragoverCardIdAndPosition,
}) => {
  const [isTargetColumn, setIsTargetColumn] = useState(false);

  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        [styles.targetColumn]: isTargetColumn,
        [styles.addSkeleton]:
          isTargetColumn &&
          (!dragoverCardAndPosition ||
            (dragoverCardAndPosition?.position !== 'top' &&
              ![...cards].splice(0, cards.length - 1).some(({ id }) => id === dragoverCardAndPosition?.id))),
      })}
      role={'button'}
      tabIndex={0}
      onDragOver={(event: DragEvent) => {
        !isTargetColumn && setIsTargetColumn(true);
        event.preventDefault();
      }}
      onDragLeave={() => {
        setIsTargetColumn(false);
        setDragoverCardIdAndPosition(undefined);
      }}
      onDrop={(event: DragEvent) => {
        onCardDrop(event, id);
        setIsTargetColumn(false);
        setDragoverCardIdAndPosition(undefined);
      }}
    >
      <Typography.Text variant={'h7'}>{capitalize(name)}</Typography.Text>
      <div className={styles.cardsList}>
        {cards?.map(({ columnId, content, id, onDragOverTheCard }, index) => (
          <KanbanCard
            key={index.toString() + id}
            columnId={columnId}
            content={content}
            id={id}
            onDragOverTheCard={onDragOverTheCard}
            dragoverCardAndPosition={dragoverCardAndPosition}
            isInTargetColumn={isTargetColumn}
          />
        ))}
      </div>
    </div>
  );
};

export { KanbanColumn };
