import { ConfirmationStage, RejectStage, StartStage } from './components';
import React, { FC } from 'react';

import { Modal } from '../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { Stage } from './change-role-modal.types';
import { useChangeRole } from './change-role-modal.hook';

/**
 * ChangeRole component
 * @description Exclusively for the LoginRequest components as its component
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Components
 */
const ChangeRole: FC = () => {
  const { t, stage, role, setStage } = useChangeRole();

  const renderStage = {
    [Stage.start]: <StartStage setStage={setStage} />,
    [Stage.confirmation]: role && <ConfirmationStage setStage={setStage} role={role} />,
    [Stage.reject]: <RejectStage />,
  };

  return (
    <Modal modalId={ModalIds.loginRequestChangeRole} title={t(`modals.loginRequest.changeRole.${stage}Stage.title`)}>
      {renderStage[stage]}
    </Modal>
  );
};

export { ChangeRole };
