import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { InteractiveTooltipBehavior, InteractiveTooltipProps } from './interactive-tooltip.types';

import { Portal } from '../../../../../components';
import classNames from 'classnames';
import styles from './interactive-tooltip.module.scss';

/**
 * InteractiveTooltip component
 * @description InteractiveTooltip
 *
 * @author Sergii Goncharuk
 * @category Components
 * @param { interactiveTooltipProps } props - InteractiveTooltipProps defined in the './interactive-tooltip.types.ts'
 */
const InteractiveTooltip: FC<InteractiveTooltipProps> = ({
  items,
  children,
  className,
  behavior = 'hover',
  position = 'right',
}) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const interactiveTooltipRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [coordinates, setCoordinates] = useState<Record<'x' | 'y', number | undefined>>();

  const showInteractiveTooltip = useMemo(() => {
    if (behavior === InteractiveTooltipBehavior.CLICK) {
      return isClicked;
    } else {
      return isHovered;
    }
  }, [behavior, isClicked, isHovered]);

  useEffect(() => {
    if (interactiveTooltipRef?.current?.style) {
      interactiveTooltipRef.current.style.opacity = '0';
    }

    setTimeout(() => {
      if (!interactiveTooltipRef.current || !targetRef.current || !coordinates?.x || !coordinates.y) return;

      interactiveTooltipRef.current.style.opacity = '1';

      const { height: menuHeight, width: menuWidth } = interactiveTooltipRef.current.getBoundingClientRect();
      const { top, left, width, height } = targetRef.current?.getBoundingClientRect();
      const heightAmedment = menuHeight > height ? 0 : (height - menuHeight) / 2;
      const widthAmedment = menuWidth > width ? (menuWidth - width) / 2 : (width - menuWidth) / 2;

      if (position === 'bottom') {
        interactiveTooltipRef.current.style.top = `${top + height + 4}px`;
        interactiveTooltipRef.current.style.left = `${left - widthAmedment}px`;

        return;
      }

      interactiveTooltipRef.current.style.top = `${top + heightAmedment}px`;
      interactiveTooltipRef.current.style.left = `${left + width - 2}px`;
      interactiveTooltipRef.current.style.paddingLeft = `6px`;
    }, 10);
  }, [coordinates, position]);

  const handleMouseEnter = () => {
    setCoordinates({
      x: targetRef.current?.getBoundingClientRect().left,
      y: targetRef.current?.getBoundingClientRect().top,
    });
    setIsHovered(true);

    if (!isClicked) {
      document.addEventListener('click', handleOutsideClick, false);
    } else {
      document.removeEventListener('click', handleOutsideClick, false);
    }
    setIsClicked((prevState) => !prevState);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (!targetRef.current || targetRef.current.contains(event.target as Node)) return;

    setIsClicked(false);
    setIsHovered(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsHovered(true);

    if (!isClicked) {
      document.addEventListener('click', handleOutsideClick, false);
    } else {
      document.removeEventListener('click', handleOutsideClick, false);
    }

    setIsClicked((prevState) => !prevState);
  };

  return (
    <>
      <div className={classNames(styles.wrapper, className)} ref={targetRef}>
        <button
          className={styles.target}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          {children}
        </button>
      </div>
      <Portal domNode="dropdown-root">
        <div
          ref={interactiveTooltipRef}
          className={classNames(styles.interactiveTooltip, `${showInteractiveTooltip ? styles.show : styles.hide}`)}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
          role="button"
          tabIndex={0}
        >
          <div className={styles.innerContainer}>{items?.map((item) => item)}</div>
        </div>
      </Portal>
    </>
  );
};

export { InteractiveTooltip };
