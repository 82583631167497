import { Button, Modal } from '../../../../../../components';
import { Dropdown, Input, Radio, Typography } from '../../../../../../../../../components';
import { Form, Formik } from 'formik';
import React, { ChangeEvent, FC } from 'react';

import { ReactComponent as CalendarIcon } from '../../../../../../../assets/icons/calendar-16.svg';
import { ReactComponent as ChevronIconDown } from '../../../../../../../assets/icons/chevron-down.svg';
import { GenerateReportType } from './generate-report.types';
import { ModalIds } from '../../../../../../contexts/modals/modals.types';
import { ReportType } from '../../reports-flow.types';
import styles from './generate-report.module.scss';
import { useGenerateReportData } from './generate-report.hook';

/**
 * GenerateReport component
 * @description Content for the Reports GenerateReport flow.
 * @link
 *
 * @author Rostylav Nahornyi
 * @category Components
 */
const GenerateReport: FC = () => {
  const {
    t,
    initialValues,
    validationSchema,
    language,
    minDate,
    accountsOptions,
    portfoliosOptions,
    setMinDate,
    formatPeriodLabel,
    closeModal,
    generateReport,
    modalsDispatch,
    setSelectedAccountId,
    setReportObject,
  } = useGenerateReportData();
  return (
    <Modal modalId={ModalIds.reportsGenerateReport} title={t('title')}>
      <div className={styles.wrapper}>
        <Typography.Text variant={'bodyText5'}>{t('description')}</Typography.Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={generateReport}
          validateOnBlur={true}
        >
          {({ setFieldValue, values }) => (
            <Form className={styles.form}>
              <div className={styles.periodWrapper}>
                <Typography.Text variant={'bodyText2'}>{t('period.title')}</Typography.Text>
                <div className={styles.content}>
                  <div className={styles.radiosWrapper}>
                    <Radio
                      id={'monthly'}
                      checked={values.periodType === ReportType.monthly}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        event.currentTarget.checked && setFieldValue('periodType', 'monthly');
                        setFieldValue('selectedDate', undefined);
                        setFieldValue('portfolio', undefined);
                        setFieldValue('account', undefined);
                        setSelectedAccountId(undefined);
                      }}
                      name={t('period.type.monthlyReport')}
                      text={t('period.type.monthlyReport')}
                      value={t('period.type.monthlyReport')}
                    />
                    <Radio
                      id={'yearly'}
                      checked={values.periodType === ReportType.yearly}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        event.currentTarget.checked && setFieldValue('periodType', 'yearly');
                        setFieldValue('reportType', 'account');
                        setReportObject('account');
                        setFieldValue('selectedDate', undefined);
                        setFieldValue('portfolio', undefined);
                        setFieldValue('account', undefined);
                        setSelectedAccountId(undefined);
                      }}
                      name={t('period.type.yearlyReport')}
                      text={t('period.type.yearlyReport')}
                      value={t('period.type.yearlyReport')}
                    />
                  </div>
                  <Button
                    type={'button'}
                    variant={'main'}
                    size={'small'}
                    leadingIcon={<CalendarIcon />}
                    disabled={!values.account}
                    onClick={() => {
                      minDate &&
                        modalsDispatch({
                          type: 'SHOW_MODAL',
                          payload: {
                            id: ModalIds.monthlyCalendar,
                            data: {
                              monthlyCalendar: {
                                onSubmit: (startDate) => setFieldValue('selectedDate', startDate),
                                isAnnual: values.periodType === ReportType.yearly,
                                periods: { startDate: minDate },
                                singleItemSelect: true,
                              },
                            },
                          },
                        });
                    }}
                  >
                    <Typography.Text variant={'bodyText3'}>
                      {values.selectedDate
                        ? formatPeriodLabel(new Date(values.selectedDate), values.periodType, language, t)
                        : t('period.calendarEmpty')}
                    </Typography.Text>
                  </Button>
                </div>
              </div>

              <div className={styles.objectWrapper}>
                <Typography.Text variant={'bodyText2'}>{t('object.title')}</Typography.Text>
                <div className={styles.content}>
                  {values.periodType === ReportType.monthly && (
                    <div className={styles.radiosWrapper}>
                      <Radio
                        id={'portfolio'}
                        checked={values.reportType === 'portfolio'}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (event.currentTarget.checked) {
                            setFieldValue('reportType', 'portfolio');
                            setReportObject('portfolio');
                            setFieldValue('selectedDate', undefined);
                            setFieldValue('portfolio', undefined);
                            setFieldValue('account', undefined);
                            setSelectedAccountId(undefined);
                          }
                        }}
                        name={t('object.type.portfolio')}
                        text={t('object.type.portfolio')}
                        value={t('object.type.portfolio')}
                      />
                      <Radio
                        id={'account'}
                        checked={values.reportType === 'account'}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (event.currentTarget.checked) {
                            setFieldValue('reportType', 'account');
                            setReportObject('account');
                            setFieldValue('selectedDate', undefined);
                            setFieldValue('portfolio', undefined);
                            setFieldValue('account', undefined);
                            setSelectedAccountId(undefined);
                          }
                        }}
                        name={t('object.type.account')}
                        text={t('object.type.account')}
                        value={t('object.type.account')}
                      />
                    </div>
                  )}
                  <Input
                    id={'account'}
                    label={t('object.type.account')}
                    type={'text'}
                    onChange={() => null}
                    readOnly
                    tailingIcon={
                      <Dropdown
                        button={<ChevronIconDown />}
                        options={accountsOptions?.map(({ id, value }) => ({ id, value })) ?? []}
                        onChange={(value) => {
                          if (values.account !== value) {
                            setFieldValue('account', value);
                            setMinDate([...(accountsOptions ?? [])].filter(({ id }) => id === value)[0].openDate);
                            setSelectedAccountId(Array.isArray(value) ? value[0] : value);
                            setFieldValue('selectedDate', undefined);
                            setFieldValue('portfolio', undefined);
                          }
                        }}
                      />
                    }
                    value={values.account ?? initialValues?.account}
                  />

                  {values.reportType === GenerateReportType.portfolio && values.periodType === ReportType.monthly && (
                    <Input
                      id={'portfolio'}
                      label={t('object.type.portfolio')}
                      type={'text'}
                      onChange={() => null}
                      readOnly
                      tailingIcon={
                        <Dropdown
                          button={<ChevronIconDown />}
                          options={
                            portfoliosOptions?.map(({ id, value }) => ({
                              id,
                              value,
                            })) ?? []
                          }
                          onChange={(value) => {
                            setFieldValue('portfolio', value);
                            setMinDate(
                              (prevState) =>
                                portfoliosOptions?.find(({ id }) => id === values.portfolio)?.openDate ?? prevState,
                            );
                          }}
                        />
                      }
                      value={portfoliosOptions?.find(({ id }) => values.portfolio === id)?.value}
                    />
                  )}
                </div>
              </div>
              <div className={styles.controlButtons}>
                <Button className={styles.button} variant={'outline'} size={'large'} onClick={closeModal}>
                  {t('controlButtons.cancel')}
                </Button>
                <Button
                  className={styles.button}
                  disabled={
                    !values.selectedDate || (values.reportType === GenerateReportType.portfolio && !values.portfolio)
                  }
                  variant={'main'}
                  size={'large'}
                  onClick={() => null}
                  type={'submit'}
                >
                  {t('controlButtons.confirm')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export { GenerateReport };
