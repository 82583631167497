import { useCallback, useMemo, useState } from 'react';

import { ModalIds } from '../../../../contexts/modals/modals.types';
import { MonthlyCalendarModalProps } from './monthly-calendar-modal.types';
import dayjs from 'dayjs';
import { useModalsContext } from '../../../../contexts';
import { useTranslation } from 'react-i18next';

/**
 useMonthlyCalendarModalData
 * @description Gets useMonthlyCalendarModal component data from store
 *
 * @author Rostyslav Nahornyi, Oleksii Medvediev
 * @category Hooks
 */
const useMonthlyCalendarModalData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { t: tMonths } = useTranslation('translation', { keyPrefix: 'common.months' });
  const {
    data: { monthlyCalendar },
    dispatch,
  } = useModalsContext();

  const [period, setPeriod] = useState<MonthlyCalendarModalProps['periods']>({ startDate: '', endDate: '' });

  const monthlyCalendarModalData = useMemo(() => monthlyCalendar, [monthlyCalendar]);

  const closeCurrentModal = useCallback(() => {
    dispatch({ type: 'HIDE_MODAL', payload: ModalIds.monthlyCalendar });
  }, [dispatch]);

  const selectDate = useCallback(
    (year: number, monthNumber: number) => {
      const { startDate, endDate } = period;
      const selectedDate = dayjs(`${year}-${monthNumber + 1}-01`).format('YYYY-MM-DD');

      if (monthlyCalendarModalData?.singleItemSelect) {
        setPeriod({
          ...period,
          startDate: selectedDate,
          endDate: dayjs(selectedDate)
            .endOf(monthlyCalendarModalData?.isAnnual ? 'year' : 'month')
            .format('YYYY-MM-DD'),
        });
      } else if (!startDate && !endDate) {
        setPeriod({
          ...period,
          startDate: selectedDate,
        });
      } else if (startDate && !endDate) {
        setPeriod({
          ...period,
          endDate: dayjs(
            new Date(
              new Date(new Date(selectedDate).setMonth(new Date(selectedDate).getMonth() + 1)).setDate(
                new Date(new Date(new Date(selectedDate).setMonth(new Date(selectedDate).getMonth() + 1))).getDate() -
                  1,
              ),
            ),
          ).format('YYYY-MM-DD'),
        });
      } else if (startDate && endDate) {
        setPeriod({ startDate: selectedDate, endDate: '' });
      }
    },
    [monthlyCalendarModalData?.isAnnual, monthlyCalendarModalData?.singleItemSelect, period],
  );

  const removePeriod = () => {
    setPeriod({ startDate: '', endDate: '' });
  };

  const submitPeriod = () => {
    monthlyCalendarModalData?.onSubmit(
      period.startDate,
      period.endDate
        ? period.endDate
        : dayjs(
            new Date(
              new Date(new Date(period.startDate).setMonth(new Date(period.startDate).getMonth() + 1)).setDate(
                new Date(
                  new Date(new Date(period.startDate).setMonth(new Date(period.startDate).getMonth() + 1)),
                ).getDate() - 1,
              ),
            ),
          ).format('YYYY-MM-DD'),
    );
    closeCurrentModal();
  };

  return { t, tMonths, monthlyCalendarModalData, period, selectDate, removePeriod, submitPeriod };
};

export { useMonthlyCalendarModalData };
