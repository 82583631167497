import { FC } from 'react';
import { useCollectivePortfoliosData } from './collective-portfolios.hook';

/**
 * CollectivePortfolios component
 * @description Content for the Reports CollectivePortfolios flow.
 * @link https://www.figma.com/file/16ideRW6XB1s57uA93ppSl/Manager--%7C-File-for-development?type=design&node-id=2118-412405&mode=dev
 *
 * @author Oleksii Medvediev
 * @category Components
 */
const CollectivePortfolios: FC = () => {
  const { stages, currentStage } = useCollectivePortfoliosData();

  return stages && currentStage ? stages[currentStage] : null;
};

export { CollectivePortfolios };
