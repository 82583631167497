import { Add, Delete, Edit, Summary } from './components';

const PortfoliosSettingsFlow = {
  Summary,
  Add,
  Edit,
  Delete,
};

export { PortfoliosSettingsFlow };
