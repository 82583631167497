import React, { FC } from 'react';

import { KanbanColumn } from './components';
import { KanbanProps } from './kanban.types';
import styles from './kanban.module.scss';
import { useKanbanData } from './kanban.hook';

/**
 * Kanban component.
 *
 * @description Kanban for the ManagementOffice app usage
 * @author Oleksii Medvediev
 * @param { KanbanProps } props - KanbanProps defined in the './kanban.types.ts'
 * @category Components
 */
const Kanban: FC<KanbanProps> = ({ areColumnsDraggable = false, minHeight, ...rest }) => {
  const { state, dragoverCardIdAndPosition, onColumnDrop, onCardDrop, setDragoverCardIdAndPosition } =
    useKanbanData(rest);
  return (
    <div
      className={styles.wrapper}
      style={{
        gridTemplateColumns: state ? `repeat(${Object.keys(state).length}, 1fr)` : undefined,
        minHeight,
      }}
    >
      {state &&
        Object.keys(state)?.map((id, index) => (
          <KanbanColumn
            key={index.toString() + id}
            cards={state[id]}
            id={id}
            name={rest.columns.find((item) => id === item.id)?.name ?? id}
            onCardDrop={onCardDrop}
            onDrop={areColumnsDraggable ? onColumnDrop : undefined}
            dragoverCardAndPosition={dragoverCardIdAndPosition}
            setDragoverCardIdAndPosition={setDragoverCardIdAndPosition}
          />
        ))}
    </div>
  );
};

export { Kanban };
