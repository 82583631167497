import { PillTab } from './pill-tab';
import { DatePickerTab } from './date-picker-tab';
import { UnderlineTab } from './underline-tab';
import { StepperTab } from './stepper-tab';

const Tab = {
  Pill: PillTab,
  DatePicker: DatePickerTab,
  Underline: UnderlineTab,
  Stepper: StepperTab,
};

export { Tab };
