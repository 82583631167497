import {
  // AdminInvoicePdf,
  AdminInvoicesAction,
  AdminInvoicesDto,
  AdminSubscriptionsResponse,
  GetSupportInfoResponse,
} from './is-connector.model';

import { request } from '../../http';

export const getAdminSubscriptions = () =>
  request<{ readonly data: { data: AdminSubscriptionsResponse } }>({
    method: 'get',
    url: 'is-connector/subscriptions',
  });

// export const getAdminInvoices = (data: AdminInvoicesDto) =>
//   request<AdminInvoicePdf>({
//     method: 'post',
//     url: 'is-connector/invoices',
//     data: {
//       ...data,
//       action: AdminInvoicesAction.download,
//     },
//     headers: {
//       Accept: 'application/pdf',
//     },
//     responseType: 'blob',
//   });

export const cancelAdminInvoices = (data: AdminInvoicesDto) =>
  request<unknown>({
    method: 'post',
    url: 'is-connector/invoices',
    data: {
      ...data,
      action: AdminInvoicesAction.cancel,
    },
  });

export const setSubscription = (subscriptionId: string) =>
  request<unknown>({
    method: 'post',
    url: `is-connector/subscriptions/${subscriptionId}`,
  });

export const getSupportInfo = () =>
  request<{ readonly data: GetSupportInfoResponse }>({ method: 'get', url: 'is-connector/support' });
