import { FoldItem, MenuItem } from './components';
import React, { FC } from 'react';

import { FoldItemProps } from './components/fold-item/fold-item.types';
import { InteractiveTooltip } from '../tooltip-interactive';
import { SidebarProps } from './sidebar.types';
import { Text } from '../../../../../components/typography/text';
import { ReactComponent as ToLeftIcon } from '../../../assets/icons/arrow-to-left.svg';
import { ReactComponent as ToRightIcon } from '../../../assets/icons/arrow-to-right.svg';
import classNames from 'classnames';
import styles from './sidebar.module.scss';
import { useAppContext } from '../../contexts';
import { useTranslation } from 'react-i18next';

/**
 * Sidebar
 * @description Sidebar component displayed on the left side of the app.
 *
 * @author Oleksii Medvediev
 * @category Components
 * @param { SidebarProps } props - SidebarProps defined in the './sidebar.types.ts'.
 */
const Sidebar: FC<SidebarProps> = ({ sections }) => {
  const { sidebarCollapsed, dispatch } = useAppContext();
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const onCollapseToggleHandler = () => {
    dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' });
  };

  return (
    <aside className={classNames(styles.wrapper, sidebarCollapsed ? styles.collapsed : '')}>
      <div className={styles.menu}>
        {!!sections?.length &&
          sections.map((section, index) => (
            <div className={styles.section} key={index.toString().concat(section?.length.toString())}>
              {!!section?.length &&
                section
                  .filter(({ label, icon }) => typeof label === 'string' && !!icon)
                  .map((item, index) =>
                    (item as FoldItemProps)?.items ? (
                      item.tooltipItems ? (
                        <InteractiveTooltip items={item.tooltipItems}>
                          <FoldItem {...(item as FoldItemProps)} key={item.label.concat(index.toString())} />
                        </InteractiveTooltip>
                      ) : (
                        <FoldItem {...(item as FoldItemProps)} key={item.label.concat(index.toString())} />
                      )
                    ) : item.tooltipItems ? (
                      <InteractiveTooltip items={item.tooltipItems}>
                        <MenuItem {...item} collapsed={sidebarCollapsed} key={item.label.concat(index.toString())} />
                      </InteractiveTooltip>
                    ) : (
                      <MenuItem {...item} collapsed={sidebarCollapsed} key={item.label.concat(index.toString())} />
                    ),
                  )}
            </div>
          ))}
      </div>
      {sidebarCollapsed ? (
        <InteractiveTooltip
          items={[
            <Text variant="label4" key={0}>
              {t('sidebar.expand')}
            </Text>,
          ]}
        >
          <button onClick={onCollapseToggleHandler} className={styles.collapseBtn}>
            <ToRightIcon />
          </button>
        </InteractiveTooltip>
      ) : (
        <button onClick={onCollapseToggleHandler} className={styles.collapseBtn}>
          <ToLeftIcon />
          {
            <Text className={styles.btnText} variant="bodyText3">
              {t('sidebar.collapse')}
            </Text>
          }
        </button>
      )}
    </aside>
  );
};

export { Sidebar };
