/**
 * environment variable.
 * @description Global app environment variables stored in the object.
 */
const environment = {
  api: {
    base:
      process.env.REACT_APP_ENV === 'local'
        ? 'http://localhost:8291/v1'
        : window?.location?.href?.split('/').splice(0, 3)?.join('/')?.concat('/v1'),
  },
  imgPath: process.env['img-path'],
};

export { environment };
