enum CacheQuery {
  USER = 'ISUser',
  MO_USER = 'ISMOUser',
  TWO_FA_TIMER = 'ISTwoFaTimer',
  TWO_FA_RESEND_COUNT = 'ISTwoFaResendCount',
  PASSWORD_RECOVERY_TIMER = 'ISPasswordRecoveryTimer',
  PASSWORD_RECOVERY_RESEND_COUNT = 'ISPasswordRecoveryResendCount',
}

export { CacheQuery };
